import { Col, Empty, Image, Row } from 'antd';
import React from 'react';
import i18n from '../../lib/Language';

const StemBooks = ({ listImg, items, setItems }) => {
  return (
    <>
      <Row gutter={[26, 26]} justify="center">
        {listImg && listImg?.length ? (
          listImg?.slice(0, items)?.map((item, index) => (
            <Col span={8} key={index}>
              <Image width="100%" src={item?.img} />
            </Col>
          ))
        ) : (
          <Row>
            <Col span={24}>
              <Empty />
            </Col>
          </Row>
        )}
      </Row>
      {listImg?.length > items && (
        <div class="load-more">
          <button class="btn btn-green" onClick={() => setItems(items + 12)}>
            <span>{i18n.t('general.button.seeMore')}</span>
          </button>
        </div>
      )}
    </>
  );
};

export default StemBooks;
