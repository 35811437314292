import { Col, Row, Space } from 'antd';
import React from 'react';
import {
  fll_logo,
  Fll_home,
  Discover_4_6,
  Explore_6_10,
  Challenge_9_16,
} from '../../../../assets/images';
import { Link } from 'react-router-dom';
import FLLCategories from '../../../../components/Competition/FLLCategories';

const FLLIntroduction = () => {
  return (
    <Space direction="vertical" style={{ width: '100%' }} className="wro">
      <div className="slider-banner">
        <div className="slider-item">
          <div
            className="slider-1"
            style={{
              backgroundImage: `url(${Fll_home})`,
            }}
          >
            <div class="slider-caption">
              <div class="container"></div>
            </div>
          </div>
        </div>
      </div>

      <FLLCategories />
      <div className="section section-padding">
        <div className="container">
          <div className="group-title-index">
            <h2 className="center-title">Giới thiệu FLL</h2>
            <p className="text-lg">Sân chơi STEM trí tuệ cho học sinh - giao lưu thế giới</p>
            <div className="bottom-title">
              <i className="bottom-icon icon-icon-04"></i>
            </div>
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8}>
              <img src={fll_logo} alt="" className="img-responsive" width={'100%'} />
            </Col>
            <Col xs={24} sm={16}>
              <div className="post-content">
                <p>
                FIRST® LEGO® League <sup>®</sup> là sân chơi khoa học, công nghệ, kỹ thuật và toán học (do FIRST & LEGO kết hợp thực hiện).
                </p>
                <p class="p1">

                FIRST® LEGO® League là sân chơi lớn về khoa học của thế giới cho trẻ em lứa tuổi 4-16* thông qua học tập thực hành thú vị, hấp dẫn. 
                Những bạn tham gia vào sẽ cùng các kỹ thuật để giải quyết các vấn đề trong xã hội thông qua các cách lắp ráp của thiết bị LEGO.
                Mục đích giúp giáo viên và học sinh cùng học tập để tạo dựng một tương lai tốt đẹp hơn.
                
                FLL bao gồm nhiều kỹ năng mà học sinh cần phải thực hiện: Ý tưởng, giải quyết vấn đề, lắp ráp, lập trình và thử nghiệm, cải tiến sản phẩm.
                Tất cả nhằm thúc đẩy các bạn học sáng tạo thông qua phương pháp thực hành, tư duy phản biện.

                Chương trình sẽ tạo điều kiện để các tài năng Việt Nam tham gia giải thi đấu Quốc tế .
                
                Cuộc thi quốc tế First Lego League được chia làm 2 bảng thi đấu:

                1. Bảng Trung học: Dành cho học sinh từ 9 - 16 tuổi - Nội dung thi đấu: Lập trình Robot hoàn thành thử thách của chương trình. - Công cụ thi đấu: Lego Mindstorm
                2. Bảng Tiểu học: Dành cho học sinh từ 6 - 11 tuổi

                - Nội dung thi đấu: Lắp ráp, lập trình và trưng bày sản phẩm; Thuyết trình nêu ý tưởng. - Công cụ thi đấu: Lego WeDo
                * Thời gian tổ chức:

                - Tại Việt Nam: Vào tháng 1 hằng năm

                - Tại Mỹ: Vào tháng 4 hằng năm
                </p>
                <p>
                   Có thể tìm thêm thông tin từ FLL Quốc tế {' '}
                  <a href="https://www.firstlegoleague.org/events">tại đây</a>.
                </p>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="wro-introduction-row-2">
            <Col xs={24} sm={12} md={9}>
              <div>
                <div className="post-content">
                  <p>
                    Be aware that in your country local competitions may follow slightly different rules. Always use the
                    rules your National Organizer provides. Please contact your National Organizer first when you have
                    questions relating to the games &amp; rules. Click on the button to find your National Organizer.
                  </p>
                </div>
                <Link to={''}>
                  <button
                    className="btn btn-bold"
                    style={{ background: 'linear-gradient(90deg, #e62243 0%,#c1267d 100%)' }}
                  >
                    <span class="fusion-button-text">Find National Organizer</span>
                  </button>
                </Link>
              </div>
            </Col>
            <Col xs={24} sm={12} md={15}>
              <div style={{ margin: '0 auto', textAlign: 'center' }}>
                <div className="video-shortcode">
                  <iframe
                    width="100%"
                    style={{ maxWidth: 600 }}
                    height="338"
                    src="https://www.youtube.com/embed/kkN08bDv9EI"
                    title="FLL Introduction"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="section pricing">
        <div style={{ padding: '80px 30px', backgroundColor: '#161026' }}>
          <div className="row">
            <div className="pricing-wrapper">
              <div className="col-sm-3">
                <div className="pricing-widget">
                  <div className="pricing-content">
                    <h3 className="pricing-title">Khám phá 4-6 tuổi</h3>
                    <ul className="pricing-list">
                      <li>
                        <img src={Discover_4_6} alt="" width="250" height="250" />
                      </li>
                    </ul>
                    <div className="pricing-button">
                      <a
                        target="_blank"
                        href=""
                      >
                        Xem chi tiết
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="pricing-widget main">
                  <div className="pricing-content">
                    <h3 className="pricing-title">Tìm hiểu 6-10 tuổi</h3>

                    <ul className="pricing-list">
                      <li>
                        <img src={Explore_6_10} alt="" width="250" height="250" />
                      </li>
                    </ul>
                    <div className="pricing-button">
                      <a
                        target="_blank"
                        href=""
                      >
                        Xem chi tiết
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="pricing-widget">
                  <div className="pricing-content">
                    <h3 className="pricing-title">Thử thách 9-16 tuổi</h3>

                    <ul className="pricing-list">
                      <li>
                        <img src={Challenge_9_16} alt="" width="250" height="250" />
                      </li>
                    </ul>
                    <div className="pricing-button">
                      <a
                        target="_blank"
                        href=""
                      >
                        Xem chi tiết
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="group-btn-slider">
          <div className="btn-prev">
            <i className="fa fa-angle-left"></i>
          </div>
          <div className="btn-next">
            <i className="fa fa-angle-right"></i>
          </div>
        </div> */}
        </div>
      </div>
    </Space>
  );
};

export default FLLIntroduction;
