import { Space } from 'antd';
import React from 'react';
import Categories from '../../../../components/Competition/Categories';
import { RoboMissionHeader } from '../../../../assets/images';
import FLLCategories from '../../../../components/Competition/FLLCategories';
import {
  Fll_home,
} from '../../../../assets/images';

const FLLFinalRound = () => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div className="slider-banner">
        <div className="slider-item">
          <div
            className="slider-1"
            style={{
              backgroundImage: `url(${Fll_home})`,
            }}
          >
            <div class="slider-caption">
              <div class="container"></div>
            </div>
          </div>
        </div>
      </div>

      <FLLCategories />
      <div className="section courses-detail">
        <div className="container">
          <h1 className="title-lg">Vòng chung kết - FLL Quốc tế</h1>

          <Space direction="vertical" align="center" style={{ padding: 50, width: '100%' }}>
            <a href="https://www.firstlegoleague.org/" target="_blank" rel="noreferrer">
              <button className="btn btn-green">
                <span>
                  FLL 2023 THẾ GIỚI<i className="fa fa-long-arrow-right"></i>
                </span>
              </button>
            </a>
          </Space>
        </div>
      </div>
    </Space>
  );
};

export default FLLFinalRound;
