import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ items, address, parent }) => {
  return (
    <div className="section background-opacity page-title set-height-top">
      <div className="container">
        <div className="page-title-wrapper">
          <h2 className="captions">{address}</h2>
          {(items || parent) && (
            <ol className="breadcrumb">
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              {items &&
                items.map((item, i) => (
                  <li key={i}>
                    <Link to={item.path}>{item.label}</Link>
                  </li>
                ))}
              <li className="active">
                <a>{parent}</a>
              </li>
            </ol>
          )}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
