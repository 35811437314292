import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { subjectByOrgState } from '../../../recoil/atom/subjectState';
import ListCourse from '../../Courses/ListCourse';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Link } from 'react-router-dom';
import { ROUTER } from '../../../constant/router';
import { useRef } from 'react';
import i18n from '../../../lib/Language';
import { useOrg } from '../../../hooks/org';
import { useCourse } from '../../../hooks/course';
import 'swiper/css';
import 'swiper/css/navigation';
import { useSubject } from '../../../hooks/subject';

const TopCourses = () => {
  const { getAllCourses, courses } = useCourse();
  const { getAllSubjects, subjects, isLoading } = useSubject();
  const [popularCourses, setPopularCourses] = useState([]);
  const [bestCourses, setBestCourses] = useState([]);
  const [openCourses, setOpenCourses] = useState([]);
  const courseRef = useRef(null);

  // org
  useOrg();
  const subjectByOrg = useRecoilValue(subjectByOrgState);

  // GET ALL COURSES
  useEffect(() => {
    if (courses?.length === 0) {
      getAllCourses();
    }
    if (subjects?.length === 0) {
      getAllSubjects();
    }
  }, []);

  // GET ALL SUBJECT ID BY COURSES
  const subjectIdList = useMemo(() => {
    return courses?.map((course) => course?.SUBJECT_ID);
  }, [courses]);

  // FILTER SUBJECT BY COURSE
  const data = useMemo(() => {
    const tmp = subjectByOrg?.filter((subject) => subjectIdList?.includes(subject.id));
    const newData = [];
    tmp?.forEach((subject) => {
      courses?.forEach((course) => {
        if (subject?.id === course?.SUBJECT_ID) {
          return newData.push({
            COURSE_ID: course?.id,
            COURSE_CD: course?.COURSE_CD,
            COURSE_NAME: course?.NAME,
            COURSE_STATUS_ID: course?.COURSE_STATUS_ID,
            LEARN_START_DATE: course?.LEARN_START_DATE,
            LEARN_END_DATE: course?.LEARN_END_DATE,
            GRADE_ID: course?.Grade?.id,
            GRADE_NAME: course?.Grade?.NAME,
            ...subject,
          });
        }
      });
    });
    return newData?.sort((a, b) => a.INDEX - b.INDEX);
  }, [subjectByOrg, subjectIdList, courses]);

  useEffect(() => {
    // if (data?.length > 0) {
    const popularCourses = data?.filter((item) => item?.POPULAR === 1);
    const bestCourses = data?.filter((item) => item?.BEST === 1);
    const openCourses = data?.filter((item) => item?.COURSE_STATUS_ID === 1);

    setPopularCourses(popularCourses);
    setBestCourses(bestCourses);
    setOpenCourses(openCourses);
    // }
  }, [data]);

  return (
    <div className="section section-padding top-courses course_carousel" ref={courseRef}>
      <div className="container">
        <div style={{ marginBottom: 16 }}>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            navigation
            modules={[Navigation]}
            autoHeight={true}
            style={{ paddingBottom: 20 }}
          >
            <SwiperSlide>
              <div>
                <div className="group-title-index">
                  <h4 className="top-title">{i18n.t('public.course')}</h4>

                  <h2 className="center-title">{i18n.t('homepage.coursePopular')}</h2>

                  <div className="bottom-title">
                    <i className="bottom-icon icon-icon-04"></i>
                  </div>
                </div>
                <div className="top-courses-wrapper">
                  <ListCourse data={popularCourses} isLoading={isLoading} pageSize={4} courseRef={courseRef} />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="group-title-index">
                  <h4 className="top-title">{i18n.t('public.course')}</h4>

                  <h2 className="center-title">{i18n.t('homepage.courseBest')}</h2>

                  <div className="bottom-title">
                    <i className="bottom-icon icon-icon-04"></i>
                  </div>
                </div>
                <div className="top-courses-wrapper">
                  <ListCourse data={bestCourses} isLoading={isLoading} pageSize={4} courseRef={courseRef} />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <div className="group-title-index">
                  <h4 className="top-title">{i18n.t('public.course')}</h4>

                  <h2 className="center-title">{i18n.t('schedules.courseOpening')}</h2>

                  <div className="bottom-title">
                    <i className="bottom-icon icon-icon-04"></i>
                  </div>
                </div>
                <div className="top-courses-wrapper">
                  <ListCourse data={openCourses} isLoading={isLoading} pageSize={4} courseRef={courseRef} />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <Link to={ROUTER.COURSES.COURSE.path}>
          <button className="btn btn-green ">
            <span>
              {i18n.t('public.showAll')}
              <i className="fa fa-long-arrow-right"></i>
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default TopCourses;
