import { atom, selector } from "recoil";

const employeeState = atom({
    key: "employeeState",
    default: [],
});

const employeeFEState = atom({
    key: "employeeFEState",
    default: [],
});

const listEmployeeFEState = selector({
    key: 'listEmployeeFEState',
    get: ({ get }) => {
        const listEmp = get(employeeFEState);
        if (listEmp.length) {
            return listEmp.filter(item => (
                item?.SHOW_WEB === true && item.Employee_Status?.EMPLOYEE_STATUS_NAME === "WORKING"
            ));
        }
        return [];
    },
})


export {
    employeeState,
    employeeFEState,
    listEmployeeFEState
}