import React from 'react';
import Categories from '../../../../components/Competition/Categories';
import BtnViewMore from '../../../../components/Global/Button/BtnViewMore';

import { Col, Row, Space } from 'antd';
import { ConnectingTheWorld } from '../../../../assets/images';

import { img_1, img_2, img_3, img_4, img_5 } from '../../../../assets/images';
import { RoboMissionHeader, Wro2023IntroductionPanel } from '../../../../assets/images';
import FLLCategories from '../../../../components/Competition/FLLCategories';
import { Fll_home } from '../../../../assets/images';
import i18n from '../../../../lib/Language';

const FLLRegionalRound = () => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div className="slider-banner">
        <div className="slider-item">
          <div
            className="slider-1"
            style={{
              backgroundImage: `url(${Fll_home})`,
            }}
          >
            <div class="slider-caption">
              <div class="container"></div>
            </div>
          </div>
        </div>
      </div>

      <FLLCategories />
      <div className="section courses-detail">
        <div className="container">
          <h1 className="title-lg">Vòng khu vực - Việt Nam</h1>
          <div className="courses-detail-wrapper">
            <div>
              <div>
                <h1 className="course-title">VÒNG THI ĐẤU FLL 2023 QUỐC GIA</h1>
                <div className="course-info info">
                  <div className="author item">
                    <a href="#">
                      <span>By&nbsp;</span>
                      <span>Stem Square</span>
                    </a>
                  </div>
                  <div className="date-time item">
                    <a href="#">01/06/2023</a>
                  </div>
                </div>

                <div>
                  <Row gutter={[16, 16]}>
                    <Col md={6}>
                      <img src={ConnectingTheWorld} alt="" className="img-responsive" />
                    </Col>
                    <Col md={18}>
                      <p>
                        VÒNG ĐẤU KHU VỰC ĐƯỢC TỔ CHỨC TẠI VIỆT NAM, GỌI LÀ <strong>FLL</strong>
                      </p>
                      <p>
                        Là sân chơi STEM Robotics hàng đầu Việt Nam được tổ chức theo nguyên mẫu cuộc thi thế giới WRO
                        (World Robot Olympiad) do Công ty Cổ phần Việt Tinh Anh – đại diện duy nhất giữ bản quyền cuộc
                        thi WRO – Cuộc thi ROBOT Thế Giới và là thành viên của WROA – Hiệp hội rô bốt thế giới tại Việt
                        Nam tổ chức nhằm tuyển chọn các đội thi xuất sắc nhất tranh tài tại vòng Chung kết thế giới.
                        <br />
                        ROBOTACON WRO được tổ chức lần đầu tiên vào năm 2013, cho đến nay ROBOTACON WRO đã trở thành sân
                        chơi khoa học, công nghệ uy tín và quy mô nhất Việt Nam hàng năm thu hút hàng ngàn học sinh từ
                        06 đến 19 tuổi đam mê lập trình robot khắp cả nước đến tham gia và tranh tài.
                        <br />
                        Đại diện Việt Nam đã đạt những thành tích cao qua các năm tại vòng chung kết thế giới được tổ
                        chức thường niên luân phiên tại các quốc gia thuộc WROA.
                        <br />
                        Vô địch thế giới năm 2018
                        <br />
                        Hạng 2 thế giới năm 2020
                        <br />
                        Hạng 3 thế giới năm 2016
                        <br />
                        Top 4 thế giới năm 2020, 2021
                        <br />
                        Giải Bạc thế giới năm 2022,…
                        <br />
                        STEM SQUARE đã nhiều năm tham dự và mang thành tích cao cho đội Đà Nẵng.
                      </p>
                    </Col>
                  </Row>
                </div>

                {/* <div className="course-video">
                  <iframe
                    width="1246"
                    height="704"
                    src="https://www.youtube.com/embed/CjOjFcy3m9E"
                    allowfullscreen="allowfullscreen"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                  
                 
                  <div className="clearfix"></div>
                </div> */}
                {/* <div className="course-des">
                  <div className="course-des-title underline">1. GIỚI THIỆU VỀ VÒNG SƠ TUYỂN STEM WRO</div>
                  <div className="course-des-content">
                    <p>STEM SQUARE thực hiện vòng sơ tuyển cho các bạn tại Miền Trung</p>
                  </div>
                </div> */}
                {/*  */}

                <div>
                  <p>
                    ROBOTACON WRO 2023 là cuộc thi được tổ chức thường niên hằng năm của STEM SQUARE và các đối tác kết
                    hợp tổ chức, nhằm mang lại một sân chơi trí tuệ về khoa học ứng dụng Robotics và được liên thông
                    theo chuẩn của cuộc thi Robotacon Quốc gia, WRO Quốc tế được tổ chức bởi LEGO EDUCATION.
                  </p>
                  <p>
                    Cuộc thi xoay quanh chủ đề của năm 2023 là “KẾT NỐI THẾ GIỚI”, nối kết các vấn đề, con người với
                    nhau, tự nhiên với con người. Thời điểm của cuộc thi được diễn ra là ngày chủ nhật cuối cùng của
                    tháng 07 hằng năm, năm nay được chọn ngày 01/07/2023 để làm sự kiện.
                  </p>
                  <p>
                    Vòng sơ tuyển mục đích thực hiện giao lưu các đội tại STEM SQUARE, các bạn sẽ được học tập, trao đổi
                    kiến thức, tham gia như thi đấu thật, để thuận lợi trong việc giao lưu các đội trong vòng khu vực
                    (quốc gia) tại Tp HCM cuối tháng 07 2023.
                  </p>
                  <p>
                    Đây là vòng loại tập dợt giao lưu, bổ ích, sáng tạo và cọ sát thực tế cho đợt thi đấu của các bạn.
                  </p>
                  <p>
                    <strong>Các bảng thi và trải nghiệm sáng tạo khoa học ứng dụng tại cuộc thi như sau:</strong>
                  </p>
                  <p>
                    .  B0: Bảng vỡ lòng, sân chơi các thiết bị khoa học sáng tạo vui với độ tuổi là 06-08 tuổi (học
                    sinh lớp 1,2)
                  </p>
                  <p>
                    <p>.  B1: Bảng Robot nhiệm vụ, sân chơi thi đấu sa bàn độ tuổi là 07 tuổi (học sinh lớp 2,3,4)</p>
                    <p>.  B2: Bảng Robot nhiệm vụ, Phần thi đấu Robot tài năng Spake, EV3 độ tuổi 09 đến 15 tuổi</p>
                  </p>
                  <p></p>

                  <div className="image">
                    <img
                      className="img-responsive"
                      src={Wro2023IntroductionPanel}
                      style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                    />
                  </div>
                </div>
                <br></br>
                {/*  */}
                <div className="course-syllabus">
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <div className="course-des-title underline">2. CÁC BẢNG THI ĐẤU</div>
                    <div>
                      <div>
                        <div className="inner-container">
                          <div className="table-header">
                            <table className="edu-table-responsive">
                              <thead>
                                <tr className="heading-table">
                                  <th className="col-3">BẢNG THI ĐẤU</th>
                                  <th className="col-1">NỘI DUNG</th>
                                  <th className="col-2">CHI TIẾT</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div className="table-body">
                            <table className="edu-table-responsive table-hover">
                              <tbody>
                                <tr className="heading-content">
                                  <td colspan="4" className="left heading-content">
                                    1. BẢNG VỠ LÒNG
                                  </td>
                                </tr>
                                <tr className="table-row">
                                  <td className="left col-2">B0</td>
                                  <td className="col-1">
                                    <span>Dành cho các bạn 6-7 tuổi</span>
                                  </td>
                                  <td className="col-2">
                                    <span>
                                      <a
                                        target="_blank"
                                        href="https://drive.google.com/drive/folders/13pNwKvBcNesqiRhFO_PZSL3uTeeuxMYa"
                                      >
                                        Xem chi tiết
                                      </a>
                                    </span>
                                  </td>
                                </tr>

                                <tr className="heading-content">
                                  <td colspan="4" className="left heading-content">
                                    2. BẢNG NHIỆM VỤ
                                  </td>
                                </tr>
                                <tr className="table-row">
                                  <td className="left col-2">B1</td>
                                  <td className="col-1">
                                    <span>Dành cho các bạn 8-11 tuổi</span>
                                  </td>
                                  <td className="col-2">
                                    <span>
                                      <a
                                        target="_blank"
                                        href="https://drive.google.com/drive/folders/14GNBUwcIudcc0jR8nK-FdpGSh89pPLKF"
                                      >
                                        Xem chi tiết
                                      </a>
                                    </span>
                                  </td>
                                </tr>

                                <tr className="table-row">
                                  <td className="left col-2">B2</td>
                                  <td className="col-1">
                                    <span>Dành cho các bạn 9-15 tuổi</span>
                                  </td>
                                  <td className="col-2">
                                    <span>
                                      <a
                                        target="_blank"
                                        href="https://drive.google.com/drive/folders/1JtTz51e3sy5Cw3zvOF9TPNMYrQ6R5sHO"
                                      >
                                        Xem chi tiết
                                      </a>
                                    </span>
                                  </td>
                                </tr>

                                <tr className="heading-content">
                                  <td colspan="4" className="left heading-content">
                                    3. NHÀ KIẾN TẠO TƯƠNG LAI
                                  </td>
                                </tr>
                                <tr className="table-row">
                                  <td className="left col-2">B4-01</td>
                                  <td className="col-1">
                                    <span>Dành cho các bạn 8-11 tuổi</span>
                                  </td>
                                  <td className="col-2">
                                    <span>
                                      <a
                                        target="_blank"
                                        href="https://drive.google.com/drive/folders/1JtTz51e3sy5Cw3zvOF9TPNMYrQ6R5sHO"
                                      >
                                        Xem chi tiết
                                      </a>
                                    </span>
                                  </td>
                                </tr>

                                <tr className="table-row">
                                  <td className="left col-2">B4-02</td>
                                  <td className="col-1">
                                    <span>Dành cho các bạn 9-15 tuổi</span>
                                  </td>
                                  <td className="col-2">
                                    <span>
                                      <a
                                        target="_blank"
                                        href="https://drive.google.com/drive/folders/1JtTz51e3sy5Cw3zvOF9TPNMYrQ6R5sHO"
                                      >
                                        Xem chi tiết
                                      </a>
                                    </span>
                                  </td>
                                </tr>

                                <tr className="table-row">
                                  <td className="left col-2">B4-03</td>
                                  <td className="col-1">
                                    <span>Dành cho các bạn 9-15 tuổi</span>
                                  </td>
                                  <td className="col-2">
                                    <span>
                                      <a
                                        target="_blank"
                                        href="https://drive.google.com/drive/folders/1JtTz51e3sy5Cw3zvOF9TPNMYrQ6R5sHO"
                                      >
                                        Xem chi tiết
                                      </a>
                                    </span>
                                  </td>
                                </tr>

                                <tr className="heading-content">
                                  <td colspan="4" className="left heading-content">
                                    4. ROBOT THỂ THAO
                                  </td>
                                </tr>
                                <tr className="table-row">
                                  <td className="left col-2">B5</td>
                                  <td className="col-1">
                                    <span>Quần vợt đồng đội</span>
                                  </td>
                                  <td className="col-2">
                                    <span>
                                      <a
                                        target="_blank"
                                        href="https://drive.google.com/drive/folders/17o8aJQi1VwVdcQfjvbjJxtFp8Q_WG5ku"
                                      >
                                        Xem chi tiết
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="course-video">
                      <iframe
                        width="1246"
                        height="1000"
                        src="https://www.youtube.com/embed/7OoKO-i17cg"
                        allowfullscreen="allowfullscreen"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                      <div className="clearfix"></div>
                    </div>
                  </Space>

                  <Space direction="vertical" style={{ width: '100%' }}>
                    <div className="competition-route">
                      <div className="course-des-title underline">3. LỘ TRÌNH THI ĐẤU CÁC TỈNH</div>
                      <p>1. Vòng loại khu vực miền Trung và Miền Nam:</p>
                      <ul>
                        <li>Thời gian: Ngày 29-30 tháng 7 năm 2023</li>
                        <li>Địa điểm: Thành phố Hồ Chí Minh</li>
                        <li>Các bảng thi đấu: Các bảng thi đấu: B0, B1, B2, B3</li>
                        <li>
                          Thời hạn đăng ký: <strong>đến 17h ngày 30/06/2023</strong> (sau thời gian này sẽ không được
                          ghi nhận đăng ký).
                        </li>
                      </ul>
                      <p>2. Vòng loại khu vực Miền Bắc:</p>
                      <ul>
                        <li>Thời gian: Ngày 12-13 tháng 8 năm 2023</li>
                        <li>Địa điểm: Thành phố Hải Phòng</li>
                        <li>Các bảng thi đấu: B0, B1, B2,B3</li>
                        <li>
                          Thời hạn đăng ký: <strong>đến 17h ngày 30/06/2023</strong> (sau thời gian này sẽ không được
                          ghi nhận đăng ký).
                        </li>
                      </ul>
                      <p>3. Vòng chung kết toàn quốc:</p>
                      <ul>
                        <li>Thời gian: Ngày 19-20 tháng 8 năm 2023</li>
                        <li>Địa điểm: Thủ đô Hà Nội</li>
                        <li>Các bảng thi đấu: B1, B2, B3, B4-01, B4-02, B4-03, B5</li>
                        <li>
                          Đối với bảng B4-01, B4-02, B4-03, B5:
                          <ul>
                            <li>
                              Thời hạn đăng ký <strong>số lượng</strong> đội thi đến{' '}
                              <strong>17h ngày 08/06/2023</strong>
                            </li>
                            <li>
                              Thời hạn đăng ký <strong>danh sách đội thi</strong> đến{' '}
                              <strong>17h ngày 30/06/2023</strong>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </Space>

                  <br></br>

                  <div className="course-des-title underline">4. LỊCH TRÌNH TỔ CHỨC THI ĐẤU (DỰ KIẾN)</div>
                  <div className="course-table">
                    <div className="outer-container">
                      <div className="inner-container">
                        <div className="table-header">
                          <table className="edu-table-responsive">
                            <thead>
                              <tr className="heading-table">
                                <th className="col-3">Thời gian dự kiến</th>
                                <th className="col-1">Nội dung chương trình</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <div className="table-body">
                          <table className="edu-table-responsive table-hover">
                            <tbody>
                              <tr className="heading-content">
                                <td colspan="4" className="left heading-content">
                                  1. Bảng B0
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">7h30 - 8h00</td>
                                <td className="col-1">
                                  <span>Đón khách, thí sinh và phụ huynh. Ổn định vị trí.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h00 - 8h15</td>
                                <td className="col-1">
                                  <span>
                                    Đại diện Ban tổ chức chia sẻ một số lưu ý trong Công tác thi và Công bố luật thi.
                                  </span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h15 - 8h30</td>
                                <td className="col-1">
                                  <span>Huấn luyện viên hướng dẫn thí sinh.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h30 – 9h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h00 – 9h30</td>
                                <td className="col-1">
                                  <span>Thời gian thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h30 – 9h45</td>
                                <td className="col-1">
                                  <span>Tổng hợp kết quả thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h45 – 10h45</td>
                                <td className="col-1">
                                  <span>Tự do tham quan các gian hàng.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h45 – 11h00</td>
                                <td className="col-1">
                                  <span>Công bố kết quả thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">11h00</td>
                                <td className="col-1">
                                  <span>Kết thúc</span>
                                </td>
                              </tr>

                              <tr className="heading-content">
                                <td colspan="4" className="left heading-content">
                                  3. Bảng B1
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">7h30 - 8h00</td>
                                <td className="col-1">
                                  <span>Đón khách, thí sinh và phụ huynh. Ổn định vị trí.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h00 - 8h15</td>
                                <td className="col-1">
                                  <span>
                                    Đại diện Ban tổ chức chia sẻ một số lưu ý trong Công tác thi và Công bố luật thi.
                                  </span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h15 - 8h30</td>
                                <td className="col-1">
                                  <span>Huấn luyện viên hướng dẫn thí sinh.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h30 – 9h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h00 – 9h30</td>
                                <td className="col-1">
                                  <span>Lượt thi đấu thứ 1.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h30 – 10h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn (Lần 2).</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h – 10h30</td>
                                <td className="col-1">
                                  <span>Lượt thi đấu thứ 2.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h30 – 10h45</td>
                                <td className="col-1">
                                  <span>Tổng hợp kết quả thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h45 – 11h00</td>
                                <td className="col-1">
                                  <span>Công bố kết quả thi đấu</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">11h00</td>
                                <td className="col-1">
                                  <span>Kết thúc</span>
                                </td>
                              </tr>

                              <tr className="heading-content">
                                <td colspan="4" className="left heading-content">
                                  3. Bảng B2
                                </td>
                              </tr>

                              <tr className="table-row">
                                <td className="left col-2">7h30 - 8h00</td>
                                <td className="col-1">
                                  <span>Đón khách, thí sinh và phụ huynh. Ổn định vị trí.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h00 - 8h15</td>
                                <td className="col-1">
                                  <span>
                                    Đại diện Ban tổ chức chia sẻ một số lưu ý trong Công tác thi và Công bố luật thi.
                                  </span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h15 - 8h30</td>
                                <td className="col-1">
                                  <span>Huấn luyện viên hướng dẫn thí sinh.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h30 – 9h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h00 – 9h30</td>
                                <td className="col-1">
                                  <span>Lượt thi đấu thứ 1.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h30 – 10h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn (Lần 2).</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h – 10h30</td>
                                <td className="col-1">
                                  <span>Lượt thi đấu thứ 2.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h30 – 10h45</td>
                                <td className="col-1">
                                  <span>Tổng hợp kết quả thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h45 – 11h00</td>
                                <td className="col-1">
                                  <span>Công bố kết quả thi đấu</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">11h00</td>
                                <td className="col-1">
                                  <span>Kết thúc</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br></br>
                        <div>• Thời gian có thể thay đổi theo thực tế</div>
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>

                {/* <div className="course-des-title underline">3. THỂ LỆ CUỘC THI</div>
                <div>
                  <div className="list-expand-title">a. Nhiệm vụ của chủ đề</div>
                  <p>
                    {' '}
                    Trong mùa giải này, GRG sẽ tập trung vào chủ đề Giải pháp bền vững. Tài nguyên thiên
                    nhiên là vô hạn và để đạt được một tương lai bền vững hơn, chúng ta sẽ cần áp dụng các
                    phương pháp và công nghệ có thể giảm thiểu các tác động tiêu cực đến môi trường và thúc
                    đẩy việc bảo tồn các nguồn tài nguyên thiên nhiên của chúng ta. Chúng tôi mong muốn khám
                    phá việc sử dụng năng lượng tái tạo, phát triển hệ thống giao thông bền vững và/hoặc thực
                    hiện quản lý chất thải. Hãy biến những ý tưởng bền vững của chúng ta thành hành động để
                    tạo ra một thế giới tốt đẹp hơn cho chính chúng ta và cho các thế hệ tương lai của chúng ta.
                  </p>
                  <p>
                    {' '}
                    Trong bảng Robo Nhiệm vụ, các nhóm thiết kế robot giải quyết các thử thách trên sa bàn thi
                    đấu. Robot hoàn toàn tự động.
                    Đối với mỗi nhóm tuổi, một bảng thi và nhiệm vụ mới được phát triển mỗi năm. Vào ngày thi
                    đấu, một đề bất ngờ bổ sung một yếu tố mới cho nhiệm vụ. Một thử thách cộng thêm sẽ kiểm
                    tra khả năng sáng tạo và tư duy nhanh nhạy của các đội tại các cuộc thi.
                  </p>
                 
                </div> */}

                {/* <div>
                  <div className="list-expand-title">b. Thời gian và địa điểm:</div>
                  <p>  Thời gian đăng ký dự thi: trước ngày 01/07/2023</p>
                  <p>
                    {' '}
                     Hình thức ăng ký: Đăng ký trực tiếp với trung tâm STEM SQUARE với điều kiện đã mua bộ LEGO tại
                    trung tâm hoặc đã và đang học tại STEM SQUARE.
                  </p>
                  <p> Có thể đăng ký trực tuyến với các học sinh chưa học tại STEM SQUARE</p>
                  <BtnViewMore title="Đăng ký tham gia" />
                  <p></p>
                  <p>  Thời gian tổ chức: Sáng ngày 10/07/2019 (chủ nhật)</p>
                  <p>  Địa điểm tổ chức: Trung tâm STEM SQUARE (dự kiến)</p>
                </div> */}
                {/* <div className="course-des-title underline">4. GIẢI THƯỞNG</div>
                <div>
                  <div>
                    <div className="inner-container">
                      <div className="table-header">
                        <table className="edu-table-responsive">
                          <thead>
                            <tr className="heading-table">
                              <th className="col-3">Giải</th>
                              <th className="col-1">Giá trị giải thưởng</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="table-body">
                        <table className="edu-table-responsive table-hover">
                          <tbody>
                            <tr className="heading-content">
                              <td colspan="4" className="left heading-content">
                                1. Bảng B0
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2"></td>
                              <td className="col-1">
                                <span>10 phần quà</span>
                              </td>
                            </tr>
                            <tr className="heading-content">
                              <td colspan="4" className="left heading-content">
                                3. Bảng B1
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải nhất</td>
                              <td className="col-1">
                                <span>1.000.000 VND</span>
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải nhì</td>
                              <td className="col-1">
                                <span>500.000 VND</span>
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải ba</td>
                              <td className="col-1">
                                <span>300.000 VND</span>
                              </td>
                            </tr>

                            <tr className="heading-content">
                              <td colspan="4" className="left heading-content">
                                3. Bảng B2
                              </td>
                            </tr>

                            <tr className="table-row">
                              <td className="left col-2">01 giải nhất</td>
                              <td className="col-1">
                                <span>1.000.000 VND</span>
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải nhì</td>
                              <td className="col-1">
                                <span>500.000 VND</span>
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải ba</td>
                              <td className="col-1">
                                <span>300.000 VND</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <div>
                  <BtnViewMore title="Download giới thiệu chương trình" />
                  <BtnViewMore title="Download lịch cụ tổ chức cụ thể" />
                  <BtnViewMore title="Download đề và luật thi đấu B0" />
                  <BtnViewMore title="Download đề và luật thi đấu B1" />
                  <BtnViewMore title="Download đề và luật thi đấu B2" />
                </div>
                <br></br> */}
                <div className="course-des-title underline">
                  5. MỘT SỐ SA BÀN VÀ HÌNH ẢNH CỦA CUỘC THI THEO CHUẨN WRO THẾ GIỚI
                </div>

                <div className="course-video">
                  <iframe
                    width="1246"
                    height="1000"
                    src="https://www.youtube.com/embed/CjOjFcy3m9E"
                    allowfullscreen="allowfullscreen"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                  <div className="clearfix"></div>
                </div>

                <div class="course-des-title underline">MỘT SỐ HÌNH ẢNH NGÀY HỘI</div>
                <div>
                  <div class="list-expand-title">Hình ảnh Robotacon qua các năm</div>
                  <div class="image">
                    <img
                      class="img-responsive"
                      src={img_1}
                      style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                    />
                    <img
                      class="img-responsive"
                      src={img_2}
                      style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                    />
                    <img
                      class="img-responsive"
                      src={img_3}
                      style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                    />
                    <img
                      class="img-responsive"
                      src={img_5}
                      style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                    />
                    <img
                      class="img-responsive"
                      src={img_4}
                      style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                    />
                  </div>
                  <BtnViewMore title={i18n.t('general.button.seeMore')} />
                </div>

                {/* <div className="list-expand-title">a. Hình ảnh</div>
                  <div className="image">
                    <img
                      className="img-responsive"
                      src={m8}
                      style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                    />
                    <img
                      className="img-responsive"
                      src={m13}
                      style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                    />
                    <img
                      className="img-responsive"
                      src={m1}
                      style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                    />
                    <img
                      className="img-responsive"
                      src={m65}
                      style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                    /> 
                  </div> */}
                {/* <BtnViewMore title="Xem thêm" /> */}
                {/*  */}
                {/* <div>
                  <div className="list-expand-title">b. Links báo chí đã đăng</div>

                  <RelatedNews />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Space>
  );
};

export default FLLRegionalRound;
