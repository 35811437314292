import React, { useMemo } from 'react';
import { languageState } from '../../../recoil/atom/languageState';
import { useRecoilState } from 'recoil';
import { Dropdown, Image } from 'antd';

const LanguageSelect = () => {
  const [language, setLanguage] = useRecoilState(languageState);

  const onClick = ({ key }) => {
    localStorage.setItem('language', key);
    setLanguage(key);
  };

  const items = [
    {
      label: 'Vietnamese',
      key: 'vie',
      icon: (
        <>
          <Image
            src="https://cdn.pixabay.com/photo/2012/04/10/23/04/vietnam-26834_960_720.png"
            width={22}
            height={14}
            preview={false}
          />
        </>
      ),
    },
    {
      label: 'English',
      key: 'en',
      icon: (
        <>
          <Image
            src="https://img.freepik.com/free-vector/illustration-uk-flag_53876-18166.jpg"
            width={22}
            height={14}
            preview={false}
          />
        </>
      ),
    },

    // {
    //   label: 'Japanese',
    //   key: 'ja',
    //   icon: (
    //     <>
    //       <Image
    //         src="https://upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg"
    //         width={24}
    //         height={16}
    //         preview={false}
    //       />
    //     </>
    //   ),
    // },
  ];

  const languageSelect = useMemo(() => {
    return items?.find((item) => item.key === language);
  }, [language]);

  return (
    <>
      <Dropdown
        menu={{
          items,
          onClick,
        }}
      >
        <a onClick={(e) => e.preventDefault()}>{languageSelect?.icon}</a>
      </Dropdown>
    </>
  );
};

export default LanguageSelect;
