import React from 'react';
import Breadcrumb from '../../../Global/Breadcrumb';
import Teacher from '../../../Global/Teacher';
import Courses from '../../../Public/News/Courses';
import StempRobotics from '../../../Public/News/StempRobotics';
import { wedo20_boxinside, wedo4, ev3_box, ev3_robot1, curibot, garastem_grobot } from '../../../../assets/images';
import BtnViewMore from '../../../Global/Button/BtnViewMore';
import RelatedNews from '../../../Global/RelatedNews';
import RoboticSchedule from '../../../Global/RoboticSchedule';

const Distribute = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Breadcrumb address="NHÀ PHÂN PHỐI THIẾT BỊ - LEGO, NEGENDO, GARASTEM" />
            <div class="section" style={{ display: 'none' }}>
              <div class="search-input">
                <div class="container">
                  <div class="search-input-wrapper">
                    <form>
                      <select class="form-select style-1 selectbox">
                        <option value="all">all categories</option>
                        <option value="languages">languages</option>
                        <option value="science">science</option>
                      </select>
                      <select class="form-select style-2 selectbox">
                        <option value="price">price</option>
                        <option value="datetime">datetime</option>
                        <option value="teacher">teacher</option>
                      </select>
                      <input type="text" placeholder="Do you want to learn today?" class="form-input" />
                      <button type="submit" class="form-submit btn btn-blue">
                        <span>
                          search now<i class="fa fa-search"></i>
                        </span>
                      </button>
                      <div class="clearfix"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="section section-padding courses-detail">
              <div class="container">
                <div class="courses-detail-wrapper">
                  <div class="row">
                    <div class="col-md-9 layout-left" style={{ padding: '0 15px' }}>
                      <h1 class="course-title">STEM SQUARE LÀ NHÀ PHÂN PHỐI THIẾT BỊ STEM</h1>

                      <div class="course-info info">
                        <div class="author item">
                          <a href="#">
                            <span>By&nbsp;</span>
                            <span>Sale</span>
                          </a>
                        </div>
                        <div class="date-time item">
                          <a href="#">20/06/2019</a>
                        </div>
                      </div>

                      <div class="course-des">
                        <div class="course-des-title underline">SẢN PHẨM CỦA LEGO EDUCATION</div>
                        {/*  */}
                        <div>
                          <div class="image">
                            <img
                              class="img-responsive"
                              src={wedo20_boxinside}
                              style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                            />
                            <img
                              class="img-responsive"
                              src={wedo4}
                              style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                            />
                            <img
                              class="img-responsive"
                              src={ev3_box}
                              style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                            />
                            <img
                              class="img-responsive"
                              src={ev3_robot1}
                              style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                            />
                          </div>
                          <BtnViewMore title="Xem thêm" />
                        </div>

                        <div class="course-syllabus-title underline">Thiết bị NEGENDO</div>
                        <div>
                          <div class="image">
                            {/* <img
                              class="img-responsive"
                              src="assets/images/products/umake.png"
                              style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                            /> */}
                            <img
                              class="img-responsive"
                              src={curibot}
                              style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                            />
                          </div>
                          <BtnViewMore title="Xem thêm" />
                        </div>

                        <div class="course-des-title underline">Thiết bị GaraSTEM</div>
                        <div>
                          <div class="image">
                            <img
                              class="img-responsive"
                              src={garastem_grobot}
                              style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                            />
                          </div>
                          <BtnViewMore title="Xem thêm" />
                        </div>
                        <br></br>
                        <RelatedNews />
                        <div class="course-syllabus" style={{ display: 'none' }}>
                          <RoboticSchedule />
                        </div>
                        {/*  */}
                      </div>
                    </div>

                    {/*  */}
                    <div class="col-md-3 sidebar layout-right">
                      <div class="row">
                        <Teacher />
                        <div class="clearfix"></div>
                        {/* <StempRobotics /> */}
                        {/* <Courses /> */}
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Distribute;
