import React from 'react';
import { Link } from 'react-router-dom';
import {
  Gallery01,
  Gallery02,
  Gallery03,
  Gallery04,
  Gallery05,
  Gallery06,
  Gallery07,
  Gallery08,
} from '../../../assets/images';
import { ROUTER } from '../../../constant/router';
import i18n from '../../../lib/Language';

const Footer = () => {
  return (
    <footer>
      <div className="footer-top">
        <div className="container">
          <div className="footer-top-wrapper">
            <div className="footer-top-left">
              <p className="footer-top-focus">{i18n.t('homepage.teacher?')}</p>

              <p className="footer-top-text">{i18n.t('homepage.contactConten')}</p>
            </div>
            <div className="footer-top-right">
              {/* <button
                onClick={() => (window.location.href = 'http://novasquare.vn/hrm/apply')}
                className="btn btn-blue btn-bold"
              > */}
              <a href="http://novasquare.vn/hrm/apply" target="_blank" className="btn btn-blue btn-bold">
                {i18n.t('menu.lblContact')}
              </a>
              {/* </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-main">
        <div className="container">
          <div className="footer-main-wrapper">
            <div className="row">
              <div className="col-2">
                <div className="col-md-3 col-sm-6 col-xs-12 sd380">
                  <div className="edugate-widget widget">
                    <div className="title-widget">STEM SQUARE EDUCATION</div>
                    <div className="content-widget">
                      <p>{i18n.t('homepage.footer.stemContent')}</p>

                      <div className="info-list">
                        <ul className="list-unstyled">
                          <li>
                            <i className="fa fa-envelope-o"></i>
                            <a href="#">stemsquare.vn@gmail.com</a>
                          </li>
                          <li>
                            <i className="fa fa-phone"></i>
                            <a href="#">0971 234 042 - 0971 234 043 - 0913 111 576</a>
                          </li>
                          <li>
                            <i className="fa fa-map-marker"></i>
                            <a href="#">{i18n.t('homepage.footer.address1')}</a>
                          </li>

                          <li>
                            <i className="fa fa-map-marker"></i>

                            <a>{i18n.t('homepage.footer.address2')}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 sd380">
                  <div className="useful-link-widget widget">
                    <div className="title-widget">{i18n.t('homepage.footer.linkSupport')}</div>
                    <div className="content-widget">
                      <div className="useful-link-list">
                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-xs-6">
                            <ul className="list-unstyled">
                              <li>
                                <i className="fa fa-angle-right"></i>
                                <a href="#">{i18n.t('homepage.processBar.teacher')}</a>
                              </li>
                              <li>
                                <i className="fa fa-angle-right"></i>
                                <Link to={ROUTER.COURSES.COURSE.path}>{i18n.t('public.course')}</Link>
                              </li>
                              <li>
                                <i className="fa fa-angle-right"></i>
                                <Link to={ROUTER.CONTACT.path}>{i18n.t('homepage.footer.support')}</Link>
                              </li>
                              <li>
                                <i className="fa fa-angle-right"></i>
                                <Link to={ROUTER.HOME.INTRODUCE.path}>{i18n.t('homepage.footer.whyStemSquare')}</Link>
                              </li>
                              <li>
                                <i className="fa fa-angle-right"></i>
                                <Link to={ROUTER.LIBRARY.STEM_BOOK.path}>{i18n.t('menu.lblLibrary')}</Link>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-sm-6 col-xs-6" style={{ display: 'none' }}>
                            <ul className="list-unstyled">
                              <li>
                                <i className="fa fa-angle-right"></i>
                                <a href="#">Company</a>
                              </li>
                              <li>
                                <i className="fa fa-angle-right"></i>
                                <a href="#">Latest Courses</a>
                              </li>
                              <li>
                                <i className="fa fa-angle-right"></i>
                                <a href="#">Partners</a>
                              </li>
                              <li>
                                <i className="fa fa-angle-right"></i>
                                <a href="#">Blog Post</a>
                              </li>
                              <li>
                                <i className="fa fa-angle-right"></i>
                                <a href="#">Help Topic</a>
                              </li>
                              <li>
                                <i className="fa fa-angle-right"></i>
                                <a href="#">Policies</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="col-md-3 col-sm-6 col-xs-12 sd380">
                  <div className="gallery-widget widget">
                    <div className="title-widget">{i18n.t('homepage.footer.image')}</div>
                    <div className="content-widget">
                      <div className="gallery-list">
                        <a href="#" className="thumb">
                          <img src={Gallery01} alt="" className="img-responsive" />
                        </a>
                        <a href="#" className="thumb">
                          <img src={Gallery02} alt="" className="img-responsive" />
                        </a>
                        <a href="#" className="thumb">
                          <img src={Gallery03} alt="" className="img-responsive" />
                        </a>
                        <a href="#" className="thumb">
                          <img src={Gallery04} alt="" className="img-responsive" />
                        </a>
                        <a href="#" className="thumb">
                          <img src={Gallery05} alt="" className="img-responsive" />
                        </a>
                        <a href="#" className="thumb">
                          <img src={Gallery06} alt="" className="img-responsive" />
                        </a>
                        <a href="#" className="thumb">
                          <img src={Gallery07} alt="" className="img-responsive" />
                        </a>
                        <a href="#" className="thumb">
                          <img src={Gallery08} alt="" className="img-responsive" />
                        </a>
                      </div>
                      <div className="clearfix"></div>
                      <a href={ROUTER.PUBLIC.IMAGEEVENT.path} className="view-more">
                        View more&nbsp;
                        <i className="fa fa-angle-double-right mls"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12 sd380">
                  <div className="mailing-widget widget">
                    <div className="title-widget">{i18n.t('homepage.footer.signupToreciveInfo')}</div>
                    <div className="content-wiget">
                      <p>{i18n.t('homepage.footer.enterYourEmail')}</p>

                      <form action="http://swlabs.co/edugate/index.html">
                        <div className="input-group">
                          <input type="text" placeholder="Email address" className="form-control form-email-widget" />
                          <span className="input-group-btn">
                            <input type="submit" value="✓" className="btn btn-email" />
                          </span>
                        </div>
                      </form>
                      <p>{i18n.t('homepage.footer.privacyStatement')}</p>

                      <div className="socials">
                        <a href="https://www.facebook.com/stemsquare" className="facebook">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hyperlink" style={{ display: 'none' }}>
            <div className="pull-left hyper-left">
              <ul className="list-inline">
                <li>
                  <a href="index.html">HOME</a>
                </li>
                <li>
                  <a href="courses.html">COURSES</a>
                </li>
                <li>
                  <a href="about-us.html">ABOUT</a>
                </li>
                <li>
                  <a href="#">PAGES</a>
                </li>
                <li>
                  <a href="news.html">NEWS</a>
                </li>
                <li>
                  <a href="contact.html">CONTACT</a>
                </li>
              </ul>
            </div>
            <div className="pull-right hyper-right">@ STEM SQUARE</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
