import React from 'react';
import Breadcrumb from '../../../Global/Breadcrumb';
import RelatedNews from '../../../Global/RelatedNews';
import RoboticSchedule from '../../../Global/RoboticSchedule';

const EducationModal = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Breadcrumb address="PHƯƠNG PHÁP GIÁO DỤC STEM SQUARE" />
            <div class="section" style={{ display: 'none' }}>
              <div class="search-input">
                <div class="container">
                  <div class="search-input-wrapper">
                    <form>
                      <select class="form-select style-1 selectbox">
                        <option value="all">all categories</option>
                        <option value="languages">languages</option>
                        <option value="science">science</option>
                      </select>
                      <select class="form-select style-2 selectbox">
                        <option value="price">price</option>
                        <option value="datetime">datetime</option>
                        <option value="teacher">teacher</option>
                      </select>
                      <input type="text" placeholder="Do you want to learn today?" class="form-input" />
                      <button type="submit" class="form-submit btn btn-blue">
                        <span>
                          search now<i class="fa fa-search"></i>
                        </span>
                      </button>
                      <div class="clearfix"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="section section-padding courses-detail">
              <div class="container">
                <div class="courses-detail-wrapper">
                  <div class="row">
                    <div class="col-md-9 layout-left">
                      <h1 class="course-title">PHƯƠNG PHÁP GIÁO DỤC STEM SQUARE</h1>

                      <div class="course-info info">
                        <div class="author item">
                          <a href="#">
                            <span>By&nbsp;</span>
                            <span>Stem Square Education</span>
                          </a>
                        </div>
                        <div class="date-time item">
                          <a href="#">20/06/2019</a>
                        </div>
                      </div>
                      <div class="course-video">
                        <iframe
                          src="https://www.youtube.com/embed/aOms7w_DGTo"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <div class="video-btn">
                          <button class="btn btn-grey video-btn-right pull-right">
                            <span>next lesson</span>
                            <i class="fa fa-angle-right"></i>
                          </button>
                          <button class="btn btn-grey video-btn-left pull-right">
                            <i class="fa fa-angle-left"></i>
                            <span>previous lesson</span>
                          </button>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <div class="course-des">
                        <div class="course-des-title underline">I. GIỚI THIỆU VỀ LEGO EDUCATION</div>
                        <div class="course-des-content">
                          <p>
                            LEGO Education là chương trình giáo dục phát triển tài năng trẻ do tập đoàn LEGO – Đan mạch
                            nổi tiếng thế giới sáng lập với mục đích áp dụng các phương pháp dạy học tiến bộ nhằm khơi
                            dậy ở trẻ niềm đam mê STEM (Khoa học, Công nghệ, Kỹ thuật, Toán học). Cho đến nay, chương
                            trình của LEGO Education đã được áp dụng rộng rãi ở hơn 55 quốc gia trên thế giới như Mỹ,
                            Đức, Nga, Nhật...
                          </p>
                        </div>
                        <br></br>
                        <div class="course-des-title underline">Sơ nét về LEGO Education Việt Nam</div>
                        <div>
                          <p>
                            Từ những ngày đầu tiên có mặt ở Việt Nam với sứ mệnh mang đến một chương trình giáo dục mới
                            mẻ, tốt nhất nhằm đào tạo và hoàn thiện kỹ năng thế hệ trẻ, LEGO Education đã nhận được sự
                            quan tâm đặc biệt của các bậc phụ huynh và các em học sinh. Là đơn vị tiên phong và được sự
                            cho phép của Sở GD&ĐT Tp. Hồ Chí Minh và Sở GD&ĐT Tp. Đà Nẵng, đến nay, chương trình của
                            LEGO Education đã được áp dụng ở hơn 50 trường Tiểu học - Trung học tại TP. Hồ Chí Minh và
                            các thành phố lớn. Dựa theo giáo trình của LEGO Education Đan Mạch, điểm đặc biệt của giáo
                            trình là Phương pháp 4C cùng sự hướng dẫn của những giảng viên là giáo viên tại các Trường
                            Tiểu Học, THCS và THPT giàu kinh nghiệm, đảm bảo được chất lượng học tập cũng như tính sư
                            phạm trong mỗi bài giảng, phù hợp với chương trình học tập tại Việt Nam, từ đó học sinh có
                            thể phát triển những mô hình sáng tạo và ứng dụng cao, có thể áp dụng trong đời sống hằng
                            ngày. Cách học này giúp các em nhanh chóng tiếp thu, ghi nhớ kiến thức cũng như hỗ trợ các
                            em tối đa trong việc phát huy khả năng tư duy, sáng tạo của bản thân.
                          </p>
                        </div>
                        <br></br>
                        <div class="course-des-title underline">Phương Pháp 4C là gì?</div>
                        <div>
                          <p>4C là từ viết tắt của các từ khóa: Connect, Construct, Contemplate và Continue..</p>
                          <p>
                            - Connect - Kết nối: học sinh sẽ được giới thiệu về những vấn đề thực tiễn trong cuộc sống
                            hằng ngày nhằm giúp các em có cái nhìn tổng quát và toàn diện về vấn đề và thử thách của bài
                            học.
                          </p>
                          <p>
                            - Contruct - Tạo dựng: Các em được yêu cầu lắp ráp mô hình robot nhằm khuyến khích hợp tác
                            làm việc nhóm và nâng cao trải nghiệm đôi tay
                          </p>
                          <p>
                            - Contemplate – Đánh giá: Đây là bước các em sẽ được học cách đánh giá, kiểm tra và khám phá
                            những giải pháp mới.
                          </p>
                          <p>
                            - Continue – Tiếp tục: khuyến khích các em học sinh tự tìm tòi sáng tạo thêm để có thể nâng
                            cấp sản phầm robot của mình đạt hiệu quả tốt hơn cũng như hiểu thêm về những bài học vận
                            dụng vào cuộc sống. .
                          </p>
                        </div>
                        <br></br>
                        <div class="course-des-title underline">Đại diện triển khai chương trình LEGO EDUCATION</div>
                        <div>
                          <p>
                            Công ty cổ phần Việt Tinh Anh (VTA) là Đại diện độc quyền của LEGO Education tại Việt Nam,
                            và STEM SQUARE là đối tác triển khai của VTA tại Việt Nam nói chung và miền trung nói riêng.
                          </p>
                        </div>

                        <div class="course-des-title underline">II. MÔ HÌNH GIÁO DỤC STEM ROBOTICS</div>
                        <div>
                          STEM Robotics là một nền tảng tuyệt vời cho việc học các kỹ năng thế kỷ 21. Giải quyết các thử
                          thách robot giúp xây dựng sự đổi mới, sáng tạo và kỹ năng giải quyết vấn đề cho học sinh, và
                          bởi vì robot hàm chứa nhiều chương trình giảng dạy các môn học, do đó học sinh cần phải học và
                          áp dụng kiến thức của các em từ khoa học, kỹ thuật, toán học và lập trình máy tính. Đối với
                          chương trình giáo dục STEM Robotics trên thị trường thì có rất nhiều, nhưng chúng tôi tuyển
                          chọn và tích góp kinh nghiệm, cùng với tất cả tài liệu của LEGO EDUCATION cung cấp, STEM
                          SQUARE đưa ra các mô hình giáo dục STEM ROBOTICS như sau:
                        </div>
                        <br></br>
                        <div class="course-des-title underline">
                          1. Bộ chương trình giảng dạy LEGO EDUCATION WEDO 2.0
                        </div>
                        <div>
                          a. Công cụ Robot WeDo 2.0 LEGO Education WeDo 2.0 được phát triển từ tập đoàn LEGO và nhằm thu
                          hút và thúc đẩy niềm yêu thích của học sinh tiểu học đối với những bộ môn liên quan đến khoa
                          học và kỹ thuật. Với mô hình này, học sinh ở cấp tiểu học có thể tìm tòi tính sáng tạo, giải
                          quyết những vấn đề có tính thực tiễn cao, bộ tài liệu được hoàn thành thông qua việc sử dụng
                          mô hình LEGO và áp dụng các dạng lập trình hết sức cơ bản cho các học sinh ở cấp độ tuổi 6-11
                          tuổi. WeDo 2.0 mục đích hỗ trợ phương pháp học tập thông qua thực hành, luôn tạo cho các em tự
                          tin tư duy, đặt câu hỏi và dùng công cụ để tìm câu trả lời giải quyết vấn đề thực tế. Không
                          chỉ là mô hình lắp ráp thuần túy như các thiết bị chơi của trẻ em tại thị trường, mà LEGO WeDo
                          2.0 hướng đến cho các em tự giải quyết vấn đề một cách có tính logic, hướng các em học mà chơi
                          – chơi mà học thông qua bộ Robot WeDo 2.0 và tài liệu giáo trình từ STEM SQUARE biên soạn. Với
                          tài liệu không hướng dẫn các em một cách máy móc, mà chỉ hướng dẫn các em hướng đi, cách suy
                          nghĩ và tự giải quyết lắp ghép theo mô hình hướng dẫn sao cho nhằm giải quyết 1 công việc cụ
                          thể, giúp các em có thể đặt câu hỏi đối với những gì các em biết và khám phá những điều các em
                          chưa rõ.
                        </div>
                        <br></br>
                        <div>
                          b. Học và giải quyết vấn đề thông qua các bài giảng: WeDo 2.0 đã thiết kế đa dạng các dự án
                          khác nhau và chia thành các loại sau:  01 dự án khởi động chia thành 4 phần, nhằm giúp các em
                          nắm được những chức năng cơ bản của wedo 2.0  08 dự án theo hướng dẫn liên kết với tiêu chuẩn
                          chương trình giảng dạy, cùng với những hướng dẫn từng bước giúp hoàn thành dự án.  08 dự án
                          mở liên kết với tiêu chuẩn chương trình giảng dạy, cùng với nhiều trải nghiệm mở hơn
                        </div>
                        <br></br>
                        <div>
                          Tất cả các dự án được chia thành ba giai đoạn chuẩn: Cho phép học sinh lắp ráp và lập trình;
                          và giai đoạn Chia sẻ, giải thích và trình bày dự án của các em. Mỗi dự án nên kéo dài khoảng
                          ba giờ. Mỗi giai đoạn đều có tầm quan trọng như nhau trong quá trình dự án và có thể kéo dài
                          khoảng 45 phút, nhưng giáo viên có thể điều chỉnh thời gian dành cho từng giai đoạn.  Giai
                          đoạn Khám phá  Học sinh sẽ thực hiện kết nối với các câu hỏi khoa học hoặc vấn đề kỹ thuật,
                          xem xét và thiết lập thứ tự câu hỏi, cân nhắc những giải pháp hợp lý, sau đó cùng nhau thảo
                          luận trong đội hoặc giáo viên hướng dẫn để chọn giải pháp.  Các bước của giai đoạn Khám phá
                          bao gồm: Kết nối và Thảo luận.  Giai đoạn Sáng tạo  Tại giai đoạn này học sinh lắp ráp thiết
                          bị, lập trình và điều chỉnh mô hình LEGO. Dự án có thể là một trong ba loại: nghiên cứu, thiết
                          kế giải pháp, và sử dụng mô hình. Phụ thuộc vào loại dự án, giai đoạn Sáng tạo sẽ khác nhau. 
                          Các bước của giai đoạn Sáng tạo bao gồm : lắp ráp, lập trình và điều chỉnh.  Giai đoạn Chia
                          sẻ  Học sinh trình bày và giải thích giải pháp của mình thông qua mô hình LEGO và chứng minh
                          các em tạo ra khám phá của mình kết hợp với công cụ Dẫn chứng.  Các bước của giai đoạn Chia
                          sẻ bao gồm: dẫn chứng và trình bày.
                        </div>
                        <br></br>
                        <div>
                          c. Học theo các dự án (chủ đề) hướng dẫn Dự án hay chủ đề là hỗ trợ cho các em có được một nền
                          tảng cần thiết cho việc khám phá cụ thể về một chủ đề/ dự án nào đó, ví dụ: xử lý nước, tham
                          gia không gian vũ trụ, xe thông minh, thành phố thông minh…tất cả sẽ được giáo viên giúp thiết
                          lập thành một chuỗi hoạt động và hỗ trợ trãi nghiệm, để các em có thể tự giải quyết vấn đề, và
                          thông qua công cụ trình bài, thực hiện chủ đề đó. Tất cả các dự án theo hướng dẫn đều dựa trên
                          sự phối hợp các giai đoạn Khám phá, Sáng tạo và Chia sẻ nhằm đảm bảo các em tiến bộ theo từng
                          bước thông qua trải nghiệm học tập. Từng dự án cụ thể, giáo viên sẽ cung cấp những ghi chú bao
                          gồm:  Kết nối với chương trình giảng dạy STEM SQUARE  Chuẩn bị chi tiết cho chủ đề  Hệ
                          thống đánh giá từ STEM SQUARE  Hỗ trợ và kỹ thuật phân biệt để các em hiểu đúng vấn đề của
                          chủ đề mà chưa được trải nghiệm.  Nhóm hỗ trợ cho các giai đoạn Khám phá, Sáng tạo và Chia sẻ
                           Cần tham khảo thêm chương trình “Dự án theo hướng dẫn của STEM SQUARE” để khám phá và nghiên
                          cứu.
                        </div>
                        <br></br>
                        <div>
                          d. Học theo các dự án (chủ đề) mở và cộng tác Dự án/ chủ đề mở là dự án mà không có sự hướng
                          dẫn chi tiết, mà được đưa ra đầu bài, sau đó các em sẽ dùng kiến thức học được và những gì mà
                          các em hình dung, tưởng tượng để giải quyết và đưa ra giải pháp phù hợp nhất, nhưng cũng phải
                          qua các giai đoạn chuẩn Khám phá, Sáng tạo và Chia sẻ. Tại dự án này, các Giáo viên sẽ hỗ trợ
                          các thông tin, hình ảnh, video…cách giải quyết vấn đề để các em hoàn toàn độc lập thực hiện
                          theo nhóm. Thường là ngắn gọn, nhưng vẫn đảm bảo được sử dụng công cụ để lắp ráp và giải pháp
                          hiệu quả. Điểm quan trọng khi sử dụng Dự án mở là biến các dự án này có thể thực hiện và áp
                          dụng thực tế tại địa phương hoặc cộng đồng xã hội. Bên cạnh đó cần có trí sáng tạo của giáo
                          viên để điều chỉnh ý tưởng dự án phù hợp với các em và xã hội. Giáo viên và các em sẽ tích góp
                          trở thành một kho tàng kiến thức sáng tạo để có thể là nền tảng vững chắc trong giảng dạy,
                          đồng thời là có thể áp dụng vào thực tế. Tham khảo giám trình và kho “Dự án mở” từ STEM SQUARE
                          để giảng dạy và nghiêng cứu. Cũng có thể sẽ tìm thấy “Thư viện của dự án mở” trong phần mềm
                          WeDo 2.0 thêm.
                        </div>
                        <br></br>
                        <RelatedNews />
                      </div>
                      <div class="course-syllabus" style={{ display: 'none' }}>
                        <RoboticSchedule />
                      </div>
                    </div>

                    {/* <div class="col-md-3 sidebar layout-right">
                      <div class="row">
                        <div class="author-widget widget col-sm-6 col-md-12 col-xs-6 sd380">
                          <div class="title-widget">GV MAI VĂN TÙNG</div>
                          <div class="content-widget">
                            <div class="staff-item author-widget-wrapper customize">
                              <div class="staff-item-wrapper">
                                <div class="staff-info">
                                  <a href="#" class="staff-avatar">
                                    <img
                                      src="assets/images/Teacher 2 - Mai Van Tung.jpg"
                                      alt=""
                                      class="img-responsive"
                                    />
                                  </a>
                                  <a href="#" class="staff-name">
                                    MAI VĂN TÙNG
                                  </a>

                                  <div class="staff-job">head teacher</div>
                                  <div class="staff-desctiption">
                                    Đã có 15 năm giảng dạy STEM Robotics, hiện nay là trưởng nhóm giáo viên tại STEM
                                    SQUARE.
                                  </div>
                                </div>
                              </div>
                              <div class="staff-socials">
                                <a href="#" class="facebook">
                                  <i class="fa fa-facebook"></i>
                                </a>
                                <a href="#" class="google">
                                  <i class="fa fa-google-plus"></i>
                                </a>
                                <a href="#" class="twitter">
                                  <i class="fa fa-twitter"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="popular-course-widget widget col-sm-6 col-md-12 col-xs-6 sd380">
                          <div class="title-widget">Khóa học STEM Robotics</div>
                          <div class="content-widget">
                            <div class="media">
                              <div class="media-left">
                                <a href="courses-detail.html" class="link">
                                  <img src="assets/images/popular/popular-1.jpg" alt="" class="media-image" />
                                </a>
                              </div>
                              <div class="media-right">
                                <a href="courses-detail.html" class="link title">
                                  STEM Robotics Explorer 1-2
                                </a>
                                <div class="info">
                                  <div class="author item">
                                    <a href="#">
                                      <span>Thầy Đức</span>
                                    </a>
                                  </div>
                                </div>
                                <div>Học phí: 800 ngàn/ tháng</div>
                              </div>
                            </div>
                            <div class="media">
                              <div class="media-left">
                                <a href="courses-detail.html" class="link">
                                  <img src="assets/images/popular/popular-2.jpg" alt="" class="media-image" />
                                </a>
                              </div>
                              <div class="media-right">
                                <a href="courses-detail.html" class="link title">
                                  STEM Robotics Creator 3,4,5
                                </a>

                                <div class="info">
                                  <div class="author item">
                                    <a href="#">
                                      <span>Thầy Tùng, Thầy Đức</span>
                                    </a>
                                  </div>
                                </div>
                                <div>Học phí: 1 triệu/ tháng</div>
                              </div>
                            </div>
                            <div class="media">
                              <div class="media-left">
                                <a href="courses-detail.html" class="link">
                                  <img src="assets/images/popular/popular-3.jpg" alt="" class="media-image" />
                                </a>
                              </div>
                              <div class="media-right">
                                <a href="courses-detail.html" class="link title">
                                  STEM Robotics luyện thi
                                </a>

                                <div class="info">
                                  <div class="author item">
                                    <a href="#">
                                      <span>Thầy Tùng, Thầy Đức</span>
                                    </a>
                                  </div>
                                </div>
                                <div>Học phí: 1 triệu/ tháng</div>
                              </div>
                            </div>
                            <div class="media">
                              <div class="media-left">
                                <a href="courses-detail.html" class="link">
                                  <img src="assets/images/popular/popular-4.jpg" alt="" class="media-image" />
                                </a>
                              </div>
                              <div class="media-right">
                                <a href="courses-detail.html" class="link title">
                                  Lớp Cratch
                                </a>

                                <div class="info">
                                  <div class="author item">
                                    <a href="#">
                                      <span>Thầy Tùng</span>
                                    </a>
                                  </div>
                                </div>
                                <div>Học phí: 300 ngàn/ tháng</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="category-widget widget col-sm-6 col-md-12 col-xs-6 sd380">
                          <div class="title-widget">Các khóa học</div>
                          <div class="content-widget">
                            <ul class="category-widget list-unstyled">
                              <li>
                                <a href="categories.html" class="link cat-item">
                                  <span class="pull-left">STEM Robotics Explorer 1-2</span>
                                  <span class="pull-right">125</span>
                                </a>
                              </li>
                              <li>
                                <a href="categories.html" class="link cat-item">
                                  <span class="pull-left">STEM Robotics Creator 3,4,5</span>
                                  <span class="pull-right">97</span>
                                </a>
                              </li>
                              <li>
                                <a href="categories.html" class="link cat-item">
                                  <span class="pull-left">Scratch</span>
                                  <span class="pull-right">56</span>
                                </a>
                              </li>
                              <li>
                                <a href="categories.html" class="link cat-item">
                                  <span class="pull-left">Tin học không chuyên</span>
                                  <span class="pull-right">24</span>
                                </a>
                              </li>
                              <li>
                                <a href="categories.html" class="link cat-item">
                                  <span class="pull-left">STEM Robotics Thi đấu</span>
                                  <span class="pull-right">13</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationModal;
