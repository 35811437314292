import { atom, selector } from "recoil";
import { selectOptions } from "../../common";

export const classesState = atom({
    key: "classesState",
    default: [],
});

export const classesOptionState = selector({
    key: "classesOption",
    get: ({ get }) => {
        const classes = get(classesState);
        if (classes.length) {
            return selectOptions(classes);
        }
        return [];
    },
});




