import { useState, } from 'react';
import { useRecoilState } from 'recoil';
import { gradeState } from '../recoil/atom/gradeState';
import gradeApi from '../api/gradeApi';

export const useGrade = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [grades, setGrades] = useRecoilState(gradeState);

  const getAllGrade = async () => {
    setIsLoading(true);
    try {
      let res = await gradeApi.getAllGrade();
      if (res.data) {
        setGrades(res.data.elements);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    grades,
    getAllGrade,
    isLoading,
  };
};
