import { List } from 'antd';
import React from 'react';
import CourseItem from './CourseItem';

const ListCourse = ({ data, isLoading, grade, pageSize, courseRef }) => {
  return (
    <List
      grid={{
        gutter: 50,
        xs: 1,
        sm: 1,
        md: 1,
        lg: 2,
        xl: 2,
        xxl: 2,
      }}
      loading={isLoading}
      dataSource={data}
      pagination={{
        pageSize: pageSize,
        onChange: () => courseRef?.current.scrollIntoView(),
      }}
      renderItem={(item) => (
        <List.Item>
          <div className="col-style" style={{ width: '100%', marginBottom: 50 }}>
            <CourseItem item={item} />
          </div>
        </List.Item>
      )}
    />
  );
};

export default ListCourse;
