import Header from './components/Global/Header';
import Footer from './components/Global/Footer';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import RouteWithSubRoutes from './routers/RouteWithSubRoutes';
import { routes } from './routers';
import { ROUTER } from './constant/router';
import NewsDetail from './components/Public/News/NewsDetail';
import CourseDetail from './pages/Courses/CourseDetail';

import './styles/App.css';
import './styles/index.css';
import DetailProfile from './components/DetailProfile';
import WROIntroduction from './pages/Competitions/WRO/Introduction';
import WROFriendlyRound from './pages/Competitions/WRO/FriendlyRound';
import WROQualifyingRound from './pages/Competitions/WRO/QualifyingRound';
import WRORegionalRound from './pages/Competitions/WRO/RegionalRound';
import WROFinalRound from './pages/Competitions/WRO/FinalRound';
import FLLIntroduction from './pages/Competitions/FLL/Introduction';
import FLLFriendlyRound from './pages/Competitions/FLL/FriendlyRound';
import FLLQualifyingRound from './pages/Competitions/FLL/QualifyingRound';
import FLLRegionalRound from './pages/Competitions/FLL/RegionalRound';
import FLLFinalRound from './pages/Competitions/FLL/FinalRound';
import FLL2019 from './pages/Competitions/FLL2019';
import { languageState } from './recoil/atom/languageState';
import { useRecoilState } from 'recoil';
import { i18nConfig } from './lib/Language';
import moment from 'moment';
import "moment/locale/vi";
import "moment/locale/ja";
import { useEffect } from 'react';
import RegisterRobotics from './pages/dangky';

function App() {

  const [language, setLanguage] = useRecoilState(languageState);
  i18nConfig(language);
  moment.locale("vi");

  // check language
  useEffect(() => {
    const key = localStorage.getItem("language")
    if (key) {
      setLanguage(key)
    } else {
      setLanguage(language)
    }
  }, [])


  function flatten(destArray, nodeList) {
    nodeList.forEach((node) => {
      destArray.push(node);
      flatten(destArray, node.children || []);
    });
  }

  const menu = [];
  flatten(menu, routes);

  return (
    <div className="App">
      <Header />
      <Switch>
        {menu.map((route) => {
          if (route.path === null) {
            return 0;
          }
          return <RouteWithSubRoutes key={route.key} {...route} />;
        })}
        <Route path={`${ROUTER.PUBLIC.SHOW_NEWS_DETAIL.path}/:id`} exact children={<NewsDetail />} />
        <Route path={`${ROUTER.COURSES.COURSE_DETAIL.path}/:id`} exact children={<CourseDetail />} />
        <Route path={`${ROUTER.HOME.DETAILPROFILE.path}/:id`} exact children={<DetailProfile />} />
        {/* WRO */}
        <Route path={`${ROUTER.COMPETITIONS.WROINTRODUCTION.path}`} exact children={<WROIntroduction />} />
        <Route path={`${ROUTER.COMPETITIONS.WROFRIENDLYROUND.path}`} exact children={<WROFriendlyRound />} />
        <Route path={`${ROUTER.COMPETITIONS.WROQUALIFYINGROUND.path}`} exact children={<WROQualifyingRound />} />
        <Route path={`${ROUTER.COMPETITIONS.WROREGIONALROUND.path}`} exact children={<WRORegionalRound />} />
        <Route path={`${ROUTER.COMPETITIONS.WROFINALROUND.path}`} exact children={<WROFinalRound />} />
        {/* FLL */}
        <Route path={`${ROUTER.COMPETITIONS.FLLINTRODUCTION2019.path}`} exact children={<FLL2019 />} />

        <Route path={`${ROUTER.COMPETITIONS.FLLINTRODUCTION.path}/:year`} exact children={<FLLIntroduction />} />
        <Route path={`${ROUTER.COMPETITIONS.FLLFRIENDLYROUND.path}/:year`} exact children={<FLLFriendlyRound />} />
        <Route path={`${ROUTER.COMPETITIONS.FLLQUALIFYINGROUND.path}/:year`} exact children={<FLLQualifyingRound />} />
        <Route path={`${ROUTER.COMPETITIONS.FLLREGIONALROUND.path}/:year`} exact children={<FLLRegionalRound />} />
        <Route path={`${ROUTER.COMPETITIONS.FLLFINALROUND.path}/:year`} exact children={<FLLFinalRound />} />
        <Route path={`${ROUTER.REGISTER.path}`} exact children={<RegisterRobotics />} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
