import { Space } from 'antd';
import React from 'react';
import Categories from '../../../../components/Competition/Categories';
import BtnViewMore from '../../../../components/Global/Button/BtnViewMore';
import RelatedNews from '../../../../components/Global/RelatedNews';
import { m1, m13, m65, m8, wro_2022_01, wro_2022_02 } from '../../../../assets/images';
import { RoboMissionHeader, Wro2023IntroductionPanel } from '../../../../assets/images';

const WROFriendlyRound = () => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div className="slider-banner">
        <div className="slider-item">
            <div
              className="slider-1"
              style={{
                backgroundImage: `url(${RoboMissionHeader})`,
              }}
            >
            <div class="slider-caption">
              <div class="container"></div>
            </div>
          </div>
        </div>
      </div>

      <Categories />
      <div className="section courses-detail">
        <div className="container">
          <h1 className="title-lg">Vòng giao lưu</h1>
          <div className="courses-detail-wrapper">
            <div>
              <div>
                <h1 className="course-title">Ngày hội Giao lưu ROBOTACON WRO 2023</h1>
                <div className="course-info info">
                  <div className="author item">
                    <a href="#">
                      <span>By&nbsp;</span>
                      <span>Stem Square</span>
                    </a>
                  </div>
                  <div className="date-time item">
                    <a href="#">01/05/2023</a>
                  </div>
                </div>

                <div className="course-video">
                  <iframe
                    width="1246"
                    height="704"
                    src="https://www.youtube.com/embed/NRtf2wCGqiQ"
                    allowfullscreen="allowfullscreen"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                  <div className="video-btn">
                    <button className="btn btn-grey video-btn-right pull-right">
                      <span>next video</span>
                      <i className="fa fa-angle-right"></i>
                    </button>
                    <button className="btn btn-grey video-btn-left pull-right">
                      <i className="fa fa-angle-left"></i>
                      <span>previous video</span>
                    </button>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="course-des">
                  <div className="course-des-title underline">1. GIỚI THIỆU NGÀY HỘI STEM WRO</div>
                  <div className="course-des-content">
                    <p>STEM SQUARE thực hiện sân chơi giao lưu cho các bạn tại Miền Trung</p>
                  </div>
                </div>
                {/*  */}
                <div className="image">
                  <img
                    className="img-responsive"
                    src={Wro2023IntroductionPanel}
                    style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                  />
                </div>
                <br></br>
                <div>
                  <p>
                    ROBOTACON WRO 2023 là cuộc thi được tổ chức thường niên hằng năm của STEM SQUARE và các đối tác kết hợp tổ
                    chức, nhằm mang lại một sân chơi trí tuệ về khoa học ứng dụng Robotics và được liên thông theo chuẩn
                    của cuộc thi Robotacon Quốc gia, WRO Quốc tế được tổ chức bởi LEGO EDUCATION.
                  </p>
                  <p>
                    Cuộc thi xoay quanh chủ đề của năm 2023 là “KẾT NỐI THẾ GIỚI”, nối kết các vấn đề, con người với nhau, tự nhiên với con người. 
                    Thời điểm của cuộc thi được diễn ra là ngày chủ nhật cuối cùng của tháng 07 hằng năm, năm nay được chọn ngày 01/07/2023 để
                    làm sự kiện.
                  </p>
                  <p>
                    Tất cả học sinh của STEM SQUARE đều sẽ được tham gia như là một sân chơi giao lưu học hỏi của các
                    bạn học sinh, được chia thành các đội để thi đấu với nhau. Cũng như các học sinh của các đối tác
                    trong hệ thống cuộc thi đều sẽ được tham gia, bên cạnh đó cuộc thi được mở rộng cho tất cả các bạn
                    học sinh khác mà mua thiết bị tại STEM SQUARE đều được tham gia như đội mở rộng. Các bạn khác không
                    nằm trong cuộc thi đều được đến với sân chơi như một ngày hội, có rất nhiều chương trình hấp dẫn về
                    STEM, Toán tư duy, English, sản phẩm sáng tạo khác mà các em học sinh sẽ được trải nghiệm. Chủ đề
                    cuộc thi đều hoàn toàn dựa theo các cuộc thi Robotacon Quốc gia, và WRO Quốc tế để nhằm cho các em
                    trải nghiệm, rút kinh nghiệm trước cho cuộc thi có tính cạnh tranh lớn tại Việt Nam và thế giới.
                  </p>
                  <p>Đây là sân chơi bổ ích, sáng tạo và cọ sát thực tế cho môn học STEM của các bạn.</p>
                  <p>
                    <strong>Các bảng thi và trải nghiệm sáng tạo khoa học ứng dụng tại cuộc thi như sau:</strong>
                  </p>
                  <p>.  B0: Bảng vỡ lòng, sân chơi các thiết bị khoa học sáng tạo vui với độ tuổi là 06-08 tuổi (học sinh lớp 1,2)</p>
                  <p>
                  <p>.  B1: Bảng Robot nhiệm vụ, sân chơi thi đấu sa bàn độ tuổi là 07 tuổi (học sinh lớp 2,3,4)</p>
                  <p>.  B2: Bảng Robot nhiệm vụ, Phần thi đấu Robot tài năng Spake, EV3 độ tuổi 09 đến 15 tuổi</p>
                  </p>
                  <p>
                  </p>
                </div>
                <br></br>
                {/*  */}
                <div className="course-syllabus">
                  <div className="course-des-title underline">2. LỊCH TRÌNH TỔ CHỨC THI ĐẤU (DỰ KIẾN)</div>
                  <div className="course-table">
                    <div className="outer-container">
                      <div className="inner-container">
                        <div className="table-header">
                          <table className="edu-table-responsive">
                            <thead>
                              <tr className="heading-table">
                                <th className="col-3">Thời gian dự kiến</th>
                                <th className="col-1">Nội dung chương trình</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <div className="table-body">
                          <table className="edu-table-responsive table-hover">
                            <tbody>
                              <tr className="heading-content">
                                <td colspan="4" className="left heading-content">
                                  1. Bảng B0
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">7h30 - 8h00</td>
                                <td className="col-1">
                                  <span>Đón khách, thí sinh và phụ huynh. Ổn định vị trí.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h00 - 8h15</td>
                                <td className="col-1">
                                  <span>
                                    Đại diện Ban tổ chức chia sẻ một số lưu ý trong Công tác thi và Công bố luật thi.
                                  </span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h15 - 8h30</td>
                                <td className="col-1">
                                  <span>Huấn luyện viên hướng dẫn thí sinh.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h30 – 9h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h00 – 9h30</td>
                                <td className="col-1">
                                  <span>Thời gian thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h30 – 9h45</td>
                                <td className="col-1">
                                  <span>Tổng hợp kết quả thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h45 – 10h45</td>
                                <td className="col-1">
                                  <span>Tự do tham quan các gian hàng.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h45 – 11h00</td>
                                <td className="col-1">
                                  <span>Công bố kết quả thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">11h00</td>
                                <td className="col-1">
                                  <span>Kết thúc</span>
                                </td>
                              </tr>

                              <tr className="heading-content">
                                <td colspan="4" className="left heading-content">
                                  3. Bảng B1
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">7h30 - 8h00</td>
                                <td className="col-1">
                                  <span>Đón khách, thí sinh và phụ huynh. Ổn định vị trí.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h00 - 8h15</td>
                                <td className="col-1">
                                  <span>
                                    Đại diện Ban tổ chức chia sẻ một số lưu ý trong Công tác thi và Công bố luật thi.
                                  </span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h15 - 8h30</td>
                                <td className="col-1">
                                  <span>Huấn luyện viên hướng dẫn thí sinh.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h30 – 9h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h00 – 9h30</td>
                                <td className="col-1">
                                  <span>Lượt thi đấu thứ 1.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h30 – 10h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn (Lần 2).</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h – 10h30</td>
                                <td className="col-1">
                                  <span>Lượt thi đấu thứ 2.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h30 – 10h45</td>
                                <td className="col-1">
                                  <span>Tổng hợp kết quả thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h45 – 11h00</td>
                                <td className="col-1">
                                  <span>Công bố kết quả thi đấu</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">11h00</td>
                                <td className="col-1">
                                  <span>Kết thúc</span>
                                </td>
                              </tr>

                              <tr className="heading-content">
                                <td colspan="4" className="left heading-content">
                                  3. Bảng B2
                                </td>
                              </tr>

                              <tr className="table-row">
                                <td className="left col-2">7h30 - 8h00</td>
                                <td className="col-1">
                                  <span>Đón khách, thí sinh và phụ huynh. Ổn định vị trí.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h00 - 8h15</td>
                                <td className="col-1">
                                  <span>
                                    Đại diện Ban tổ chức chia sẻ một số lưu ý trong Công tác thi và Công bố luật thi.
                                  </span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h15 - 8h30</td>
                                <td className="col-1">
                                  <span>Huấn luyện viên hướng dẫn thí sinh.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h30 – 9h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h00 – 9h30</td>
                                <td className="col-1">
                                  <span>Lượt thi đấu thứ 1.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h30 – 10h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn (Lần 2).</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h – 10h30</td>
                                <td className="col-1">
                                  <span>Lượt thi đấu thứ 2.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h30 – 10h45</td>
                                <td className="col-1">
                                  <span>Tổng hợp kết quả thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h45 – 11h00</td>
                                <td className="col-1">
                                  <span>Công bố kết quả thi đấu</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">11h00</td>
                                <td className="col-1">
                                  <span>Kết thúc</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br></br>
                        <div>• Thời gian có thể thay đổi theo thực tế</div>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <br></br>

                <div className="course-des-title underline">3. THỂ LỆ CUỘC THI</div>
                <div>
                  <div className="list-expand-title">a. Nhiệm vụ của chủ đề</div>
                  <p>
                    {' '}
                    Trong mùa giải này, GRG sẽ tập trung vào chủ đề Giải pháp bền vững. Tài nguyên thiên
                    nhiên là vô hạn và để đạt được một tương lai bền vững hơn, chúng ta sẽ cần áp dụng các
                    phương pháp và công nghệ có thể giảm thiểu các tác động tiêu cực đến môi trường và thúc
                    đẩy việc bảo tồn các nguồn tài nguyên thiên nhiên của chúng ta. Chúng tôi mong muốn khám
                    phá việc sử dụng năng lượng tái tạo, phát triển hệ thống giao thông bền vững và/hoặc thực
                    hiện quản lý chất thải. Hãy biến những ý tưởng bền vững của chúng ta thành hành động để
                    tạo ra một thế giới tốt đẹp hơn cho chính chúng ta và cho các thế hệ tương lai của chúng ta.
                  </p>
                </div>

                <div>
                  <div className="list-expand-title">b. Thời gian và địa điểm:</div>
                  <p>  Thời gian đăng ký dự thi: trước ngày 15/06/2023</p>
                  <p>
                    {' '}
                     Hình thức ăng ký: Đăng ký trực tiếp với trung tâm STEM SQUARE với điều kiện đã mua bộ LEGO tại
                    trung tâm hoặc đã và đang học tại STEM SQUARE.
                  </p>
                  <p> Có thể đăng ký trực tuyến với các học sinh chưa học tại STEM SQUARE</p>
                  <BtnViewMore title="Đăng ký tham gia" />
                  <p></p>
                  <p>  Thời gian tổ chức: Sáng ngày 01/07/2019 (chủ nhật)</p>
                  <p>  Địa điểm tổ chức: Sân trường Đại học Bách Khoa (dự kiến)</p>
                </div>
                <br></br>
                <div className="course-des-title underline">4. GIẢI THƯỞNG</div>
                <div>
                  <div>
                    <div className="inner-container">
                      <div className="table-header">
                        <table className="edu-table-responsive">
                          <thead>
                            <tr className="heading-table">
                              <th className="col-3">Giải</th>
                              <th className="col-1">Giá trị giải thưởng</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="table-body">
                        <table className="edu-table-responsive table-hover">
                          <tbody>
                            <tr className="heading-content">
                              <td colspan="4" className="left heading-content">
                                1. Bảng B0
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2"></td>
                              <td className="col-1">
                                <span>10 phần quà</span>
                              </td>
                            </tr>
                            <tr className="heading-content">
                              <td colspan="4" className="left heading-content">
                                3. Bảng B1
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải nhất</td>
                              <td className="col-1">
                                <span>1.000.000 VND</span>
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải nhì</td>
                              <td className="col-1">
                                <span>500.000 VND</span>
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải ba</td>
                              <td className="col-1">
                                <span>300.000 VND</span>
                              </td>
                            </tr>

                            <tr className="heading-content">
                              <td colspan="4" className="left heading-content">
                                3. Bảng B2
                              </td>
                            </tr>

                            <tr className="table-row">
                              <td className="left col-2">01 giải nhất</td>
                              <td className="col-1">
                                <span>1.000.000 VND</span>
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải nhì</td>
                              <td className="col-1">
                                <span>500.000 VND</span>
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải ba</td>
                              <td className="col-1">
                                <span>300.000 VND</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <br></br>
                <div>
                  <BtnViewMore title="Download giới thiệu chương trình" />
                  <BtnViewMore title="Download lịch cụ tổ chức cụ thể" />
                  <BtnViewMore title="Download đề và luật thi đấu B0" />
                  <BtnViewMore title="Download đề và luật thi đấu B1" />
                  <BtnViewMore title="Download đề và luật thi đấu B2" />
                </div>
                <br></br>
                <div className="course-des-title underline">5. MỘT SỐ HÌNH ẢNH NGÀY HỘI ĐÃ TỔ CHỨC NẰM 2019 TẠI TRƯỜNG SAKURA</div>
                <div className="list-expand-title">a. Hình ảnh</div>
                <div className="image">
                  <img
                    className="img-responsive"
                    src={m8}
                    style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                  />
                  <img
                    className="img-responsive"
                    src={m13}
                    style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                  />
                  <img
                    className="img-responsive"
                    src={m1}
                    style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                  />
                  <img
                    className="img-responsive"
                    src={m65}
                    style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                  />
                  
                </div>
                {/* <BtnViewMore title="Xem thêm" /> */}
                {/*  */}
                {/* <div>
                  <div className="list-expand-title">b. Links báo chí đã đăng</div>

                  <RelatedNews />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Space>
  );
};

export default WROFriendlyRound;
