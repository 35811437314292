import { axiosApiInstanceAuth } from "../utils/axiosClient";

const classApi = {
  getAllClasses: () => {
    const path = `/class/getAll`;
    return axiosApiInstanceAuth.get(path);
  },
};

export default classApi;
