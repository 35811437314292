import React from 'react';

const Schedule = () => {
  return (
    <div class="course-syllabus">
      <div class="course-syllabus-title underline">Thời khóa biểu khai giảng hè - 2019</div>
      <div class="course-table">
        <div class="outer-container">
          <div class="inner-container">
            <div class="table-header">
              <table class="edu-table-responsive">
                <thead>
                  <tr class="heading-table">
                    <th class="col-1">Khóa học</th>
                    <th class="col-2">Ngày khai giảng</th>
                    <th class="col-3">Thời gian</th>
                    <th class="col-4">Tình trạng</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div class="table-body">
              <table class="edu-table-responsive table-hover">
                <tbody>
                  <tr class="heading-content">
                    <td colspan="4" class="left heading-content">
                      1. STEM Robotics Explorer (Thiết bị giảng dạy WeDo 2.0)
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="left col-1">
                      <a href="#">
                        <i class="bg-green mr25 fa fa-caret-right"></i>
                        <span>Lớp WeDo Dài hạn</span>
                      </a>
                    </td>
                    <td class="col-2">
                      <i class="w20 fa fa-date-time"></i>
                      <span>01/06/2019</span>
                    </td>
                    <td class="col-3">
                      <i class="w20 fa fa-clock-o"></i>
                      <span>8h00:9h30</span>
                    </td>
                    <td class="green-color col-4">
                      <i class="w27 fa fa-check-square"></i>
                      <span>Đang học</span>
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="left col-1">
                      <a href="#">
                        <i class="bg-green mr25 fa fa-caret-right"></i>
                        <span>Lớp WeDo thi đấu FLL</span>
                      </a>
                    </td>
                    <td class="col-2">
                      <i class="w20 fa fa-date-time"></i>
                      <span>01/06/2019</span>
                    </td>
                    <td class="col-3">
                      <i class="w20 fa fa-clock-o"></i>
                      <span>8h00:9h30</span>
                    </td>
                    <td class="bold-color col-4">
                      <i class="w27 fa fa-pencil-square-o"></i>
                      <span>Còn đăng ký</span>
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="left col-1">
                      <a href="#">
                        <i class="mr18 fa fa-file-text"></i>
                        <span>Lớp WeDo khóa học hè</span>
                      </a>
                    </td>
                    <td class="col-2">
                      <i class="w20 fa fa-date-time"></i>
                      <span>01/06/2019</span>
                    </td>
                    <td class="col-3">
                      <i class="w20 fa fa-clock-o"></i>
                      <span>8h00:9h30</span>
                    </td>
                    <td class="col-4">
                      <div class="bg-yellow">Học thử</div>
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="left col-1">
                      <a href="#">
                        <i class="mr18 fa fa-file-text"></i>
                        <span>Lớp WeDo sáng tạo</span>
                      </a>
                    </td>
                    <td class="col-2">
                      <i class="w20 fa fa-date-time"></i>
                      <span>01/07/2019</span>
                    </td>
                    <td class="col-3">
                      <i class="w20 fa fa-clock-o"></i>
                      <span>8h00:9h30</span>
                    </td>
                    <td class="bolder-color col-4">
                      <i class="w27 fa fa-lock"></i>
                      <span>Chuẩn bị khai giảng</span>
                    </td>
                  </tr>
                  <tr class="spacing-table">
                    <td colspan="4"></td>
                  </tr>

                  <tr class="heading-content">
                    <td colspan="4" class="left heading-content">
                      2. STEM Robotics Creator (Thiết bị giảng dạy EV3)
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="left col-1">
                      <a href="#">
                        <i class="bg-green mr25 fa fa-caret-right"></i>
                        <span>Lớp EV3 Dài hạn</span>
                      </a>
                    </td>
                    <td class="col-2">
                      <i class="w20 fa fa-date-time"></i>
                      <span>01/06/2019</span>
                    </td>
                    <td class="col-3">
                      <i class="w20 fa fa-clock-o"></i>
                      <span>8h00:9h30</span>
                    </td>
                    <td class="green-color col-4">
                      <i class="w27 fa fa-check-square"></i>
                      <span>Đang học</span>
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="left col-1">
                      <a href="#">
                        <i class="bg-green mr25 fa fa-caret-right"></i>
                        <span>Lớp EV3 thi đấu FLL</span>
                      </a>
                    </td>
                    <td class="col-2">
                      <i class="w20 fa fa-date-time"></i>
                      <span>01/06/2019</span>
                    </td>
                    <td class="col-3">
                      <i class="w20 fa fa-clock-o"></i>
                      <span>8h00:9h30</span>
                    </td>
                    <td class="bold-color col-4">
                      <i class="w27 fa fa-pencil-square-o"></i>
                      <span>Còn đăng ký</span>
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="left col-1">
                      <a href="#">
                        <i class="mr18 fa fa-file-text"></i>
                        <span>Lớp EV3 khóa học hè</span>
                      </a>
                    </td>
                    <td class="col-2">
                      <i class="w20 fa fa-date-time"></i>
                      <span>01/06/2019</span>
                    </td>
                    <td class="col-3">
                      <i class="w20 fa fa-clock-o"></i>
                      <span>8h00:9h30</span>
                    </td>
                    <td class="col-4">
                      <div class="bg-yellow">Học thử</div>
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="left col-1">
                      <a href="#">
                        <i class="mr18 fa fa-file-text"></i>
                        <span>Lớp EV3 sáng tạo</span>
                      </a>
                    </td>
                    <td class="col-2">
                      <i class="w20 fa fa-date-time"></i>
                      <span>01/07/2019</span>
                    </td>
                    <td class="col-3">
                      <i class="w20 fa fa-clock-o"></i>
                      <span>8h00:9h30</span>
                    </td>
                    <td class="bolder-color col-4">
                      <i class="w27 fa fa-lock"></i>
                      <span>Chuẩn bị khai giảng</span>
                    </td>
                  </tr>

                  <tr class="spacing-table">
                    <td colspan="4"></td>
                  </tr>

                  <tr class="heading-content">
                    <td colspan="4" class="left heading-content">
                      3. Scratch (Lập trình nhí)
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="left col-1">
                      <a href="#">
                        <i class="bg-green mr25 fa fa-caret-right"></i>
                        <span>Lớp Scratch Dài hạn</span>
                      </a>
                    </td>
                    <td class="col-2">
                      <i class="w20 fa fa-date-time"></i>
                      <span>01/06/2019</span>
                    </td>
                    <td class="col-3">
                      <i class="w20 fa fa-clock-o"></i>
                      <span>8h00:9h30</span>
                    </td>
                    <td class="green-color col-4">
                      <i class="w27 fa fa-check-square"></i>
                      <span>Đang học</span>
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="left col-1">
                      <a href="#">
                        <i class="bg-green mr25 fa fa-caret-right"></i>
                        <span>Lớp Scratch thi đấu FLL</span>
                      </a>
                    </td>
                    <td class="col-2">
                      <i class="w20 fa fa-date-time"></i>
                      <span>01/06/2019</span>
                    </td>
                    <td class="col-3">
                      <i class="w20 fa fa-clock-o"></i>
                      <span>8h00:9h30</span>
                    </td>
                    <td class="bold-color col-4">
                      <i class="w27 fa fa-pencil-square-o"></i>
                      <span>Còn đăng ký</span>
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="left col-1">
                      <a href="#">
                        <i class="mr18 fa fa-file-text"></i>
                        <span>Lớp Scratch khóa học hè</span>
                      </a>
                    </td>
                    <td class="col-2">
                      <i class="w20 fa fa-date-time"></i>
                      <span>01/06/2019</span>
                    </td>
                    <td class="col-3">
                      <i class="w20 fa fa-clock-o"></i>
                      <span>8h00:9h30</span>
                    </td>
                    <td class="col-4">
                      <div class="bg-yellow">Học thử</div>
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="left col-1">
                      <a href="#">
                        <i class="mr18 fa fa-file-text"></i>
                        <span>Lớp Scratch sáng tạo</span>
                      </a>
                    </td>
                    <td class="col-2">
                      <i class="w20 fa fa-date-time"></i>
                      <span>01/07/2019</span>
                    </td>
                    <td class="col-3">
                      <i class="w20 fa fa-clock-o"></i>
                      <span>8h00:9h30</span>
                    </td>
                    <td class="bolder-color col-4">
                      <i class="w27 fa fa-lock"></i>
                      <span>Chuẩn bị khai giảng</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
