import React from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import { Curibot, MayGiatCuaTren, RobotAmThanh, Umake, XeNangLuong } from '../../../assets/images';
import RelatedNews from '../../../components/Global/RelatedNews';
import i18n from '../../../lib/Language';

const InnovationStem = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div className="main-content">
          <div className="content">
            <Breadcrumb address={i18n.t('FLLs.diveceLego.diviceStem')} />

            {/* SEARCH */}
            <div className="section" style={{ display: 'none' }}>
              <div className="search-input">
                <div className="container">
                  <div className="search-input-wrapper">
                    <form>
                      <select className="form-select style-1 selectbox">
                        <option value="all">all categories</option>
                        <option value="languages">languages</option>
                        <option value="science">science</option>
                      </select>
                      <select className="form-select style-2 selectbox">
                        <option value="price">price</option>
                        <option value="datetime">datetime</option>
                        <option value="teacher">teacher</option>
                      </select>
                      <input type="text" placeholder="Do you want to learn today?" className="form-input" />
                      <button type="submit" className="form-submit btn btn-blue">
                        <span>
                          search now<i className="fa fa-search"></i>
                        </span>
                      </button>
                      <div className="clearfix"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* END SEARCH */}

            <div className="section section-padding latest-news">
              <div className="container">
                <div className="group-title-index">
                  <h4 className="top-title">{i18n.t('FLLs.diveceLego.diviceStemRobo')} </h4>

                  <h2 className="center-title">STEM SQUARE INNOVATION</h2>

                  <div className="bottom-title">
                    <i className="bottom-icon icon-a-01-01"></i>
                  </div>
                </div>
                <div className="latest-news-wrapper">
                  <div className="edugate-layout-1">
                    <div className="edugate-image">
                      <img src={XeNangLuong} alt="" className="img-responsive" />
                    </div>
                    <div className="edugate-content">
                      <a href="#" className="title">
                        XE NĂNG LƯỢNG - HỌC VÀ CHƠI STEM SÁNG TẠO
                      </a>
                      <div className="info">
                        <div className="author item">
                          <a href="#">By Stem Square</a>
                        </div>
                        <div className="date-time item">
                          <a href="#">10/07/2019</a>
                        </div>
                      </div>
                      <div className="info-more">
                        <div className="view item">
                          <i className="fa fa-user"></i>
                          <p> 120</p>
                        </div>
                        <div className="comment item">
                          <i className="fa fa-comment"></i>

                          <p> 239</p>
                        </div>
                      </div>
                      <div className="description">
                        Dể sử dụng, dùng năng lượng mặt trời
                        <p>Có thể sử dụng cho bé 02 tuổi trở lên</p>
                      </div>
                      <div className="edugate-content">
                        <a className="title">Giá: 45,000 VNĐ</a>
                      </div>
                      <button onclick="window.location.href='#'" className="btn btn-green">
                        <span>{i18n.t('public.viewDetail')}</span>
                      </button>
                    </div>
                  </div>

                  <div className="edugate-layout-1">
                    <div className="edugate-image">
                      <img src={RobotAmThanh} alt="" className="img-responsive" />
                    </div>
                    <div className="edugate-content">
                      <a href="#" className="title">
                        Robot điều khiển bằng âm thanh
                      </a>

                      <div className="info">
                        <div className="author item">
                          <a href="#">By Stem Square</a>
                        </div>
                        <div className="date-time item">
                          <a href="#">10/07/2019</a>
                        </div>
                      </div>
                      <div className="info-more">
                        <div className="view item">
                          <i className="fa fa-user"></i>
                          <p> 13</p>
                        </div>
                        <div className="comment item">
                          <i className="fa fa-comment"></i>
                          <p> 39</p>
                        </div>
                      </div>
                      <div className="description">
                        <p>Robot điều khiển âm thanh dễ dàng cho trẻ chơi và học</p>
                        <p>Tuyệt đối an toàn</p>
                      </div>
                      <div className="edugate-content">
                        <a className="title">Giá: 240,000 VNĐ</a>
                      </div>
                      <button onclick="window.location.href='#'" className="btn btn-green">
                        <span>{i18n.t('public.viewDetail')}</span>
                      </button>
                      <br />
                    </div>
                  </div>

                  <div className="edugate-layout-1">
                    <div className="edugate-image">
                      <img src={MayGiatCuaTren} alt="" className="img-responsive" />
                    </div>
                    <div className="edugate-content">
                      <a href="#" className="title">
                        Máy giặt cửa trên - mô phỏng dạy học
                      </a>

                      <div className="info">
                        <div className="author item">
                          <a href="#">By Stem Square</a>
                        </div>
                        <div className="date-time item">
                          <a href="#">10/07/2019</a>
                        </div>
                      </div>
                      <div className="info-more">
                        <div className="view item">
                          <i className="fa fa-user"></i>
                          <p> 13</p>
                        </div>
                        <div className="comment item">
                          <i className="fa fa-comment"></i>
                          <p> 39</p>
                        </div>
                      </div>
                      <div className="description">
                        <p>Thể hiện khả năng tư duy, biết được quy trình của máy giặt ở nhà</p>
                        <p>Tuyệt đối an toàn</p>
                      </div>
                      <div className="edugate-content">
                        <a className="title">Giá: 115,000 VNĐ</a>
                      </div>
                      <button onclick="window.location.href='#'" className="btn btn-green">
                        <span>{i18n.t('public.viewDetail')}</span>
                      </button>
                      <br />
                    </div>
                  </div>

                  <button className="btn btn-green btn-latest-new">
                    <span>
                      {i18n.t('public.showAll')}
                      <i className="fa fa-long-arrow-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="section section-padding courses-detail" style={{ display: 'none' }}>
              <div className="container">
                <div className="courses-detail-wrapper">
                  <div className="row">
                    <div className="col-md-9 layout-left">
                      <h1 className="course-title">SÂN CHƠI THI ĐẤU STEM ROBOTICS</h1>

                      <div className="course-info info">
                        <div className="author item">
                          <a href="#">
                            <span>By&nbsp;</span>
                            <span>Tuấn Trương</span>
                          </a>
                        </div>
                        <div className="date-time item">
                          <a href="#">20 Aug 2019</a>
                        </div>
                      </div>
                      <div className="course-video">
                        <iframe
                          src="https://www.youtube.com/embed/xo1VInw-SKc?list=PL7JgdmQ0zTfatnGhUnRBnhMwYEraSIRdD"
                          allowfullscreen="allowfullscreen"
                        ></iframe>
                        <div className="video-btn">
                          <button className="btn btn-grey video-btn-right pull-right">
                            <span>next lesson</span>
                            <i className="fa fa-angle-right"></i>
                          </button>
                          <button className="btn btn-grey video-btn-left pull-right">
                            <i className="fa fa-angle-left"></i>
                            <span>previous lesson</span>
                          </button>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="course-des">
                        <div className="course-des-title underline">Lợi ích cho việc học STEM</div>
                        <div className="course-des-content">
                          <p>Nâng cao khả năng khoa học</p>
                          <p>Nâng cao kỹ năng công nghệ</p>
                          <p>Nâng cao kỹ năng kỹ thuật</p>
                          <p>Nâng cao kỹ năng toán học</p>
                          <p>Trang bị đầy đủ các kỹ năng của thế kỷ 21</p>

                          <div className="course-des-title underline">Giáo dục STEM là gì?</div>
                          <p>
                            Là giáo dục làm sao người học có thể hình thành từ ý tưởng, kỹ năng giải quyết vấn đề với sự
                            kết hợp các kiến thức học được thông qua những sự việc, vấn đề cụ thể. Đây không chỉ là một
                            cách dạy cụ thể cho một môn học nào cả như Robot hay Toán học, mà là mục tiêu đào tạo để
                            người học có đầy đủ kỹ năng và phát triển trong thế giới công nghệ và xu hướng thời đại công
                            nghiệp 4.0 hiện nay. Giáo dục STEM tạo ra những con người có thể đáp ứng được nhu cầu công
                            việc của thế kỷ 21, đáp ứng sự phát triển kinh tế, xã hội của quốc gia và có thể tác động
                            tích cực đến sự thay đổi của nền kinh tế tri thức trong bối cảnh toàn cầu hóa.
                          </p>
                          <p>
                            Những điểm mạnh của giáo dục STEM:
                            <p>Thứ nhất:</p>
                            Giáo dục STEM là phương thức giáo dục tích hợp theo cách tiếp cận liên môn
                            (interdisciplinary) và thông qua mô hình thực hành - ứng dụng. Thay vì dạy bốn môn học như
                            các đối tượng tách biệt và rời rạc, STEM kết hợp chúng thành một mô hình học tập gắn kết dựa
                            trên các ứng dụng thực tế. Qua đó, học sinh vừa học được kiến thức khoa học, vừa học được
                            cách vận dụng kiến thức đó vào thực tiễn.
                            <p>Thứ hai:</p>
                            Giáo dục STEM đề cao đến việc hình thành và phát triển năng lực giải quyết vấn đề cho người
                            học. Trong mỗi bài học theo chủ đề STEM, học sinh được đặt trước một tình huống có vấn đề
                            thực tiễn cần giải quyết liên quan đến các kiến thức khoa học.
                            <p>Thứ ba:</p>
                            Giáo dục STEM đề cao một phong cách học tập mới cho người học, đó là phong cách học tập sáng
                            tạo. Đặt người học vào vai trò của một nhà phát minh, người học sẽ phải hiểu thực chất của
                            các kiến thức được trang bị; phải biết cách mở rộng kiến thức; phải biết cách sửa chữa, chế
                            biến lại chúng cho phù hợp với tình huống có vấn đề mà người học đang phải giải quyết. .
                          </p>
                          <blockquote>
                            <div className="main-quote">
                              Ta không nên dạy trẻ nhỏ khoa học; mà hãy để trẻ nếm trải nó!
                            </div>
                            <div className="sub-quote">
                              STEM, giáo dục STEM, là một hướng mới đang phát triển trong giáo dục thế giới, đặc biệt ở
                              Hoa Kỳ. Việt Nam cũng đang phát triển sự quan tâm đến giáo dục này..
                            </div>
                            <footer>
                              Trương Quốc Tuấn&nbsp;
                              <small>(CEO - STEM SQUARE)</small>
                            </footer>
                          </blockquote>
                        </div>
                        <RelatedNews />
                      </div>
                      <div className="course-syllabus">
                        <div className="course-syllabus-title underline">Thời khóa biểu khai giảng hè - 2019</div>
                        <div className="course-table">
                          <div className="outer-container">
                            <div className="inner-container">
                              <div className="table-header">
                                <table className="edu-table-responsive">
                                  <thead>
                                    <tr className="heading-table">
                                      <th className="col-1">Khóa học</th>
                                      <th className="col-2">Ngày khai giảng</th>
                                      <th className="col-3">Thời gian</th>
                                      <th className="col-4">Tình trạng</th>
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                              <div className="table-body">
                                <table className="edu-table-responsive table-hover">
                                  <tbody>
                                    <tr className="heading-content">
                                      <td colspan="4" className="left heading-content">
                                        1. STEM Robotics Explorer (Thiết bị giảng dạy WeDo 2.0)
                                      </td>
                                    </tr>
                                    <tr className="table-row">
                                      <td className="left col-1">
                                        <a href="#">
                                          <i className="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp WeDo Dài hạn</span>
                                        </a>
                                      </td>
                                      <td className="col-2">
                                        <i className="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td className="col-3">
                                        <i className="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td className="green-color col-4">
                                        <i className="w27 fa fa-check-square"></i>
                                        <span>Đang học</span>
                                      </td>
                                    </tr>
                                    <tr className="table-row">
                                      <td className="left col-1">
                                        <a href="#">
                                          <i className="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp WeDo thi đấu FLL</span>
                                        </a>
                                      </td>
                                      <td className="col-2">
                                        <i className="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td className="col-3">
                                        <i className="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td className="bold-color col-4">
                                        <i className="w27 fa fa-pencil-square-o"></i>
                                        <span>Còn đăng ký</span>
                                      </td>
                                    </tr>
                                    <tr className="table-row">
                                      <td className="left col-1">
                                        <a href="#">
                                          <i className="mr18 fa fa-file-text"></i>
                                          <span>Lớp WeDo khóa học hè</span>
                                        </a>
                                      </td>
                                      <td className="col-2">
                                        <i className="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td className="col-3">
                                        <i className="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td className="col-4">
                                        <div className="bg-yellow">Học thử</div>
                                      </td>
                                    </tr>
                                    <tr className="table-row">
                                      <td className="left col-1">
                                        <a href="#">
                                          <i className="mr18 fa fa-file-text"></i>
                                          <span>Lớp WeDo sáng tạo</span>
                                        </a>
                                      </td>
                                      <td className="col-2">
                                        <i className="w20 fa fa-date-time"></i>
                                        <span>01/07/2019</span>
                                      </td>
                                      <td className="col-3">
                                        <i className="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td className="bolder-color col-4">
                                        <i className="w27 fa fa-lock"></i>
                                        <span>Chuẩn bị khai giảng</span>
                                      </td>
                                    </tr>
                                    <tr className="spacing-table">
                                      <td colspan="4"></td>
                                    </tr>

                                    <tr className="heading-content">
                                      <td colspan="4" className="left heading-content">
                                        2. STEM Robotics Creator (Thiết bị giảng dạy EV3)
                                      </td>
                                    </tr>
                                    <tr className="table-row">
                                      <td className="left col-1">
                                        <a href="#">
                                          <i className="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp EV3 Dài hạn</span>
                                        </a>
                                      </td>
                                      <td className="col-2">
                                        <i className="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td className="col-3">
                                        <i className="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td className="green-color col-4">
                                        <i className="w27 fa fa-check-square"></i>
                                        <span>Đang học</span>
                                      </td>
                                    </tr>
                                    <tr className="table-row">
                                      <td className="left col-1">
                                        <a href="#">
                                          <i className="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp EV3 thi đấu FLL</span>
                                        </a>
                                      </td>
                                      <td className="col-2">
                                        <i className="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td className="col-3">
                                        <i className="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td className="bold-color col-4">
                                        <i className="w27 fa fa-pencil-square-o"></i>
                                        <span>Còn đăng ký</span>
                                      </td>
                                    </tr>
                                    <tr className="table-row">
                                      <td className="left col-1">
                                        <a href="#">
                                          <i className="mr18 fa fa-file-text"></i>
                                          <span>Lớp EV3 khóa học hè</span>
                                        </a>
                                      </td>
                                      <td className="col-2">
                                        <i className="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td className="col-3">
                                        <i className="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td className="col-4">
                                        <div className="bg-yellow">Học thử</div>
                                      </td>
                                    </tr>
                                    <tr className="table-row">
                                      <td className="left col-1">
                                        <a href="#">
                                          <i className="mr18 fa fa-file-text"></i>
                                          <span>Lớp EV3 sáng tạo</span>
                                        </a>
                                      </td>
                                      <td className="col-2">
                                        <i className="w20 fa fa-date-time"></i>
                                        <span>01/07/2019</span>
                                      </td>
                                      <td className="col-3">
                                        <i className="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td className="bolder-color col-4">
                                        <i className="w27 fa fa-lock"></i>
                                        <span>Chuẩn bị khai giảng</span>
                                      </td>
                                    </tr>

                                    <tr className="spacing-table">
                                      <td colspan="4"></td>
                                    </tr>

                                    <tr className="heading-content">
                                      <td colspan="4" className="left heading-content">
                                        3. Scratch (Lập trình nhí)
                                      </td>
                                    </tr>
                                    <tr className="table-row">
                                      <td className="left col-1">
                                        <a href="#">
                                          <i className="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp Scratch Dài hạn</span>
                                        </a>
                                      </td>
                                      <td className="col-2">
                                        <i className="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td className="col-3">
                                        <i className="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td className="green-color col-4">
                                        <i className="w27 fa fa-check-square"></i>
                                        <span>Đang học</span>
                                      </td>
                                    </tr>
                                    <tr className="table-row">
                                      <td className="left col-1">
                                        <a href="#">
                                          <i className="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp Scratch thi đấu FLL</span>
                                        </a>
                                      </td>
                                      <td className="col-2">
                                        <i className="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td className="col-3">
                                        <i className="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td className="bold-color col-4">
                                        <i className="w27 fa fa-pencil-square-o"></i>
                                        <span>Còn đăng ký</span>
                                      </td>
                                    </tr>
                                    <tr className="table-row">
                                      <td className="left col-1">
                                        <a href="#">
                                          <i className="mr18 fa fa-file-text"></i>
                                          <span>Lớp Scratch khóa học hè</span>
                                        </a>
                                      </td>
                                      <td className="col-2">
                                        <i className="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td className="col-3">
                                        <i className="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td className="col-4">
                                        <div className="bg-yellow">Học thử</div>
                                      </td>
                                    </tr>
                                    <tr className="table-row">
                                      <td className="left col-1">
                                        <a href="#">
                                          <i className="mr18 fa fa-file-text"></i>
                                          <span>Lớp Scratch sáng tạo</span>
                                        </a>
                                      </td>
                                      <td className="col-2">
                                        <i className="w20 fa fa-date-time"></i>
                                        <span>01/07/2019</span>
                                      </td>
                                      <td className="col-3">
                                        <i className="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td className="bolder-color col-4">
                                        <i className="w27 fa fa-lock"></i>
                                        <span>Chuẩn bị khai giảng</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 sidebar layout-right">
                      <div className="row">
                        <div className="author-widget widget col-sm-6 col-md-12 col-xs-6 sd380">
                          <div className="title-widget">GV MAI VĂN TÙNG</div>
                          <div className="content-widget">
                            <div className="staff-item author-widget-wrapper customize">
                              <div className="staff-item-wrapper">
                                <div className="staff-info">
                                  <a href="#" className="staff-avatar">
                                    <img
                                      src="assets/images/Teacher 2 - Mai Van Tung.jpg"
                                      alt=""
                                      className="img-responsive"
                                    />
                                  </a>
                                  <a href="#" className="staff-name">
                                    MAI VĂN TÙNG
                                  </a>

                                  <div className="staff-job">head teacher</div>
                                  <div className="staff-desctiption">
                                    Đã có 15 năm giảng dạy STEM Robotics, hiện nay là trưởng nhóm giáo viên tại STEM
                                    SQUARE.
                                  </div>
                                </div>
                              </div>
                              <div className="staff-socials">
                                <a href="#" className="facebook">
                                  <i className="fa fa-facebook"></i>
                                </a>
                                <a href="#" className="google">
                                  <i className="fa fa-google-plus"></i>
                                </a>
                                <a href="#" className="twitter">
                                  <i className="fa fa-twitter"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="popular-course-widget widget col-sm-6 col-md-12 col-xs-6 sd380">
                          <div className="title-widget">Khóa học STEM Robotics</div>
                          <div className="content-widget">
                            <div className="media">
                              <div className="media-left">
                                <a href="courses-detail.html" className="link">
                                  <img src="assets/images/popular/popular-1.jpg" alt="" className="media-image" />
                                </a>
                              </div>
                              <div className="media-right">
                                <a href="courses-detail.html" className="link title">
                                  STEM Robotics Explorer 1-2
                                </a>
                                <div className="info">
                                  <div className="author item">
                                    <a href="#">
                                      <span>Thầy Đức</span>
                                    </a>
                                  </div>
                                </div>
                                <div>Học phí: 800 ngàn/ tháng</div>
                              </div>
                            </div>
                            <div className="media">
                              <div className="media-left">
                                <a href="courses-detail.html" className="link">
                                  <img src="assets/images/popular/popular-2.jpg" alt="" className="media-image" />
                                </a>
                              </div>
                              <div className="media-right">
                                <a href="courses-detail.html" className="link title">
                                  STEM Robotics Creator 3,4,5
                                </a>

                                <div className="info">
                                  <div className="author item">
                                    <a href="#">
                                      <span>Thầy Tùng, Thầy Đức</span>
                                    </a>
                                  </div>
                                </div>
                                <div>Học phí: 1 triệu/ tháng</div>
                              </div>
                            </div>
                            <div className="media">
                              <div className="media-left">
                                <a href="courses-detail.html" className="link">
                                  <img src="assets/images/popular/popular-3.jpg" alt="" className="media-image" />
                                </a>
                              </div>
                              <div className="media-right">
                                <a href="courses-detail.html" className="link title">
                                  STEM Robotics luyện thi
                                </a>

                                <div className="info">
                                  <div className="author item">
                                    <a href="#">
                                      <span>Thầy Tùng, Thầy Đức</span>
                                    </a>
                                  </div>
                                </div>
                                <div>Học phí: 1 triệu/ tháng</div>
                              </div>
                            </div>
                            <div className="media">
                              <div className="media-left">
                                <a href="courses-detail.html" className="link">
                                  <img src="assets/images/popular/popular-4.jpg" alt="" className="media-image" />
                                </a>
                              </div>
                              <div className="media-right">
                                <a href="courses-detail.html" className="link title">
                                  Lớp Cratch
                                </a>

                                <div className="info">
                                  <div className="author item">
                                    <a href="#">
                                      <span>Thầy Tùng</span>
                                    </a>
                                  </div>
                                </div>
                                <div>Học phí: 300 ngàn/ tháng</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="category-widget widget col-sm-6 col-md-12 col-xs-6 sd380">
                          <div className="title-widget">Các khóa học</div>
                          <div className="content-widget">
                            <ul className="category-widget list-unstyled">
                              <li>
                                <a href="categories.html" className="link cat-item">
                                  <span className="pull-left">STEM Robotics Explorer 1-2</span>
                                  <span className="pull-right">125</span>
                                </a>
                              </li>
                              <li>
                                <a href="categories.html" className="link cat-item">
                                  <span className="pull-left">STEM Robotics Creator 3,4,5</span>
                                  <span className="pull-right">97</span>
                                </a>
                              </li>
                              <li>
                                <a href="categories.html" className="link cat-item">
                                  <span className="pull-left">Scratch</span>
                                  <span className="pull-right">56</span>
                                </a>
                              </li>
                              <li>
                                <a href="categories.html" className="link cat-item">
                                  <span className="pull-left">Tin học không chuyên</span>
                                  <span className="pull-right">24</span>
                                </a>
                              </li>
                              <li>
                                <a href="categories.html" className="link cat-item">
                                  <span className="pull-left">STEM Robotics Thi đấu</span>
                                  <span className="pull-right">13</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="back-top">
        <a href="#top">
          <i className="fa fa-angle-double-up"></i>
        </a>
      </div>
    </div>
  );
};

export default InnovationStem;
