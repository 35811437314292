import { ROUTER } from '../constant/router';
import Introduce from '../pages/Home/Introduce';
import Home from '../pages/Home';
import News from '../pages/Public/News';
import Activities from '../pages/Public/Activities';
import Facebook from '../pages/Public/Facebook';
import ImageEvent from '../pages/Public/ImageEvent';
import Certifications from '../pages/Home/Certifications';
import Teams from '../pages/Home/Teams';
// import Philosophy from '../pages/Home/Philosophy';
import OpenClass from '../pages/Courses/OpenClass';
import Course from '../pages/Courses/Course';
import Lego from '../pages/Product/Lego';
import Negendo from '../pages/Product/Negendo';
import GaraStem from '../pages/Product/GaraStem';
import InnovationStem from '../pages/Product/InnovationStem';
import BenefitStem from '../components/Home/ChooseCourse/BenefitStem';
import WhatIsStem from '../components/Home/ChooseCourse/WhatIsStem';
import Competition from '../pages/Competitions/Competition';
import EducationModal from '../components/Home/ChooseCourse/EducationModal';
import Distribute from '../components/Home/ChooseCourse/Distribute';
import Robokids from '../pages/Competitions/Robokids';
import Robotacon from '../pages/Competitions/Robotacon';
import WRO from '../pages/Competitions/WRO';
import FLL from '../pages/Competitions/FLL';
import LearningTracking from '../pages/Library/LearningTracking';
import StemBook from '../pages/Library/StemBook';
import SteamVideos from '../pages/Library/StemVideos';
import Contact from '../pages/Contact';
import i18n from '../lib/Language';
import FAQs from '../pages/Public/FAQ';

export const routes = [
  // home
  {
    path: '/',
    key: 'HOME',
    label: 'HOME',
    exact: true,
    component: Home,
    children: [
      {
        path: ROUTER.HOME.INTRODUCE.path,
        key: 'INTRODUCE',
        label: `${i18n.t('public.introduce')}`,
        exact: true,
        component: Introduce,
        children: null,
      },
      // {
      //   path: ROUTER.HOME.PHILOSOPHY.path,
      //   key: 'PHILOSOPHY',
      //   label: 'Triết lý giáo dục',
      //   exact: true,
      //   // component: Philosophy,
      //   component: null,
      //   children: null,
      // },
      {
        path: ROUTER.HOME.TEAM.path,
        key: 'TEAM',
        label: `${i18n.t('menu.home.teams')}`,
        exact: true,
        component: Teams,
        children: null,
      },
      {
        path: ROUTER.HOME.CERTIFICATION.path,
        key: 'CERTIFICATION',
        label: `${i18n.t('menu.home.certification')}`,
        exact: true,
        component: Certifications,
        children: null,
      },
    ],
  },

  //  courses
  {
    path: '/courses',
    key: 'COURSES',
    label: `${i18n.t('public.course')}`,
    exact: true,
    component: null,
    children: [
      {
        path: ROUTER.COURSES.OPENCLASS.path,
        key: 'OPENCLASS',
        label: `${i18n.t('menu.course.schedule')}`,
        exact: true,
        component: OpenClass,
        children: null,
      },
      {
        path: ROUTER.COURSES.COURSE.path,
        key: 'COURSE',
        label: `${i18n.t('public.course')}`,
        exact: true,
        component: Course,
        children: null,
      },
    ],
  },

  // competitions
  {
    path: '/competitions',
    key: 'COMPETITIONS',
    label: `${i18n.t('menu.lblCompetition')}`,
    exact: true,
    component: null,
    children: [
      {
        path: ROUTER.COMPETITIONS.ROBOTICS.path,
        key: 'ROBOTICS',
        label: 'Robotkids',
        exact: true,
        component: Robokids,
        children: null,
      },
      {
        path: ROUTER.COMPETITIONS.ROBOTACON.path,
        key: 'ROBOTACON',
        label: 'Robotacon',
        exact: true,
        component: Robotacon,
        children: null,
      },
      {
        path: ROUTER.COMPETITIONS.WRO.path,
        key: 'WRO',
        label: 'WRO',
        exact: true,
        component: WRO,
        children: null,
      },

      {
        path: ROUTER.COMPETITIONS.FLL.path,
        key: 'FLL',
        label: 'FLL',
        exact: true,
        component: FLL,
        children: null,
      },
    ],
  },

  //product
  {
    path: '/product',
    key: 'PRODUCT',
    label: `${i18n.t('menu.lblWarehouse')}`,
    exact: true,
    component: null,
    children: [
      {
        path: ROUTER.PRODUCT.LEGO.path,
        key: 'LEGO',
        label: `${i18n.t('menu.warehouse.deviceLego')}`,
        exact: true,
        component: Lego,
        children: null,
      },
      {
        path: ROUTER.PRODUCT.NEGENDO.path,
        key: 'NEGENDO',
        label: `${i18n.t('menu.warehouse.deviceNegendo')}`,
        exact: true,
        component: Negendo,
        children: null,
      },
      {
        path: ROUTER.PRODUCT.GARASTEM.path,
        key: 'GARASTEM',
        label: `${i18n.t('menu.warehouse.deviceGaraStem')}`,
        exact: true,
        component: GaraStem,
        children: null,
      },
      {
        path: ROUTER.PRODUCT.INNOVATIONSTEM.path,
        key: 'INNOVATIONSTEM',
        label: `${i18n.t('menu.warehouse.deviceCreativeStem')}`,
        exact: true,
        component: InnovationStem,
        children: null,
      },
    ],
  },

  // library
  {
    path: '/library',
    key: 'LYBRARY',
    label: `${i18n.t('menu.lblLibrary')}`,
    exact: true,
    component: null,
    children: [
      {
        path: ROUTER.LIBRARY.STEM_BOOK.path,
        key: 'STEM_BOOK',
        label: `${i18n.t('menu.library.bookStem')}`,
        exact: true,
        component: StemBook,
        children: null,
      },
      {
        path: ROUTER.LIBRARY.STEM_VIDEOS.path,
        key: 'STEM_VIDEOS',
        label: 'Videos STEM',
        exact: true,
        component: SteamVideos,
        children: null,
      },
      {
        path: ROUTER.LIBRARY.LEARNING_TRACKING.path,
        key: 'LEARNING_TRACKING',
        label: `${i18n.t('menu.library.learningTracking')}`,
        exact: true,
        component: LearningTracking,
        children: null,
      },
    ],
  },

  // Public
  {
    path: '/public',
    key: 'PUBLIC',
    label: `${i18n.t('menu.lblCommunity')}`,
    exact: true,
    component: null,
    children: [
      {
        path: ROUTER.PUBLIC.FAQ.path,
        key: 'FAQ',
        label: `FAQ`,
        exact: true,
        component: FAQs,
        children: null,
      },
      {
        path: ROUTER.PUBLIC.NEWS.path,
        key: 'NEWS',
        label: `${i18n.t('menu.community.news')}`,
        exact: true,
        component: News,
        children: null,
      },
      {
        path: ROUTER.PUBLIC.ACTIVITIES.path,
        key: 'ACTIVITIES',
        label: `${i18n.t('menu.community.extraActivities')}`,
        exact: true,
        component: Activities,
        children: null,
      },
      {
        path: ROUTER.PUBLIC.FACEBOOK.path,
        key: 'FACEBOOK',
        label: 'Facebook STEM SQUARE',
        exact: true,
        component: Facebook,
        children: null,
      },
      {
        path: ROUTER.PUBLIC.IMAGEEVENT.path,
        key: 'IMAGEEVENT',
        label: `${i18n.t('menu.community.imgEvent')}`,
        exact: true,
        component: ImageEvent,
        children: null,
      },
    ],
  },

  //
  {
    path: ROUTER.CONTACT.path,
    key: 'CONTACT',
    label: `${i18n.t('menu.lblContact')}`,
    exact: true,
    component: Contact,
    children: null,
  },

  // choose course
  {
    path: null,
    key: 'CHOOSE_COURSES',
    exact: true,
    component: null,
    children: [
      {
        path: ROUTER.CHOOSE_COURSES.WHAT_IS_STEM.path,
        key: 'WHAT_IS_STEM',
        label: `${i18n.t('homepage.introduce.stem?')}`,
        exact: true,
        component: WhatIsStem,
      },
      {
        path: ROUTER.CHOOSE_COURSES.BENEFIT_STEM.path,
        key: 'BENEFIT_STEM',
        label: `${i18n.t('homepage.introduce.benefit?')}`,
        exact: true,
        component: BenefitStem,
      },
      {
        path: ROUTER.CHOOSE_COURSES.COMPETION.path,
        key: 'COMPETION',
        label: `${i18n.t('homepage.introduce.playground')}`,
        exact: true,
        component: Competition,
      },
      {
        path: ROUTER.CHOOSE_COURSES.EDUCATION_MODEL.path,
        key: 'EDUCATION_MODEL',
        label: `${i18n.t('homepage.introduce.methodEdu')}`,
        exact: true,
        component: EducationModal,
      },
      {
        path: ROUTER.CHOOSE_COURSES.DISTRIBUTE.path,
        key: 'DISTRIBUTE',
        label: `${i18n.t('homepage.introduce.warehouseDevice')}`,
        exact: true,
        component: Distribute,
      },
    ],
  },

];
