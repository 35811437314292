import React, { useState } from 'react';
import { Button, Select, Space } from 'antd';
import { Link } from 'react-router-dom';
import { WROCategoriesOverview } from '../../../assets/images';
import { ROUTER } from '../../../constant/router';

const WRO = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Space direction="vertical" style={{ width: '100%' }}>
              <div className="container">
                <div className="section" style={{ paddingTop: 16 }}>
                  <div style={{ padding: '80px 0' }}>
                    <div style={{ textAlign: 'center', maxWidth: 940, margin: '0 auto' }}>
                      <h1 className="title-heading">
                        WRO<sup>®</sup> Categories
                      </h1>
                      <p className="text-md">
                        At WRO® we offer challenging competitions for students in the age range from 8 up to 19. We have
                        four competition categories, each with their own characteristics.
                      </p>
                      <Space direction="vertical" align="center">
                        {/* <Space wrap>
                          <Link to={''}>
                            <button className="btn btn-green">
                              <span>RoboMission</span>
                            </button>
                          </Link>
                          <Link to={''}>
                            <button className="btn btn-green ">
                              <span>RoboSports</span>
                            </button>
                          </Link>
                          <Link to={''}>
                            <button className="btn btn-green ">
                              <span>Future Innovators</span>
                            </button>
                          </Link>

                          <Link to={''}>
                            <button className="btn btn-green ">
                              <span>Future Engineers</span>
                            </button>
                          </Link>
                        </Space> */}
                        <Space wrap>
                          <h3>
                            Select year:
                          </h3>
                          <Select
                            style={{ width: 150 }}
                            defaultValue={'2023'}
                            className="select-year"
                            options={[
                              {
                                value: '2023',
                                label: '2023',
                              },
                            ]}
                          />
                          <Link to={`${ROUTER.COMPETITIONS.WRO.path}/introduction/2023`}>
                            <button className="btn btn-green">
                              <span>GO...</span>
                            </button>
                          </Link>
                        </Space>

                      </Space>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <img src={WROCategoriesOverview} className="img-responsive" alt="" />
              </div>
              <br></br>
            </Space>
          </div>
        </div>
      </div>
      <div id="back-top">
        <a href="#top">
          <i class="fa fa-angle-double-up"></i>
        </a>
      </div>
    </div>
  );
};

export default WRO;
