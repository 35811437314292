import { Space } from 'antd';
import React from 'react';
import Categories from '../../../../components/Competition/Categories';
import BtnViewMore from '../../../../components/Global/Button/BtnViewMore';
import RelatedNews from '../../../../components/Global/RelatedNews';
import { m1, m13, m65, m8, robokids2 } from '../../../../assets/images';
import { RoboMissionHeader, FLL2023IntroductionPanel} from '../../../../assets/images';
import FLLCategories from '../../../../components/Competition/FLLCategories';
import {
  Fll_home,
} from '../../../../assets/images';
import { fll_pd_team1, fll_ss_team1, fll_ss_team2, fll_2018, fll_pd_team2 } from '../../../../assets/images';


const FLLFriendlyRound = () => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div className="slider-banner">
        <div className="slider-item">
          <div
            className="slider-1"
            style={{
              backgroundImage: `url(${Fll_home})`,
            }}
          >
            <div class="slider-caption">
              <div class="container"></div>
            </div>
          </div>
        </div>
      </div>

      <FLLCategories />
      <div className="section courses-detail">
        <div className="container">
          <h1 className="title-lg">Vòng giao lưu</h1>
          <div className="courses-detail-wrapper">
            <div>
              <div>
                <h1 className="course-title">Ngày hội FLL 2023</h1>
                <div className="course-info info">
                  <div className="author item">
                    <a href="#">
                      <span>By&nbsp;</span>
                      <span>Stem Square</span>
                    </a>
                  </div>
                  <div className="date-time item">
                    <a href="#">01/05/2023</a>
                  </div>
                </div>

                <div className="course-video">
                  <iframe
                    width="1246"
                    height="704"
                    src="https://www.youtube.com/embed/V1MCpk4ucCQ"
                    allowfullscreen="allowfullscreen"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                  <div className="video-btn">
                    <button className="btn btn-grey video-btn-right pull-right">
                      <span>next video</span>
                      <i className="fa fa-angle-right"></i>
                    </button>
                    <button className="btn btn-grey video-btn-left pull-right">
                      <i className="fa fa-angle-left"></i>
                      <span>previous video</span>
                    </button>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="course-des">
                  <div className="course-des-title underline">1. GIỚI THIỆU NGÀY HỘI FLL</div>
                  <div className="course-des-content">
                    <p>STEM SQUARE thực hiện sân chơi giao lưu cho các bạn tại Miền Trung</p>
                  </div>
                </div>
                {/*  */}
                <div className="image">
                  <img
                    className="img-responsive"
                    src={FLL2023IntroductionPanel}
                    style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                  />
                </div>
                <br></br>
                <div class="course-des">
                        <div class="course-des-content">
                          <p>LEGO EDUCATION - STEM SQUARE</p>{' '}
                        </div>
                        <p>
                          First Lego League là cuộc thi quốc tế về lập trình và lắp ghép Robot Lego khu vực Châu Á Thái
                          Bình Dương. Cuộc thi là sự kiện toàn cầu về khoa học, kỹ thuật được tổ chức thường niên dành
                          cho các em học sinh từ 6-16 tuổi. Hàng năm cuộc thi này thu hút hơn 255.000 thí sinh, 32.000
                          đội thi và hơn 88 quốc gia tham dự.
                        </p>
                        Với mục đích tìm những ứng cử viên tài năng của Việt Nam tham gia giải thi đấu quốc tế này, Sở
                        Giáo dục và Đào tạo Hồ Chí Minh kết hợp cùng với các đơn vị tổ chức “Cuộc thi khoa học ứng dụng”
                        Năm 2017, cuộc thi lần đầu tiên được tổ chức tại Việt Nam với hơn 40 đội - 120 thí sinh tham dự.
                        Năm 2018 - cuộc thi đã quy tụ hơn 1200 thí sinh trong khắp cả nước. Đây là điều minh chứng cho
                        sức hút của các nội dung, thử thách của FLL dành cho các thí sinh tham dự.
                        <p>Cuộc thi quốc tế First Lego League được chia làm 2 bảng thi đấu:</p>
                        1. Bảng Trung học: Dành cho học sinh từ 9 – 16 tuổi - Nội dung thi đấu: Lập trình Robot hoàn
                        thành thử thách của chương trình. - Công cụ thi đấu: Lego Mindstorm
                        <p>2. Bảng Tiểu học: Dành cho học sinh từ 6 – 11 tuổi</p>- Nội dung thi đấu: Lắp ráp, lập trình
                        và trưng bày sản phẩm; Thuyết trình nêu ý tưởng. - Công cụ thi đấu: Lego WeDo
                        <p> Thời gian tổ chức:</p>
                        <p>- Tại Việt Nam: Vào tháng 1 hằng năm</p>
                        <p>- Tại Mỹ: Vào tháng 4 hằng năm</p>
                      </div>

                <br></br>
                {/*  */}
                <div className="course-syllabus">
                  <div className="course-des-title underline">2. LỊCH TRÌNH TỔ CHỨC THI ĐẤU (DỰ KIẾN)</div>
                  <div className="course-table">
                    <div className="outer-container">
                      <div className="inner-container">
                        <div className="table-header">
                          <table className="edu-table-responsive">
                            <thead>
                              <tr className="heading-table">
                                <th className="col-3">Thời gian dự kiến</th>
                                <th className="col-1">Nội dung chương trình</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <div className="table-body">
                          <table className="edu-table-responsive table-hover">
                            <tbody>
                              <tr className="heading-content">
                                <td colspan="4" className="left heading-content">
                                  1. Bảng B0
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">7h30 - 8h00</td>
                                <td className="col-1">
                                  <span>Đón khách, thí sinh và phụ huynh. Ổn định vị trí.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h00 - 8h15</td>
                                <td className="col-1">
                                  <span>
                                    Đại diện Ban tổ chức chia sẻ một số lưu ý trong Công tác thi và Công bố luật thi.
                                  </span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h15 - 8h30</td>
                                <td className="col-1">
                                  <span>Huấn luyện viên hướng dẫn thí sinh.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h30 – 9h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h00 – 9h30</td>
                                <td className="col-1">
                                  <span>Thời gian thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h30 – 9h45</td>
                                <td className="col-1">
                                  <span>Tổng hợp kết quả thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h45 – 10h45</td>
                                <td className="col-1">
                                  <span>Tự do tham quan các gian hàng.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h45 – 11h00</td>
                                <td className="col-1">
                                  <span>Công bố kết quả thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">11h00</td>
                                <td className="col-1">
                                  <span>Kết thúc</span>
                                </td>
                              </tr>

                              <tr className="heading-content">
                                <td colspan="4" className="left heading-content">
                                  3. Bảng B1
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">7h30 - 8h00</td>
                                <td className="col-1">
                                  <span>Đón khách, thí sinh và phụ huynh. Ổn định vị trí.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h00 - 8h15</td>
                                <td className="col-1">
                                  <span>
                                    Đại diện Ban tổ chức chia sẻ một số lưu ý trong Công tác thi và Công bố luật thi.
                                  </span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h15 - 8h30</td>
                                <td className="col-1">
                                  <span>Huấn luyện viên hướng dẫn thí sinh.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h30 – 9h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h00 – 9h30</td>
                                <td className="col-1">
                                  <span>Lượt thi đấu thứ 1.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h30 – 10h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn (Lần 2).</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h – 10h30</td>
                                <td className="col-1">
                                  <span>Lượt thi đấu thứ 2.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h30 – 10h45</td>
                                <td className="col-1">
                                  <span>Tổng hợp kết quả thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h45 – 11h00</td>
                                <td className="col-1">
                                  <span>Công bố kết quả thi đấu</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">11h00</td>
                                <td className="col-1">
                                  <span>Kết thúc</span>
                                </td>
                              </tr>

                              <tr className="heading-content">
                                <td colspan="4" className="left heading-content">
                                  3. Bảng B2
                                </td>
                              </tr>

                              <tr className="table-row">
                                <td className="left col-2">7h30 - 8h00</td>
                                <td className="col-1">
                                  <span>Đón khách, thí sinh và phụ huynh. Ổn định vị trí.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h00 - 8h15</td>
                                <td className="col-1">
                                  <span>
                                    Đại diện Ban tổ chức chia sẻ một số lưu ý trong Công tác thi và Công bố luật thi.
                                  </span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h15 - 8h30</td>
                                <td className="col-1">
                                  <span>Huấn luyện viên hướng dẫn thí sinh.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">8h30 – 9h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h00 – 9h30</td>
                                <td className="col-1">
                                  <span>Lượt thi đấu thứ 1.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">9h30 – 10h00</td>
                                <td className="col-1">
                                  <span>Thời gian lắp ráp và thử sa bàn (Lần 2).</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h – 10h30</td>
                                <td className="col-1">
                                  <span>Lượt thi đấu thứ 2.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h30 – 10h45</td>
                                <td className="col-1">
                                  <span>Tổng hợp kết quả thi đấu.</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">10h45 – 11h00</td>
                                <td className="col-1">
                                  <span>Công bố kết quả thi đấu</span>
                                </td>
                              </tr>
                              <tr className="table-row">
                                <td className="left col-2">11h00</td>
                                <td className="col-1">
                                  <span>Kết thúc</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br></br>
                        <div>• Thời gian có thể thay đổi theo thực tế</div>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <br></br>

                <div className="course-des-title underline">3. THỂ LỆ CUỘC THI</div>
                <div>
                  <div className="list-expand-title">a. Nhiệm vụ của chủ đề</div>
                  <p>
                    {' '}
                    Every year, FIRST® LEGO® League releases a new Challenge based on a real-world, scientific theme.

Starting with Discover, children are introduced to the fundamentals of STEM while working together to solve fun challenges and building models using LEGO® DUPLO® bricks.

As participants progress into Explore, children will take their background knowledge of STEM and put it into practice as they work in teams to design and build robots using the LEGO® Education SPIKE Essential. 

Once they are ready to move into a competitive setting, they join Challenge and apply their STEM skills combined with critical thinking to work with a team, build a robot, and compete in an exciting, mission-based robotics game.
                  </p>
                </div>

                <div>
                  <div className="list-expand-title">b. Thời gian và địa điểm:</div>
                  <p>  Thời gian đăng ký dự thi: trước ngày 15/11/2023</p>
                  <p>
                    {' '}
                     Hình thức ăng ký: Đăng ký trực tiếp với trung tâm STEM SQUARE với điều kiện đã mua bộ LEGO tại
                    trung tâm hoặc đã và đang học tại STEM SQUARE.
                  </p>
                  <p> Có thể đăng ký trực tuyến với các học sinh chưa học tại STEM SQUARE</p>
                  <BtnViewMore title="Đăng ký tham gia" />
                  <p></p>
                  <p>  Thời gian tổ chức: Sáng ngày 01/11/2023 (chủ nhật)</p>
                  <p>  Địa điểm tổ chức: Sân trường Đại học Bách Khoa (dự kiến)</p>
                </div>
                <br></br>
                <div className="course-des-title underline">4. GIẢI THƯỞNG</div>
                <div>
                  <div>
                    <div className="inner-container">
                      <div className="table-header">
                        <table className="edu-table-responsive">
                          <thead>
                            <tr className="heading-table">
                              <th className="col-3">Giải</th>
                              <th className="col-1">Giá trị giải thưởng</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="table-body">
                        <table className="edu-table-responsive table-hover">
                          <tbody>
                            <tr className="heading-content">
                              <td colspan="4" className="left heading-content">
                                1. Bảng B0
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2"></td>
                              <td className="col-1">
                                <span>10 phần quà</span>
                              </td>
                            </tr>
                            <tr className="heading-content">
                              <td colspan="4" className="left heading-content">
                                3. Bảng B1
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải nhất</td>
                              <td className="col-1">
                                <span>1.000.000 VND</span>
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải nhì</td>
                              <td className="col-1">
                                <span>500.000 VND</span>
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải ba</td>
                              <td className="col-1">
                                <span>300.000 VND</span>
                              </td>
                            </tr>

                            <tr className="heading-content">
                              <td colspan="4" className="left heading-content">
                                3. Bảng B2
                              </td>
                            </tr>

                            <tr className="table-row">
                              <td className="left col-2">01 giải nhất</td>
                              <td className="col-1">
                                <span>1.000.000 VND</span>
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải nhì</td>
                              <td className="col-1">
                                <span>500.000 VND</span>
                              </td>
                            </tr>
                            <tr className="table-row">
                              <td className="left col-2">01 giải ba</td>
                              <td className="col-1">
                                <span>300.000 VND</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <br></br>
                <div>
                  <BtnViewMore title="Download giới thiệu chương trình" />
                  <BtnViewMore title="Download lịch cụ tổ chức cụ thể" />
                  <BtnViewMore title="Download đề và luật thi đấu B0" />
                  <BtnViewMore title="Download đề và luật thi đấu B1" />
                  <BtnViewMore title="Download đề và luật thi đấu B2" />
                </div>
                <br></br>
                <div className="course-des-title underline">
                  5. MỘT SỐ HÌNH ẢNH NGÀY HỘI VÀ VIDEO ĐỘI STEM SQUARE VÔ ĐỊCH KHU VỰC 2019
                </div>
                <div className="list-expand-title">a. Hình ảnh</div>
                <div class="image">
                        <img
                          class="img-responsive"
                          src={fll_ss_team1}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                      </div>

                      <div class="image">
                        <img
                          class="img-responsive"
                          src={fll_ss_team2}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                      </div>

                      <div class="image">
                        <img
                          class="img-responsive"
                          src={fll_2018}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                      </div>

                      <div class="image">
                        <img
                          class="img-responsive"
                          src={fll_pd_team2}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                      </div>

                      <div className="list-expand-title">B. Đội vô địch thuyết trình bằng tiếng Anh</div>
                      <div class="course-video">
                        <iframe
                          width="1280"
                          height="720"
                          src="https://www.youtube.com/embed/eBdluaXAkqc"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <div class="clearfix"></div>
                      </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Space>
  );
};

export default FLLFriendlyRound;
