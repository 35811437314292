import React from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import { About_us } from '../../../assets/images';
import i18n from '../../../lib/Language';

const Introduce = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div className="main-content">
          <div className="content">
            <Breadcrumb address={i18n.t('aboutUs.about')} parent="About" />
            <div className="section nav-subscribe">
              <div className="container">
                <div className="nav-subscribe-wrapper">
                  <div className="nav-subscribe-left">
                    <p className="subscribe-text">
                      {' '}
                      <b className="focus">{i18n.t('aboutUs.title')}</b>
                    </p>
                  </div>
                  <div className="nav-subscribe-right" style={{ display: 'none' }}>
                    <button className="btn btn-green btn-bold">
                      <span>SUBSCRIBE NOW</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="section intro-edu">
              <div className="container">
                <div className="intro-edu-wrapper">
                  <div className="row">
                    <div className="col-md-5">
                      <img src={About_us} alt="" className="intro-image fadeInLeft animated wow" />
                    </div>
                    <div className="col-md-7">
                      <div className="intro-title">{i18n.t('aboutUs.introStem')}</div>
                      <div className="intro-content">
                        <p>{i18n.t('aboutUs.introContent1')}</p>
                      </div>
                      <p>{i18n.t('aboutUs.introContent2')}</p>
                      <p>{i18n.t('aboutUs.introContent3')}</p>
                      {/* <p>STEM SQUARE là đối tác của LEGO EDUCATION (1)</p> */}
                    </div>
                    <div className="group-button" style={{ display: 'none' }}>
                      <button className="btn btn-transition-2">
                        <span>purchase theme</span>
                      </button>
                      <button className="btn btn-green">
                        <span>start learn now</span>
                      </button>
                    </div>
                  </div>
                  <div>
                    <p>{i18n.t('aboutUs.contactUs')}</p>
                    <p>{i18n.t('aboutUs.companyName')}</p>
                    {i18n.t('aboutUs.address')}
                    <p>{i18n.t('aboutUs.phone')}</p>
                    <p>{i18n.t('aboutUs.email')}</p>
                    <p>Website: www.stemsquare.vn;</p>
                    <p> FB/ STEM SQUARE EDUCATION</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section section-padding edu-ab" style={{ display: 'none' }}>
              <div className="container">
                <div className="edu-ab">
                  <div className="group-title-index edu-ab-title">
                    <h2 className="center-title">
                      WITH <b>EDUGATE</b> YOU CAN
                    </h2>
                    <h4 className="top-title">Lorem Ipsum is simply dummy text of te printing industry</h4>
                  </div>
                  <div className="edu-ab-content">
                    <ul className="list-unstyled list-inline">
                      <li>
                        <div className="circle-icon">
                          <i className="fa fa-wifi fa-2x"></i>
                        </div>
                        <span>Learning online 100%</span>
                      </li>
                      <li>
                        <div className="circle-icon fa-2x">
                          <i className="fa fa-search"></i>
                        </div>
                        <span>Learn everything you want</span>
                      </li>
                      <li>
                        <div className="circle-icon fa-2x">
                          <i className="fa fa-thumbs-up"></i>
                        </div>
                        <span>Learn from top expert</span>
                      </li>
                      <li>
                        <div className="circle-icon fa-2x">
                          <i className="fa fa-user"></i>
                        </div>
                        <span>Training internal HR</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="section section-padding edu-feature" style={{ display: 'none' }}>
              <div className="container">
                <div className="edu-feature-wrapper">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="group-title-index edu-feature-text">
                            <h2 className="center-title">interface friendly learning at edugate</h2>

                            <p className="top-title">
                              Aliquam pulvinar pellentesque purus, nec condimentum nibh. Aenean dapibus iaculis odio id
                              vestibulum. Nam at justo ante. Aenean hendrerit gravida ligula, id lacinia sapien
                              tristique eget. In elit ipsum, vestibulum eu pulvinar sed.
                            </p>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <ul id="accordion" role="tablist" className="panel-group list-unstyled edu-feature-list">
                            <li className="panel">
                              <div role="tab" id="item-1" className="col-item-1">
                                <i className="fa fa-check-circle"></i>
                                <a
                                  href="#lesson"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  role="button"
                                  aria-expanded="true"
                                  aria-controls="lesson"
                                >
                                  <span>90% lessons</span> are video
                                </a>
                              </div>
                              <div id="lesson" role="tabpanel" aria-labelledby="item-1" className="collapse">
                                <div className="panel-body col-item-2">
                                  Lorem ipsum dolor sit amet, feugiat delicata liberavisse id cum, no quo maiorum
                                  intellegebat, liber regione eu sit. Mea cu case ludus integre.
                                </div>
                              </div>
                            </li>
                            <li className="panel">
                              <div role="tab" id="item-2" className="col-item-1">
                                <i className="fa fa-check-circle"></i>
                                <a
                                  href="#display"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  role="button"
                                  aria-expanded="true"
                                  aria-controls="lesson"
                                >
                                  <span>Displaying</span> clear curriculum
                                </a>
                              </div>
                              <div id="display" role="tabpanel" aria-labelledby="item-2" className="collapse">
                                <div className="panel-body col-item-2">
                                  Lorem ipsum dolor sit amet, feugiat delicata liberavisse id cum, no quo maiorum
                                  intellegebat, liber regione eu sit. Mea cu case ludus integre.
                                </div>
                              </div>
                            </li>
                            <li className="panel">
                              <div role="tab" id="item-3" className="col-item-1">
                                <i className="fa fa-check-circle"></i>
                                <a
                                  href="#discuss"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  role="button"
                                  aria-expanded="true"
                                  aria-controls="lesson"
                                >
                                  <span>Discussion</span> with other students
                                </a>
                              </div>
                              <div id="discuss" role="tabpanel" aria-labelledby="item-3" className="collapse">
                                <div className="panel-body col-item-2">
                                  Lorem ipsum dolor sit amet, feugiat delicata liberavisse id cum, no quo maiorum
                                  intellegebat, liber regione eu sit. Mea cu case ludus integre.
                                </div>
                              </div>
                            </li>
                            <li className="panel">
                              <div role="tab" id="item-4" className="col-item-1">
                                <i className="fa fa-check-circle"></i>
                                <a
                                  href="#capability1"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  role="button"
                                  aria-expanded="true"
                                  aria-controls="lesson"
                                >
                                  <span>Compatible</span> with Ubermenu
                                </a>
                              </div>
                              <div id="capability1" role="tabpanel" aria-labelledby="item-4" className="collapse">
                                <div className="panel-body col-item-2">
                                  Lorem ipsum dolor sit amet, feugiat delicata liberavisse id cum, no quo maiorum
                                  intellegebat, liber regione eu sit. Mea cu case ludus integre.
                                </div>
                              </div>
                            </li>
                            <li className="panel">
                              <div role="tab" id="item-5" className="col-item-1">
                                <i className="fa fa-check-circle"></i>
                                <a
                                  href="#capability2"
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  role="button"
                                  aria-expanded="true"
                                  aria-controls="lesson"
                                >
                                  <span>Compatible</span> with Many Popular Plugins
                                </a>
                              </div>
                              <div id="capability2" role="tabpanel" aria-labelledby="item-5" className="collapse">
                                <div className="panel-body col-item-2">
                                  Lorem ipsum dolor sit amet, feugiat delicata liberavisse id cum, no quo maiorum
                                  intellegebat, liber regione eu sit. Mea cu case ludus integre.
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img src="assets/images/picture-8.png" alt="" className="computer-image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduce;
