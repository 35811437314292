import React from 'react';
import { wedo20, ev3, curibot } from '../../../assets/images';
import i18n from '../../../lib/Language';

const Pricing = () => {
  return (
    <div className="section section-padding pricing">
      <div className="container">
        <div className="group-title-index">
          <h4 className="top-title">STEM SQUARE EDUCATION</h4>

          <h2 className="center-title">{i18n.t('homepage.product')}</h2>

          <div className="bottom-title">
            <i className="bottom-icon icon-a-1-01-01"></i>
          </div>
        </div>
        <div className="row">
          <div className="pricing-wrapper">
            <div className="col-sm-4">
              <div className="pricing-widget">
                <div className="pricing-header">
                  <div className="price-cost">
                    <div className="inner">
                      <p data-from="0" data-to="0" data-speed="1000" className="inner-number">
                        0
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pricing-content">
                  <h3 className="pricing-title">WEDO 2.0</h3>

                  <p className="pricing-subtitle">Giá: 8,700,000 VNĐ (chưa VAT)</p>
                  <ul className="pricing-list">
                    <li>
                      <img src={wedo20} alt="" width="250" height="250" />
                    </li>
                    <li>
                      <p>
                        <strong>** </strong>Có thể dùng thử tại STEM SQUARE
                      </p>
                    </li>
                  </ul>
                  <div className="pricing-button">
                    <a href="products-lego.html">{i18n.t('public.viewDetail')}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="pricing-widget main active">
                <div className="pricing-header">
                  <div className="price-cost">
                    <div className="inner">
                      <p data-from="0" data-to="0" data-speed="1000" className="inner-number">
                        0
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pricing-content">
                  <h3 className="pricing-title">MINDSTORM EV3</h3>

                  <p className="pricing-subtitle">Giá: 20,600,000 VNĐ (chưa VAT)</p>
                  <ul className="pricing-list">
                    <li>
                      <img src={ev3} alt="" width="250" height="250" />
                    </li>
                    <li>
                      <p>
                        <strong>** </strong>Có thể dùng thử tại STEM SQUARE
                      </p>
                    </li>
                  </ul>
                  <div className="pricing-button">
                    <a href="products-lego.html">{i18n.t('public.viewDetail')}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="pricing-widget">
                <div className="pricing-header">
                  <div className="price-cost">
                    <div className="inner">
                      <p data-from="0" data-to="0" data-speed="1000" className="inner-number">
                        0
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pricing-content">
                  <h3 className="pricing-title">CURIBOT NEGENGO</h3>

                  <p className="pricing-subtitle">Giá: 1,400,000 VND (chưa VAT)</p>
                  <ul className="pricing-list">
                    <li>
                      <img src={curibot} alt="" width="250" height="250" />
                    </li>
                    <li>
                      <p>
                        <strong>** </strong>Có thể dùng thử tại STEM SQUARE
                      </p>
                    </li>
                  </ul>
                  <div className="pricing-button">
                    <a href="products-negendo.html">{i18n.t('public.viewDetail')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="group-btn-slider">
          <div className="btn-prev">
            <i className="fa fa-angle-left"></i>
          </div>
          <div className="btn-next">
            <i className="fa fa-angle-right"></i>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Pricing;
