import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo/logo_ngang.jpg';
import { ROUTER } from '../../../constant/router';
import { MenuOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import i18n from '../../../lib/Language';

const Navigation = () => {
  const [visible, setVisible] = useState(false);
  const [isHiden, setIsHidden] = useState(true);
  const [current, setCurrent] = useState('1');

  const routes = [
    // home
    {
      path: '/',
      key: 'HOME',
      label: 'HOME',
      exact: true,
      children: [
        {
          path: ROUTER.HOME.INTRODUCE.path,
          key: 'INTRODUCE',
          label: `${i18n.t('public.introduce')}`,
          exact: true,
          children: null,
        },
        // {
        //   path: ROUTER.HOME.PHILOSOPHY.path,
        //   key: 'PHILOSOPHY',
        //   label: 'Triết lý giáo dục',
        //   exact: true,
        //   // component: Philosophy,
        //   component: null,
        //   children: null,
        // },
        {
          path: ROUTER.HOME.TEAM.path,
          key: 'TEAM',
          label: `${i18n.t('menu.home.teams')}`,
          exact: true,
          children: null,
        },
        {
          path: ROUTER.HOME.CERTIFICATION.path,
          key: 'CERTIFICATION',
          label: `${i18n.t('menu.home.certification')}`,
          exact: true,
          children: null,
        },
      ],
    },

    //  courses
    {
      // path: '/courses',
      path: null,
      key: 'COURSES',
      label: `${i18n.t('public.course')}`,
      exact: true,
      children: [
        {
          path: ROUTER.COURSES.OPENCLASS.path,
          key: 'OPENCLASS',
          label: `${i18n.t('menu.course.schedule')}`,
          exact: true,
          children: null,
        },
        {
          path: ROUTER.COURSES.COURSE.path,
          key: 'COURSE',
          label: `${i18n.t('public.course')}`,
          exact: true,
          children: null,
        },
      ],
    },

    // competitions
    {
      // path: '/competitions',
      path: null,
      key: 'COMPETITIONS',
      label: `${i18n.t('menu.lblCompetition')}`,
      exact: true,
      children: [
        {
          path: ROUTER.COMPETITIONS.ROBOTICS.path,
          key: 'ROBOTICS',
          label: 'Robotkids',
          exact: true,
          children: null,
        },
        {
          path: ROUTER.COMPETITIONS.ROBOTACON.path,
          key: 'ROBOTACON',
          label: 'Robotacon',
          exact: true,
          children: null,
        },
        {
          path: ROUTER.COMPETITIONS.WRO.path,
          key: 'WRO',
          label: 'WRO',
          exact: true,
          children: null,
        },

        {
          path: ROUTER.COMPETITIONS.FLL.path,
          key: 'FLL',
          label: 'FLL',
          exact: true,
          children: null,
        },
      ],
    },

    //product
    {
      // path: '/product',
      path: null,
      key: 'PRODUCT',
      label: `${i18n.t('menu.lblWarehouse')}`,
      exact: true,
      children: [
        {
          path: ROUTER.PRODUCT.LEGO.path,
          key: 'LEGO',
          label: `${i18n.t('menu.warehouse.deviceLego')}`,
          exact: true,
          children: null,
        },
        {
          path: ROUTER.PRODUCT.NEGENDO.path,
          key: 'NEGENDO',
          label: `${i18n.t('menu.warehouse.deviceNegendo')}`,
          exact: true,
          children: null,
        },
        {
          path: ROUTER.PRODUCT.GARASTEM.path,
          key: 'GARASTEM',
          label: `${i18n.t('menu.warehouse.deviceGaraStem')}`,
          exact: true,
          children: null,
        },
        {
          path: ROUTER.PRODUCT.INNOVATIONSTEM.path,
          key: 'INNOVATIONSTEM',
          label: `${i18n.t('menu.warehouse.deviceCreativeStem')}`,
          exact: true,
          children: null,
        },
      ],
    },

    // library
    {
      // path: '/library',
      path: null,
      key: 'LYBRARY',
      label: `${i18n.t('menu.lblLibrary')}`,
      exact: true,
      children: [
        {
          path: ROUTER.LIBRARY.STEM_BOOK.path,
          key: 'STEM_BOOK',
          label: `${i18n.t('menu.library.bookStem')}`,
          exact: true,
          children: null,
        },
        {
          path: ROUTER.LIBRARY.STEM_VIDEOS.path,
          key: 'STEM_VIDEOS',
          label: 'Videos STEM',
          exact: true,
          children: null,
        },
        {
          path: ROUTER.LIBRARY.LEARNING_TRACKING.path,
          key: 'LEARNING_TRACKING',
          label: `${i18n.t('menu.library.learningTracking')}`,
          exact: true,
          children: null,
        },
      ],
    },

    // Public
    {
      // path: '/public',
      path: null,
      key: 'PUBLIC',
      label: `${i18n.t('menu.lblCommunity')}`,
      exact: true,
      children: [
        {
          path: ROUTER.PUBLIC.FAQ.path,
          key: 'FAQ',
          label: `FAQ`,
          exact: true,
          children: null,
        },
        {
          path: ROUTER.PUBLIC.NEWS.path,
          key: 'NEWS',
          label: `${i18n.t('menu.community.news')}`,
          exact: true,
          children: null,
        },
        {
          path: ROUTER.PUBLIC.ACTIVITIES.path,
          key: 'ACTIVITIES',
          label: `${i18n.t('menu.community.extraActivities')}`,
          exact: true,
          children: null,
        },
        {
          path: ROUTER.PUBLIC.FACEBOOK.path,
          key: 'FACEBOOK',
          label: 'Facebook STEM SQUARE',
          exact: true,
          children: null,
        },
        {
          path: ROUTER.PUBLIC.IMAGEEVENT.path,
          key: 'IMAGEEVENT',
          label: `${i18n.t('menu.community.imgEvent')}`,
          exact: true,
          children: null,
        },
      ],
    },

    //
    {
      path: ROUTER.CONTACT.path,
      key: 'CONTACT',
      label: `${i18n.t('menu.lblContact')}`,
      exact: true,
      children: null,
    },

    // choose course
    {
      path: null,
      key: 'CHOOSE_COURSES',
      exact: true,
      children: [
        {
          path: ROUTER.CHOOSE_COURSES.WHAT_IS_STEM.path,
          key: 'WHAT_IS_STEM',
          label: `${i18n.t('homepage.introduce.stem?')}`,
          exact: true,
        },
        {
          path: ROUTER.CHOOSE_COURSES.BENEFIT_STEM.path,
          key: 'BENEFIT_STEM',
          label: `${i18n.t('homepage.introduce.benefit?')}`,
          exact: true,
        },
        {
          path: ROUTER.CHOOSE_COURSES.COMPETION.path,
          key: 'COMPETION',
          label: `${i18n.t('homepage.introduce.playground')}`,
          exact: true,
        },
        {
          path: ROUTER.CHOOSE_COURSES.EDUCATION_MODEL.path,
          key: 'EDUCATION_MODEL',
          label: `${i18n.t('homepage.introduce.methodEdu')}`,
          exact: true,
        },
        {
          path: ROUTER.CHOOSE_COURSES.DISTRIBUTE.path,
          key: 'DISTRIBUTE',
          label: `${i18n.t('homepage.introduce.warehouseDevice')}`,
          exact: true,
        },
      ],
    },
  ];

  const handleMenuMobile = () => {
    setIsHidden(!isHiden);
  };

  const onClick = (e) => {
    setCurrent(e.key);
    setIsHidden(!isHiden);
  };

  const menu = routes?.slice(0, routes?.length - 1).map((item) => {
    return {
      label: <Link to={item?.path !== null && item?.path}>{item.label}</Link>,
      key: item.key,
      children: item?.children?.map((x) => {
        return {
          label: <Link to={x?.path}>{x.label}</Link>,
          key: x.key,
          children: x?.children?.map((y) => {
            return {
              label: <Link to={y?.path}>{y.label}</Link>,
              key: y.key,
            };
          }),
        };
      }),
    };
  });

  return (
    <div className="header-main homepage-01">
      <div className="container">
        <div className="header-main-wrapper">
          <div className="navbar-heade">
            <div className="logo pull-left">
              <Link to={ROUTER.HOME.path} className="header-logo">
                <img src={logo} alt="" />
              </Link>
            </div>
            {/* toggle button menu mobile */}
            <button
              type="button"
              data-toggle="collapse"
              data-target=".navigation"
              className="navbar-toggle edugate-navbar"
              onClick={() => handleMenuMobile()}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          <nav className="navigation collapse navbar-collapse pull-right">
            <ul className="nav-links nav navbar-nav">
              <li className="dropdown active">
                <a href="javascript:void(0)" className="main-menu">
                  {/* {i18n.t('menu.lblHome')} */}
                  Home
                  <span className="fa fa-angle-down icons-dropdown"></span>
                </a>
                <ul className="dropdown-menu edugate-dropdown-menu-1 ">
                  {/* dropdown-focus */}
                  <li>
                    <Link to={ROUTER.HOME.INTRODUCE.path} className="link-page">
                      {i18n.t('public.introduce')}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={ROUTER.HOME.PHILOSOPHY.path} className="link-page">
                      Triết lý giáo dục
                    </Link>
                  </li> */}
                  <li>
                    <Link to={ROUTER.HOME.TEAM.path} className="link-page">
                      {i18n.t('menu.home.teams')}
                    </Link>
                  </li>
                  <li>
                    <Link to={ROUTER.HOME.CERTIFICATION.path} className="link-page">
                      {i18n.t('menu.home.certification')}
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="dropdown">
                <a href="javascript:void(0)" className="main-menu">
                  {i18n.t('public.course')}
                  <span className="fa fa-angle-down icons-dropdown"></span>
                </a>
                <ul class="dropdown-menu edugate-dropdown-menu-1">
                  <li>
                    <Link to={ROUTER.COURSES.OPENCLASS.path} className="link-page">
                      {i18n.t('menu.course.schedule')}
                    </Link>
                  </li>
                  <li>
                    <Link to={ROUTER.COURSES.COURSE.path} className="link-page">
                      {i18n.t('public.course')}
                    </Link>
                  </li>
                </ul>
              </li>

              <li class="dropdown">
                <a href="javascript:void(0)" class="main-menu">
                  {i18n.t('menu.lblCompetition')}
                  <span class="fa fa-angle-down icons-dropdown"></span>
                </a>
                <ul class="dropdown-menu edugate-dropdown-menu-1">
                  {/* <li>
                    <Link to={ROUTER.COMPETITIONS.ROBOTACON.path} className="link-page">
                      Robotacon
                    </Link>
                  </li> */}
                  <li>
                    <Link to={ROUTER.COMPETITIONS.WRO.path} className="link-page">
                      WRO
                    </Link>
                  </li>
                  <li>
                    <Link to={ROUTER.COMPETITIONS.FLL.path} className="link-page">
                      FLL
                    </Link>
                  </li>
                  <li>
                    <Link to={ROUTER.COMPETITIONS.ROBOTICS.path} className="link-page">
                      ROBOKIDS
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="dropdown">
                <a href="javascript:void(0)" className="main-menu">
                  {i18n.t('menu.lblWarehouse')}
                  <span className="fa fa-angle-down icons-dropdown"></span>
                </a>
                <ul className="dropdown-menu edugate-dropdown-menu-1">
                  <li>
                    <Link to={ROUTER.PRODUCT.LEGO.path} className="link-page">
                      {i18n.t('menu.warehouse.deviceLego')}
                    </Link>
                  </li>
                  <li>
                    <Link to={ROUTER.PRODUCT.NEGENDO.path} className="link-page">
                      {i18n.t('menu.warehouse.deviceNegendo')}
                    </Link>
                  </li>
                  <li>
                    <Link to={ROUTER.PRODUCT.GARASTEM.path} className="link-page">
                      {i18n.t('menu.warehouse.deviceGaraStem')}
                    </Link>
                  </li>
                  <li>
                    <Link to={ROUTER.PRODUCT.INNOVATIONSTEM.path} className="link-page">
                      {i18n.t('menu.warehouse.deviceCreativeStem')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)" className="main-menu">
                  {i18n.t('menu.lblLibrary')}
                  <span className="fa fa-angle-down icons-dropdown"></span>
                </a>
                <ul className="dropdown-menu edugate-dropdown-menu-1">
                  <li>
                    <Link to={ROUTER.LIBRARY.STEM_BOOK.path} className="link-page">
                      {i18n.t('menu.library.bookStem')}
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCzmueccz-WuanzMaJ1ds1Tg/videos"
                      className="link-page"
                      target="_blank"
                    >
                      Videos STEM
                    </a>
                  </li>
                  <li>
                    <Link to={ROUTER.LIBRARY.LEARNING_TRACKING.path} className="link-page">
                      {i18n.t('menu.library.learningTracking')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="javascript:void(0)" className="main-menu">
                  {i18n.t('menu.lblCommunity')}
                  <span className="fa fa-angle-down icons-dropdown"></span>
                </a>
                <ul className="dropdown-menu edugate-dropdown-menu-1">
                  <li>
                    <Link to={ROUTER.PUBLIC.FAQ.path} className="link-page">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to={ROUTER.PUBLIC.NEWS.path} className="link-page">
                      {i18n.t('menu.community.news')}
                    </Link>
                  </li>
                  <li>
                    <Link to={ROUTER.PUBLIC.ACTIVITIES.path} className="link-page">
                      {i18n.t('menu.community.extraActivities')}
                    </Link>
                  </li>
                  <li>
                    {/* <Link to={ROUTER.PUBLIC.FACEBOOK.path} className="link-page"> */}
                    <a href="https://www.facebook.com/stemsquare" className="link-page" target="_blank">
                      Facebook STEM SQUARE
                    </a>
                    {/* </Link> */}
                  </li>
                  <li>
                    <Link to={ROUTER.PUBLIC.IMAGEEVENT.path} className="link-page">
                      {i18n.t('menu.community.imgEvent')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={ROUTER.CONTACT.path} className="main-menu">
                  {i18n.t('menu.lblContact')}
                </Link>
              </li>
              <li className="button-search" onClick={() => setVisible(true)}>
                <p className="main-menu">
                  <i className="fa fa-search"></i>
                </p>
              </li>
              {visible && (
                <div className="nav-search ">
                  <div style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                    <p onClick={() => setVisible(false)}>X</p>
                  </div>
                  <form>
                    <input type="text" placeholder={i18n.t('general.ipSearch')} className="searchbox" />
                    <button type="submit" className="searchbutton fa fa-search"></button>
                  </form>
                </div>
              )}
            </ul>
          </nav>
        </div>
      </div>
      {!isHiden && (
        <>
          <div className=" navigation-mobile">
            <Menu
              className="menu"
              mode="inline"
              items={menu}
              defaultSelectedKeys="1"
              onClick={onClick}
              selectedKeys={[current]}
              overflowedIndicator={<MenuOutlined />}
            />
            <div className="search-mobile">
              <input className="input-search" placeholder={i18n.t('general.ipSearch')} />
              <i className="fa fa-search"></i>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Navigation;
