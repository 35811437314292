import React from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import { robokids2 } from '../../../assets/images';
import RelatedNews from '../../../components/Global/RelatedNews';
import { m1, m13, m8, m65 } from '../../../assets/images';
import BtnViewMore from '../../../components/Global/Button/BtnViewMore';
import i18n from '../../../lib/Language';

const Robokids = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Breadcrumb address={i18n.t('robokids')} parent="Robotics" />
            <div class="section" style={{ display: 'none' }}>
              <div class="search-input">
                <div class="container">
                  <div class="search-input-wrapper">
                    <form>
                      <select class="form-select style-1 selectbox">
                        <option value="all">all categories</option>
                        <option value="languages">languages</option>
                        <option value="science">science</option>
                      </select>
                      <select class="form-select style-2 selectbox">
                        <option value="price">price</option>
                        <option value="datetime">datetime</option>
                        <option value="teacher">teacher</option>
                      </select>
                      <input type="text" placeholder="Do you want to learn today?" class="form-input" />
                      <button type="submit" class="form-submit btn btn-blue">
                        <span>
                          search now<i class="fa fa-search"></i>
                        </span>
                      </button>
                      <div class="clearfix"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div class="section section-padding courses-detail">
              <div class="container">
                <div class="courses-detail-wrapper">
                  <div class="row">
                    <div class="col-md-9 layout-left">
                      <h1 class="course-title">Ngày hội STEM RoboKids - 2019</h1>
                      <div class="course-info info">
                        <div class="author item">
                          <a href="#">
                            <span>By&nbsp;</span>
                            <span>Tuấn Trương</span>
                          </a>
                        </div>
                        <div class="date-time item">
                          <a href="#">05 July 2019</a>
                        </div>
                      </div>

                      <div class="course-video">
                        <iframe
                          width="1246"
                          height="704"
                          src="https://www.youtube.com/embed/feOTsQrxUnk"
                          allowfullscreen="allowfullscreen"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <div class="video-btn">
                          <button class="btn btn-grey video-btn-right pull-right">
                            <span>next video</span>
                            <i class="fa fa-angle-right"></i>
                          </button>
                          <button class="btn btn-grey video-btn-left pull-right">
                            <i class="fa fa-angle-left"></i>
                            <span>previous video</span>
                          </button>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <div class="course-des">
                        <div class="course-des-title underline">1. GIỚI THIỆU NGÀY HỘI STEM ROBOKIDS</div>
                        <div class="course-des-content">
                          <p>STEM SQUARE cùng SAKURA OLYMPIA và đối tác thực hiện NEGENDO, GARASTEM, MATHNASEUM, RES</p>
                        </div>
                      </div>
                      {/*  */}
                      <div class="image">
                        <img
                          class="img-responsive"
                          src={robokids2}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                      </div>
                      <br></br>
                      <div>
                        <p>
                          Robokids là cuộc thi được tổ chức thường niên hằng năm của STEM SQUARE và các đối tác kết hợp
                          tổ chức, nhằm mang lại một sân chơi trí tuệ về khoa học ứng dụng Robotics và được liên thông
                          theo chuẩn của cuộc thi Robotacon Quốc gia, WRO Quốc tế được tổ chức bởi LEGO EDUCATION.
                        </p>
                        <p>
                          Cuộc thi xoay quanh chủ đề của năm 2019 là “THÀNH PHỐ THÔNG MINH”, và các thể loại phương tiện
                          giao thông thông minh là những vấn đề mà các thành phố cần giải quyết. Thời điểm của cuộc thi
                          được diễn ra là ngày chủ nhật cuối cùng của tháng 07 hằng năm, năm nay được chọn ngày
                          28/07/2019 (chủ nhật) để làm sự kiện.
                        </p>
                        <p>
                          Tất cả học sinh của STEM SQUARE đều sẽ được tham gia như là một sân chơi giao lưu học hỏi của
                          các bạn học sinh, được chia thành các đội để thi đấu với nhau. Cũng như các học sinh của các
                          đối tác trong hệ thống cuộc thi đều sẽ được tham gia, bên cạnh đó cuộc thi được mở rộng cho
                          tất cả các bạn học sinh khác mà mua thiết bị tại STEM SQUARE đều được tham gia như đội mở
                          rộng. Các bạn khác không nằm trong cuộc thi đều được đến với sân chơi như một ngày hội, có rất
                          nhiều chương trình hấp dẫn về STEM, Toán tư duy, English, sản phẩm sáng tạo khác mà các em học
                          sinh sẽ được trải nghiệm. Chủ đề cuộc thi đều hoàn toàn dựa theo các cuộc thi Robotacon Quốc
                          gia, và WRO Quốc tế để nhằm cho các em trải nghiệm, rút kinh nghiệm trước cho cuộc thi có tính
                          cạnh tranh lớn tại Việt Nam và thế giới.
                        </p>
                        <p>Đây là sân chơi bổ ích, sáng tạo và cọ sát thực tế cho môn học STEM của các bạn.</p>
                        <p>
                          <strong>Các bảng thi và trải nghiệm sáng tạo khoa học ứng dụng tại cuộc thi như sau:</strong>
                        </p>
                        <p>
                          .  A1: Sân chơi các thiết bị khoa học sáng tạo vui với độ tuổi là 06 tuổi (học sinh lớp 1)
                        </p>
                        <p>.  A2: Sân chơi thi đấu Sumo-Wedo, của các bạn với độ tuổi là 07 tuổi (học sinh lớp 2)</p>
                        <p>
                          .  B1: Phần thi đấu Robot tài năng EV3 (theo chương trình Robotacon, WRO), từ 08 tuổi đến 15
                          tuổi
                        </p>
                        <p>
                          .  C1: Phần thi Negedo theo chương trình mở rộng của Negendo, sản phẩm Robot của Negendo
                          (Make in Việt Nam)
                        </p>
                        <p>
                          .  C2: Phần thi GaraSTEM theo chương trình mở rộng của GaraSTEM, sản phẩm Robot của GaraSTEM
                          (Make in Việt Nam)
                        </p>
                      </div>
                      <br></br>
                      {/*  */}
                      <div class="course-syllabus">
                        <div class="course-des-title underline">2. LỊCH TRÌNH TỔ CHỨC THI ĐẤU (DỰ KIẾN)</div>
                        <div class="course-table">
                          <div class="outer-container">
                            <div class="inner-container">
                              <div class="table-header">
                                <table class="edu-table-responsive">
                                  <thead>
                                    <tr class="heading-table">
                                      <th class="col-3">Thời gian dự kiến</th>
                                      <th class="col-1">Nội dung chương trình</th>
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                              <div class="table-body">
                                <table class="edu-table-responsive table-hover">
                                  <tbody>
                                    <tr class="heading-content">
                                      <td colspan="4" class="left heading-content">
                                        1. Bảng A2
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">7h30 - 8h00</td>
                                      <td class="col-1">
                                        <span>Đón khách, thí sinh và phụ huynh. Ổn định vị trí.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">8h00 - 8h15</td>
                                      <td class="col-1">
                                        <span>
                                          Đại diện Ban tổ chức chia sẻ một số lưu ý trong Công tác thi và Công bố luật
                                          thi.
                                        </span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">8h15 - 8h30</td>
                                      <td class="col-1">
                                        <span>Huấn luyện viên hướng dẫn thí sinh.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">8h30 – 9h00</td>
                                      <td class="col-1">
                                        <span>Thời gian lắp ráp và thử sa bàn.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">9h00 – 9h30</td>
                                      <td class="col-1">
                                        <span>Thời gian thi đấu.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">9h30 – 9h45</td>
                                      <td class="col-1">
                                        <span>Tổng hợp kết quả thi đấu.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">9h45 – 10h45</td>
                                      <td class="col-1">
                                        <span>Tự do tham quan các gian hàng.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">10h45 – 11h00</td>
                                      <td class="col-1">
                                        <span>Công bố kết quả thi đấu.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">11h00</td>
                                      <td class="col-1">
                                        <span>Kết thúc</span>
                                      </td>
                                    </tr>

                                    <tr class="heading-content">
                                      <td colspan="4" class="left heading-content">
                                        3. Bảng B1
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">7h30 - 8h00</td>
                                      <td class="col-1">
                                        <span>Đón khách, thí sinh và phụ huynh. Ổn định vị trí.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">8h00 - 8h15</td>
                                      <td class="col-1">
                                        <span>
                                          Đại diện Ban tổ chức chia sẻ một số lưu ý trong Công tác thi và Công bố luật
                                          thi.
                                        </span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">8h15 - 8h30</td>
                                      <td class="col-1">
                                        <span>Huấn luyện viên hướng dẫn thí sinh.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">8h30 – 9h00</td>
                                      <td class="col-1">
                                        <span>Thời gian lắp ráp và thử sa bàn.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">9h00 – 9h30</td>
                                      <td class="col-1">
                                        <span>Lượt thi đấu thứ 1.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">9h30 – 10h00</td>
                                      <td class="col-1">
                                        <span>Thời gian lắp ráp và thử sa bàn (Lần 2).</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">10h – 10h30</td>
                                      <td class="col-1">
                                        <span>Lượt thi đấu thứ 2.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">10h30 – 10h45</td>
                                      <td class="col-1">
                                        <span>Tổng hợp kết quả thi đấu.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">10h45 – 11h00</td>
                                      <td class="col-1">
                                        <span>Công bố kết quả thi đấu</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">11h00</td>
                                      <td class="col-1">
                                        <span>Kết thúc</span>
                                      </td>
                                    </tr>

                                    <tr class="heading-content">
                                      <td colspan="4" class="left heading-content">
                                        3. Bảng C1
                                      </td>
                                    </tr>

                                    <tr class="table-row">
                                      <td class="left col-2">7h30 - 8h00</td>
                                      <td class="col-1">
                                        <span>Đón khách, thí sinh và phụ huynh. Ổn định vị trí.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">8h00 - 8h15</td>
                                      <td class="col-1">
                                        <span>
                                          Đại diện Ban tổ chức chia sẻ một số lưu ý trong Công tác thi và Công bố luật
                                          thi.
                                        </span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">8h15 - 8h30</td>
                                      <td class="col-1">
                                        <span>Huấn luyện viên hướng dẫn thí sinh.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">8h30 – 9h00</td>
                                      <td class="col-1">
                                        <span>Thời gian lắp ráp và thử sa bàn.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">9h00 – 9h30</td>
                                      <td class="col-1">
                                        <span>Lượt thi đấu thứ 1.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">9h30 – 10h00</td>
                                      <td class="col-1">
                                        <span>Thời gian lắp ráp và thử sa bàn (Lần 2).</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">10h – 10h30</td>
                                      <td class="col-1">
                                        <span>Lượt thi đấu thứ 2.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">10h30 – 10h45</td>
                                      <td class="col-1">
                                        <span>Tổng hợp kết quả thi đấu.</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">10h45 – 11h00</td>
                                      <td class="col-1">
                                        <span>Công bố kết quả thi đấu</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-2">11h00</td>
                                      <td class="col-1">
                                        <span>Kết thúc</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <br></br>
                              <div>• Thời gian có thể thay đổi theo thực tế</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      <br></br>

                      <div class="course-des-title underline">3. THỂ LỆ CUỘC THI</div>
                      <div>
                        <div class="list-expand-title">a. Nhiệm vụ của chủ đề</div>
                        <p>
                          {' '}
                          Giao thông vận tải là ngành công nghiệp đang thay đổi và phát triển với tốc độ chóng mặt trong
                          thế kỷ 21. Các thử thách và giải pháp công nghệ truyền thông và thông tin về giao thông xuất
                          hiện mỗi ngày. Những thay đổi này dẫn đến việc gia tăng tự động hóa trong lĩnh vực giao thông
                          vận tải.
                        </p>
                        <p>
                          {' '}
                          Một phần của tự động hóa trong giao thông vận tải là xe tự lái. Một chiếc xe tự lái có thể cảm
                          nhận được môi trường xung quanh tự định vị mà không cần sự điều khiển của con người. Nhờ sự
                          kiểm soát theo máy móc mà loại hình xe này có thể hạn chế được các rủi ro về tai nạn giao
                          thông, kẹt xe và cắt giảm được nhu cầu về không gian chạy xe và đỗ xe. Xe tự lái có khả năng
                          thay thế taxi và các phương tiện công cộng trong tương lai.
                        </p>
                        <p>
                          {' '}
                          Từ năm 2015, Hungary đã tích cực tham gia phát triển xe tự lái, và trong một tương lai không
                          xa, hệ thống đường bộ thử nghiệm hiện đại sẽ hoàn thành để sẵn sàng cho việc kiểm tra các công
                          nghệ tiên tiến.
                        </p>
                        <p>
                          <strong>
                            {' '}
                            Năm nay, nhiệm vụ đặt ra là lắp ráp một robot có cơ chế tự hành như một chiếc taxi tự lái
                            thực thụ để đưa hành khách từ vị trí xuất phát đến khu vực đích.
                          </strong>
                        </p>
                      </div>

                      <div>
                        <div class="list-expand-title">b. Thời gian và địa điểm:</div>
                        <p>  Thời gian đăng ký dự thi: trước ngày 20/7/2019</p>
                        <p>
                          {' '}
                           Hình thức ăng ký: Đăng ký trực tiếp với trung tâm STEM SQUARE với điều kiện đã mua bộ LEGO
                          tại trung tâm hoặc đã và đang học tại STEM SQUARE.
                        </p>
                        <p> Có thể đăng ký trực tuyến với các học sinh chưa học tại STEM SQUARE</p>
                        <BtnViewMore title="Đăng ký tham gia" />
                        <p></p>
                        <p>  Thời gian tổ chức: Sáng ngày 28/07/2019 (chủ nhật)</p>
                        <p>  Địa điểm tổ chức: Sân trường Sakura Olympia (dự kiến)</p>
                      </div>
                      <br></br>
                      <div class="course-des-title underline">4. GIẢI THƯỞNG</div>
                      <div>
                        <div>
                          <div class="inner-container">
                            <div class="table-header">
                              <table class="edu-table-responsive">
                                <thead>
                                  <tr class="heading-table">
                                    <th class="col-3">Giải</th>
                                    <th class="col-1">Giá trị giải thưởng</th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                            <div class="table-body">
                              <table class="edu-table-responsive table-hover">
                                <tbody>
                                  <tr class="heading-content">
                                    <td colspan="4" class="left heading-content">
                                      1. Bảng A2
                                    </td>
                                  </tr>
                                  <tr class="table-row">
                                    <td class="left col-2"></td>
                                    <td class="col-1">
                                      <span>10 phần quà</span>
                                    </td>
                                  </tr>
                                  <tr class="heading-content">
                                    <td colspan="4" class="left heading-content">
                                      3. Bảng B1
                                    </td>
                                  </tr>
                                  <tr class="table-row">
                                    <td class="left col-2">01 giải nhất</td>
                                    <td class="col-1">
                                      <span>1.000.000 VND</span>
                                    </td>
                                  </tr>
                                  <tr class="table-row">
                                    <td class="left col-2">01 giải nhì</td>
                                    <td class="col-1">
                                      <span>500.000 VND</span>
                                    </td>
                                  </tr>
                                  <tr class="table-row">
                                    <td class="left col-2">01 giải ba</td>
                                    <td class="col-1">
                                      <span>300.000 VND</span>
                                    </td>
                                  </tr>

                                  <tr class="heading-content">
                                    <td colspan="4" class="left heading-content">
                                      3. Bảng C1
                                    </td>
                                  </tr>

                                  <tr class="table-row">
                                    <td class="left col-2">01 giải nhất</td>
                                    <td class="col-1">
                                      <span>1.000.000 VND</span>
                                    </td>
                                  </tr>
                                  <tr class="table-row">
                                    <td class="left col-2">01 giải nhì</td>
                                    <td class="col-1">
                                      <span>500.000 VND</span>
                                    </td>
                                  </tr>
                                  <tr class="table-row">
                                    <td class="left col-2">01 giải ba</td>
                                    <td class="col-1">
                                      <span>300.000 VND</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <br></br>
                      <div>
                        <BtnViewMore title="Download giới thiệu chương trình" />
                        <BtnViewMore title="Download lịch cụ tổ chức cụ thể" />
                        <BtnViewMore title="Download đề và luật thi đấu WeDo" />
                        <BtnViewMore title="Download đề và luật thi đấu EV3" />
                      </div>
                      <br></br>
                      <div class="course-des-title underline">5. MỘT SỐ HÌNH ẢNH NGÀY HỘI</div>
                      <div class="list-expand-title">a. Hình ảnh</div>
                      <div class="image">
                        <img
                          class="img-responsive"
                          src={m8}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                        <img
                          class="img-responsive"
                          src={m13}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                        <img
                          class="img-responsive"
                          src={m1}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                        <img
                          class="img-responsive"
                          src={m65}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                      </div>
                      <BtnViewMore title={i18n.t('general.button.seeMore')} />
                      {/*  */}
                      <div>
                        <div class="list-expand-title">b. Links báo chí đã đăng</div>

                        <RelatedNews />
                      </div>
                    </div>
                    <div class="col-md-3 sidebar layout-right">
                      <div class="row">
                        {/* <RecentNews /> */}
                        {/* <StempRobotics />
                        <Courses /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Robokids;
