import React, { useEffect, useMemo, useState } from 'react';
import { LegoLogo, VtaLogo, NegendoLogo, SSLogo, WroLogo, FllLogo, GarastemLogo } from '../../../assets/images';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Grid } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import { Empty, Image } from 'antd';
import { useUpload } from '../../../hooks/upload';
import { listEmployeeFEState } from '../../../recoil/atom/employeeState';
import { useRecoilValue } from 'recoil';
import { useEmployee } from '../../../hooks/employee';
import { Link } from 'react-router-dom';
import { ROUTER } from '../../../constant/router';
import i18n from '../../../lib/Language';

const BestStaff = () => {
  useEmployee();
  const { files, getAllFilesFromFolder } = useUpload();
  const [imageFiles, setImageFiles] = useState([]);
  const listEmployee = useRecoilValue(listEmployeeFEState);

  const filterdEmployee = useMemo(()=>{
    const tmp = listEmployee.filter(
      (item) => item?.Employee_Status?.EMPLOYEE_STATUS_NAME === 'WORKING' && item?.SHOW_WEB,
    );
    return tmp
  },[listEmployee])

  // GET ALL FILE FROM FOLDER
  useEffect(() => {
    if (files?.length === 0) {
      getAllFilesFromFolder('root');
    }
  }, [files]);

  useEffect(() => {
    if (files?.length > 0) {
      const imagesExtension = ['png', 'jpg', 'jpeg'];
      const tmp = files.filter((file) => imagesExtension.indexOf(file.replace(/^.*\./, '')) !== -1);
      setImageFiles(tmp);
    }
  }, [files]);

  return (
    <>
      <div className="section section-padding background-opacity best-staff">
        <div className="container">
          <div className="group-title-index">
            <h4 className="top-title">{i18n.t('homepage.whyContactUs')}</h4>

            <h2 className="center-title">{i18n.t('homepage.teachingStaff')}</h2>

            <div className="bottom-title">
              <i className="bottom-icon icon-icon-05"></i>
            </div>
          </div>

          {/* LIST STAFF */}
          <div className="best-staff-wrapper">
            <div className="best-staff-content">
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                // loop={true}
                breakpoints={{
                  580: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
                modules={[Autoplay]}
              >
                {filterdEmployee && filterdEmployee?.length ? (
                  filterdEmployee.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="staff-item">
                        <Link to={`${ROUTER.HOME.DETAILPROFILE.path}/${item?.id}`}>
                          <div className="staff-item-wrapper" style={{ marginBottom: 15 }}>
                            <div className="staff-info">
                              <span href="#" className="staff-avatar">
                                <img src={item.User?.AVATAR} alt="" className="img-responsive" />
                              </span>
                              <span href="#" className="staff-name">
                                {item.User?.FIRST_NAME + ' ' + item.User?.LAST_NAME}
                              </span>

                              {/* <div className="staff-job">Trưởng nhóm chuyên môn</div> */}
                              <div className="staff-desctiption" style={{ marginTop: 20 }}>
                                {item?.JOB_FULL_DESC}
                              </div>
                            </div>
                          </div>
                        </Link>
                        <div className="staff-socials">
                          <a href={item?.FACEBOOK_LINK} className="facebook" target="_blank" rel="noreferrer">
                            <i className="fa fa-facebook"></i>
                          </a>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))
                ) : (
                  <>
                    <Empty />
                  </>
                )}
              </Swiper>
            </div>
          </div>
          {/* END LIST STAFF */}
        </div>

        {/* PICTURE AND GALLERY */}
        <div className="section section-padding picture-gallery">
          <div className="group-title-index">
            <h4 className="top-title">{i18n.t('menu.community.imgEvent')}</h4>

            <h2 className="center-title">{i18n.t('homepage.activeStem')}</h2>

            <div className="bottom-title">
              <i className="bottom-icon icon-a-1-01"></i>
            </div>
          </div>
          <div className="picture-gallery-wrapper">
            <Image.PreviewGroup>
              <Swiper
                slidesPerView={2}
                spaceBetween={2}
                grid={{
                  rows: 2,
                  fill: 'row',
                }}
                modules={[Grid]}
                className="gallery-swiper"
                breakpoints={{
                  992: {
                    slidesPerView: 4,
                    spaceBetween: 2,
                    grid: {
                      rows: 2,
                      fill: 'row',
                    },
                  },
                }}
              >
                {imageFiles &&
                  imageFiles.map((image, i) => (
                    <SwiperSlide key={i}>
                      <Image src={image} />
                    </SwiperSlide>
                  ))}
                {/* {Array.from({ length: 12 }).map((item, i) => (
                  <SwiperSlide key={i}>
                    <Image src={`https://source.unsplash.com/random/?Cryptocurrency&${i}`} />
                  </SwiperSlide>
                ))} */}
              </Swiper>
            </Image.PreviewGroup>
          </div>
        </div>

        <div className="section slider-logo">
          <div className="container">
            <div className="slider-logo-wrapper">
              <div className="slider-logo-content">
                <div className="carousel-logos">
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={20}
                    autoplay={{
                      delay: 3500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    breakpoints={{
                      580: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                      },

                      992: {
                        slidesPerView: 6,
                        spaceBetween: 30,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div className="logo-iteam item ">
                        <a href="#">
                          <img src={LegoLogo} alt="" className="img-responsive" />
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-iteam item ">
                        <a href="#">
                          <img src={VtaLogo} alt="" className="img-responsive" />
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-iteam item ">
                        <a href="#">
                          <img src={GarastemLogo} alt="" className="img-responsive" />
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-iteam item ">
                        <a href="#">
                          <img src={FllLogo} alt="" className="img-responsive" />
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-iteam item ">
                        <a href="#">
                          <img src={WroLogo} alt="" className="img-responsive" />
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-iteam item ">
                        <a href="#">
                          <img src={NegendoLogo} alt="" className="img-responsive" />
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-iteam item ">
                        <a href="#">
                          <img src={SSLogo} alt="" className="img-responsive" />
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="logo-iteam item ">
                        <a href="#">
                          <img src={LegoLogo} alt="" className="img-responsive" />
                        </a>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BestStaff;
