import { Space } from 'antd';
import React from 'react';
import Categories from '../../../../components/Competition/Categories';
import { RoboMissionHeader } from '../../../../assets/images';

const WROFinalRound = () => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div className="slider-banner">
        <div className="slider-item">
            <div
              className="slider-1"
              style={{
                backgroundImage: `url(${RoboMissionHeader})`,
              }}
            >
            <div class="slider-caption">
              <div class="container"></div>
            </div>
          </div>
        </div>
      </div>

      <Categories />
      <div className="section courses-detail">
        <div className="container">
          <h1 className="title-lg">Vòng chung kết - Quốc tế</h1>

          <Space direction="vertical" align="center" style={{ padding: 50, width: '100%' }}>
            <a href="https://wro-association.org/competition/2023-season/" target="_blank" rel="noreferrer">
              <button className="btn btn-green">
                <span>
                  WRO 2023 THẾ GIỚI<i className="fa fa-long-arrow-right"></i>
                </span>
              </button>
            </a>
          </Space>
        </div>
      </div>
    </Space>
  );
};

export default WROFinalRound;
