import { Image } from 'antd';
import React from 'react';
import {
  VTA_Academy_Small,
  Tung_Academy_Small,
  Duc_Academy_Small,
  KimNguyen_Academy,
  PhamMinhQuan_Academy,
  AIoT_Small,
} from '../../../assets/images';
import Breadcrumb from '../../../components/Global/Breadcrumb';

const Certifications = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div className="main-content">
          <div className="content">
            <Breadcrumb address="STEM CERTIFICATES" parent="Certificate" />
            <div className="section nav-subscribe" style={{ display: 'none' }}>
              <div className="container">
                <div className="nav-subscribe-wrapper">
                  <div className="nav-subscribe-left">
                    <p className="subscribe-text">
                      Certificate <b className="focus">STEM SQUARE</b> ...
                    </p>
                  </div>
                  <div className="nav-subscribe-right">
                    <button className="btn btn-green btn-bold">
                      <span>SUBSCRIBE NOW</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="section section-padding gallery-page gallery-mansonry">
              <div className="container">
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 50 }}>
                  <Image width={500} src={PhamMinhQuan_Academy} />
                  <Image width={500} src={KimNguyen_Academy} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Image width={300} src={Tung_Academy_Small} />
                  <Image width={300} src={AIoT_Small} />
                  <Image width={300} src={VTA_Academy_Small} />
                </div>

                {/* <div className="load-more">
                  <button className="btn btn-green">
                    <span>Xem thêm</span>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certifications;
