import axiosApiInstance from "../utils/axiosClient";
const eduDashboardApi = {
  getCountStudentAnalytic: () => {
    const path = `/edudashboard/getCountStudentAnalytic`;
    // const path = `/student/getStudentTotal`;//test
    return axiosApiInstance.get(path);
  },

  getCountStudentBaseOnBranch: () => {
    const path = `/edudashboard/getCountStudentBaseOnBranch`;
    return axiosApiInstance.get(path);
  },
  getCountStudentBaseOnGender: () => {
    const path = `/sysdashboard/getCountEmployeeGroupByGender`;
    return axiosApiInstance.get(path);
  },

  getCountClassByType: () => {
    const path = `/edudashboard/getCountClassByType`;
    return axiosApiInstance.get(path);
  },

  getCountStudentByYear: () => {
    const path = `/edudashboard/getCountStudentByYear`;
    return axiosApiInstance.get(path);
  },

  getCountUserByYear: () => {
    const path = `/edudashboard/getCountUserByYear`;
    return axiosApiInstance.get(path);
  },

  getCountAllLessons: () => {
    const path = `/edudashboard/getCountAllLessons`;
    return axiosApiInstance.get(path);
  },

  //student payment percentage
  getBalanceUserPaymentWithClasssPercentage: () => {
    const path = `/edudashboard/getBalanceUserPaymentWithClasssPercentage`; //path of API eduDashBoard controller
    return axiosApiInstance.get(path);
  },

  getCountStudentGroupBySourceRegistration: () => {
    const path = `/edudashboard/getCountStudentGroupBySourceRegistration`;
    return axiosApiInstance.get(path);
  },

  getCountStudentGroupByStudentStatus: () => {
    const path = `/edudashboard/getCountStudentGroupByStudentStatus`;
    return axiosApiInstance.get(path);
  },

  getCountStudentGroupByConsulting: () => {
    const path = `/edudashboard/getCountStudentGroupByConsulting`;
    return axiosApiInstance.get(path);
  },

  getCountStudentGroupByFollowing: () => {
    const path = `/edudashboard/getCountStudentGroupByFollowing`;
    return axiosApiInstance.get(path);
  },

  getCountStudentGroupBySchool: () => {
    const path = `/edudashboard/getCountStudentGroupBySchool`;
    return axiosApiInstance.get(path);
  },

  getCountTeacherCourseStudentBranch: () => {
    const path = `/edudashboard/getCountTeacherCourseStudentBranch`;
    return axiosApiInstance.get(path);
  },
};

export default eduDashboardApi;
