import axiosApiInstance from "../utils/axiosClient";

const subjectApi = {
  getAllSubjects: () => {
    const path = `/subject/getAll`;
    return axiosApiInstance.get(path);
  },
  getSubject: (id) => {
    const path = `/subject/getSubject/${id}`;
    return axiosApiInstance.get(path);
  },
  createSubject: (data) => {
    const path = `/subject/createSubject`;
    return axiosApiInstance.post(path, data);
  },
  updateSubject: (data, id) => {
    const path = `/subject/updateSubject/${id}`;
    return axiosApiInstance.patch(path, data);
  },
  deleteSubject: (id) => {
    const path = `/subject/deleteSubject/${id}`;
    return axiosApiInstance.delete(path);
  },
};

export default subjectApi;
