import axiosApiInstance from "../utils/axiosClient";

const customerApi = {
  getAll: () => {
    const path = `/customer/getAll`;
    return axiosApiInstance.get(path);
  },
  create: (data) => {
    const path = `/customer/createCustomer`;
    return axiosApiInstance.post(path, data);
  },
  update: (id, data) => {
    const path = `/customer/updateCustomer/${id}`;
    return axiosApiInstance.patch(path, data);
  },
  delete: (id) => {
    const path = `/customer/deleteCustomer/${id}`;
    return axiosApiInstance.delete(path);
  },
};

export default customerApi;
