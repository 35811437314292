import { axiosApiInstanceAuth } from "../utils/axiosClient";

const questionApi = {
    getAllQuestions: () => {
        const path = `/question/getAll`;
        return axiosApiInstanceAuth.get(path);
    },
};

export default questionApi;
