import { useState } from 'react';
import uploadApi from '../api/uploadApi';

export const useUpload = () => {
  const [folderContent, setFolderContent] = useState([]);
  const [files, setFiles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getFolderContent = async (folderName) => {
    setIsLoading(true);
    try {
      let res = await uploadApi.getFolderContent(folderName);
      if (res.data) {
        setFolderContent(res.data.elements);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getAllFilesFromFolder = async (folderName) => {
    setIsLoading(true);
    try {
      let res = await uploadApi.getAllFilesFromFolder(folderName);
      if (res.data) {
        setFiles(res.data.elements?.files);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    folderContent,
    files,
    getFolderContent,
    getAllFilesFromFolder,
  };
};
