import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { orgState } from '../recoil/atom/orgState';
import orgApi from '../api/orgApi';

export const useOrg = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orgs, setOrgs] = useRecoilState(orgState);

  useEffect(() => {
    if (orgs?.length === 0) {
      getAllOrgs();
    }
  }, []);

  const getAllOrgs = async () => {
    setIsLoading(true);
    try {
      let res = await orgApi.getAllOrgs();
      if (res.data) {
        setOrgs(res.data.elements);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    orgs,
    getAllOrgs,
    isLoading,
  };
};
