import axiosApiInstance from "../utils/axiosClient";

const sourceRegistrationApi = {
  getAllSourceRegistrations: () => {
    const path = `/sourceRegistration/getAll`;
    return axiosApiInstance.get(path);
  },
};

export default sourceRegistrationApi;
