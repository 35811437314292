import { useState } from 'react';
import { useRecoilState } from 'recoil';
import subjectApi from '../api/subjectApi';
import { subjectState } from '../recoil/atom/subjectState';

export const useSubject = () => {
  const [subject, setSubject] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [subjects, setSubjects] = useRecoilState(subjectState);

  const getAllSubjects = async () => {
    setIsLoading(true);
    try {
      let res = await subjectApi.getAllSubjects();
      if (res.data) {
        setSubjects(res.data.elements);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getSubject = async (id, callback) => {
    setIsLoading(true);
    try {
      let res = await subjectApi.getSubject(id);
      if (res.data) {
        setSubject(res.data.elements.subject);
        callback();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const createSubject = async (data, callback) => {
    setIsLoading(true);
    try {
      let res = await subjectApi.createSubject(data);
      if (res.data) {
        getAllSubjects();
        setIsLoading(false);
        callback();
        setSubject(undefined);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const updateSubject = async (data, id, callback) => {
    setIsLoading(true);
    try {
      let res = await subjectApi.updateSubject(data, id);
      if (res.data) {
        getAllSubjects();
        setIsLoading(false);
        callback();
        setSubject(undefined);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteSubject = async (id) => {
    setIsLoading(true);
    try {
      let res = await subjectApi.deleteSubject(id);
      if (res.data) {
        getAllSubjects();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    subjects,
    subject,
    getAllSubjects,
    getSubject,
    createSubject,
    updateSubject,
    deleteSubject,
    isLoading,
  };
};
