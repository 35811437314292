import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ROUTER } from '../../constant/router';
import i18n from '../../lib/Language';

const CATEGORY_MENU = [
  {
    key: 1,
    path: 'introduction',
    label: <span>{i18n.t('public.introduce')}</span>,
  },
  {
    key: 2,
    path: 'friendly-round',
    label: <span>{i18n.t('WROs.btnExchange')}</span>,
  },
  {
    key: 3,
    path: 'qualifying-round',
    label: <span>{i18n.t('WROs.prequalification')}</span>,
  },
  {
    key: 4,
    path: 'regional-round',
    label: <span>{i18n.t('WROs.area')}</span>,
  },
  {
    key: 5,
    path: 'final-round',
    label: <span>{i18n.t('WROs.final')}</span>,
  },
];

const FLLCategories = () => {
  const { year } = useParams();
  const location = useLocation();
  const [active, setActive] = useState();

  useEffect(() => {
    if (location) {
      const path = location.pathname.split('/');
      setActive(path[path.length - 2]);
    }
  }, [location]);

  return (
    <div className="container">
      <Space className="wro-categories" style={{ width: '100%' }} wrap>
        {CATEGORY_MENU.map((category) => (
          <Link to={`${ROUTER.COMPETITIONS.FLL.path}/${category.path}/${year}`} key={category.key}>
            <button className={`btn btn-green ${active === category.path && 'active'}`}>
              <span>{category.label}</span>
            </button>
          </Link>
        ))}

        <Link to={`${ROUTER.PUBLIC.IMAGEEVENT.path}`}>
          <button className="btn btn-green ">
            <span>{i18n.t('WROs.library')}</span>
          </button>
        </Link>
      </Space>
    </div>
  );
};

export default FLLCategories;
