import React from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import { thebooks2019_3, thebooks2019_1, thebooks2019_2, thuvien2019_1 } from '../../../assets/images';
import { Col, Image, Row } from 'antd';
import i18n from '../../../lib/Language';

const Activities = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Breadcrumb address={i18n.t('FLLs.imgExtra')} parent="Gallery" />
            <div class="section nav-subscribe" style={{ display: 'none' }}>
              <div class="container">
                <div class="nav-subscribe-wrapper">
                  <div class="nav-subscribe-left">
                    <p class="subscribe-text">
                      Sự kiện <b class="focus">STEM SQUARE</b>
                    </p>
                  </div>
                  <div class="nav-subscribe-right">
                    <button class="btn btn-green btn-bold">
                      <span>SUBSCRIBE NOW</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="section section-padding gallery-page gallery-mansonry">
              <div class="container">
                <div class="gallery-nav">
                  <ul id="filters" class="nav nav-tabs edugate-tabs button-group filters-button-group">
                    <li data-filter="*" data-category="all" class="tab active">
                      <div class="text">all</div>
                    </li>
                  </ul>
                </div>
                <Row gutter={[16, 16]} justify="center">
                  <Col span={12}>
                    <Image width="100%" src={thebooks2019_3} />
                  </Col>
                  <Col span={12}>
                    <Image width="100%" src={thebooks2019_1} />
                  </Col>
                  <Col span={12}>
                    <Image width="100%" src={thebooks2019_2} />
                  </Col>
                  <Col span={12}>
                    <Image width="100%" src={thuvien2019_1} />
                  </Col>
                </Row>
                <div class="load-more">
                  <button class="btn btn-green">
                    <span>{i18n.t('general.button.seeMore')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="back-top">
        <a href="#top">
          <i class="fa fa-angle-double-up"></i>
        </a>
      </div>
    </div>
  );
};

export default Activities;
