import React, { useState } from 'react';
import { Select, Space } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTER } from '../../../constant/router';
import { FLLCategoriesOverview } from '../../../assets/images';

const FLL = () => {
  const [selectYear, setSelectYear] = useState(2023);
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Space direction="vertical" style={{ width: '100%' }}>
              <div className="container">
                <div className="section" style={{ paddingTop: 16 }}>
                  <div style={{ padding: '80px 0' }}>
                    <div style={{ textAlign: 'center', maxWidth: 940, margin: '0 auto' }}>
                      <h1 className="title-heading">
                        FLL<sup>®</sup> Categories
                      </h1>
                      <p className="text-md">
                        At FLL® we offer challenging competitions for students in the age range from 8 up to 19. We have
                        four competition categories, each with their own characteristics.
                      </p>
                      <Space direction="vertical" align="center">
                        <Space wrap>
                          <h3>Select year:</h3>
                          <Select
                            style={{ width: 150 }}
                            defaultValue={'2023'}
                            className="select-year"
                            options={[
                              {
                                value: '2019',
                                label: '2019',
                              },
                              {
                                value: '2023',
                                label: '2023',
                              },
                            ]}
                            onSelect={(value) => setSelectYear(value)}
                          />
                          {selectYear === 2019 ? (
                            <Link to={`${ROUTER.COMPETITIONS.FLLINTRODUCTION2019.path}/${selectYear}`}>
                              <button className="btn btn-green">
                                <span>GO...</span>
                              </button>
                            </Link>
                          ) : (
                            <Link to={`${ROUTER.COMPETITIONS.FLLINTRODUCTION.path}/${selectYear}`}>
                              <button className="btn btn-green">
                                <span>GO...</span>
                              </button>
                            </Link>
                          )}
                        </Space>
                      </Space>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <img src={FLLCategoriesOverview} className="img-responsive" alt="" />
              </div>
              <br></br>
            </Space>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FLL;
