import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { formatDateToString } from '../../../common';
import { ROUTER } from '../../../constant/router';
import { useNews } from '../../../hooks/news';
import { useSystem } from '../../../hooks/system';

const Relatednews = () => {
  const { id } = useParams();
  const { similarNews, getNewsListByNewsId } = useNews();
  const { createUserActionHistory } = useSystem();

  // GET SIMILAR LIST NEWS
  useEffect(() => {
    if (id) {
      getNewsListByNewsId(id);
    }
  }, [id]);

  const similarData = useMemo(() => {
    const tmp = similarNews?.filter((news) => news?.id !== +id);
    const sortData = tmp.slice(0, 5).sort((a, b) => {
      if (formatDateToString(new Date(a?.CREATED_DATE)) > formatDateToString(new Date(b?.CREATED_DATE))) return -1;
      if (formatDateToString(new Date(a?.CREATED_DATE)) < formatDateToString(new Date(b?.CREATED_DATE))) return 1;

      if (a?.INDEX > b?.INDEX) return 1;
      if (a?.INDEX < b?.INDEX) return -1;
    });

    return sortData;
  }, [similarNews, id]);

  // INSERT VIEW COUNT NEWS
  const handleNewsClick = async (id, name) => {
    const data = {
      NEWS_ID: id,
      ACTION_KEY: 'R',
      NAME: 'News',
      DESC: name,
      NOTE: 'Read News',
    };

    await createUserActionHistory(data);
  };

  return (
    <div class="news-list">
      <div class="list-expand-title">Các tin liên quan</div>
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-12">
          <ul class="list-unstyled list-detail">
            {similarData && similarData?.length
              ? similarData.map((item, index) => (
                  <li key={index}>
                    <i class="fa fa-angle-right"></i>
                    <Link
                      to={`${ROUTER.PUBLIC.SHOW_NEWS_DETAIL.path}/${item?.id}`}
                      onClick={() => handleNewsClick(item?.id, item?.TITLE)}
                    >
                      {item.TITLE}.
                    </Link>
                  </li>
                ))
              : ''}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Relatednews;
