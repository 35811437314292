import React from 'react';
import CourseItem from './CourseItem';

const ListOther = ({ data }) => {
  return (
    <div className="row">
      {data &&
        data?.map((item) => (
          <div className="col-md-4" style={{ marginBottom: 50 }}>
            {/* <div className="edugate-layout-3 news-gird">
              <div className="edugate-layout-3-wrapper">
                <Link to={`/course/view/${item?.COURSE_ID}`} className="edugate-image">
                  <img src={item?.IMAGE} alt="" className="img-responsive" />
                </Link>

                <div className="edugate-content">
                  <Link to={`/course/view/${item?.COURSE_ID}`} className="title">
                    {item?.NAME}
                  </Link>

                  <div className="info-more info-event-detail">
                    <Space>
                      <div className="item">
                        <i className="fa fa-user"></i>
                        <p> {item?.STUDENT_LEARNING_COUNT}</p>
                      </div>
                      <div className=" item">
                        <i className="fa fa-eye"></i>
                        <p> {item?.SUBJECT_VIEW}</p>
                      </div>
                    </Space>
                  </div>
                  <Space className="info">
                    <div className="author item">By {item?.DESIGNED_BY}</div>
                    <Divider type="vertical" />
                    <div className="item">
                      <i className="fa fa-clock-o fa-fw"></i>
                      <span>{moment(item?.CREATED_DATE).format(formatDate.Type)}</span>
                    </div>
                  </Space>
                  <Space style={{ width: '100%', marginBottom: 20 }} direction="vertical">
                    <strong>{item?.COURSE_NAME}</strong>
                    <p>{item?.GRADE_NAME}</p>
                    <Space size={4} wrap>
                      <Tag color="green">
                        Khai giảng:&nbsp;
                        {item?.LEARN_START_DATE === ('' || null)
                          ? 'Chưa có'
                          : formatDateToString(new Date(item?.LEARN_START_DATE))}
                      </Tag>
                      <Tag color="volcano">
                        Kết thúc:&nbsp;
                        {item?.LEARN_END_DATE === ('' || null)
                          ? 'Chưa có'
                          : formatDateToString(new Date(item?.LEARN_END_DATE))}
                      </Tag>
                    </Space>
                  </Space>
                  <button onClick={() => history.push(`/course/view/${item?.id}`)} className="btn btn-green">
                    <span>Xem chi tiết</span>
                  </button>
                </div>
              </div>
            </div> */}
            <CourseItem item={item} />
          </div>
        ))}
    </div>
  );
};

export default ListOther;
