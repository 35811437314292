import React from 'react';
import Courses from '../../../Public/News/Courses';
import StempRobotics from '../../../Public/News/StempRobotics';
import Teacher from '../../../Global/Teacher';
import Schedule from '../../../Global/Schedule';
import Breadcrumb from '../../../Global/Breadcrumb';
import RelatedNews from '../../../Global/RelatedNews';

const BenefitStem = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Breadcrumb address="Lợi ích việc học STEM" />
            <div class="section" style={{ display: 'none' }}>
              <div class="search-input">
                <div class="container">
                  <div class="search-input-wrapper">
                    <form>
                      <select class="form-select style-1 selectbox">
                        <option value="all">all categories</option>
                        <option value="languages">languages</option>
                        <option value="science">science</option>
                      </select>
                      <select class="form-select style-2 selectbox">
                        <option value="price">price</option>
                        <option value="datetime">datetime</option>
                        <option value="teacher">teacher</option>
                      </select>
                      <input type="text" placeholder="Do you want to learn today?" class="form-input" />
                      <button type="submit" class="form-submit btn btn-blue">
                        <span>
                          search now<i class="fa fa-search"></i>
                        </span>
                      </button>
                      <div class="clearfix"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="section section-padding courses-detail">
              <div class="container">
                <div class="courses-detail-wrapper">
                  <div class="row">
                    <div class="col-md-9 layout-left">
                      <h1 class="course-title">Lợi ích việc học STEM</h1>

                      <div class="course-info info">
                        <div class="author item">
                          <a href="#">
                            <span>By&nbsp;</span>
                            <span>Tuấn Trương</span>
                          </a>
                        </div>
                        <div class="date-time item">
                          <a href="#">20 Aug 2019</a>
                        </div>
                      </div>
                      <div class="course-video">
                        <iframe
                          src="https://www.youtube.com/embed/wms1Yo9_wGY"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <div class="video-btn">
                          <button class="btn btn-grey video-btn-right pull-right">
                            <span>next</span>
                            <i class="fa fa-angle-right"></i>
                          </button>
                          <button class="btn btn-grey video-btn-left pull-right">
                            <i class="fa fa-angle-left"></i>
                            <span>previous</span>
                          </button>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <div class="course-des">
                        <div class="course-des-title underline">Lợi ích cho việc học STEM</div>
                        <div class="course-des-content">
                          <p>Nâng cao khả năng khoa học</p>
                          <p>Nâng cao kỹ năng công nghệ</p>
                          <p>Nâng cao kỹ năng kỹ thuật</p>
                          <p>Nâng cao kỹ năng toán học</p>
                          <p>Trang bị đầy đủ các kỹ năng của thế kỷ 21</p>
                          <p>Bố Mẹ học cùng con trong lĩnh vực STEM</p>

                          <div class="course-des-title underline">Giáo dục STEM là gì?</div>
                          <p>
                            Là giáo dục làm sao người học có thể hình thành từ ý tưởng, kỹ năng giải quyết vấn đề với sự
                            kết hợp các kiến thức học được thông qua những sự việc, vấn đề cụ thể. Đây không chỉ là một
                            cách dạy cụ thể cho một môn học nào cả như Robot hay Toán học, mà là mục tiêu đào tạo để
                            người học có đầy đủ kỹ năng và phát triển trong thế giới công nghệ và xu hướng thời đại công
                            nghiệp 4.0 hiện nay. Giáo dục STEM tạo ra những con người có thể đáp ứng được nhu cầu công
                            việc của thế kỷ 21, đáp ứng sự phát triển kinh tế, xã hội của quốc gia và có thể tác động
                            tích cực đến sự thay đổi của nền kinh tế tri thức trong bối cảnh toàn cầu hóa.
                          </p>
                          <p>
                            Những điểm mạnh của giáo dục STEM:
                            <p>Thứ nhất:</p>
                            Giáo dục STEM là phương thức giáo dục tích hợp theo cách tiếp cận liên môn
                            (interdisciplinary) và thông qua mô hình thực hành - ứng dụng. Thay vì dạy bốn môn học như
                            các đối tượng tách biệt và rời rạc, STEM kết hợp chúng thành một mô hình học tập gắn kết dựa
                            trên các ứng dụng thực tế. Qua đó, học sinh vừa học được kiến thức khoa học, vừa học được
                            cách vận dụng kiến thức đó vào thực tiễn.
                            <p>Thứ hai:</p>
                            Giáo dục STEM đề cao đến việc hình thành và phát triển năng lực giải quyết vấn đề cho người
                            học. Trong mỗi bài học theo chủ đề STEM, học sinh được đặt trước một tình huống có vấn đề
                            thực tiễn cần giải quyết liên quan đến các kiến thức khoa học.
                            <p>Thứ ba:</p>
                            Giáo dục STEM đề cao một phong cách học tập mới cho người học, đó là phong cách học tập sáng
                            tạo. Đặt người học vào vai trò của một nhà phát minh, người học sẽ phải hiểu thực chất của
                            các kiến thức được trang bị; phải biết cách mở rộng kiến thức; phải biết cách sửa chữa, chế
                            biến lại chúng cho phù hợp với tình huống có vấn đề mà người học đang phải giải quyết. .
                          </p>
                          <blockquote>
                            <div class="main-quote">Ta không nên dạy trẻ nhỏ khoa học; mà hãy để trẻ nếm trải nó!</div>
                            <div class="sub-quote">
                              STEM, giáo dục STEM, là một hướng mới đang phát triển trong giáo dục thế giới, đặc biệt ở
                              Hoa Kỳ. Việt Nam cũng đang phát triển sự quan tâm đến giáo dục này..
                            </div>
                            <footer>
                              Trương Quốc Tuấn&nbsp;
                              <small>(CEO - STEM SQUARE)</small>
                            </footer>
                          </blockquote>
                        </div>
                        <RelatedNews />
                      </div>
                      <Schedule />
                    </div>

                    <div class="col-md-3 sidebar layout-right">
                      <div class="row">
                        <Teacher />
                        <div class="clearfix"></div>
                        {/* <StempRobotics /> */}
                        {/* <Courses /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="back-top">
        <a href="#top">
          <i class="fa fa-angle-double-up"></i>
        </a>
      </div>
    </div>
  );
};

export default BenefitStem;
