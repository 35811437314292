import axiosApiInstance from "../utils/axiosClient";

const gradeApi = {
  getAllGrade: () => {
    const path = `/grade/getAll`;
    return axiosApiInstance.get(path);
  },
};

export default gradeApi;
