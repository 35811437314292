import { Empty, List, Space, Spin, Typography } from 'antd';
import React from 'react';

const { Title, Text, Paragraph } = Typography;

const ListLesson = ({ isLoading, data }) => {
  const useStyles = {
    titleBgRoundStyles: {
      width: 40,
      height: 40,
      backgroundColor: '#eee',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
    },
  };

  return (
    <>
      {data?.length === 0 ? (
        <Empty
          style={{ width: '100%', padding: 20 }}
          description={<Text strong>Khóa học này chưa có tài liệu học nào!</Text>}
        />
      ) : (
        <List
          itemLayout="vertical"
          dataSource={data}
          pagination={{
            pageSize: 10,
          }}
          renderItem={(lesson) => (
            <List.Item key={lesson.id}>
              {/* <Link
                    to={`/education/lesson_detail/${lesson.id}`}
                    style={{
                      width: "100%",
                    }}
                  > */}
              <Space>
                <Title level={4} style={useStyles.titleBgRoundStyles}>
                  {lesson.ORDER + 1}
                </Title>
                <Space direction="vertical" style={{ flex: 1, margin: '0 16px' }}>
                  <Title level={5}>{lesson.NAME}</Title>
                  <Paragraph ellipsis={{ rows: 2 }} className="text-gray">
                    {lesson?.DESC}
                  </Paragraph>
                </Space>
              </Space>
              {/* </Link> */}
            </List.Item>
          )}
        />
      )}
    </>
  );
};

export default ListLesson;
