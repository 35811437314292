import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

export default withRouter(function ScrollToTop(props) {
  let { location } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{props.children}</>;
});
