import axiosApiInstance from "../utils/axiosClient";

const courseFileApi = {
  getAll: () => {
    const path = `/courseFile/getAll`;
    return axiosApiInstance.get(path);
  },
  getCourseFileByCourseId: (id) => {
    const path = `/courseFile/getCourseFileByCourseId/${id}`;
    return axiosApiInstance.get(path);
  },
  createCourseFile: (data) => {
    const path = `/courseFile/createCourseFile`;
    return axiosApiInstance.post(path, data);
  },
  updateCourseFile: (data, id) => {
    const path = `/courseFile/updateCourseFile/${id}`;
    return axiosApiInstance.patch(path, data);
  },
  deleteCourseFile: (id) => {
    const path = `/courseFile/deleteCourseFile/${id}`;
    return axiosApiInstance.delete(path);
  },
};

export default courseFileApi;
