import React from 'react';

const RelatedNews = () => {
  return (
    <div class="news-list">
      <div class="list-expand-title">Các tin liên quan</div>
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-6">
          <ul class="list-unstyled list-detail">
            <li>
              <i class="fa fa-angle-right"></i>
              <a href="benefit-stem.html">Lợi ích của việc học STEM.</a>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>
              <a href="courses.html">Tư vấn chương trình học STEM.</a>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>
              <a href="competion.html">Sân chơi STEM, thi đấu Robotics</a>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>
              <a href="model.html">Chất lượng giáo dục tại STEM SQUARE.</a>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>
              <a href="distribute">Nhà phân phối LEGO EDUCATION.</a>
            </li>
          </ul>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6">
          <ul class="list-unstyled list-detail">
            <li>
              <i class="fa fa-angle-right"></i>
              <a href="robokids">Cuộc thi đấu Robokids 2019.</a>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>
              <a href="robotacon.html">Cuộc thi đấu Robotacon Quốc Gia 2019.</a>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>
              <a href="wro.html">Cuộc thi đấu WRO 2019 tại Hungary.</a>
            </li>
            <li>
              <i class="fa fa-angle-right"></i>
              <a href="fll.html">FLL 2019 - Tại Đà nẵng và Tại Mỹ.</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RelatedNews;
