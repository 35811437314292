import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import { Col, DatePicker, Row, Table, Image, Space, Empty, Spin, Popover } from 'antd';
import { checkAttendance, checkAttendanceStatus, formatDate } from '../../../common';
import moment from 'moment';
import { studentAttendanceByQueryData } from '../../../common/getAllApi';
import { useRecoilState } from 'recoil';
import { StudentAttendanceStatuseState } from '../../../recoil/atom/studentAttendanceState';
import studentAttendanceStatusesApi from '../../../api/studentAttendanceStatusesApi';
import dayjs from 'dayjs';
import i18n from '../../../lib/Language';

const LearningTracking = () => {
  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );

  const columns = [
    {
      title: 'STT',
      width: '5%',
      render: (text, record, index) => index + 1,
      width: 50,
    },
    {
      title: `${i18n.t('FLLs.learningTrackings.code')}`,
      key: 'MA',
      render: (record) => <a>{record?.User?.USER_CD}</a>,
      width: 100,
    },
    {
      title: 'Avatar',
      key: 'AVATAR',
      render: (record) => (
        <div>
          <Image
            width={40}
            src={'http://erp.novasquare.vn:8888/users/' + record?.User?.AVATAR}
            style={{ borderRadius: '50%' }}
          />
        </div>
      ),
      width: 70,
    },
    {
      title: `${i18n.t('FLLs.learningTrackings.firstName')}`,
      key: 'FIRST_NAME',
      render: (record) => <p>{record?.User?.FIRST_NAME}</p>,
      width: 200,
    },
    {
      title: `${i18n.t('FLLs.learningTrackings.lastName')}`,
      key: 'LAST_NAME',
      render: (record) => <p>{record?.User?.LAST_NAME}</p>,
      sorter: (a, b) => ('' + a.User?.LAST_NAME).localeCompare(b.User?.LAST_NAME),
      width: 100,
    },
    {
      title: `${i18n.t('FLLs.learningTrackings.bod')}`,
      key: 'BOD',
      render: (record) => <p>{moment(record.User?.BOD).format(formatDate.Type)}</p>,
      width: 120,
    },
    {
      title: `${i18n.t('FLLs.learningTrackings.schoolDay')}`,
      key: 'LEARNED_DATE',
      render: (record) => <p>{moment(record.LEARNED_DATE).format(formatDate.Type)}</p>,
      width: 120,
    },
    {
      title: `${i18n.t('FLLs.learningTrackings.class')}`,
      key: 'CLASS',
      render: (record) => <p>{record?.Learning_Tracking?.Class?.NAME}</p>,
      width: 250,
    },
    {
      title: `${i18n.t('FLLs.learningTrackings.status')}`,
      key: 'STATUS',
      render: (record) =>
        record.REASON ? (
          <Popover placement="topLeft" content={<p>{record.REASON}</p>}>
            <p className="Attendance-status">{checkAttendance(record?.Student_Attendance_Status?.CD)}</p>
          </Popover>
        ) : (
          <p className="Attendance-status">{checkAttendance(record?.Student_Attendance_Status?.CD)}</p>
        ),
      width: 100,
    },
  ];

  const [listStudentAttendance, setLitStudentAttendance] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [studentAttendanceStatus, setStudentAttendanceStatus] = useRecoilState(StudentAttendanceStatuseState);

  const getStudentAttendanceStatus = async () => {
    try {
      let res = await studentAttendanceStatusesApi.getAllStudentAttendanceStatuses();
      if (res.data) {
        setStudentAttendanceStatus(res.data.elements);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (studentAttendanceStatus?.length === 0) {
      getStudentAttendanceStatus();
    }
  }, []);

  const onChange = async (date) => {
    const learDate = { LEARNED_DATE: moment.utc(new Date(date).setHours('00')).format('YYYY-MM-DDTHH:00:00.000[Z]') };
    studentAttendanceByQueryData(setLitStudentAttendance, learDate, setIsLoading);
  };

  useEffect(() => {
    const learDate = { LEARNED_DATE: moment.utc(new Date().setHours('00')).format('YYYY-MM-DDTHH:00:00.000[Z]') };
    studentAttendanceByQueryData(setLitStudentAttendance, learDate, setIsLoading);
  }, []);

  function checkAttend(id) {
    const attend = studentAttendanceStatus?.filter((item) => item?.id === id);
    return attend[0]?.NAME;
  }

  const [absent, setAbsent] = useState([]);
  const [student, setStudent] = useState([]);
  useEffect(() => {
    if (listStudentAttendance?.length) {
      const students = listStudentAttendance?.filter((item) => item?.User !== null);
      setAbsent(
        students?.filter(
          (item) => item?.Student_Attendance_Status?.CD === 'KP' || item?.Student_Attendance_Status?.CD === 'VP',
        ),
      );

      setStudent(students);
    } else {
      setAbsent([]);
      setStudent([]);
    }
  }, [listStudentAttendance]);

  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Breadcrumb address={i18n.t('menu.library.learningTracking')} parent="Learning tracking" />

            <div class="section section-padding gallery-page gallery-mansonry">
              <div class="container ">
                <Row>
                  <Col span={24}>
                    <h2 className="tracking-title" style={{ textAlign: 'center', paddingBottom: 40 }}>
                      {i18n.t('FLLs.learningTrackings.listCheckin')}
                    </h2>
                  </Col>
                </Row>
                <Row style={{ paddingBottom: 20 }}>
                  <Col xs={12} sm={6} lg={4} xl={4}>
                    <DatePicker
                      style={{ width: '100%' }}
                      onChange={onChange}
                      format={formatDate.Type}
                      defaultValue={dayjs()}
                      placeholder="Chọn ngày"
                    />
                  </Col>
                  <Col xs={10} sm={7} lg={5} xl={5} offset={1}>
                    <Space style={{ paddingTop: 6 }}>
                      <p>
                        {i18n.t('FLLs.learningTrackings.total')}: {student?.length}
                      </p>
                      <p>
                        {i18n.t('FLLs.learningTrackings.absent')}: {absent?.length}
                      </p>
                    </Space>
                  </Col>
                  <Col xs={24} sm={24} lg={14} xl={14} className="layout-pc">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 20,
                        paddingTop: 6,
                      }}
                      className="layout-pc"
                    >
                      {studentAttendanceStatus &&
                        studentAttendanceStatus?.length &&
                        studentAttendanceStatus.map((item, index) => (
                          <p key={index}>
                            {checkAttendance(item.CD)} : {item.NAME}
                          </p>
                        ))}
                    </div>
                  </Col>
                </Row>
                <Row className="layout-pc">
                  <Col span={24}>
                    <Table
                      columns={columns}
                      dataSource={student}
                      loading={isLoading}
                      scroll={{
                        x: 1100,
                      }}
                      pagination={{
                        pageSize: 20,
                      }}
                    />
                  </Col>
                </Row>
                <Row className="layout-mobile">
                  {isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150 }}>
                      <Spin />
                    </div>
                  ) : student?.length ? (
                    student?.map((item, index) => (
                      <Col span={24} key={index}>
                        <div className="card-tracking">
                          <Row>
                            <Col span={18}>
                              <p className="font-weight-500">
                                {item?.User?.USER_CD} - {item?.User?.FIRST_NAME} {item?.User?.LAST_NAME}
                              </p>
                              <p>
                                Lớp: <span className="font-weight-500">{item?.Learning_Tracking?.Class?.NAME}</span>
                              </p>
                              <p>
                                Ngày học:{' '}
                                <span className="font-weight-500">
                                  {moment(item.LEARNED_DATE).format(formatDate.Type)}
                                </span>
                              </p>
                              {item.REASON && (
                                <p>
                                  Lý do: <span className="font-weight-500">{item.REASON}</span>
                                </p>
                              )}
                            </Col>
                            <Col
                              span={6}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Image
                                width={40}
                                src={'http://erp.novasquare.vn:8888/users/' + item?.User?.AVATAR}
                                style={{ borderRadius: '50%' }}
                              />
                              <p
                                className={checkAttendanceStatus(item?.Student_Attendance_Status?.CD)}
                                style={{ textAlign: 'center', marginTop: 8 }}
                              >
                                {checkAttend(item?.ATTENDANCE_STATUS_ID)}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <Row>
                      <Col span={24}>
                        <Empty />
                      </Col>
                    </Row>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="back-top">
        <a href="#top">
          <i class="fa fa-angle-double-up"></i>
        </a>
      </div>
    </div>
  );
};

export default LearningTracking;
