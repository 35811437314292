import axiosApiInstance from "../utils/axiosClient";

const customerCategoryApi = {
  getAllCustomerCat: () => {
    const path = `/customerCategory/getAll`;
    return axiosApiInstance.get(path);
  },
};

export default customerCategoryApi;
