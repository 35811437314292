import areaApi from "../api/areaApi";
import cityApi from "../api/cityApi";
import departmentApi from "../api/departmentApi";
import diseaseApi from "../api/diseaseApi";
import diseaseStatusApi from "../api/diseaseStatusApi";
import districtApi from "../api/districtApi";
import divisionApi from "../api/divisionApi";
import employeeApi from "../api/employeeApi";
import employeeContractTypeApi from "../api/employeeContractTypeApi";
import genderApi from "../api/genderApi";
import generalSettingsApi from "../api/generalSettingsApi";
import healthHisApi from "../api/healthHisApi";
import lessonManagementApi from "../api/lessonManagementApi";
import maritalStatusApi from "../api/maritalStatusApi";
import medicalFacilityApi from "../api/medicalFacilityApi";
import physicalClassificationApi from "../api/physicalClassificationApi";
import physicalExamApi from "../api/physicalExamApi";
import positionApi from "../api/positionApi";
import sourceRegistrationApi from "../api/sourceRegistrationApi";
import classTypeApi from "../api/classTypeApi";
import studentEvaluationCriteriaApi from "../api/studentEvaluationCriteriaApi";
import studentTypeApi from "../api/studentTypeApi";
import courseStatusApi from "../api/courseStatusApi";
import classStatusApi from "../api/classStatusApi";
import unitApi from "../api/unitApi";
import workPlaceApi from "../api/workPlaceApi";
import axiosApiInstance from "../utils/axiosClient";
import notificationApi from "../api/notificationApi";
import newsApi from "../api/newsApi";
import classApi from "../api/classApi";
import classScheduleApi from "../api/classScheduleApi";
import studentPaymentApi from "../api/studentPayment";
import studentApi from "../api/studentApi";

// GET ALL EMPLOYEE
export const employeeData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await employeeApi.getAllEmployees();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};


// GET ALL CLASS_TYPE
export const classTypeData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await classTypeApi.getAllClassTypes();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL STUDENT EVALUATION CRITERIA
export const studentEvaluationCriteriaData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res =
          await studentEvaluationCriteriaApi.getAllStudentEvaluationCriterias();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL STUDENT TYPE
export const studentTypeData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await studentTypeApi.getAllStudentTypes();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL COURSE STATUS
export const courseStatusData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await courseStatusApi.getAllCourseStatuses();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL CLASS STATUS
export const classStatusData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await classStatusApi.getAllClassStatuses();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL CLASS
export const classData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await classApi.getAllClasses();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET CLASS SCHEDULE QUERY BY CLASS ID
export const classScheduleByClassIdData = (callback, data) => {
  (async () => {
    const path = `classSchedule/getAllByQuery`;
    try {
      const res = await axiosApiInstance.post(path, data);
      if (res.data) {
        callback(() => res.data.elements);
      }
    } catch (err) {
      console.log(err);
    }
  })();
};

// GET ALL NOTIFICATION
export const notificationData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await notificationApi.getAllNotifications();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET LATEST NOTIFICATION
export const latestNotificationData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await notificationApi.getLatestNotifications();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL NEWS
export const newsData = (options, callback, setIsLoading) => {
  if (options?.length === 0) {
    (async () => {
      setIsLoading(true);
      try {
        let res = await newsApi.getAllNewss();
        if (res.data) {
          callback(() => res.data.elements);
        }
        setIsLoading(false);
      } catch (error) {
        console.log("error");
        setIsLoading(false);
      }
    })();
  }
};

// GET ALL STUDENT PAYMENT
export const studentPaymentData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await studentPaymentApi.getAll();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL STUDENT PAYMENT BY USER ID
export const studentPaymentDataByQuery = (
  options,
  callback,
  id,
  setIsLoading
) => {
  if (options?.length === 0) {
    (async () => {
      setIsLoading(true);
      try {
        let res = await studentPaymentApi.getAllByQuery({ USER_ID: id });
        if (res.data) {
          callback(() => res.data.elements);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error");
      }
    })();
  }
};

// GET ALL STUDENT
export const studentSearchByUserData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await studentApi.getStudentSearchByUser();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL LESSON
export const lessonData = (options, callback, setIsLoading) => {
  (async () => {
    setIsLoading(true);
    try {
      let res = await lessonManagementApi.getAllLessons();
      if (res.data) {
        callback(() => res.data.elements);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(true);
      console.log("error");
    }
  })();
};

// GET ALL STUDENT BY CLASS ID
export const studentByClasIdData = (options, callback, data, setIsLoading) => {
  (async () => {
    setIsLoading(true);
    const path = `class/getAllStudentByClassId/${data?.CLASS_ID}`;
    try {
      const res = await axiosApiInstance.get(path);
      if (res.data) {
        callback(() => res.data.elements);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  })();
};

// GET ALL LESSON BY QUERY
export const lessonByQueryData = (options, callback, data, setIsLoading) => {
  (async () => {
    setIsLoading(true);
    const path = `lesson/getAllByQuery`;
    try {
      const res = await axiosApiInstance.post(path, data);
      if (res.data) {
        callback(() => res.data.elements);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  })();
};

// /api/v1 / classSchedule / getAllByDateRange / { branchId } / { classId } / { teacherId } / { startDate } / { endDate }

export const searchClassScheduleByQuery = (
  callback,
  setIsLoading,
  branchId,
  classId,
  teacherId,
  startDate,
  endDate
) => {
  (async () => {
    const path = `classSchedule/getAllByDateRange/${JSON.stringify(
      branchId
    )}/${JSON.stringify(classId)}/${JSON.stringify(
      teacherId
    )}/${startDate}/${endDate}`;
    try {
      setIsLoading(true);
      const res = await axiosApiInstance.get(path);
      if (res.data) {
        callback(res.data.elements);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  })();
};

export const autoGenerateClassScheduleByClassId = (
  setClassSchedules,
  id,
  boolean
) => {
  (async () => {
    const path = `classSchedule/autoGenerateClassSchedule/${id}/${boolean}`;
    try {
      const res = await axiosApiInstance.get(path);
      if (res.data) {
        let resp = await classScheduleApi.getAllClassSchedules();
        if (resp.data) {
          setClassSchedules(resp.data.elements);
        }
      }
    } catch (err) {
      console.log(err);
    }
  })();
};

// GET ALL STUDENT ATTENDANCE BY QUERY
export const studentAttendanceByQueryData = (callback, data, setIsLoading) => {
  (async () => {
    setIsLoading(true)
    const path = `studentAttendance/getAllByQuery`;
    try {
      const res = await axiosApiInstance.post(path, data);
      if (res.data) {
        callback(() => res.data.elements);
        setIsLoading(false)
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false)
    }
  })();
};

// GET ALL LEARNING TRACKING BY QUERY
export const learningTrackingByQueryData = (options, callback, data) => {
  (async () => {
    const path = `learningTracking/getAllByQuery`;
    try {
      const res = await axiosApiInstance.post(path, data);
      if (res.data) {
        callback(() => res.data.elements);
      }
    } catch (err) {
      console.log(err);
    }
  })();
};

// GET ALL LEARNING TRACKING BY QUERY

export const attendanceByStudentAndClassIdQueryData = (
  options,
  callback,
  data,
  data2,
  setIsLoading
) => {
  (async () => {
    setIsLoading(true);
    const path = `studentAttendance/getAttendanceByStudentAndClassId/${data?.USER_ID}/${data2?.CLASS_ID}`;
    try {
      const res = await axiosApiInstance.get(path);
      if (res.data) {
        callback(() => res.data.elements.studentAttendance);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  })();
};

// GET ALL PHYSICAL EXAM
export const physicalExamData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await physicalExamApi.getAllPhysicalExams();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL PHYSICAL EXAM BY QUERY
export const physicalExamByQueryData = (options, callback, data, isDelete) => {
  // if (options?.length === 0) {
  (async () => {
    const path = `physicalExam/getAllByQuery`;
    try {
      const res = await axiosApiInstance.post(path, data);
      if (res.data) {
        callback(() => res.data.elements);
      }
    } catch (err) {
      console.log(err);
    }
  })();
  // }
  if (isDelete === true) {
    (async () => {
      const path = `physicalExam/getAllByQuery`;
      try {
        const res = await axiosApiInstance.post(path, data);
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }
};

// GET ALL HEALTH HISTORY
export const healthHissData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await healthHisApi.getAllHealthHiss();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL DISEASE
export const diseasesData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await diseaseApi.getAllDisease();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET DISEASE BY QUERY
export const diseaseByQueryData = (options, callback, data) => {
  // if (options?.length === 0) {
  (async () => {
    const path = `disease/getAllByQuery`;
    try {
      const res = await axiosApiInstance.post(path, data);
      if (res.data) {
        callback(() => res.data.elements);
      }
    } catch (err) {
      console.log(err);
    }
  })();
  // }
};

//GET ALL MEDICAL FACILITY
export const medicalFacilityData = (options, callback) => {
  (async () => {
    try {
      let res = await medicalFacilityApi.getAllMedicalFacilities();
      if (res.data) {
        callback(() => res.data.elements);
      }
    } catch (error) {
      console.log("error");
    }
  })();
};

// GET ALL GENDER
export const genderData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await genderApi.getAllGenders();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL MARITAL
export const maritalStatusData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await maritalStatusApi.getAllMaritalStatuses();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL AREA
export const areaData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await areaApi.getAllAreas();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL DEPARTMENT
export const departmentData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await departmentApi.getAllDepartment();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL POSITION
export const positionData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await positionApi.getAllPosition();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL UNIT
export const unitData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await unitApi.getAllUnits();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL DIVISION
export const divisionData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await divisionApi.getAllDivisions();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL EMPLOYEE CONTRACT TYPE
export const employeeContractTypeData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await employeeContractTypeApi.getAllEmployeeContractTypes();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL WORK PLACE
export const workPlaceData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await workPlaceApi.getAllWorkPlaces();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL CITY
export const cityData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await cityApi.getAllCities();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL DISTRICT
export const districtData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await districtApi.getAllDistricts();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};
// GET ALL GENERAL SETTING
export const generalSettingData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await generalSettingsApi.getAllGeneralSettings();
        if (res.data) {
          callback(() => res.data.elements.generalSetting);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

// GET ALL CLASSIFICATION
export const classificationData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res =
          await physicalClassificationApi.getAllPhysicalClassification();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};

export const diseaseStatusData = (options, callback) => {
  if (options?.length === 0) {
    (async () => {
      try {
        let res = await diseaseStatusApi.getAllDiseaseStatus();
        if (res.data) {
          callback(() => res.data.elements);
        }
      } catch (error) {
        console.log("error");
      }
    })();
  }
};
