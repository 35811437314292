import React from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import { Ev3, Wedo } from '../../../assets/images';
import RelatedNews from '../../../components/Global/RelatedNews';
import i18n from '../../../lib/Language';

const Lego = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Breadcrumb address={i18n.t('FLLs.diveceLego.diviceStem')} />

            {/*  */}
            <div class="section" style={{ display: 'none' }}>
              <div class="search-input">
                <div class="container">
                  <div class="search-input-wrapper">
                    <form>
                      <select class="form-select style-1 selectbox">
                        <option value="all">all categories</option>
                        <option value="languages">languages</option>
                        <option value="science">science</option>
                      </select>
                      <select class="form-select style-2 selectbox">
                        <option value="price">price</option>
                        <option value="datetime">datetime</option>
                        <option value="teacher">teacher</option>
                      </select>
                      <input type="text" placeholder="Do you want to learn today?" class="form-input" />
                      <button type="submit" class="form-submit btn btn-blue">
                        <span>
                          search now<i class="fa fa-search"></i>
                        </span>
                      </button>
                      <div class="clearfix"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div class="section section-padding latest-news">
              <div class="container">
                <div class="group-title-index">
                  <h4 class="top-title">{i18n.t('FLLs.diveceLego.diviceStemRobo')} </h4>

                  <h2 class="center-title">LEGO EDUCATION</h2>

                  <div class="bottom-title">
                    <i class="bottom-icon icon-a-01-01"></i>
                  </div>
                </div>
                <div class="latest-news-wrapper">
                  <div class="edugate-layout-1">
                    <div class="edugate-image">
                      <img src={Wedo} alt="" class="img-responsive" />
                    </div>
                    <div class="edugate-content">
                      <a href="#" class="title">
                        LEGO Education WeDo 2.0 Core Set
                      </a>
                      <div class="info">
                        <div class="author item">
                          <a href="#">By Stem Square</a>
                        </div>
                        <div class="date-time item">
                          <a href="#">06/07/2019</a>
                        </div>
                      </div>
                      <div class="info-more">
                        <div class="view item">
                          <i class="fa fa-user"></i>
                          <p> 120</p>
                        </div>
                        <div class="comment item">
                          <i class="fa fa-comment"></i>

                          <p> 239</p>
                        </div>
                      </div>
                      <div class="description">45300 Bộ Kỹ Sư Robot và WeDo Milo</div>
                      <div class="edugate-content">
                        <a class="title">Giá: 8,700,000 VNĐ</a>
                      </div>
                      <button onclick="window.location.href='#'" class="btn btn-green">
                        <span>{i18n.t('public.viewDetail')}</span>
                      </button>
                    </div>
                  </div>

                  <div class="edugate-layout-1">
                    <div class="edugate-image">
                      <img src={Ev3} alt="" class="img-responsive" />
                    </div>
                    <div class="edugate-content">
                      <a href="#" class="title">
                        LEGO Mindstorm EV3 Core Set
                      </a>

                      <div class="info">
                        <div class="author item">
                          <a href="#">By Stem Square</a>
                        </div>
                        <div class="date-time item">
                          <a href="#">06/07/2019</a>
                        </div>
                      </div>
                      <div class="info-more">
                        <div class="view item">
                          <i class="fa fa-user"></i>

                          <p> 56</p>
                        </div>
                        <div class="comment item">
                          <i class="fa fa-comment"></i>

                          <p> 239</p>
                        </div>
                      </div>
                      <div class="description">
                        <p>45544 Bộ Kỹ Sư Robot EV3 cơ bản</p>
                      </div>
                      <div class="edugate-content">
                        <a class="title">Giá: 20,600,000 VNĐ</a>
                      </div>
                      <button onclick="window.location.href='#'" class="btn btn-green">
                        <span>{i18n.t('public.viewDetail')}</span>
                      </button>
                      <br />
                    </div>
                  </div>

                  <button class="btn btn-green btn-latest-new">
                    <span>
                      {i18n.t('public.showAll')}
                      <i class="fa fa-long-arrow-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div class="section section-padding courses-detail" style={{ display: 'none' }}>
              <div class="container">
                <div class="courses-detail-wrapper">
                  <div class="row">
                    <div class="col-md-9 layout-left">
                      <h1 class="course-title">SÂN CHƠI THI ĐẤU STEM ROBOTICS</h1>

                      <div class="course-info info">
                        <div class="author item">
                          <a href="#">
                            <span>By&nbsp;</span>
                            <span>Tuấn Trương</span>
                          </a>
                        </div>
                        <div class="date-time item">
                          <a href="#">20 Aug 2019</a>
                        </div>
                      </div>
                      <div class="course-video">
                        <iframe
                          src="https://www.youtube.com/embed/xo1VInw-SKc?list=PL7JgdmQ0zTfatnGhUnRBnhMwYEraSIRdD"
                          allowfullscreen="allowfullscreen"
                        ></iframe>
                        <div class="video-btn">
                          <button class="btn btn-grey video-btn-right pull-right">
                            <span>next lesson</span>
                            <i class="fa fa-angle-right"></i>
                          </button>
                          <button class="btn btn-grey video-btn-left pull-right">
                            <i class="fa fa-angle-left"></i>
                            <span>previous lesson</span>
                          </button>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <div class="course-des">
                        <div class="course-des-title underline">Lợi ích cho việc học STEM</div>
                        <div class="course-des-content">
                          <p>Nâng cao khả năng khoa học</p>
                          <p>Nâng cao kỹ năng công nghệ</p>
                          <p>Nâng cao kỹ năng kỹ thuật</p>
                          <p>Nâng cao kỹ năng toán học</p>
                          <p>Trang bị đầy đủ các kỹ năng của thế kỷ 21</p>

                          <div class="course-des-title underline">Giáo dục STEM là gì?</div>
                          <p>
                            Là giáo dục làm sao người học có thể hình thành từ ý tưởng, kỹ năng giải quyết vấn đề với sự
                            kết hợp các kiến thức học được thông qua những sự việc, vấn đề cụ thể. Đây không chỉ là một
                            cách dạy cụ thể cho một môn học nào cả như Robot hay Toán học, mà là mục tiêu đào tạo để
                            người học có đầy đủ kỹ năng và phát triển trong thế giới công nghệ và xu hướng thời đại công
                            nghiệp 4.0 hiện nay. Giáo dục STEM tạo ra những con người có thể đáp ứng được nhu cầu công
                            việc của thế kỷ 21, đáp ứng sự phát triển kinh tế, xã hội của quốc gia và có thể tác động
                            tích cực đến sự thay đổi của nền kinh tế tri thức trong bối cảnh toàn cầu hóa.
                          </p>
                          <p>
                            Những điểm mạnh của giáo dục STEM:
                            <p>Thứ nhất:</p>
                            Giáo dục STEM là phương thức giáo dục tích hợp theo cách tiếp cận liên môn
                            (interdisciplinary) và thông qua mô hình thực hành - ứng dụng. Thay vì dạy bốn môn học như
                            các đối tượng tách biệt và rời rạc, STEM kết hợp chúng thành một mô hình học tập gắn kết dựa
                            trên các ứng dụng thực tế. Qua đó, học sinh vừa học được kiến thức khoa học, vừa học được
                            cách vận dụng kiến thức đó vào thực tiễn.
                            <p>Thứ hai:</p>
                            Giáo dục STEM đề cao đến việc hình thành và phát triển năng lực giải quyết vấn đề cho người
                            học. Trong mỗi bài học theo chủ đề STEM, học sinh được đặt trước một tình huống có vấn đề
                            thực tiễn cần giải quyết liên quan đến các kiến thức khoa học.
                            <p>Thứ ba:</p>
                            Giáo dục STEM đề cao một phong cách học tập mới cho người học, đó là phong cách học tập sáng
                            tạo. Đặt người học vào vai trò của một nhà phát minh, người học sẽ phải hiểu thực chất của
                            các kiến thức được trang bị; phải biết cách mở rộng kiến thức; phải biết cách sửa chữa, chế
                            biến lại chúng cho phù hợp với tình huống có vấn đề mà người học đang phải giải quyết. .
                          </p>
                          <blockquote>
                            <div class="main-quote">Ta không nên dạy trẻ nhỏ khoa học; mà hãy để trẻ nếm trải nó!</div>
                            <div class="sub-quote">
                              STEM, giáo dục STEM, là một hướng mới đang phát triển trong giáo dục thế giới, đặc biệt ở
                              Hoa Kỳ. Việt Nam cũng đang phát triển sự quan tâm đến giáo dục này..
                            </div>
                            <footer>
                              Trương Quốc Tuấn&nbsp;
                              <small>(CEO - STEM SQUARE)</small>
                            </footer>
                          </blockquote>
                        </div>
                        <RelatedNews />
                      </div>
                      <div class="course-syllabus">
                        <div class="course-syllabus-title underline">Thời khóa biểu khai giảng hè - 2019</div>
                        <div class="course-table">
                          <div class="outer-container">
                            <div class="inner-container">
                              <div class="table-header">
                                <table class="edu-table-responsive">
                                  <thead>
                                    <tr class="heading-table">
                                      <th class="col-1">Khóa học</th>
                                      <th class="col-2">Ngày khai giảng</th>
                                      <th class="col-3">Thời gian</th>
                                      <th class="col-4">Tình trạng</th>
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                              <div class="table-body">
                                <table class="edu-table-responsive table-hover">
                                  <tbody>
                                    <tr class="heading-content">
                                      <td colspan="4" class="left heading-content">
                                        1. STEM Robotics Explorer (Thiết bị giảng dạy WeDo 2.0)
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp WeDo Dài hạn</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="green-color col-4">
                                        <i class="w27 fa fa-check-square"></i>
                                        <span>Đang học</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp WeDo thi đấu FLL</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="bold-color col-4">
                                        <i class="w27 fa fa-pencil-square-o"></i>
                                        <span>Còn đăng ký</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Lớp WeDo khóa học hè</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="col-4">
                                        <div class="bg-yellow">Học thử</div>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Lớp WeDo sáng tạo</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/07/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="bolder-color col-4">
                                        <i class="w27 fa fa-lock"></i>
                                        <span>Chuẩn bị khai giảng</span>
                                      </td>
                                    </tr>
                                    <tr class="spacing-table">
                                      <td colspan="4"></td>
                                    </tr>

                                    <tr class="heading-content">
                                      <td colspan="4" class="left heading-content">
                                        2. STEM Robotics Creator (Thiết bị giảng dạy EV3)
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp EV3 Dài hạn</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="green-color col-4">
                                        <i class="w27 fa fa-check-square"></i>
                                        <span>Đang học</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp EV3 thi đấu FLL</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="bold-color col-4">
                                        <i class="w27 fa fa-pencil-square-o"></i>
                                        <span>Còn đăng ký</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Lớp EV3 khóa học hè</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="col-4">
                                        <div class="bg-yellow">Học thử</div>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Lớp EV3 sáng tạo</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/07/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="bolder-color col-4">
                                        <i class="w27 fa fa-lock"></i>
                                        <span>Chuẩn bị khai giảng</span>
                                      </td>
                                    </tr>

                                    <tr class="spacing-table">
                                      <td colspan="4"></td>
                                    </tr>

                                    <tr class="heading-content">
                                      <td colspan="4" class="left heading-content">
                                        3. Scratch (Lập trình nhí)
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp Scratch Dài hạn</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="green-color col-4">
                                        <i class="w27 fa fa-check-square"></i>
                                        <span>Đang học</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp Scratch thi đấu FLL</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="bold-color col-4">
                                        <i class="w27 fa fa-pencil-square-o"></i>
                                        <span>Còn đăng ký</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Lớp Scratch khóa học hè</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="col-4">
                                        <div class="bg-yellow">Học thử</div>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Lớp Scratch sáng tạo</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/07/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="bolder-color col-4">
                                        <i class="w27 fa fa-lock"></i>
                                        <span>Chuẩn bị khai giảng</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 sidebar layout-right">
                      <div class="row">
                        <div class="author-widget widget col-sm-6 col-md-12 col-xs-6 sd380">
                          <div class="title-widget">GV MAI VĂN TÙNG</div>
                          <div class="content-widget">
                            <div class="staff-item author-widget-wrapper customize">
                              <div class="staff-item-wrapper">
                                <div class="staff-info">
                                  <a href="#" class="staff-avatar">
                                    <img
                                      src="assets/images/Teacher 2 - Mai Van Tung.jpg"
                                      alt=""
                                      class="img-responsive"
                                    />
                                  </a>
                                  <a href="#" class="staff-name">
                                    MAI VĂN TÙNG
                                  </a>

                                  <div class="staff-job">head teacher</div>
                                  <div class="staff-desctiption">
                                    Đã có 15 năm giảng dạy STEM Robotics, hiện nay là trưởng nhóm giáo viên tại STEM
                                    SQUARE.
                                  </div>
                                </div>
                              </div>
                              <div class="staff-socials">
                                <a href="#" class="facebook">
                                  <i class="fa fa-facebook"></i>
                                </a>
                                <a href="#" class="google">
                                  <i class="fa fa-google-plus"></i>
                                </a>
                                <a href="#" class="twitter">
                                  <i class="fa fa-twitter"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="popular-course-widget widget col-sm-6 col-md-12 col-xs-6 sd380">
                          <div class="title-widget">Khóa học STEM Robotics</div>
                          <div class="content-widget">
                            <div class="media">
                              <div class="media-left">
                                <a href="courses-detail.html" class="link">
                                  <img src="assets/images/popular/popular-1.jpg" alt="" class="media-image" />
                                </a>
                              </div>
                              <div class="media-right">
                                <a href="courses-detail.html" class="link title">
                                  STEM Robotics Explorer 1-2
                                </a>
                                <div class="info">
                                  <div class="author item">
                                    <a href="#">
                                      <span>Thầy Đức</span>
                                    </a>
                                  </div>
                                </div>
                                <div>Học phí: 800 ngàn/ tháng</div>
                              </div>
                            </div>
                            <div class="media">
                              <div class="media-left">
                                <a href="courses-detail.html" class="link">
                                  <img src="assets/images/popular/popular-2.jpg" alt="" class="media-image" />
                                </a>
                              </div>
                              <div class="media-right">
                                <a href="courses-detail.html" class="link title">
                                  STEM Robotics Creator 3,4,5
                                </a>

                                <div class="info">
                                  <div class="author item">
                                    <a href="#">
                                      <span>Thầy Tùng, Thầy Đức</span>
                                    </a>
                                  </div>
                                </div>
                                <div>Học phí: 1 triệu/ tháng</div>
                              </div>
                            </div>
                            <div class="media">
                              <div class="media-left">
                                <a href="courses-detail.html" class="link">
                                  <img src="assets/images/popular/popular-3.jpg" alt="" class="media-image" />
                                </a>
                              </div>
                              <div class="media-right">
                                <a href="courses-detail.html" class="link title">
                                  STEM Robotics luyện thi
                                </a>

                                <div class="info">
                                  <div class="author item">
                                    <a href="#">
                                      <span>Thầy Tùng, Thầy Đức</span>
                                    </a>
                                  </div>
                                </div>
                                <div>Học phí: 1 triệu/ tháng</div>
                              </div>
                            </div>
                            <div class="media">
                              <div class="media-left">
                                <a href="courses-detail.html" class="link">
                                  <img src="assets/images/popular/popular-4.jpg" alt="" class="media-image" />
                                </a>
                              </div>
                              <div class="media-right">
                                <a href="courses-detail.html" class="link title">
                                  Lớp Cratch
                                </a>

                                <div class="info">
                                  <div class="author item">
                                    <a href="#">
                                      <span>Thầy Tùng</span>
                                    </a>
                                  </div>
                                </div>
                                <div>Học phí: 300 ngàn/ tháng</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="category-widget widget col-sm-6 col-md-12 col-xs-6 sd380">
                          <div class="title-widget">Các khóa học</div>
                          <div class="content-widget">
                            <ul class="category-widget list-unstyled">
                              <li>
                                <a href="categories.html" class="link cat-item">
                                  <span class="pull-left">STEM Robotics Explorer 1-2</span>
                                  <span class="pull-right">125</span>
                                </a>
                              </li>
                              <li>
                                <a href="categories.html" class="link cat-item">
                                  <span class="pull-left">STEM Robotics Creator 3,4,5</span>
                                  <span class="pull-right">97</span>
                                </a>
                              </li>
                              <li>
                                <a href="categories.html" class="link cat-item">
                                  <span class="pull-left">Scratch</span>
                                  <span class="pull-right">56</span>
                                </a>
                              </li>
                              <li>
                                <a href="categories.html" class="link cat-item">
                                  <span class="pull-left">Tin học không chuyên</span>
                                  <span class="pull-right">24</span>
                                </a>
                              </li>
                              <li>
                                <a href="categories.html" class="link cat-item">
                                  <span class="pull-left">STEM Robotics Thi đấu</span>
                                  <span class="pull-right">13</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="back-top">
        <a href="#top">
          <i class="fa fa-angle-double-up"></i>
        </a>
      </div>
    </div>
  );
};

export default Lego;
