export const ROUTER = {
  // home
  HOME: {
    path: '/',
    INTRODUCE: {
      path: '/introduce',
    },
    PHILOSOPHY: {
      path: '/philosophy',
    },
    TEAM: {
      path: '/team',
    },
    CERTIFICATION: {
      path: '/tertifications',
    },
    DETAILPROFILE: {
      path: '/view_detail_profile',
    },
  },

  //
  COURSES: {
    path: null,
    OPENCLASS: {
      path: '/open_class',
    },
    COURSE: {
      path: '/course',
    },
    COURSE_DETAIL: {
      path: '/course/view',
    },
  },

  //
  COMPETITIONS: {
    path: null,
    ROBOTICS: {
      path: '/robotkids',
    },
    ROBOTACON: {
      path: '/robotacon',
    },
    WRO: {
      path: '/competition/wro',
    },
    WROINTRODUCTION: {
      path: '/competition/wro/introduction/:year',
    },
    WROFRIENDLYROUND: {
      path: '/competition/wro/friendly-round/:year',
    },
    WROQUALIFYINGROUND: {
      path: '/competition/wro/qualifying-round/:year',
    },
    WROREGIONALROUND: {
      path: '/competition/wro/regional-round/:year',
    },
    WROFINALROUND: {
      path: '/competition/wro/final-round/:year',
    },
    FLL: {
      path: '/competition/fll',
    },
    FLLINTRODUCTION2019: {
      path: '/competition/fll/introduction/2019',
    },
    FLLINTRODUCTION: {
      path: '/competition/fll/introduction',
    },
    FLLFRIENDLYROUND: {
      path: '/competition/fll/friendly-round',
    },
    FLLQUALIFYINGROUND: {
      path: '/competition/fll/qualifying-round',
    },
    FLLREGIONALROUND: {
      path: '/competition/fll/regional-round',
    },
    FLLFINALROUND: {
      path: '/competition/fll/final-round',
    },
  },

  //
  PRODUCT: {
    path: '/product',
    LEGO: {
      path: '/lego',
    },
    NEGENDO: {
      path: '/negendo',
    },
    GARASTEM: {
      path: '/gara_stem',
    },
    INNOVATIONSTEM: {
      path: '/innovation_stem',
    },
  },

  //
  LIBRARY: {
    path: null,
    STEM_BOOK: {
      path: '/stem_book',
    },
    STEM_VIDEOS: {
      path: 'https://www.youtube.com/channel/UCzmueccz-WuanzMaJ1ds1Tg/videos',
    },
    LEARNING_TRACKING: {
      path: '/learning_tracking',
    },
  },

  //
  PUBLIC: {
    path: null,
    FAQ: {
      path: '/faq',
    },
    NEWS: {
      path: '/news',
    },
    ACTIVITIES: {
      path: '/ativities',
    },
    FACEBOOK: {
      path: '/facebook',
    },
    IMAGEEVENT: {
      path: '/image_event',
    },
    SHOW_NEWS_DETAIL: {
      path: '/newsdetail',
    },
  },

  //
  CONTACT: {
    path: '/contact',
  },

  //
  CHOOSE_COURSES: {
    path: null,
    WHAT_IS_STEM: {
      path: '/what_is_stem',
    },
    BENEFIT_STEM: {
      path: '/benefit_stem',
    },
    COMPETION: {
      path: '/competion',
    },
    EDUCATION_MODEL: {
      path: '/education_modal',
    },
    DISTRIBUTE: {
      path: '/distribute',
    },
  },

  //
  REGISTER: {
    path: '/dangky',
  },
};
