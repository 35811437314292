import React, { useRef } from 'react';
import { people_avatar_1, p1, s1 } from '../../../assets/images';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

const SliderTaklAboutUs = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="section background-opacity slider-talk-about-us">
      <div className="container">
        <div id="people-talk" style={{ padding: '90px 0' }}>
          <div role="listbox" className="slider-talk-about-us-content carousel-inner">
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Navigation]}
              grabCursor={true}
            >
              <SwiperSlide>
                <div className="peopel-item item">
                  <p className="peopel-comment">
                    "Tôi rất vui khi thấy con tôi đã vượt qua được chính mình, và tự lập trong các bài thi trong nước
                    cũng như Quốc tế. Tại câu lạc bộ Robotics của STEM SQUARE, chúng tôi thấy các Thầy rất nhiệt tâm
                    giảng dạy, đã đưa con chúng tôi đến với môn học đầy thú vị."
                  </p>

                  <div className="group-peole-info">
                    <div className="peopel-avatar">
                      <img src={p1} alt="" className="img-responsive" />
                    </div>
                    <div className="peopel-name">Chị Nguyễn Thị Thanh Huyền</div>
                    <div className="people-job">Phụ huynh của bạn Trương Văn Nghĩa</div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="peopel-item item ">
                  <p className="peopel-comment">
                    "Chỉ 5 tháng trước đó hãy còn là một cậu bé chập chững với LEGO và không biết gì về lập trình. Nhưng
                    từ vòng quốc gia cho đến vòng quốc tế, con tôi đã vượt qua được từng chặng đường, nay đã có thể
                    chinh phục được đấu trường Quốc tế, đồng thời có thể tham gia sân chơi của toàn cầu. Tôi cảm thấy
                    hạnh phúc."
                  </p>

                  <div className="group-peole-info">
                    <div className="peopel-avatar">
                      <img src={people_avatar_1} alt="" className="img-responsive" />
                    </div>
                    <div className="peopel-name">Trang Thị Hồng Nhung</div>
                    <div className="people-job">Phụ huynh của bé Nguyễn Hồng Phúc</div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="peopel-item item ">
                  <p className="peopel-comment">
                    "Với kiến thức sâu rộng từ STEM, tôi cảm nhận đây là một môn học vô cùng hữu ích và thú vị cho các
                    bạn trẻ, đặc biệt vời con tôi lúc nào cũng hào hứng để học môn này."
                  </p>

                  <div className="group-peole-info">
                    <div className="peopel-avatar">
                      <img src={s1} alt="" className="img-responsive" />
                    </div>
                    <div className="peopel-name">Mai Văn Đoãn</div>
                    <div className="people-job">Phụ huynh Mai Văn Túy</div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div className="group-btn-slider">
        <a href="#people-talk" role="button" data-slide="prev">
          <div className="btn-prev carousel-control left" ref={prevRef}>
            <i className="fa fa-angle-left"></i>
          </div>
        </a>
        <a href="#people-talk" role="button" data-slide="next">
          <div className="btn-next carousel-control right" ref={nextRef}>
            <i className="fa fa-angle-right"></i>
          </div>
        </a>
      </div>
    </div>
  );
};

export default SliderTaklAboutUs;
