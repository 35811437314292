import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useEmployee } from '../../hooks/employee';
import { listEmployeeFEState } from '../../recoil/atom/employeeState';
import Breadcrumb from '../Global/Breadcrumb';

const DetailProfile = () => {
  useEmployee();
  const { id } = useParams();
  const listEmployee = useRecoilValue(listEmployeeFEState);
  const [teacher, setTecher] = useState(undefined);

  useEffect(() => {
    if (listEmployee?.length) {
      setTecher(listEmployee?.filter((item) => item?.id === Number(id))[0]);
    }
  }, [id, listEmployee]);

  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div className="main-content">
          <div className="content">
            <Breadcrumb address="Giới thiệu về tiểu sử" parent="Profile" />

            <div class="section section-padding profile-teacher">
              <div class="container">
                <div class="profile-teacher-wrapper">
                  <div class="teacher-info">
                    <div class="staff-item2 customize">
                      <div class="staff-item-wrapper">
                        <div class="staff-info">
                          <a href="#" class="staff-avatar">
                            <img src={teacher?.User?.AVATAR} alt="" class="img-responsive" />
                          </a>
                          {/* <a href="#" class="staff-name">
                            barry join
                          </a> */}
                        </div>
                      </div>
                      <div class="staff-socials">
                        <a href={teacher?.FACEBOOK_LINK} class="facebook" target="_blank">
                          <i class="fa fa-facebook"></i>
                        </a>
                        {/* <a href="#" class="google">
                          <i class="fa fa-google-plus"></i>
                        </a>
                        <a href="#" class="twitter">
                          <i class="fa fa-twitter"></i>
                        </a> */}
                      </div>
                    </div>
                    <div class="teacher-des">
                      <div class="title">{teacher?.User?.FIRST_NAME + ' ' + teacher?.User?.LAST_NAME}</div>
                      <div class="subtitle">{teacher?.Position?.POSITION_NAME}</div>
                      <div class="content">
                        {teacher?.JOB_SHORT_DESC}
                        {/* <ul class="detail-list">
                          <li>Fusce iaculis ornare nunc rutrum ornare.</li>
                          <li>Proin ut placerat enim, vel venenatis urna.</li>
                          <li>Phasellus sed diam tincidunt mauris malesuada mattis et in nisl.</li>
                          <li>Quisque massa eros, molestie at mi eget, aliquam tristique eros.</li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                  <div class="certificate-title underline">Thông tin chi tiết</div>
                  <div>{teacher?.JOB_FULL_DESC}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailProfile;
