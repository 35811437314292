import React from 'react';

const SearchWrapper = () => {
  return (
    <div class="search-input">
      <div class="container">
        <div class="search-input-wrapper">
          <form>
            <select class="form-select style-1 selectbox">
              <option value="all">all categories</option>
              <option value="languages">languages</option>
              <option value="science">science</option>
            </select>
            <select class="form-select style-2 selectbox">
              <option value="price">price</option>
              <option value="datetime">datetime</option>
              <option value="teacher">teacher</option>
            </select>
            <input type="text" placeholder="Do you want to learn today?" class="form-input" />
            <button type="submit" class="form-submit btn btn-blue">
              <span>
                search now<i class="fa fa-search"></i>
              </span>
            </button>
            <div class="clearfix"></div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchWrapper;
