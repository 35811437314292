import React from 'react';
import { Link } from 'react-router-dom';
import { girl_1 } from '../../../assets/images';
import { ROUTER } from '../../../constant/router';
import i18n from '../../../lib/Language';

const WhyChooseUs = () => {
  return (
    <div className="section why-choose-us">
      <div className="why-choose-us-wrapper-top">
        <div className="container">
          <div className="why-choose-us-wrapper">
            <h2 className="title-2">{i18n.t('homepage.whyChooseUs')}</h2>

            <p>{i18n.t('homepage.chooseStem.decsChooseUs')}</p>
            <Link to={ROUTER.HOME.INTRODUCE.path}>
              <button className="btn btn-green-2">
                <span>{i18n.t('general.button.seeMore')}</span>
              </button>
            </Link>
          </div>
          <div data-wow-delay="0.2s" data-wow-duration="1.2s" className="background-girl-1 wow fadeInUp">
            <img src={girl_1} alt="" className="img-responsive" />
          </div>
        </div>
      </div>
      <div className="why-choose-us-wrapper-bottom background-opacity">
        <div className="container">
          <div data-wow-delay="0.4s" data-wow-duration="1s" className="row why-choose-us-wrapper wow zoomIn">
            <div className="customs-row">
              <div className="col-sm-4 col-xs-6 section-icon">
                <i className="fa fa-pencil-square-o"></i>

                <p>{i18n.t('homepage.chooseStem.first')}</p>
              </div>
              <div className="col-sm-4 col-xs-6 section-icon">
                <i className="fa fa-compress"></i>

                <p>{i18n.t('homepage.chooseStem.third')}</p>
              </div>
            </div>
            <div className="customs-row">
              <div className="col-sm-4 col-xs-6 section-icon">
                <i className="fa fa-paper-plane"></i>

                <p>{i18n.t('homepage.chooseStem.fifth')}</p>
              </div>
              <div className="col-sm-4 col-xs-6 section-icon">
                <i className="fa fa-folder-open"></i>

                <p>{i18n.t('homepage.chooseStem.second')}</p>
              </div>
            </div>
            <div className="customs-row">
              <div className="col-sm-4 col-xs-6 section-icon">
                <i className="fa fa-external-link"></i>

                <p>{i18n.t('homepage.chooseStem.fourth')}</p>
              </div>
              <div className="col-sm-4 col-xs-6 section-icon">
                <i className="fa fa-stumbleupon-circle"></i>

                <p>{i18n.t('homepage.chooseStem.sixth')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
