import React from 'react';
import { useRecoilValue } from 'recoil';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import { useEmployee } from '../../../hooks/employee';
import { listEmployeeFEState } from '../../../recoil/atom/employeeState';

const Teacher = () => {
  useEmployee();
  const listEmployee = useRecoilValue(listEmployeeFEState);

  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, EffectFade]}
      pagination={{
        clickable: true,
      }}
      effect={'fade'}
      grabCursor={true}
      style={{ marginBottom: 30 }}
    >
      {listEmployee && listEmployee?.length
        ? listEmployee.slice(0, 9).map((item, index) => (
            <SwiperSlide key={index}>
              <div class="author-widget widget col-sm-6 col-md-12 col-xs-12 sd380">
                <div className="title-widget" style={{ fontSize: 14 }}>
                  GV {item.User?.FIRST_NAME + ' ' + item.User?.LAST_NAME}
                </div>
                <div className="content-widget">
                  <div className="staff-item author-widget-wrapper customize" style={{ height: 400 }}>
                    <div className="staff-item-wrapper">
                      <div className="staff-info">
                        <a href="#" className="staff-avatar">
                          <img src={item.User?.AVATAR} alt="" className="img-responsive" />
                        </a>
                        <a href="#" className="staff-name">
                          {item.User?.FIRST_NAME + ' ' + item.User?.LAST_NAME}
                        </a>

                        {/* <div className="staff-job">head teacher</div> */}
                        <div className="staff-desctiption">{item?.JOB_FULL_DESC}</div>
                      </div>
                    </div>
                    <div className="staff-socials" style={{ marginTop: 10 }}>
                      <a href="#" className="facebook">
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a href="#" className="google">
                        <i className="fa fa-google-plus"></i>
                      </a>
                      <a href="#" className="twitter">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        : null}
    </Swiper>
  );
};

export default Teacher;
