import axiosApiInstance from "../utils/axiosClient";

const studentHistoryApi = {
    getAllByQuery: (data) => {
        const path = `/studenthistory/getAllByQuery`;
        return axiosApiInstance.post(path, data);
    },
    getStudentHistory: (id) => {
        const path = `/studenthistory​/getStudentHistory​/${id}`;
        return axiosApiInstance.get(path);
    },
    createStudentHistory: (data) => {
        const path = `/studenthistory/createStudentHistory`;
        return axiosApiInstance.post(path, data);
    },
    deleteStudentHistory: (id) => {
        const path = `/studenthistory/deleteStudentHistory/${id}`;
        return axiosApiInstance.delete(path);
    }
};

export default studentHistoryApi;
