import React from 'react';
import i18n from '../../../lib/Language';
import LanguageSelect from './LanguageSelect';

const Setting = () => {
  return (
    <div className="header-topbar" style={{ padding: '4px 0' }}>
      <div className="container">
        <div className="topbar-left pull-left">
          <div className="email">
            <a href="#">
              <i className="topbar-icon fa fa-envelope-o"></i>
              <span>stemsquare.vn@gmail.com</span>
            </a>
          </div>
          <div className="hotline">
            <a href="#">
              <i className="topbar-icon fa fa-phone"></i>
              <span>097-1234-042</span>
            </a>
          </div>
        </div>
        <div className="topbar-right pull-right">
          <div className="socials">
            <a
              href="https://www.facebook.com/STEM-Square-Education-479581309227178/"
              target="_blank"
              className="facebook"
              style={{ paddingLeft: 5 }}
            >
              <i className="fa fa-facebook"></i>
            </a>
            {/* <a href="#" style={{ display: 'none' }} className="google">
              <i className="fa fa-google-plus"></i>
            </a>
            <a href="#" style={{ display: 'none' }} className="twitter">
              <i className="fa fa-twitter"></i>
            </a>
            <a href="#" style={{ display: 'none' }} className="pinterest">
              <i className="fa fa-pinterest"></i>
            </a>
            <a href="#" style={{ display: 'none' }} className="blog">
              <i className="fa fa-rss"></i>
            </a>
            <a href="#" style={{ display: 'none' }} className="dribbble">
              <i className="fa fa-dribbble"></i>
            </a> */}
            <LanguageSelect />
          </div>
          <span style={{ marginRight: 10 }} className="language-mobile">
            <LanguageSelect />
          </span>
          <div className="group-sign-in">
            <a href="http://edu.novasquare.vn:3002/" className="login">
              {i18n.t('general.login')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
