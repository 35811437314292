import { Empty, List, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const ListSimilar = ({ data }) => {
  return data?.length === 0 ? (
    <Empty style={{ width: '100%', padding: 20 }} description={<Text strong>Không có khóa học liên quan nào!</Text>} />
  ) : (
    <List
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <div className="media">
            <div className="media-left">
              <Link to={`/course/view/${item?.COURSE_ID}`} className="link">
                <img src={item?.IMAGE} alt="" className="media-image" />
              </Link>
            </div>
            <div className="media-right">
              <Link to={`/course/view/${item?.COURSE_ID}`} className="link title">
                {item?.COURSE_NAME}
                &nbsp; &#40;
                {item?.NAME}
                &#41;
              </Link>
              <div className="info" style={{ display: 'flex' }}>
                <div className="item">
                  <i className="fa fa-user"></i>
                  &nbsp;
                  <span>{item?.STUDENT_LEARNING_COUNT}</span>
                </div>
                <div className="item">
                  <i className="fa fa-eye"></i>
                  &nbsp;
                  <span>{item?.SUBJECT_VIEW}</span>
                </div>
              </div>
            </div>
          </div>
        </List.Item>
      )}
    />
  );
};

export default ListSimilar;
