import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import customerStatusApi from "../api/customerStatusApi";
import { customerStatusState } from "../recoil/atom/customerState";

export const useCustomerStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [customerStatuss, setCustomerStatuss] = useRecoilState(customerStatusState);

  const getAllCustomerStatuss = async () => {
    setIsLoading(true);
    try {
      let res = await customerStatusApi.getAllCustomerStatuss();
      if (res.data) {
        setCustomerStatuss(res.data.elements);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    customerStatuss,
    getAllCustomerStatuss,
    isLoading,
  };
};
