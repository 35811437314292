import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTER } from '../../../constant/router';
import i18n from '../../../lib/Language';

const ChooseCourse = () => {
  return (
    <div className="section section-padding choose-course">
      <div className="container">
        <div className="group-title-index">
          <h4 className="top-title">STEM SQUARE EDUCATION</h4>

          <h2 className="center-title">{i18n.t('public.introduce')}</h2>

          <div className="bottom-title">
            <i className="bottom-icon icon-a-1-01-01"></i>
          </div>
        </div>
        <div className="choose-course-wrapper row">
          <div className="col-md-4 col-xs-6">
            <div className="item-course item-1">
              <div className="icon-circle">
                <div className="icon-background">
                  <i className="icons-img icon-globe"></i>
                </div>
                <div className="info">
                  <div className="info-back">
                    <Link to={ROUTER.CHOOSE_COURSES.WHAT_IS_STEM.path}>
                      {i18n.t('homepage.introduce.knowledgeStem')}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="name-course">
                <Link to={ROUTER.CHOOSE_COURSES.WHAT_IS_STEM.path}>{i18n.t('homepage.introduce.stem?')}</Link>
                <i className="fa fa-long-arrow-right"></i>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-xs-6">
            <div className="item-course item-2">
              <div className="icon-circle">
                <div className="icon-background">
                  <i className="icons-img fa fa-database"></i>
                </div>
                <div className="info">
                  <div className="info-back">
                    <Link to={ROUTER.CHOOSE_COURSES.BENEFIT_STEM.path}>
                      {i18n.t('homepage.introduce.benefitStems')}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="name-course">
                <Link to={ROUTER.CHOOSE_COURSES.BENEFIT_STEM.path}>{i18n.t('homepage.introduce.benefit?')}</Link>
                <i className="fa fa-long-arrow-right"></i>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xs-6">
            <div className="item-course item-4">
              <div className="icon-circle">
                <div className="icon-background">
                  <i className="icons-img icon-photocamera_1"></i>
                </div>
                <div className="info">
                  <div className="info-back">
                    <Link to={ROUTER.COURSES.COURSE.path}>{i18n.t('homepage.introduce.support24/7')}</Link>
                  </div>
                </div>
              </div>
              <div className="name-course">
                <Link to={ROUTER.COURSES.COURSE.path}>{i18n.t('homepage.introduce.program')}</Link>
                <i className="fa fa-long-arrow-right"></i>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xs-6">
            <div className="item-course item-3">
              <div className="icon-circle">
                <div className="icon-background">
                  <i className="icons-img icon-computer_network"></i>
                </div>
                <div className="info">
                  <div className="info-back">
                    <Link to={ROUTER.CHOOSE_COURSES.COMPETION.path}>{i18n.t('homepage.introduce.playOfActivity')}</Link>
                  </div>
                </div>
              </div>
              <div className="name-course">
                <Link to={ROUTER.CHOOSE_COURSES.COMPETION.path}>{i18n.t('homepage.introduce.playground')}</Link>
                <i className="fa fa-long-arrow-right"></i>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xs-6">
            <div className="item-course item-5">
              <div className="icon-circle">
                <div className="icon-background">
                  <i className="icons-img icon-credit_card"></i>
                </div>
                <div className="info">
                  <div className="info-back">
                    <Link to={ROUTER.CHOOSE_COURSES.EDUCATION_MODEL.path}>
                      {i18n.t('homepage.introduce.methodOfUs')}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="name-course">
                <Link to={ROUTER.CHOOSE_COURSES.EDUCATION_MODEL.path}>{i18n.t('homepage.introduce.methodEdu')}</Link>
                <i className="fa fa-long-arrow-right"></i>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-xs-6">
            <div className="item-course item-6">
              <div className="icon-circle">
                <div className="icon-background">
                  <i className="icons-img icon-chat_bubbles"></i>
                </div>
                <div className="info">
                  <div className="info-back">
                    <Link to={ROUTER.CHOOSE_COURSES.DISTRIBUTE.path}>{i18n.t('homepage.introduce.buyDevice')}</Link>
                  </div>
                </div>
              </div>
              <div className="name-course">
                <Link to={ROUTER.CHOOSE_COURSES.DISTRIBUTE.path}>{i18n.t('homepage.introduce.warehouseDevice')}</Link>
                <i className="fa fa-long-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseCourse;
