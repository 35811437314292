import { useState } from 'react';
import lessonManagementApi from '../api/lessonManagementApi';

export const useLesson = () => {
  const [lessonByQueryData, setLessonByQueryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // get all by query
  const getLessonByProgramSubjectName = async (data) => {
    setIsLoading(true);
    try {
      let res = await lessonManagementApi.getLessonByProgramSubjectName(data);
      if (res.data) {
        setLessonByQueryData(res.data.elements);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    lessonByQueryData,
    isLoading,
    getLessonByProgramSubjectName,
    useLesson,
  };
};
