import { useState } from 'react';
import { useRecoilState } from 'recoil';
import programApi from '../api/programApi';
import { programState } from '../recoil/atom/programState';

export const useProgram = () => {
  const [program, setProgram] = useState();
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [programs, setPrograms] = useRecoilState(programState);


  const getAllPrograms = async () => {
    setIsLoading(true);
    try {
      let res = await programApi.getAllPrograms();
      if (res.data) {
        setPrograms(res.data.elements);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // enqueueSnackbar("Request failed !", { variant: "error" });
    }
  };

  const getProgram = async (id, callback) => {
    setIsLoading(true);
    try {
      let res = await programApi.getProgram(id);
      if (res.data) {
        setProgram(res.data.elements.program);
        // callback();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // enqueueSnackbar("Request failed !", { variant: "error" });
    }
  };
  const getCountSubjectByProgramId = async (id, callback) => {
    setIsLoading(true);
    try {
      let res = await programApi.getCountSubjectByProgramId(id);
      if (res.data) {
        setCount(res.data.elements.getCountSubject);
        callback();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const createProgram = async (data, callback) => {
    setIsLoading(true);
    try {
      let res = await programApi.createProgram(data);
      if (res.data) {
        getAllPrograms();
        setIsLoading(false);
        callback();
        setProgram(undefined);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const updateProgram = async (data, id, callback) => {
    setIsLoading(true);
    try {
      let res = await programApi.updateProgram(data, id);
      if (res.data) {
        getAllPrograms();
        setIsLoading(false);
        callback();
        setProgram(undefined);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteProgram = async (id) => {
    setIsLoading(true);
    try {
      let res = await programApi.deleteProgram(id);
      if (res.data) {
        getAllPrograms();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    programs,
    program,
    count,
    getAllPrograms,
    getProgram,
    getCountSubjectByProgramId,
    createProgram,
    updateProgram,
    deleteProgram,
    isLoading,
  };
};
