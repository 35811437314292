import React from 'react';

const BtnViewMore = ({ title }) => {
  return (
    <div style={{ marginTop: 20, marginBottom: 30 }}>
      <button class="btn btn-green" onclick="window.location.href='products-negendo.html'">
        <span>{title}</span>
      </button>
    </div>
  );
};

export default BtnViewMore;
