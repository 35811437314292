import { useState } from 'react';
import systemApi from '../api/systemApi';

export const useSystem = () => {
  const [userActionHistory, setUserActionHistory] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [success, setSuccess] = useState(undefined);

  const createUserActionHistory = async (data) => {
    setIsLoading(true);
    try {
      let res = await systemApi.createUserActionHistory(data);
      if (res.data) {
        setError(undefined);
        setIsLoading(false);
        setUserActionHistory(undefined);
      }
    } catch (error) {
      setSuccess(undefined);
      setIsLoading(false);
    }
  };

  return {
    userActionHistory,
    setUserActionHistory,
    createUserActionHistory,
    isLoading,
    error,
    success,
  };
};
