import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNews } from '../../../hooks/news';
import Breadcrumb from '../../Global/Breadcrumb';
// import Courses from './Courses';
import RecentNews from './RecentNews';
// import StempRobotics from './StempRobotics';
import parse from 'html-react-parser';
import { Spin } from 'antd';
import Relatednews from './Relatednews';
import moment from 'moment';
import { formatDate } from '../../../common';

const NewsDetail = () => {
  const { id } = useParams();
  const { isLoading, news, getNews } = useNews();

  // GET NEWS BY ID
  useEffect(() => {
    if (id) {
      getNews(id);
    }
  }, [id]);

  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div className="main-content">
          <div className="content">
            <Breadcrumb address="Tin tức chi tiết" parent="News" />
            {/*  */}

            <div className="section section-padding news-page">
              <div className="container">
                <div className="row">
                  <div className="col-md-9">
                    <div className="news-page-wrapper">
                      {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Spin />
                        </div>
                      ) : (
                        <>
                          <h1 className="course-title">{news?.TITLE}</h1>
                          <div className="course-info info">
                            <div className="author item" style={{ fontWeight: 700 }}>
                              <span>By&nbsp;</span>
                              <span>{news?.NEWS_BY}</span>
                            </div>
                            <div className="date-time item">
                              <a href="#">{moment(news?.CREATED_DATE).format(formatDate.Type)}</a>
                            </div>
                          </div>
                          {/* <div className="image" style={{ marginBottom: 30 }}>
                            <img className="img-responsive" src={news?.IMAGE} />
                          </div> */}
                          <div className="course-des">
                            <div className="course-des-content">
                              <p className="text-p">{news?.DESC}</p>
                              <p className="text-p" style={{ marginBottom: 20 }}>
                                {parse(String(news?.CONTENT))}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {/*  */}
                    <Relatednews />
                    {/*  */}
                  </div>
                  <div className="col-md-3 sidebar layout-right">
                    <div className="row">
                      <RecentNews />
                      {/* <StempRobotics /> */}
                      {/* <Courses /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
