import React from 'react';
import { img_1, img_2, img_3, img_4, img_5, robotacon_2018_medium } from '../../../assets/images';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import BtnViewMore from '../../../components/Global/Button/BtnViewMore';
import RelatedNews from '../../../components/Global/RelatedNews';
import Teacher from '../../../components/Global/Teacher';
import Courses from '../../../components/Public/News/Courses';
import StempRobotics from '../../../components/Public/News/StempRobotics';

const Robotacon = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Breadcrumb address="NGÀY HỘI ROBOTACON QUỐC GIA" />
            <div class="section" style={{ display: 'none' }}>
              <div class="search-input">
                <div class="container">
                  <div class="search-input-wrapper">
                    <form>
                      <select class="form-select style-1 selectbox">
                        <option value="all">all categories</option>
                        <option value="languages">languages</option>
                        <option value="science">science</option>
                      </select>
                      <select class="form-select style-2 selectbox">
                        <option value="price">price</option>
                        <option value="datetime">datetime</option>
                        <option value="teacher">teacher</option>
                      </select>
                      <input type="text" placeholder="Do you want to learn today?" class="form-input" />
                      <button type="submit" class="form-submit btn btn-blue">
                        <span>
                          search now<i class="fa fa-search"></i>
                        </span>
                      </button>
                      <div class="clearfix"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="section section-padding courses-detail">
              <div class="container">
                <div class="courses-detail-wrapper">
                  <div class="row">
                    <div class="col-md-9 layout-left">
                      <h1 class="course-title">Ngày hội ROBOTACON Quốc Gia - 2019</h1>

                      <div class="course-info info">
                        <div class="author item">
                          <a href="#">
                            <span>By&nbsp;</span>
                            <span>VTA/ Lego Education</span>
                          </a>
                        </div>
                        <div class="date-time item">
                          <a href="#">20/06/2019</a>
                        </div>
                      </div>

                      <div class="course-video">
                        <iframe
                          width="1246"
                          height="704"
                          src="https://www.youtube.com/embed/feOTsQrxUnk"
                          allowfullscreen="allowfullscreen"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <div class="video-btn">
                          <button class="btn btn-grey video-btn-right pull-right">
                            <span>next video</span>
                            <i class="fa fa-angle-right"></i>
                          </button>
                          <button class="btn btn-grey video-btn-left pull-right">
                            <i class="fa fa-angle-left"></i>
                            <span>previous video</span>
                          </button>
                        </div>
                        <div class="clearfix"></div>
                      </div>

                      <div class="course-des">
                        <div class="course-des-title underline">Giới thiệu ngày hội ROBOTACON Quốc Gia - 2019</div>
                        <div class="course-des-content">
                          <p>Chương trình được tổ chức hằng năm do LEGO EDUCATION Việt Nam tổ chức.</p>
                          <p>STEM SQUARE đơn vị giảng dạy và đối tác chính thức của VTA-LEGO EDUCATION</p>
                          <p>
                            Theo chương trình thi đấu Robotacon Quốc gia của mỗi năm, thì năm 2019 nay cuộc thi do LEGO
                            EDUCATION được tổ chức tại Hồ Chí Minh vào ngày 8-11/08/2019. Vì vậy chúng tôi xin trân
                            trọng gửi đến Quý phụ huynh thể lệ đăng ký thi đấu và chuẩn bị hành trình để các con có cơ
                            hội tham gia vào sân chơi lớn của LEGO EDUCATION toàn cầu tổ chức tại Hungary vào tháng
                            11/2019.
                          </p>
                        </div>
                      </div>

                      <div class="image">
                        <img
                          class="img-responsive"
                          src={robotacon_2018_medium}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                      </div>
                      <br></br>
                      <div class="course-des-title underline">Thể lệ thi đấu</div>
                      <div>
                        <p>
                          Chủ đề cuộc thi: <strong>Smart City – Thành phố thông minh</strong> là chủ đề của cuộc thi năm
                          nay, dành cho 3 cấp học:{' '}
                        </p>
                        <p> Cấp tiểu học (Elementary I);</p>
                        <p> Cấp trung học cơ sở (Junior I);</p>
                        <p> Cấp trung học phổ thông (Senior).</p>
                      </div>
                      <br></br>
                      <div class="course-des-title underline">Các bảng thi đấu: WRO thế giới:</div>
                      <div>
                        <p>1. Bảng Thông thường: B1-Cấp tiểu học, B2-cấp trung học cơ sở, B3-cấp trung học pt</p>
                        <p>
                          2. Bảng Mở - Open (cùng một chủ đề – cho 3 độ tuổi) – B4: Cấp tiểu học, cấp trung học cơ sở,
                          cấp trung học.
                        </p>
                        <p>3. Bảng Sumo (B5): Dành cho sinh viên</p>
                        <p>4. Bảng Bóng đá – Football (B6): Dành cho thí sinh từ 10 tuổi đến 20 tuổi.</p>
                        <p>
                          <strong> Tại Việt Nam năm nay, cuộc thi được tổ chức với tất cả các bảng nêu trên.</strong>
                        </p>
                      </div>
                      <br></br>
                      <div class="course-des-title underline">Chi tiết từng bảng thi đấu:</div>

                      <div class="list-expand-title">1. Bảng thông thường – B1, B2, B3</div>
                      <div>
                        <p>
                          <strong>- Cách thực hiện:</strong> Robot sẽ hoạt động trên một sa bàn, giải quyết các thử
                          thách và ghi điểm ở từng nhiệm vụ.
                        </p>
                        <p>
                          <strong>- Nhiệm vụ thực hiện:</strong> Thí sinh cần phải thiết kế, lắp ráp và lập trình robot
                          để giải quyết các thử thách cụ thể trên sa bàn, điểm được ghi nhận cho mỗi nhiệm vụ khi hoàn
                          thành.
                        </p>
                        <p>
                          <strong>- Độ tuổi tham gia:</strong> Từ Tiểu học đến Sinh viên .
                        </p>
                      </div>
                      <div class="list-expand-title">2. Bảng Mở - B4</div>
                      <div>
                        <p>
                          <strong>- Cách thực hiện:</strong> Lắp ráp Robots theo chủ đề Thành phố thông minh và lập
                          trình trước.
                        </p>
                        <p>
                          <strong>- Nhiệm vụ thực hiện:</strong> thí sinh sẽ cùng nhau tìm hiểu, nghiên cứu về một ý
                          tưởng theo chủ đề cuộc thi và hiện thực hóa ý tưởng này bằng một mô hình thực tế. Sau đó sẽ
                          mang đến cuộc thi trưng bày, thuyết trình cho Ban Giám Khảo và Khách tham quan.
                        </p>
                        <p>
                          <strong>- Độ tuổi tham gia:</strong> Từ tiểu học đến Trung học cơ sở
                        </p>
                      </div>

                      <div class="list-expand-title">3. Bảng Sumo – B5</div>
                      <div>
                        <p>
                          <strong>- Cách thực hiện:</strong> chế tạo một robot thi đấu đối kháng Sumo nhanh nhất, hiệu
                          quả nhất.
                        </p>
                        <p>
                          <strong>- Nhiệm vụ thực hiện:</strong> Mỗi đội gồm một Robot Sumo và hai thành viên của đội.
                          Hai đội thi đấu với nhau trong ba hiệp có tính điểm trong một sàn thi đấu hình tròn. Đội nào
                          bị đẩy ra ngoài vòng tròn trước sẽ bị thua.
                        </p>
                        <p>
                          <strong>- Độ tuổi tham gia:</strong> Từ tiểu học đến Trung học phổ thông
                        </p>
                      </div>

                      <div class="list-expand-title">4. Bảng Bóng đá – B6:</div>
                      <div>
                        <p>
                          <strong>- Cách thực hiện:</strong> WRO bảng Bóng đá với mục tiêu hướng đến việc Robot chơi
                          bóng đá càng giống con người càng tốt.
                        </p>
                        <p>
                          <strong>- Nhiệm vụ thực hiện:</strong> Nhóm gồm có 2 chú Robot tự động cùng nhau tranh cướp
                          quả bóng Hồng ngoại bên trong sân đấu WRO với mục tiêu ghi càng nhiều bàn thắng càng tốt.
                        </p>
                      </div>
                      <br></br>
                      <div class="course-des-title underline">ĐĂNG KÝ THI ĐẤU TỪ STEM SQUARE</div>
                      <div class="list-expand-title">Các bảng sẽ đăng ký: B1, B2</div>
                      <div class="list-expand-title">Thời gian đăng ký:Từ ngày thông báo đến trước ngày 15/07/2019</div>
                      <div class="list-expand-title">Quy định đối với thí sinh:</div>
                      <div>
                        <p>
                          <strong>•Về thiết bị:</strong>
                        </p>
                        <p>
                           Các bộ điều khiển, động cơ, và cảm biến được dùng để lắp ráp robot phải thuộc các bộ LEGO®
                          MINDSTORMS™ (EV3 hoặc NXT). Cảm biến màu sắc HiTechnic là yếu tố của hãng thứ ba duy nhất được
                          sử dụng.
                        </p>
                        <p>
                           Chỉ có thể dùng các khối lắp ráp khác nhau thuộc nhãn hiệu LEGO để xây dựng các bộ phận còn
                          lại của robot.
                        </p>
                        <p>
                           Robotacon WRO 2019 khuyến khích dùng các phiên bản Giáo dục (Education version) của dòng sản
                          phẩm LEGO MINDSTORMS.
                        </p>
                        <p>
                          <strong>• Phần mềm:</strong>
                        </p>
                        <p>
                          Các đội có thể sử dụng bất kỳ phần mềm nào để lập trình bài lập trình mà phù hợp với các bộ
                          điều khiển EV3 hay NXT.
                        </p>

                        <p>
                          <strong>• Đội thi:</strong> Một đội có 1 HLV và từ 2 – 3 thành viên.
                        </p>
                      </div>

                      <BtnViewMore title="Download lịch thi đấu & thể lệ thi tại HCM" />

                      <BtnViewMore title="Xem quy định tại cuộc thi" />

                      <div class="course-des-title underline">DỰ KIẾN LỊCH TRÌNH THI ĐẤU: </div>
                      <div class="list-expand-title">ĐỘI ĐÀ NẴNG VÀO HỒ CHÍ MINH</div>
                      <div>
                        <p>
                          <strong> Đăng ký và chuẩn bị:</strong>
                        </p>
                        <p>o Từ ngày 26/06 đến 15/07: đăng ký thi đấu cho con em tại STEM SQUARE.</p>
                        <p>
                          o Ngày 01/08 đến 06/08: chuẩn bị các vấn đề cho cuộc thi, áo đồng phục, book vé máy bay, khách
                          sạn, ôn luyện, …
                        </p>
                        <strong>
                          <p> Di chuyển vào Tp HCM để thi đấu:</p>
                        </strong>
                        <p>
                          o Ngày 08/08: ra sân bay để bay chuyến vào Tp HCM, nhận phòng khách sạn và hoạt động tự do;
                        </p>
                        <p>
                          o Ngày 09/08: có mặt từ 7h đến 17h00 để thủ tục nhận thẻ thí sinh, các công tác liên quan, thử
                          sa bàn;
                        </p>
                        <p>o Ngày 10/08: thi đấu tại Quân khu 7 – Phú Nhuận – Tp HCM.</p>
                        <p>o Ngày 11/08: thi đấu tại Quân khu 7 – Phú Nhuận – Tp HCM.</p>
                        <p>o Ngày 11 or 12/08: ra sân bay về lại Đà Nẵng (tùy nhóm).</p>
                      </div>
                      <BtnViewMore title="Xem danh sách nhóm đã đăng ký" />
                      <div class="list-expand-title">Quý phụ huynh quan tâm cho cuộc thi ROBOTACON- 2019 Quốc gia</div>
                      <div>
                        <p>
                          Vui lòng đăng ký tại Trung tâm STEM SQUARE để tham gia thi đấu. Và chuẩn bị cho cuộc thi đấu
                          WRO Quốc tế vào tháng 11/2019 tại Hungary.
                          <strong>Văn phòng STEM SQUARE:</strong>
                        </p>
                        <p> 47 Hải Hồ, Q. Hải Châu, Tp Đà Nẵng</p>
                        <p> Tel: 0971.234.042/ 071.234.043/ 0934.876.616/ 0913.111.576</p>
                        <p> Email: stemsquare.vn@gmail.com</p>
                        <p> Hoặc giáo viên trực tiếp để được tư vấn rõ ràng. </p>
                        <p> Thầy Đức (0936064477), Thầy Tùng (0903536193), Thầy Quốc Tuấn (0913111576) </p>
                        <p>Trân trọng cảm ơn.</p>
                      </div>

                      <BtnViewMore title="Download nội dung này" />
                      <BtnViewMore title="Đăng ký" />

                      <div class="course-des-title underline">MỘT SỐ HÌNH ẢNH NGÀY HỘI</div>
                      <div>
                        <div class="list-expand-title">Hình ảnh Robotacon năm 2018</div>
                        <div class="image">
                          <img
                            class="img-responsive"
                            src={img_1}
                            style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                          />
                          <img
                            class="img-responsive"
                            src={img_2}
                            style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                          />
                          <img
                            class="img-responsive"
                            src={img_3}
                            style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                          />
                          <img
                            class="img-responsive"
                            src={img_5}
                            style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                          />
                          <img
                            class="img-responsive"
                            src={img_4}
                            style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                          />
                        </div>
                        <BtnViewMore title="Xem thêm" />
                      </div>

                      <RelatedNews />
                      <div class="course-syllabus">
                        <div class="course-syllabus-title underline" style={{ display: 'none' }}>
                          Lịch trình RoboKids - 2019
                        </div>
                        <div class="course-table" style={{ display: 'none' }}>
                          <div class="outer-container">
                            <div class="inner-container">
                              <div class="table-header">
                                <table class="edu-table-responsive">
                                  <thead>
                                    <tr class="heading-table">
                                      <th class="col-1">Khóa học</th>
                                      <th class="col-2">Ngày khai giảng</th>
                                      <th class="col-3">Thời gian</th>
                                      <th class="col-4">Tình trạng</th>
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                              <div class="table-body">
                                <table class="edu-table-responsive table-hover">
                                  <tbody>
                                    <tr class="heading-content">
                                      <td colspan="4" class="left heading-content">
                                        1. STEM Robotics Explorer (Thiết bị giảng dạy WeDo 2.0)
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp WeDo Dài hạn</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="green-color col-4">
                                        <i class="w27 fa fa-check-square"></i>
                                        <span>Đang học</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp WeDo thi đấu FLL</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="bold-color col-4">
                                        <i class="w27 fa fa-pencil-square-o"></i>
                                        <span>Còn đăng ký</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Lớp WeDo khóa học hè</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="col-4">
                                        <div class="bg-yellow">Học thử</div>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Lớp WeDo sáng tạo</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/07/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="bolder-color col-4">
                                        <i class="w27 fa fa-lock"></i>
                                        <span>Chuẩn bị khai giảng</span>
                                      </td>
                                    </tr>
                                    <tr class="spacing-table">
                                      <td colspan="4"></td>
                                    </tr>

                                    <tr class="heading-content">
                                      <td colspan="4" class="left heading-content">
                                        2. STEM Robotics Creator (Thiết bị giảng dạy EV3)
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp EV3 Dài hạn</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="green-color col-4">
                                        <i class="w27 fa fa-check-square"></i>
                                        <span>Đang học</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp EV3 thi đấu FLL</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="bold-color col-4">
                                        <i class="w27 fa fa-pencil-square-o"></i>
                                        <span>Còn đăng ký</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Lớp EV3 khóa học hè</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="col-4">
                                        <div class="bg-yellow">Học thử</div>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Lớp EV3 sáng tạo</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/07/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="bolder-color col-4">
                                        <i class="w27 fa fa-lock"></i>
                                        <span>Chuẩn bị khai giảng</span>
                                      </td>
                                    </tr>

                                    <tr class="spacing-table">
                                      <td colspan="4"></td>
                                    </tr>

                                    <tr class="heading-content">
                                      <td colspan="4" class="left heading-content">
                                        3. Scratch (Lập trình nhí)
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp Scratch Dài hạn</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="green-color col-4">
                                        <i class="w27 fa fa-check-square"></i>
                                        <span>Đang học</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Lớp Scratch thi đấu FLL</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="bold-color col-4">
                                        <i class="w27 fa fa-pencil-square-o"></i>
                                        <span>Còn đăng ký</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Lớp Scratch khóa học hè</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/06/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="col-4">
                                        <div class="bg-yellow">Học thử</div>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Lớp Scratch sáng tạo</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>01/07/2019</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:9h30</span>
                                      </td>
                                      <td class="bolder-color col-4">
                                        <i class="w27 fa fa-lock"></i>
                                        <span>Chuẩn bị khai giảng</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 sidebar layout-right">
                      <div class="row">
                        <Teacher />
                        {/* <StempRobotics /> */}
                        {/* <Courses /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Robotacon;
