import { useState } from "react";
import { useRecoilState } from "recoil";
import customerCategoryApi from "../api/customerCategoryApi";
import { customerCatState } from "../recoil/atom/customerState";

export const useCustomerCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [customerCats, setCustomerCats] = useRecoilState(customerCatState);

  const getAllCustomerCat = async () => {
    setIsLoading(true);
    try {
      let res = await customerCategoryApi.getAllCustomerCat();
      if (res.data) {
        setCustomerCats(res.data.elements);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    customerCats,
    getAllCustomerCat,
    isLoading,
  };
};
