import axiosApiInstance from '../utils/axiosClient';

const uploadApi = {
  uploadFile: (file) => {
    const path = `/upload/uploadFile`;
    return axiosApiInstance.post(path, file);
  },
  getFolderContent: (folderName) => {
    const path = `/upload/getFolderContent/${folderName}`;
    return axiosApiInstance.get(path);
  },
  getAllFilesFromFolder: (folderName) => {
    const path = `/upload/getAllFilesFromFolder/${folderName}`;
    return axiosApiInstance.get(path);
  },
  deleteFile: (fileName) => {
    const path = `/upload/deleteFile/${fileName}`;
    return axiosApiInstance.get(path);
  },
};

export default uploadApi;
