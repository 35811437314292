import React, { useState } from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import { Empty, Space } from 'antd';
import MutationRegistry from '../../../components/Courses/MutationRegistry';
import { useGeneralSetting } from '../../../hooks/generalSettings';
import { generalSettingState } from '../../../recoil/atom/generalSettingState';
import { useRecoilValue } from 'recoil';
import i18n from '../../../lib/Language';

const OpenClass = () => {
  useGeneralSetting();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const generalSetting = useRecoilValue(generalSettingState);

  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div className="main-content">
          <div className="content">
            {/* BREADCRUMB */}
            <Breadcrumb address={i18n.t('openingSchedule')} parent={i18n.t('public.course')} />

            <div className="section section-padding">
              <div className="container">
                <div className="group-title-index">
                  <h2 className="center-title">{i18n.t('menu.course.schedule')}</h2>
                  <h4 className="top-title">{i18n.t('schedules.courseOpening')}</h4>
                  {generalSetting?.COURSE_OPENING_IMAGE ? (
                    <img
                      className="img-responsive"
                      src={generalSetting?.COURSE_OPENING_IMAGE}
                      style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                      }}
                    />
                  ) : (
                    <div>
                      <Empty />
                    </div>
                  )}
                  <br />
                  <Space direction="vertical" style={{ width: '100%' }} align="center">
                    <button className="btn btn-green-3" onClick={() => setIsModalOpen(true)}>
                      <span>{i18n.t('homepage.processBar.courseRegister')}</span>
                    </button>
                    <h4 className="top-title">{i18n.t('schedules.place')}</h4>
                    <h4 className="top-title">{i18n.t('schedules.address1')}</h4>
                    <h4 className="top-title">{i18n.t('schedules.address2')}</h4>
                    <h4 className="top-title">{i18n.t('schedules.address3')}</h4>

                    {/* MODAL COURSE REGISTRY */}
                    <MutationRegistry isShow={isModalOpen} onCancel={() => setIsModalOpen(false)} />
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- BUTTON BACK TO TOP--> */}
      <div id="back-top">
        <a href="#top">
          <i className="fa fa-angle-double-up"></i>
        </a>
      </div>
    </div>
  );
};

export default OpenClass;
