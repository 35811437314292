import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSystem } from '../../hooks/system';
import { Space, Tag, Typography } from 'antd';
import { formatDateToString } from '../../common';
import i18n from '../../lib/Language';

const CourseItem = ({ item }) => {
  const history = useHistory();
  const { createUserActionHistory } = useSystem();

  const handleSubjectClick = async (id, name) => {
    const data = {
      SUBJECT_ID: id,
      ACTION_KEY: 'R',
      NAME: 'Subject',
      DESC: name,
      NOTE: 'Read Subject',
    };

    await createUserActionHistory(data);
  };

  return (
    <div className="edugate-layout-2">
      <div className="edugate-layout-2-wrapper">
        <div className="edugate-content">
          <Link
            to={`/course/view/${item?.COURSE_ID}`}
            className="title"
            onClick={() => handleSubjectClick(item?.id, item?.NAME)}
          >
            {item?.NAME}
          </Link>

          <div className="info">
            <div className="author item">
              <a>By {item?.DESIGNED_BY ? item?.DESIGNED_BY : 'Admin'}</a>
            </div>
            <div className="item">
              <div className="info-more">
                <div className="view item">
                  <i className="fa fa-user"></i>
                  <p>{item?.STUDENT_LEARNING_COUNT}</p>
                </div>
                <div className="item">
                  <i className="fa fa-eye"></i>
                  <p>{item?.SUBJECT_VIEW}</p>
                </div>
              </div>
            </div>
          </div>

          <div title="Rated 5.00 out of 5" className="star-rating">
            <span className="width-80">
              <strong className="rating">5.00</strong> out of 5
            </span>
          </div>

          <Space style={{ width: '100%' }} direction="vertical">
            <strong>{item?.COURSE_NAME}</strong>
            <p>{item?.GRADE_NAME}</p>
            <Space size={4} wrap>
              <Tag color="green">
                Khai giảng:&nbsp;
                {item?.LEARN_START_DATE === ('' || null)
                  ? 'Chưa có'
                  : formatDateToString(new Date(item?.LEARN_START_DATE))}
              </Tag>
              <Tag color="volcano">
                Kết thúc:&nbsp;
                {item?.LEARN_END_DATE === ('' || null) ? 'Chưa có' : formatDateToString(new Date(item?.LEARN_END_DATE))}
              </Tag>
            </Space>
          </Space>
        </div>
        <div className="edugate-image">
          <img
            src={item?.IMAGE}
            alt=""
            className="img-responsive"
            style={{ objectFit: 'cover', objectPosition: 'top' }}
          />
        </div>
      </div>
      <div
        style={{
          zIndex: 10,
          position: 'relative',
          width: '90%',
          padding: '0 20px 20px 30px',
          textAlign: 'left',
        }}
      >
        <Typography.Paragraph ellipsis={{ rows: 8 }} style={{ marginBottom: 16 }}>
          {item?.DESC}
        </Typography.Paragraph>
        <button
          onClick={() => {
            handleSubjectClick(item?.id, item?.NAME);
            history.push(`/course/view/${item?.COURSE_ID}`);
          }}
          className="btn btn-green"
          style={{
            bottom: -20,
            left: '50%',
            marginLeft: -65,
            position: 'absolute',
          }}
        >
          <span>{i18n.t('public.viewDetail')}</span>
        </button>
      </div>
    </div>
  );
};

export default CourseItem;
