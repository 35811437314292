import { atom, selector } from 'recoil';

export const orgState = atom({
  key: 'orgState',
  default: [],
});

export const orgStemSquareState = selector({
  key: 'orgStemSquareState',
  get: ({ get }) => {
    const orgs = get(orgState);
    if (orgs?.length) {
      return orgs?.find(item => item.ABB === "SS");
    }
    return undefined;
  },
});

