import { useState } from 'react';
import courseApi from '../api/courseApi';
import { useRecoilState } from 'recoil';
import { coursesState } from '../recoil/atom/courseState';

export const useCourse = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [course, setCourse] = useState();
  const [courses, setCourses] = useRecoilState(coursesState);

  const getAllCourses = async () => {
    setIsLoading(true);
    try {
      let res = await courseApi.getAllCourses();
      if (res.data) {
        setCourses(res.data.elements);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getCourse = async (id, callback) => {
    setIsLoading(true);
    try {
      let res = await courseApi.getCourse(id);
      if (res.data) {
        setCourse(res.data.elements.course);
        callback();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const createCourse = async (data, callback) => {
    console.log(data);
    setIsLoading(true);
    try {
      let res = await courseApi.createCourse(data);
      if (res.data) {
        getAllCourses();
        setIsLoading(false);
        setCourse(undefined);
        callback();
      }
    } catch (error) {
      setIsLoading(false);
      console.log('error::', error);
    }
  };
  const assignBranchIdsToCourse = async (data) => {
    try {
      await courseApi.assignBranchIdsToCourse(data);
    } catch (error) {
      console.log('error::', error);
    }
  };
  const unassignBranchIdsFromCourse = async (data) => {
    try {
      await courseApi.unassignBranchIdsFromCourse(data);
    } catch (error) {
      console.log('error::', error);
    }
  };
  const updateCourse = async (data, id, callback) => {
    setIsLoading(true);
    try {
      let res = await courseApi.updateCourse(data, id);
      if (res.data) {
        getAllCourses();
        setIsLoading(false);
        callback();
        setCourse(undefined);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteCourse = async (id) => {
    setIsLoading(true);
    try {
      let res = await courseApi.deleteCourse(id);
      if (res.data) {
        getAllCourses();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return {
    course,
    courses,
    getAllCourses,
    getCourse,
    createCourse,
    deleteCourse,
    updateCourse,
    assignBranchIdsToCourse,
    unassignBranchIdsFromCourse,
    isLoading,
  };
};
