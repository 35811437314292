import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import eduDashboardApi from "../api/eduDashboard";
import { countTeacherCourseStudentBranchState } from "../recoil/atom/dashBoardState";

export const useEduDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  //1. count pannel
  const [studentRegistrationTotal, setStudentRegistrationTotal] =
    useState(undefined);
  const [studentTotal, setStudentTotal] = useState(undefined);
  const [studentInitTotal, setStudentInitTotal] = useState(undefined);
  const [studentConsultingTotal, setStudentConsultingTotal] =
    useState(undefined);
  const [studentLearningTotal, setStudentLearningTotal] = useState(undefined);
  const [studentStoppedTotal, setStudentStoppedTotal] = useState(undefined);
  const [dataPercentage, setDataPercentage] = useState([]);

  const [dataSourceRegistration, setDataSourceRegistration] = useState([]);
  const [dataStudentStatus, setDataStudentStatus] = useState([]);
  const [dataConsulting, setDataConsulting] = useState([]);
  const [dataFollowing, setDataFollowing] = useState([]);
  const [dataSchool, setDataSchool] = useState([]);

  //count branch
  const [dataPie, setDataPie] = useState([]);

  const [dataColumn, setDataColumn] = useState([]);

  //
  const [countTeacherCourseStudentBranch, setCountTeacherCourseStudentBranch] = useRecoilState(countTeacherCourseStudentBranchState)

  useEffect(() => {
    if (!countTeacherCourseStudentBranch) {
      getCountTeacherCourseStudentBranch();
    }
  }, [])

  //1. count pannel
  const getCountStudentAnalytic = async () => {
    setIsLoading(true);
    try {
      let res = await eduDashboardApi.getCountStudentAnalytic();
      if (res.data) {
        setStudentRegistrationTotal(res.data.elements.StudentRegistrationTotal); //value of data from API
        setStudentTotal(res.data.elements.StudentTotal);
        setStudentInitTotal(res.data.elements.StudentInitTotal); //value of data from API
        setStudentConsultingTotal(res.data.elements.StudentConsultingTotal);
        setStudentLearningTotal(res.data.elements.StudentLearningTotal);
        setStudentStoppedTotal(res.data.elements.StudentStoppedTotal);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  //2. pie chart
  const getCountStudentBaseOnBranch = async () => {
    setIsLoading(true);
    try {
      let res = await eduDashboardApi.getCountStudentBaseOnBranch();
      if (res.data) {
        setDataPie(res.data.elements?.result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  //3. column chart
  const getCountClassByType = async () => {
    setIsLoading(true);
    try {
      let res = await eduDashboardApi.getCountClassByType();
      // console.log("Res = ", res);
      if (res.data) {
        setDataColumn(res.data.elements?.result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  //4. student column chart
  const getCountStudentByYear = async () => {
    setIsLoading(true);
    try {
      let res = await eduDashboardApi.getCountStudentByYear();
      // console.log("Res = ", res);
      if (res.data) {
        setDataColumn(res.data.elements?.result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  //5. user column chart
  const getCountUserByYear = async () => {
    setIsLoading(true);
    try {
      let res = await eduDashboardApi.getCountUserByYear();
      if (res.data) {
        setDataColumn(res.data.elements?.users);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  //6. user column chart (dong ho)
  const getCountAllLessons = async () => {
    setIsLoading(true);
    try {
      let res = await eduDashboardApi.getCountAllLessons();
      if (res.data) {
        setDataColumn(res.data.elements?.lessons);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  //7. balance percenate
  const getBalanceUserPaymentWithClasssPercentage = async () => {
    setIsLoading(true);
    try {
      let res =
        await eduDashboardApi.getBalanceUserPaymentWithClasssPercentage();
      if (res.data) {
        setDataPercentage(res.data.elements?.result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  //8. branch chart
  const getCountStudentGroupBySourceRegistration = async () => {
    setIsLoading(true);
    try {
      let res =
        await eduDashboardApi.getCountStudentGroupBySourceRegistration();
      if (res.data) {
        setDataSourceRegistration(res.data.elements?.result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  //9. branch chart
  const getCountStudentGroupByStudentStatus = async () => {
    setIsLoading(true);
    try {
      let res = await eduDashboardApi.getCountStudentGroupByStudentStatus();
      if (res.data) {
        setDataStudentStatus(res.data.elements?.result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  //10. branch chart
  const getCountStudentGroupByConsulting = async () => {
    setIsLoading(true);
    try {
      let res = await eduDashboardApi.getCountStudentGroupByConsulting();
      if (res.data) {
        setDataConsulting(res.data.elements?.result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  //11. branch chart
  const getCountStudentGroupByFollowing = async () => {
    setIsLoading(true);
    try {
      let res = await eduDashboardApi.getCountStudentGroupByFollowing();
      if (res.data) {
        setDataFollowing(res.data.elements?.result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  //12. branch chart
  const getCountStudentGroupBySchool = async () => {
    setIsLoading(true);
    try {
      let res = await eduDashboardApi.getCountStudentGroupBySchool();
      if (res.data) {
        setDataSchool(res.data.elements?.result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };


  // setountTeacherCourseStudentBranch
  const getCountTeacherCourseStudentBranch = async () => {
    setIsLoading(true);
    try {
      let res = await eduDashboardApi.getCountTeacherCourseStudentBranch();
      if (res.data) {
        setCountTeacherCourseStudentBranch(res.data.elements?.result);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    studentRegistrationTotal,
    studentTotal,
    studentInitTotal,
    studentConsultingTotal,
    studentLearningTotal,
    studentStoppedTotal,
    dataPie,
    dataColumn,
    dataPercentage,
    dataSourceRegistration,
    dataStudentStatus,
    dataConsulting,
    dataFollowing,
    dataSchool,
    getCountStudentAnalytic,
    getCountStudentBaseOnBranch,
    getCountClassByType,
    getCountStudentByYear,
    getCountUserByYear,
    getCountAllLessons, // use?
    getBalanceUserPaymentWithClasssPercentage,
    getCountStudentGroupBySourceRegistration,
    getCountStudentGroupByStudentStatus,
    getCountStudentGroupByConsulting,
    getCountStudentGroupByFollowing,
    getCountStudentGroupBySchool,
    isLoading,
    getCountTeacherCourseStudentBranch
  };
};
