import axiosApiInstance from "../utils/axiosClient";

const sendEmailApi = {

    email: ( data) => {
        const path = `/email/sendCustomEmail`;
        return axiosApiInstance.post(path, data);
    },

};

export default sendEmailApi;