import sendEmailApi from "../api/sendEmail";


export const sendEmailReceipt = async (data, email, title,) => {
  const html = `<html>
<head>
  <style>
    .width-600{
      width: 1000px
    }
    .flex{
      display: flex;
      
    }
    .logo{
      width: 500px;
      float: left;
    }
    .cd{
      width: 500px;
      text-align: right;
    }
    .bold{
      font-weight: bold;
    }
    .center{
      text-align: center;
    }
    .left{
      text-align: left;
    }
    .width-200{
      width: 150px
    }
    .pd-left-20{
      padding-left: 40px;
    }
    .pd-40{
      padding: 40px
    }
    .mb-8 {
      margin-bottom: 8px;
    }
  </style>
</head>
<body>
  <div class="width-600 pd-40">
    <div class="width-600 flex">
        <div class="logo">
          <img  src="http://stemsquare.vn/static/media/logo_ngang.dc4b4143555eba2178e6.jpg" width="265" height="50" alt="logo"/>
        </div>
    </div>
    <div class="width-600">
      <p>Dear <b>${data?.NAME}</b></p>
      <p class="mb-8">Thông tin tài khoản của bạn như dưới đây</p>
    </div>

    <div class="flex" >
      <p class="width-200 mb-8">Họ và tên: </p>
      <p class="mb-8"><b>${data?.NAME}</b></p>
    </div>

    <div class="flex" >
      <p class="width-200 mb-8">User CD: </p>
      <p class="mb-8"><b>${data?.USER_CD}</b></p>
    </div>

    <div class="width-600 ">
        <p class="mb-8">Thông tin tài khoản đăng nhập trang hệ thống edu: </p>
    </div>

    <div class="flex" >
      <p class="width-200 mb-8">Tên đăng nhập: </p>
      <p class="mb-8"><b>${data?.USER_NAME}</b></p>
    </div>

    <div class="flex" >
      <p class="width-200 mb-8">Password: </p>
      <p class="mb-8"><b>${data?.PASSWORD}</b></p>
    </div>

    <div class="width-600" >
      <p class="mb-8">Thông tin tài khoản đăng nhập trang làm bài test http://elearning.novasquare.vn:8088 </p>
    </div>

    <div class="flex" >
      <p class="width-200 mb-8">Tên đăng nhập: </p>
      <p class="mb-8"><b>${data?.PRIMARY_PHONE}</b></p>
    </div>

    <div class="flex" >
      <p class="width-200 mb-8">Password: </p>
      <p class="mb-8"><b>Novasquare@123</b></p>
    </div>

    <div class="flex" >
      <p class="width-200 mb-8">Ghi chú: </p>
      <p class="mb-8">Vui lòng kích vào link http://edu.novasquare.vn:3002 để đăng nhập vào hệ thống.</p>
    </div>
    
    <div class="flex" >
      <p class="width-200 mb-8"></p>
      <p class="mb-8">Vui lòng kích vào link http://elearning.novasquare.vn:8088 để đăng nhập vào trang làm bài test.</p>
    </div>

    <div class="flex" >
      <p class="mb-8">(Bạn có thể đăng nhập bằng Tên đăng nhập hoặc Email hoặc Số điện thoại đã đã đăng ký.)</p>
    </div>
    <div>
      <p class="mb-8">Nếu có bất kỳ vấn đề gì, vui lòng gọi cho chúng tôi, hoặc email/ chat </p>
      <p class="mb-8">Trân trọng</p>
      <p class="mb-8">Trung tâm STEM SQUARE</p>
    </div>
</body>
</html>`;

  try {
    let res = await sendEmailApi.email({ email: email, title: title, content: html });
    if (res.data) {
      return res.data
    }
  } catch (error) {
    if (error.response.data.status !== 401) {
      return "error"
    }
  }
}