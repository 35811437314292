import React from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import SearchWrapper from '../../../components/Global/SearchWrapper';
import { useEmployee } from '../../../hooks/employee';
import { listEmployeeFEState } from '../../../recoil/atom/employeeState';
import { useRecoilValue } from 'recoil';
import { ROUTER } from '../../../constant/router';
import { Link } from 'react-router-dom';
import { List } from 'antd';
import { formatDate } from '../../../common';
import moment from 'moment/moment';
import i18n from '../../../lib/Language';

const Teams = () => {
  useEmployee();
  const listEmployee = useRecoilValue(listEmployeeFEState);

  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div className="main-content">
          <div className="content">
            <Breadcrumb address={i18n.t('teams')} parent={i18n.t('menu.home.teams')} />
            <div className="section" style={{ display: 'none' }}>
              <SearchWrapper />
            </div>
            <div className="section section-padding latest-news">
              <div className="container">
                <div className="group-title-index">
                  <h4 className="top-title">{i18n.t('homepage.newsForStem')}</h4>

                  <h2 className="center-title">{i18n.t('menu.home.teams')}</h2>

                  <div className="bottom-title">
                    <i className="bottom-icon icon-a-01-01"></i>
                  </div>
                </div>
                {/*  */}
                <div className="latest-news-wrapper">
                  <List
                    dataSource={listEmployee}
                    pagination={{ pageSize: 5 }}
                    itemLayout="vertical"
                    renderItem={(item) => (
                      <div className="edugate-layout-1">
                        <div className="edugate-image">
                          <img src={item.User?.AVATAR} alt="" className="img-responsive" />
                        </div>
                        <div className="edugate-content">
                          <a href="#" className="title">
                            {item.User?.FIRST_NAME + ' ' + item.User?.LAST_NAME}
                          </a>
                          <div className="info" style={{ marginBottom: 20 }}>
                            <div className="author item">
                              <a href="#">By Stem Square</a>
                            </div>
                            <div className="date-time item">
                              <a href="#">{moment(item.User?.CREATED_DATE).format(formatDate.DD)}</a>
                            </div>
                          </div>
                          {/* <div className="info-more">
                              <div className="view item">
                                <i className="fa fa-user"></i>
                                <p> 120</p>
                              </div>
                              <div className="comment item">
                                <i className="fa fa-comment"></i>

                                <p> 239</p>
                              </div>
                            </div> */}
                          <div className="description">
                            {/* {item.Position?.POSITION_NAME} */}
                            <p>{item.JOB_SHORT_DESC}</p>
                          </div>
                          <Link to={`${ROUTER.HOME.DETAILPROFILE.path}/${item?.id}`}>
                            <button className="btn btn-green">
                              <span>{i18n.t('public.viewDetail')}</span>
                            </button>
                          </Link>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
