import { Button, Form, Input, Modal, Select, Space } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { subjectByOrgState } from '../../recoil/atom/subjectState';
import { removeAccents, validateMessages } from '../../common';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import customerContactApi from '../../api/customerContactApi';
import customerApi from '../../api/customerApi';
import i18n from '../../lib/Language';
import { useSubject } from '../../hooks/subject';
import userApi from '../../api/userApi';
import { sendEmailReceipt } from '../../common/sendEmail';
import studentApi from '../../api/studentApi';
import { studentStatusState } from '../../recoil/atom/studentState';
import studentStatusApi from '../../api/studentStatusApi';
import { classesOptionState, classesState } from '../../recoil/atom/classState';
import classApi from '../../api/classApi';
import studentHistoryApi from '../../api/studentHistoryApi';
import { useSnackbar } from 'notistack';
import { useCustomerStatus } from '../../hooks/useCustomerStatus';
import { useCustomerCategory } from '../../hooks/useCustomerCategory';
import { useCustomerType } from '../../hooks/useCustomerType';
import { useSourceRegistration } from '../../hooks/useSourceRegistration';
const regexEmail =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

// const regPhone = /^\(?\+?([0-9]{4,4})\)?[-. ]?([0-9]{3,4})[-. ]?([0-9]{3,4})$/;
const regPhone = /^\(?\+?([0-9]{3,4})\)?[-. ]?([0-9]{2,4})[-. ]?([0-9]{3,4})$/;
// pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/g,

const MutationRegistry = (props) => {
  const { isShow, onCancel, subjectId } = props;
  const typingTimeoutRef = useRef(null);
  const [form] = Form.useForm();
  const { getAllSubjects, subjects, isLoading } = useSubject();
  const [loading, setLoading] = useState(false);
  const [captchaCode, setCaptchaCode] = useState('');
  const inpNameRef = useRef();
  // const [valueInput, setValueInput] = useState(undefined);
  const [valueEmail, setValueEmail] = useState(undefined);
  const [subjectSelect, setSubjectSelect] = useState(undefined);
  const [classData, setClassData] = useState(undefined);
  const [studentStatus, setStudentStatus] = useRecoilState(studentStatusState);
  const [classes, setClasses] = useRecoilState(classesState);
  const subjectByOrg = useRecoilValue(subjectByOrgState);
  const classesOption = useRecoilValue(classesOptionState);

  // filter class status open and org stem
  const listClassOpen = useMemo(() => {
    return classesOption
      ?.filter((item) => item?.Class_Status?.STATUS_CD?.toLowerCase() === 'open')
      ?.filter((el) => el.Workplace?.Org?.ABB?.toLowerCase() === 'ss');
  }, [classesOption]);

  //customer status
  const { getAllCustomerStatuss, customerStatuss } = useCustomerStatus();
  //customer category
  const { getAllCustomerCat, customerCats } = useCustomerCategory();
  //customer type
  const { getAllCustomerTypes, customerTypes } = useCustomerType();
  //sourceRegistance
  const { getAllSourceRegistrations, sourceRegistrations } = useSourceRegistration();

  useEffect(() => {
    if (isShow) {
      //customer status
      if (customerStatuss?.length === 0) {
        getAllCustomerStatuss();
      }
      //customer category
      if (customerCats?.length === 0) {
        getAllCustomerCat();
      }
      //customer type
      if (customerTypes?.length === 0) {
        getAllCustomerTypes();
      }
      //source register
      if (sourceRegistrations?.length === 0) {
        getAllSourceRegistrations();
      }
    }
  }, [isShow]);

  // find customer status new
  const statusCus = useMemo(() => {
    return customerStatuss?.find((item) => item.CD?.toLowerCase() === 'new');
  }, [customerStatuss]);

  // find customer category education
  const customerCat = useMemo(() => {
    return customerCats?.find((item) => item.CD?.toLowerCase() === 'edu');
  }, [customerCats]);

  // find customer type
  const customerType = useMemo(() => {
    return customerTypes?.find((item) => item.CD?.toLowerCase() === 'per');
  }, [customerTypes]);

  // find customer type
  const sourceRegistration = useMemo(() => {
    return sourceRegistrations?.find((item) => item.NAME?.includes('Website'));
  }, [sourceRegistrations]);

  const { enqueueSnackbar } = useSnackbar();

  const handleGetAllStudentStatus = async () => {
    let res = await studentStatusApi.getAllStudentStatuss();
    if (res.data) {
      setStudentStatus(res.data?.elements);
    }
  };

  const handleGetAllClass = async () => {
    let res = await classApi.getAllClasses();
    if (res.data) {
      setClasses(res.data?.elements);
    }
  };
  // GET ALL SUBJECT
  useEffect(() => {
    if (subjects?.length === 0 && isShow) {
      getAllSubjects();
    }
    if (studentStatus?.length === 0) {
      handleGetAllStudentStatus();
    }
    if (classes?.length === 0) {
      handleGetAllClass();
    }
  }, []);

  const statusLearn = useMemo(() => {
    return studentStatus?.find((item) => item.NAME === 'LEARNING');
  }, [studentStatus]);

  // const classData = useMemo(() => {
  //   if (valueInput) {
  //     return classes?.find((item) => item.CLASS_CD === valueInput);
  //   }
  // }, [valueInput, classes]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  // create capcha code random
  const generateCaptchaCode = () => {
    // declare all characters
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  // generate random strings
  useEffect(() => {
    if (isShow) {
      inpNameRef?.current.focus();
      const captchaCode = generateCaptchaCode();
      setCaptchaCode(captchaCode);
    }
  }, [inpNameRef, isShow]);

  // handle refresh capcha
  const handleRefreshCaptcha = () => {
    const captchaCode = generateCaptchaCode();
    form.setFieldValue('CAPTCHA', '');
    setCaptchaCode(captchaCode);
  };

  useEffect(() => {
    if (subjectId) {
      form.setFieldsValue({
        SUBJECT_ID: subjectId,
      });
    }
  }, [subjectId, form]);

  // handle sendmail
  const handleSendMail = async (data) => {
    const title = 'Tài khoản đăng nhập của bạn là:';
    const tempData = {
      ...data,
      NAME: data?.FIRST_NAME + ' ' + data?.LAST_NAME,
      PASSWORD: data?.USER_NAME + '@123',
    };
    if (valueEmail) {
      await sendEmailReceipt(tempData, valueEmail, title);
    }
  };

  // handle assign student in class
  const handleAssighClass = async (data) => {
    await studentApi.assignUserIdsToClass(data);
  };

  // handle create data history class user
  const handleCreateHistory = async (data) => {
    await studentHistoryApi.createStudentHistory(data);
  };

  // create user and sendmail
  const handleCreateUser = async (data, classCD) => {
    try {
      // create user
      let res = await userApi.createUserThroughStudent(data);
      if (res.data) {
        const dataUser = res.data?.elements;
        // create student
        let resp = await studentApi.createStudent({ USER_ID: dataUser?.id });
        if (resp.data) {
          // assign class for student
          const values = {
            classId: classData?.id,
            userIds: res.data?.elements?.id,
            statusId: statusLearn?.id,
            note: classData?.CLASS_CD,
          };
          await handleAssighClass(values);

          // create history class student
          const dataHistory = {
            USER_ID: res.data?.elements?.id,
            CLASS_ID_OLD: null,
            CLASS_ID_NEW: classData?.id,
            NOTE: classData?.CLASS_CD,
          };
          await handleCreateHistory(dataHistory);
        }

        //create account elearning
        const data = {
          username: dataUser?.PRIMARY_PHONE,
          password: 'Novasquare@123',
          firstname: classCD?.CODE + '_' + dataUser?.FIRST_NAME,
          lastname: dataUser?.LAST_NAME,
          email: dataUser?.EMAIL,
          country: 'VN',
          lang: 'en',
        };
        try {
          let res = await userApi.createELearningUser(data);
          if (res.data) {
            // asign exam to account elearning
            const dataAssign = {
              username: dataUser?.PRIMARY_PHONE,
              courseShortName: 'STEM',
            };
            await userApi.assignELearningUserToExam(dataAssign);
          }
        } catch (error) {
          enqueueSnackbar(error.response?.data?.message, { variant: 'error' });
        }
        // handle send mail
        await handleSendMail(res.data?.elements);
        success();
      }
    } catch (error) {
      enqueueSnackbar(error.response?.data?.message, { variant: 'error' });
    }
  };

  //handle assign elearning user to exam
  const handleAssignELearningUserToExam = async (data) => {
    await userApi.assignELearningUserToExam(data);
    enqueueSnackbar('Tài khoản elearning được tạo thành công', { variant: 'warning' });
  };

  //create customer
  const handleCreateCustomer = async (data, callback) => {
    const { CONTACT, NAME, ...result } = data;

    // remove special characters
    let primaryPhone = data?.PRIMARY_PHONE?.replace(/[D&\/\\ #,+()$~%.'":*?<>{}-]/g, '');

    // const checkVku = 'VKU';
    // const checkBK = 'BK.2023.BLOCKCHAIN';

    // const check =
    //   CONTACT?.toLowerCase()?.includes(checkVku?.toLowerCase()) ||
    //   CONTACT?.toLowerCase()?.includes(checkBK?.toLowerCase());

    // if customer is student of university
    // if (check) {
    const LAST_NAME = data?.NAME?.trim().split(' ').pop();
    const FIRST_NAME = data?.NAME?.replace(LAST_NAME, ' ')?.trim();
    const newData = {
      ...result,
      NAME: CONTACT ? CONTACT : data?.NAME,
      // NOTE: classData?.CLASS_CD + '/' + classData?.NAME + '(' + CONTACT + ')',
      NOTE: 'Class: ' + classData?.CLASS_CD + '/' + classData?.NAME,
      PRIMARY_PHONE: primaryPhone,
    };

    try {
      setLoading(true);
      let res = await customerApi.create(newData);
      if (res.data) {
        //data create customer contact
        const valuesContact = {
          FIRST_NAME: FIRST_NAME,
          LAST_NAME: LAST_NAME,
          PRIMARY_PHONE: primaryPhone,
          EMAIL: data?.EMAIL ? data?.EMAIL : null,
          CUSTOMER_ID: res.data?.elements?.id,
        };
        try {
          // create customrer contact
          let resp = await customerContactApi.create(valuesContact);
          if (resp.data) {
            const query = {
              customerId: valuesContact?.CUSTOMER_ID,
              contactIds: JSON.stringify([resp.data?.elements?.id]),
            };

            // assign contact id to table customer_contact
            await customerContactApi.assignContactIdsToCustomer(query);

            // data for create user
            const dataUser = {
              EMAIL: valuesContact?.EMAIL,
              FIRST_NAME: valuesContact?.FIRST_NAME,
              LAST_NAME: valuesContact?.LAST_NAME,
              PRIMARY_PHONE: valuesContact?.PRIMARY_PHONE,
              CONTACT_ID: resp.data?.elements?.id,
            };
            // handle create user
            await handleCreateUser(dataUser, { CODE: newData?.NOTE });
          }
        } catch (error) {
          const dataError = error.response?.data?.elements;
          // if customer contact exist
          if (dataError?.id) {
            const query = {
              customerId: valuesContact?.CUSTOMER_ID,
              contactIds: JSON.stringify([dataError?.id]),
            };
            // assign contact id to table customer_contact
            await customerContactApi.assignContactIdsToCustomer(query);

            // if (error.response?.data?.message === 'PRIMARY_PHONE already exists in contacts table.')
            //   enqueueSnackbar('Số điện thoại đã tồn tại. Vui lòng nhập số điện thoại khác.', { variant: 'warning' });

            // if (error.response?.data?.message === 'EMAIL already exists in contacts table.')
            //   enqueueSnackbar('Email đã tồn tại. Vui lòng nhập email khác.', { variant: 'warning' });

            // create account elearning when contact exist and user exist
            // data create user elearning
            const dataUser = {
              username: valuesContact?.PRIMARY_PHONE,
              password: 'Novasquare@123',
              firstname: newData?.NOTE + '_' + valuesContact?.FIRST_NAME,
              lastname: valuesContact?.LAST_NAME,
              email: valuesContact?.PRIMARY_PHONE + '@gmail.com',
              country: 'VN',
              lang: 'en',
            };
            try {
              let res = await userApi.createELearningUser(dataUser);
              if (res.data) {
                // asign exam to account elearning
                const dataAssign = {
                  username: res.data?.elements?.username,
                  courseShortName: 'STEM',
                };
                await handleAssignELearningUserToExam(dataAssign);
                success();
              }
            } catch (error) {
              if (error?.response?.data?.message?.includes('Username already exists')) {
                const dataAssign = {
                  username: valuesContact?.PRIMARY_PHONE,
                  courseShortName: 'STEM',
                };
                await handleAssignELearningUserToExam(dataAssign);
                success();
              }
            }
          }

          //  if have user and have student
          if (dataError?.userId && dataError?.user?.student?.[0]?.id) {
            const user = dataError?.user;

            const values2 = {
              FIRST_NAME: user?.FIRST_NAME,
              LAST_NAME: user?.LAST_NAME,
            };

            // create contact
            let res = await customerContactApi.create(values2);
            if (res.data) {
              let resp = await userApi.updateUserContact(user?.id, res.data?.elements?.id);
              if (resp.data) {
                const query = {
                  customerId: valuesContact?.CUSTOMER_ID,
                  contactIds: JSON.stringify([res.data?.elements?.id]),
                };

                // assign contact id to table customer_contact
                await customerContactApi.assignContactIdsToCustomer(query);
              }
            }

            // data assign student in class
            const values = {
              classId: classData?.id,
              userIds: user?.id,
              statusId: statusLearn?.id,
              note: classData?.CLASS_CD,
            };
            try {
              await handleAssighClass(values);
            } catch (error) {
              enqueueSnackbar(error.response?.data?.message, { variant: 'error' });
            }

            // create history class student
            const dataHistory = {
              USER_ID: user?.id,
              CLASS_ID_OLD: null,
              CLASS_ID_NEW: classData?.id,
              NOTE: classData?.CLASS_CD,
            };
            await handleCreateHistory(dataHistory);

            // data create user elearning
            const data = {
              username: user?.PRIMARY_PHONE,
              password: 'Novasquare@123',
              firstname: newData?.NOTE + '_' + user?.FIRST_NAME,
              lastname: user?.LAST_NAME,
              email: user?.EMAIL,
              country: 'VN',
              lang: 'en',
            };
            try {
              // create elearning
              let res = await userApi.createELearningUser(data);
              if (res.data) {
                // asign exam to account elearning
                const dataAssign = {
                  username: user?.PRIMARY_PHONE,
                  courseShortName: 'STEM',
                };
                await handleAssignELearningUserToExam(dataAssign);
                success();
              }
            } catch (error) {
              enqueueSnackbar('Account elearning have ' + error.response?.data?.message, { variant: 'warning' });
            }
          }

          // if user exist and haven't student
          if (dataError?.userId && !dataError?.user?.student?.[0]?.id) {
            const user = dataError?.user;
            const values1 = {
              FIRST_NAME: user?.FIRST_NAME,
              LAST_NAME: user?.LAST_NAME,
            };

            // create contact
            let res = await customerContactApi.create(values1);
            if (res.data) {
              //update contact_id for user
              let resp = await userApi.updateUserContact(user?.id, res.data?.elements?.id);
              if (resp.data) {
                const query = {
                  customerId: valuesContact?.CUSTOMER_ID,
                  contactIds: JSON.stringify([res.data?.elements?.id]),
                };

                // assign contact id to table customer_contact
                await customerContactApi.assignContactIdsToCustomer(query);

                // create student
                let respon = await studentApi.createStudent({ USER_ID: user?.id });
                if (respon.data) {
                  // assign class for student
                  const values = {
                    classId: classData?.id,
                    userIds: user?.id,
                    statusId: statusLearn?.id,
                    note: classData?.CLASS_CD,
                  };
                  await handleAssighClass(values);

                  // create history class student
                  const dataHistory = {
                    USER_ID: user?.id,
                    CLASS_ID_OLD: null,
                    CLASS_ID_NEW: classData?.id,
                    NOTE: classData?.CLASS_CD,
                  };
                  await handleCreateHistory(dataHistory);
                }

                //create account elearning when have user
                const data = {
                  username: user?.PRIMARY_PHONE,
                  password: 'Novasquare@123',
                  firstname: newData?.NOTE + '_' + user?.FIRST_NAME,
                  lastname: user?.LAST_NAME,
                  email: user?.EMAIL,
                  country: 'VN',
                  lang: 'en',
                };
                try {
                  // create elearning
                  let res = await userApi.createELearningUser(data);
                  if (res.data) {
                    // asign exam to account elearning
                    const dataAssign = {
                      username: user?.PRIMARY_PHONE,
                      courseShortName: 'STEM',
                    };
                    await userApi.assignELearningUserToExam(dataAssign);
                    enqueueSnackbar('Tài khoản elearning đã được tạo thành công', { variant: 'success' });
                    await handleAssignELearningUserToExam(dataAssign);
                    success();
                  }
                } catch (error) {
                  enqueueSnackbar('Account elearning have ' + error.response?.data?.message, { variant: 'error' });
                }
              }
            }
          }
        }
        callback();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.response?.data?.message, { variant: 'error' });
    }
  };

  // show message whne register course success
  const success = () => {
    Modal.success({
      content: (
        <div className="mess-content">
          <p style={{ fontSize: 24 }}>Cảm ơn bạn đã đăng ký khóa học</p>
          <p>
            <b>{subjectSelect?.NAME}</b>
          </p>
          <p style={{ textAlign: 'center' }}>
            Vui lòng kiểm tra email để có tài khoản đăng nhập. Trường hợp chưa nhận được, hãy liên lạc với TT qua
            fanpage STEM SQUARE EDUCATION hoặc phone 0971234043-0971234042.
          </p>
          <p style={{ textAlign: 'center' }}>(Chú ý kiểm tra kỹ trong mục Thư rác của phần mở rộng. Xin cảm ơn)</p>
        </div>
      ),
      onOk() {
        setSubjectSelect(undefined);
      },
    });
  };

  // handle submit form register course
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const newData = {
          NAME: values.NAME,
          PRIMARY_PHONE: values?.PHONE,
          NOTE: subjectSelect ? subjectSelect?.id + '/' + subjectSelect?.SUBJECT_CD + '/' + subjectSelect?.NAME : null,
          EMAIL: values.EMAIL,
          CONTACT: values.CONTACT ? values.CONTACT : undefined,
          CUSTOMER_STATUS_ID: statusCus?.id,
          CUSTOMER_CAT_ID: customerCat?.id,
          SOURCE_REGISTERED_ID: sourceRegistration?.id,
          CUSTOMER_TYPE_ID: customerType?.id,
        };

        handleCreateCustomer(newData, handleCancel);
      })
      .catch((error) => {
        handleRefreshCaptcha();
      });
  };

  // check value input name
  // const handleChange = (e) => {
  //   if (typingTimeoutRef.current) {
  //     clearTimeout(typingTimeoutRef.current);
  //   }
  //   typingTimeoutRef.current = setTimeout(() => {
  //     setValueInput(e.target.value);
  //   }, 500);
  // };

  // check value input email
  const handleChangeEmail = (e) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setValueEmail(e.target.value);
    }, 500);
  };

  return (
    <Modal
      title={i18n.t('homepage.processBar.courseRegister')}
      open={isShow}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button onClick={handleCancel}>{i18n.t('general.button.btnCancel')}</Button>,
        <Button key="submit" htmlType="submit" type="primary" onClick={handleOk} disabled={loading}>
          {loading ? <LoadingOutlined /> : i18n.t('general.button.btnRegister')}
        </Button>,
      ]}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
        validateMessages={validateMessages}
        labelAlign="left"
        labelWrap
      >
        <Form.Item
          label={i18n.t('public.student')}
          name="NAME"
          rules={[
            { required: true },
            {
              type: 'string',
              min: 6,
              message: 'Trường tên phải nhiều hơn 6 kí tự',
            },
          ]}
        >
          <Input ref={inpNameRef} />
        </Form.Item>
        <Form.Item label={i18n.t('public.contact/class')} name="CONTACT">
          <Input
          // onChange={handleChange}
          />
        </Form.Item>
        <Form.Item label={i18n.t('public.class')} name="CLASS_ID" rules={[{ required: true }]}>
          <Select
            placeholder={`${i18n.t('public.chooseClass')}`}
            getPopupContainer={(trigger) => trigger.parentNode}
            showSearch
            style={{ width: '100%' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              removeAccents(option?.label ?? '')
                ?.toLowerCase()
                ?.includes(removeAccents(input?.toLowerCase()))
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')?.localeCompare((optionB?.label ?? '')?.toLowerCase())
            }
            onSelect={(value, data) => setClassData(data)}
            onClear={() => setClassData(undefined)}
            allowClear
            options={listClassOpen}
          />
        </Form.Item>
        <Form.Item
          label={i18n.t('public.phoneNumber')}
          name="PHONE"
          rules={[
            { required: true },
            {
              pattern: regPhone,
              message: i18n.t('registerClass.phoneError'),
            },
          ]}
        >
          <Input placeholder="0909878787 or 321.789.4512 or 123 256 4587 or 222-055-9034" />
        </Form.Item>
        <Form.Item
          label={'Email'}
          name="EMAIL"
          rules={[
            {
              pattern: regexEmail,
              message: i18n.t('registerClass.emailError'),
            },
          ]}
        >
          <Input onChange={handleChangeEmail} />
        </Form.Item>
        <Form.Item label={i18n.t('public.subject')} name="SUBJECT_ID">
          <Select
            placeholder={`${i18n.t('public.chooseCourse')}`}
            getPopupContainer={(trigger) => trigger.parentNode}
            showSearch
            style={{ width: '100%' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              removeAccents(option?.label ?? '')
                ?.toLowerCase()
                ?.includes(removeAccents(input?.toLowerCase()))
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')?.localeCompare((optionB?.label ?? '')?.toLowerCase())
            }
            onSelect={(value, data) => setSubjectSelect(data)}
            onClear={() => setSubjectSelect(undefined)}
            allowClear
            loading={isLoading}
            options={subjectByOrg}
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 6,
          }}
        >
          <Space>
            <div className="captcha-box">{captchaCode}</div>
            <Button
              type="primary"
              icon={<ReloadOutlined />}
              title={i18n.t('registerClass.reloadCaptcha')}
              onClick={handleRefreshCaptcha}
            />
          </Space>
          {/* <img ref={imgRef} src="" width={120} height={40} alt="" /> */}
        </Form.Item>
        <Form.Item
          label={'Captcha'}
          name="CAPTCHA"
          rules={[
            { required: true },
            {
              pattern: new RegExp(`^${captchaCode}$`, 'g'),
              message: i18n.t('registerClass.capchaNot'),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MutationRegistry;
