import axiosApiInstance from "../utils/axiosClient";

const customerStatusApi = {
  getAllCustomerStatuss: () => {
    const path = `/customerStatus/getAll`;
    return axiosApiInstance.get(path);
  },

};

export default customerStatusApi;
