import axiosApiInstance from '../utils/axiosClient';

const lessonManagementApi = {
  getAllLessons: () => {
    const path = `/lesson/getAll`;
    return axiosApiInstance.get(path);
  },
  getAllLessonBySubjectId: (id) => {
    const path = `/lesson/getLesson/${id}`;
    return axiosApiInstance.get(path);
  },
  getLessonByProgramSubjectName: (data) => {
    const path = `/lesson/getLessonByProgramSubjectName`;
    return axiosApiInstance.post(path, data);
  },
  // getModulesPermissionsByAppId: (appId) => {
  //   const path = `/module/getModulesPermissionsByAppId/${appId}`;
  //   return axiosApiInstance.get(path);
  // },
  getLesson: (id) => {
    const path = `/lesson/getLesson/${id}`;
    return axiosApiInstance.get(path);
  },
  createLesson: (data) => {
    const path = `/lesson/createLesson`;
    return axiosApiInstance.post(path, data);
  },
  updateLesson: (data, id) => {
    const path = `/lesson/updateLesson/${id}`;
    return axiosApiInstance.patch(path, data);
  },
  deleteLesson: (id) => {
    const path = `/lesson/deleteLesson/${id}`;
    return axiosApiInstance.delete(path);
  },
  // assignPermissionForModule: (id, data) => {
  //   const path = `/module/assign-permission-for-module/${id}`;
  //   return axiosApiInstance.post(path, data);
  // },
  // getPermissionsByModuleId: (moduleId) => {
  //   const path = `/module/getPermissionsByModuleId/${moduleId}}`;
  //   return axiosApiInstance.get(path);
  // },
};

export default lessonManagementApi;
