import React from 'react';
import { Col, Image, Row } from 'antd';
import {
  duytan,
  phudong,
  thebooks,
  mathnasium,
  robokids,
  robotacon,
  aiot_hackathon,
  fpt12,
} from '../../../assets/images';
import i18n from '../../../lib/Language';

const ImageEvent = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <div class="section background-opacity page-title set-height-top">
              <div class="container">
                <div class="page-title-wrapper">
                  <h2 class="captions"> {i18n.t('menu.community.imgEvent')}</h2>
                  <ol class="breadcrumb">
                    <li>
                      <a href="index-2.html">Home</a>
                    </li>
                    <li class="active">
                      <a href="#">Gallery</a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="section nav-subscribe" style={{ display: 'none' }}>
              <div class="container">
                <div class="nav-subscribe-wrapper">
                  <div class="nav-subscribe-left">
                    <p class="subscribe-text">
                      Sự kiện <b class="focus">STEM SQUARE</b> dd
                    </p>
                  </div>
                  <div class="nav-subscribe-right">
                    <button class="btn btn-green btn-bold">
                      <span>SUBSCRIBE NOW</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="section section-padding gallery-page gallery-mansonry">
              <div class="container">
                <div class="gallery-nav" style={{ display: 'flex', justifyContent: 'center' }}>
                  <ul
                    id="filters"
                    class="nav nav-tabs edugate-tabs button-group filters-button-group"
                    style={{ display: 'flex' }}
                  >
                    <li data-filter="*" data-category="all" class="tab active">
                      <div class="text">all</div>
                    </li>
                    {/* <li data-filter=".campus" data-category="campus" class="tab">
                      <div class="text">the books</div>
                    </li>
                    <li data-filter=".education" data-category="education" class="tab">
                      <div class="text">thư viện</div>
                    </li>
                    <li data-filter=".building" data-category="building" class="tab">
                      <div class="text">học tại lớp</div>
                    </li>
                    <li data-filter=".teacher" data-category="teacher" class="tab">
                      <div class="text">demo tại Toán A+</div>
                    </li> */}
                  </ul>
                </div>
                <Row gutter={[16, 16]} justify="center">
                  <Col span={8}>
                    <Image width="100%" src={duytan} preview={false} />
                  </Col>
                  <Col span={8}>
                    <Image width="100%" src={phudong} preview={false} />
                  </Col>
                  <Col span={8}>
                    <Image width="100%" src={thebooks} preview={false} />
                  </Col>
                  <Col span={8}>
                    <Image width="100%" src={mathnasium} preview={false} />
                  </Col>
                  <Col span={8}>
                    <Image width="100%" src={robokids} preview={false} />
                  </Col>
                  <Col span={8}>
                    <Image width="100%" src={robotacon} preview={false} />
                  </Col>
                  <Col span={8}>
                    <Image width="100%" src={aiot_hackathon} preview={false} />
                  </Col>
                  <Col span={8}>
                    <Image width="100%" src={fpt12} preview={false} />
                  </Col>
                </Row>

                <div class="load-more">
                  <button class="btn btn-green">
                    <span>{i18n.t('general.button.seeMore')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="back-top">
        <a href="#top">
          <i class="fa fa-angle-double-up"></i>
        </a>
      </div>
    </div>
  );
};

export default ImageEvent;
