import axiosApiInstance from "../utils/axiosClient";

const courseApi = {
  getAllCourses: () => {
    const path = `/course/getAll`;
    return axiosApiInstance.get(path);
  },
  getCourse: (id) => {
    const path = `/course/getCourse/${id}`;
    return axiosApiInstance.get(path);
  },
  createCourse: (data) => {
    const path = `/course/createCourse`;
    return axiosApiInstance.post(path, data);
  },
  updateCourse: (data, id) => {
    const path = `/course/updateCourse/${id}`;
    return axiosApiInstance.patch(path, data);
  },
  deleteCourse: (id) => {
    const path = `/course/deleteCourse/${id}`;
    return axiosApiInstance.delete(path);
  },
};

export default courseApi;
