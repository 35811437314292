import { atom, selector } from 'recoil';
import { selectOptions } from '../../common';
import { orgStemSquareState } from './orgState';

const subjectState = atom({
  key: 'subjectState',
  default: [],
});

const subjectOptionState = selector({
  key: 'subjectOptionState',
  get: ({ get }) => {
    const subjects = get(subjectState);
    if (subjects?.length) {
      return selectOptions(subjects);
    }
    return [];
  },
});

const subjectByOrgState = selector({
  key: 'subjectByOrgState',
  get: ({ get }) => {
    const subjects = get(subjectOptionState);
    const orgStemSquare = get(orgStemSquareState);
    if (subjects?.length && orgStemSquare) {
      return subjects?.filter((item) => item?.Program?.ORG_ID === orgStemSquare?.id);;
    } else {
      return [];
    }
  },
});

export { subjectState, subjectOptionState, subjectByOrgState };
