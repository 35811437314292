import axiosApiInstance from "../utils/axiosClient";

const customerContactApi = {

  create: (data) => {
    const path = `/contact/createContact`;
    return axiosApiInstance.post(path, data);
  },
  assignContactIdsToCustomer: (data) => {
    const path = `/contact/assignContactIdsToCustomer`;
    return axiosApiInstance.post(path, data);
  },
  unassignContactIdsFromCustomer: (data) => {
    const path = `/contact/unassignContactIdsFromCustomer`;
    return axiosApiInstance.post(path, data);
  },

};

export default customerContactApi;

