import React, { useState } from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import { Tabs } from 'antd';
import StemBooks from '../../../components/Library/StemBooks';
import {
  robotic_level_1,
  robotic_level_2,
  robotic_level_3,
  robotic_level_4,
  robotic_level_5,
  robotic_level_6,
  robotic_level_7,
  robotic_level_8,
  robotic_level_9,
  robotic_level_10,
  WRO,
  AutomationTest,
  BlockchainForDev,
  ManualTesting,
  Nodejs,
  Python_L1,
  Python_L2,
  ReactJs,
  Scratchs_L1,
  Scratchs_L2,
  STEMforUni,
} from '../../../assets/images';

const robotic = [
  { img: `${robotic_level_1}` },
  { img: `${robotic_level_2}` },
  { img: `${robotic_level_3}` },
  { img: `${robotic_level_4}` },
  { img: `${robotic_level_5}` },
  { img: `${robotic_level_6}` },
  { img: `${robotic_level_7}` },
  { img: `${robotic_level_8}` },
  { img: `${robotic_level_9}` },
  { img: `${robotic_level_10}` },
];
const wros = [{ img: `${WRO}` }];
const it_img = [
  { img: `${AutomationTest}` },
  { img: `${BlockchainForDev}` },
  { img: `${ManualTesting}` },
  { img: `${Nodejs}` },
  { img: `${Python_L1}` },
  { img: `${Python_L2}` },
  { img: `${ReactJs}` },
  { img: `${Scratchs_L1}` },
  { img: `${Scratchs_L2}` },
  { img: `${STEMforUni}` },
];

const StemBook = () => {
  const [items, setItems] = useState(12);
  const onChange = () => {
    setItems(12);
  };

  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Breadcrumb address="STEM BOOKS" parent="Books" />
            <div class="section nav-subscribe" style={{ display: 'none' }}>
              <div class="container">
                <div class="nav-subscribe-wrapper">
                  <div class="nav-subscribe-left">
                    <p class="subscribe-text">
                      Books <b class="focus">STEM SQUARE</b>
                    </p>
                  </div>
                  <div class="nav-subscribe-right">
                    <button class="btn btn-green btn-bold">
                      <span>SUBSCRIBE NOW</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="section section-padding gallery-page gallery-mansonry">
              <div class="container">
                <div class="gallery-nav" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Tabs
                    defaultActiveKey="1"
                    centered
                    onChange={onChange}
                    items={[
                      {
                        key: '1',
                        label: `All`,
                        children: (
                          <StemBooks listImg={[...it_img, ...wros, ...robotic]} items={items} setItems={setItems} />
                        ),
                      },
                      {
                        key: '2',
                        label: `Robotic's Books`,
                        children: <StemBooks listImg={robotic} items={items} setItems={setItems} />,
                      },
                      {
                        key: '3',
                        label: `WRO's Books`,
                        children: <StemBooks listImg={wros} items={items} setItems={setItems} />,
                      },
                      {
                        key: '4',
                        label: `Lego's Books`,
                        children: <StemBooks items={items} setItems={setItems} />,
                      },
                      {
                        key: '5',
                        label: `IT Books`,
                        children: <StemBooks listImg={it_img} items={items} setItems={setItems} />,
                      },
                    ]}
                  />
                </div>
                {/* {items > 12 && (
                  <div class="load-more">
                    <button class="btn btn-green" onClick={() => setItems(items + 12)}>
                      <span>Xem thêm</span>
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="back-top">
        <a href="#top">
          <i class="fa fa-angle-double-up"></i>
        </a>
      </div>
    </div>
  );
};

export default StemBook;
