import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useEduDashboard } from '../../../hooks/eduDashboard';
import { countTeacherCourseStudentBranchState } from '../../../recoil/atom/dashBoardState';
import MutationRegistry from '../../../components/Courses/MutationRegistry';
import i18n from '../../../lib/Language';

const ProcessBars = () => {
  useEduDashboard();
  const coutData = useRecoilValue(countTeacherCourseStudentBranchState);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="section progress-bars section-padding">
      <div className="container">
        <div className="progress-bars-content">
          <div className="progress-bar-wrapper">
            <h2 className="title-2">STEM SQUARE EDUCATION</h2>

            <div className="row">
              <div className="content">
                <div className="col-sm-3">
                  <div className="progress-bar-number">
                    <div data-from="0" data-to="15" data-speed="1000" className="num">
                      {coutData?.teacherTotal}
                    </div>
                    <p className="name-inner">{i18n.t('homepage.processBar.teacher')}</p>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="progress-bar-number">
                    <div data-from="0" data-to="20" data-speed="1000" className="num">
                      {coutData?.courseTotal}
                    </div>
                    <p className="name-inner">{i18n.t('public.course')}</p>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="progress-bar-number">
                    <div data-from="0" data-to="250" data-speed="1000" className="num">
                      {coutData?.studentTotal}
                    </div>
                    <p className="name-inner">{i18n.t('homepage.processBar.member/student')}</p>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="progress-bar-number">
                    <div data-from="0" data-to="3" data-speed="1000" className="num">
                      {coutData?.branchTotal}
                    </div>
                    <p className="name-inner">{i18n.t('homepage.processBar.branch')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-button">
              <button style={{ display: 'none' }} onclick="#'" className="btn btn-transition-3">
                <span>Purchase theme</span>
              </button>
              <button
                onclick="window.location.href='https://docs.google.com/forms/d/e/1FAIpQLSf0Zfud7yTsA3tDNxnBO42SrB9x2RxmkdCKrPDQ1ffirGeeEw/viewform'"
                className="btn btn-green-3"
                onClick={() => setIsModalOpen(true)}
              >
                <span>{i18n.t('homepage.processBar.courseRegister')}</span>
              </button>

              {/* MODAL COURSE REGISTRY */}
              <MutationRegistry isShow={isModalOpen} onCancel={() => setIsModalOpen(false)} />
            </div>
            <div className="group-btn-slider">
              <div className="btn-prev">
                <i className="fa fa-angle-left"></i>
              </div>
              <div className="btn-next">
                <i className="fa fa-angle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessBars;
