import { useState } from "react";
import { useRecoilState } from "recoil";
import customerTypeApi from "../api/customerTypeApi";
import { customerTypeState } from "../recoil/atom/customerState";

export const useCustomerType = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [customerTypes, setCustomerTypes] = useRecoilState(customerTypeState);

  const getAllCustomerTypes = async () => {
    setIsLoading(true);
    try {
      let res = await customerTypeApi.getAllCustomerTypes();
      if (res.data) {
        setCustomerTypes(res.data.elements);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    customerTypes,
    getAllCustomerTypes,
    isLoading,
  };
};
