import i18n from '../../../lib/Language';
import { Collapse, Space, Typography } from 'antd';
import questionApi from '../../../api/questionApi';
import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import { questionState } from '../../../recoil/atom/questionState';
import { useRecoilState } from 'recoil';
// import { Link } from 'react-router-dom';
// import { ArrowRightOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
const { Paragraph } = Typography;

const FAQs = () => {
  const [activeKey, setActiveKey] = useState('1');
  const [listQuestion, setlistQuestion] = useRecoilState(questionState);

  const handleChangeKey = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    if (listQuestion?.length === 0) {
      (async () => {
        try {
          let res = await questionApi.getAllQuestions();
          if (res.data) {
            setlistQuestion(res.data?.elements);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Breadcrumb address={i18n.t('FLLs.FAQ')} parent="FAQ" />

            {/*  */}
            <div class="section section-padding news-page">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <h2 style={{ fontWeight: 600, marginBottom: 30 }}>{i18n.t('FLLs.FAQ')}</h2>
                  </div>
                  <div class="col-md-12">
                    <Collapse
                      accordion
                      expandIconPosition="end"
                      activeKey={activeKey}
                      onChange={handleChangeKey}
                      className="paragrap-question"
                    >
                      {listQuestion?.map((item) => (
                        <Panel
                          header={
                            <>
                              <span style={{ fontSize: 16, fontWeight: 600 }}>{item.NAME}</span>
                            </>
                          }
                          key={item.key}
                        >
                          {item?.Answers?.map((answer) => (
                            <div style={{ marginLeft: 25 }}>
                              <Paragraph
                                style={{
                                  whiteSpace: 'pre-wrap',
                                  marginBottom: 20,
                                }}
                              >
                                {answer.NAME}
                              </Paragraph>
                              <Paragraph>{answer.DESC}</Paragraph>
                              {/* <Link to={`/faq/faq_view/${item.id}`} className="text-link ">
                                <Space>
                                  {i18n.t('general.button.seeMore')}
                                  <ArrowRightOutlined />
                                </Space>
                              </Link> */}
                            </div>
                          ))}
                        </Panel>
                      ))}
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
