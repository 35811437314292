import { atom, selector } from "recoil";
import { selectOptions } from "../../common";

export const gradeState = atom({
  key: "gradeState",
  default: [],
});

export const gradeOptionState = selector({
  key: 'gradeOptionState',
  get: ({ get }) => {
    const grades = get(gradeState);
    if (grades?.length) {
      return selectOptions(grades);
    }
    return [];
  },
});
