import React, { useEffect, useMemo, useRef, useState } from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import { Col, Input, Row, Select, Typography } from 'antd';
import ListCourse from '../../../components/Courses/ListCourse';
import { useRecoilValue } from 'recoil';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import { subjectByOrgState } from '../../../recoil/atom/subjectState';
import { useEduDashboard } from '../../../hooks/eduDashboard';
import { countTeacherCourseStudentBranchState } from '../../../recoil/atom/dashBoardState';
import { removeAccents } from '../../../common';
import { useSearch } from 'react-use-search';
import i18n from '../../../lib/Language';
import { useOrg } from '../../../hooks/org';
import { useCourse } from '../../../hooks/course';
import { useProgram } from '../../../hooks/program';
import { useSubject } from '../../../hooks/subject';
import { useGrade } from '../../../hooks/grade';

const { Text } = Typography;

const predicate = (item, query) => {
  const newQuery = removeAccents(String(query)).toLowerCase().trim();
  const NAME = removeAccents(String(item?.NAME)).toLowerCase().trim();
  const COURSE_NAME = removeAccents(String(item?.COURSE_NAME)).toLowerCase().trim();
  return NAME.includes(newQuery) || COURSE_NAME.includes(newQuery);
};

const Course = () => {
  const { getAllPrograms, programs } = useProgram();
  const { getAllCourses, courses } = useCourse();
  const { getAllSubjects, subjects, isLoading } = useSubject();
  const { getAllGrade, grades } = useGrade();
  const [queryByProgram, setQueryByProgram] = useState('ALL');
  const [query, setQuery] = useState('ALL');

  // org
  useOrg();
  const subjectByOrg = useRecoilValue(subjectByOrgState);

  useEduDashboard();
  const coutData = useRecoilValue(countTeacherCourseStudentBranchState);
  const courseRef = useRef(null);

  useEffect(() => {
    //   GET ALL PROGRAM
    if (programs?.length === 0) {
      getAllPrograms();
    }
    // GET ALL COURSES
    if (courses?.length === 0) {
      getAllCourses();
    }
    // GET ALL SUBJECT
    if (subjects?.length === 0) {
      getAllSubjects();
    }
  }, []);

  // GET ALL GRADE
  const handleGetGrade = () => {
    if (grades?.length === 0) {
      getAllGrade();
    }
  };

  // GET ALL SUBJECT ID BY COURSES
  const subjectIdList = useMemo(() => {
    return courses?.map((course) => course?.SUBJECT_ID);
  }, [courses]);

  // FILTER SUBJECT BY COURSE
  const data = useMemo(() => {
    const tmp = subjectByOrg?.filter((subject) => subjectIdList?.includes(subject.id));
    const newData = [];
    tmp?.forEach((subject) => {
      courses?.forEach((course) => {
        if (subject?.id === course?.SUBJECT_ID) {
          return newData.push({
            COURSE_ID: course?.id,
            COURSE_CD: course?.COURSE_CD,
            COURSE_NAME: course?.NAME,
            LEARN_START_DATE: course?.LEARN_START_DATE,
            LEARN_END_DATE: course?.LEARN_END_DATE,
            GRADE_ID: course?.Grade?.id,
            GRADE_NAME: course?.Grade?.NAME,
            ...subject,
          });
        }
      });
    });
    return newData?.sort((a, b) => a.INDEX - b.INDEX);
  }, [subjectByOrg, subjectIdList, courses]);

  // SEARCH SUBJECT BY PROGRAM
  const filterSubjectsByProgram = useMemo(() => {
    if (queryByProgram !== 'ALL') {
      const tmp = data?.filter((item) => item?.PROGRAM_ID === queryByProgram);
      return tmp;
    } else {
      return data;
    }
  }, [queryByProgram, data, subjects]);

  // SEARCH SUBJECT BY COURSE GRADE
  const subjectsByGrade = useMemo(() => {
    if (query !== 'ALL') {
      return filterSubjectsByProgram?.filter((item) => item?.GRADE_ID === query);
    } else {
      return filterSubjectsByProgram;
    }
  }, [query, filterSubjectsByProgram, courses]);

  // FILTER SUBJECT BY SEARCH
  const [filteredSubjectsBySearch, searchQuery, handleChange] = useSearch(subjectsByGrade, predicate, {
    debounce: 500,
  });

  //
  const courseCount = useMemo(() => {
    if (searchQuery) {
      return filteredSubjectsBySearch?.length;
    } else {
      return subjectsByGrade?.length;
    }
  }, [searchQuery, filteredSubjectsBySearch, subjectsByGrade]);

  //
  const newProgram = useMemo(() => {
    return [...programs, { NAME: 'Tất cả', id: 'ALL' }]?.sort((a, b) => a.id - b.id);
  }, [programs]);

  //set active action
  const active = useMemo(() => {
    return queryByProgram;
  }, [queryByProgram]);

  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div className="main-content">
          <div className="content">
            {/* BREADCRUMB */}
            <Breadcrumb address={i18n.t('coursesStem')} parent={i18n.t('public.course')} />
            <div className="section section-padding courses">
              <div className="container ">
                <div className="group-title-index" style={{ marginBottom: 30 }}>
                  <h2 className="center-title">{i18n.t('public.course')}</h2>
                  <h4 className="top-title">
                    {i18n.t('sourses.on')}{' '}
                    <b>
                      {coutData?.courseTotal} {i18n.t('sourses.cousreTo')} {coutData?.studentTotal}{' '}
                      {i18n.t('sourses.studentStem')}
                    </b>{' '}
                    <b>
                      {coutData?.courseTotal} {i18n.t('sourses.courseOnline')}
                    </b>
                  </h4>
                </div>
                <div className="grid-tag-program">
                  {newProgram?.map((item) => (
                    <div
                      className={`tag-program ${active === item.id && 'active-program'}`}
                      onClick={() => setQueryByProgram(item?.id)}
                    >
                      {item.NAME}
                    </div>
                  ))}
                </div>

                <div className="courses-wrapper" style={{ textAlign: 'left' }}>
                  <Row gutter={[12, 12]}>
                    <Col xs={24} sm={10}>
                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Text style={{ textTransform: 'uppercase', fontSize: 12, whiteSpace: 'nowrap' }}>
                          {i18n.t('general.ipSearch')}:
                        </Text>
                        <Input
                          value={searchQuery}
                          style={{
                            padding: 3,
                            paddingLeft: 8,
                            borderRadius: 6,
                            marginLeft: 6,
                          }}
                          placeholder={i18n.t('sourses.searchTopics')}
                          prefix={<SearchOutlined />}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col xs={24} sm={14}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <FilterFilled />
                        <Select
                          style={{ textAlign: 'left', marginLeft: 6 }}
                          value={queryByProgram}
                          size="middle"
                          onChange={setQueryByProgram}
                          className="fw-mb"
                          popupClassName="menu-dropdown"
                        >
                          <Select.Option key={'ALL'} value={'ALL'}>
                            {i18n.t('sourses.allCourses')}
                          </Select.Option>
                          {programs &&
                            programs?.length > 0 &&
                            programs?.map((program) => (
                              <Select.Option key={program?.id} value={program?.id}>
                                {`${program?.NAME}`}
                              </Select.Option>
                            ))}
                        </Select>
                        <Select
                          style={{ textAlign: 'left', marginLeft: 6 }}
                          size="middle"
                          value={query}
                          onChange={setQuery}
                          className="fw-mb"
                          popupClassName="menu-dropdown"
                          onFocus={() => handleGetGrade()}
                        >
                          <Select.Option key={'ALL'} value={'ALL'}>
                            {i18n.t('sourses.allAge')}
                          </Select.Option>
                          {grades &&
                            grades?.length > 0 &&
                            grades?.map((grade) => (
                              <Select.Option key={grade?.id} value={grade?.id}>
                                {`${grade?.NAME}`}
                              </Select.Option>
                            ))}
                        </Select>
                      </div>
                    </Col>
                  </Row>

                  <Typography.Text style={{ fontSize: 16 }}>
                    {i18n.t('sourses.have')} <strong>{courseCount}</strong> {i18n.t('public.course')}
                  </Typography.Text>

                  {/* COURSE LIST */}
                  <div className="tab-content courses-content" ref={courseRef}>
                    <div className="tab-pane fade in active">
                      <div className="style-show style-grid row ">
                        <ListCourse
                          data={searchQuery ? filteredSubjectsBySearch : subjectsByGrade}
                          isLoading={isLoading}
                          pageSize={6}
                          courseRef={courseRef}
                        />

                        {/* <nav className="pagination col-md-12">
                          <ul className="pagination__list">
                            <li>
                              <a
                                rel="prev"
                                href="#"
                                className="pagination__previous btn-squae disable"
                              >
                                &#8249;
                              </a>
                            </li>
                            <li>
                              <span className="pagination__page btn-squae active">
                                1
                              </span>
                            </li>
                            <li>
                              <a
                                rel="next"
                                href="#"
                                className="pagination__next btn-squae"
                              >
                                &#8250;
                              </a>
                            </li>
                          </ul>
                        </nav> */}
                      </div>
                    </div>
                  </div>
                  {/* END COURSE LIST */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
