import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

const BannerSlider = () => {
  return (
    <div className="section slider-banner">
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, EffectFade]}
        pagination={{
          clickable: true,
        }}
        effect={'fade'}
        grabCursor={true}
        className="slider-banner"
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <div className="slider-item">
            <div className="slider-1">
              <div class="slider-caption">
                <div class="container">
                  {/* <h5 class="text-info-2">You only have to know one thing</h5>

                  <h1 class="text-info-1">You can learn anything</h1>

                  <p class="text-info-3">For free. For everyone</p>
                  <button class="btn btn-green">
                    <span>Start learning now !</span>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-item">
            <div className="slider-2">
              <div className="slider-caption">
                <div className="container">
                  {/* <h5 className="text-info-2">You only have to know one thing</h5>

                  <h1 className="text-info-1">You can learn anything</h1>

                  <p className="text-info-3">For free. For everyone</p>
                  <button className="btn btn-green">
                    <span>Start learning now !</span>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-item">
            <div className="slider-3">
              <div className="slider-caption">
                <div className="container">
                  {/* <h5 className="text-info-2">You only have to know one thing</h5>

                  <h1 className="text-info-1">You can learn anything</h1>

                  <p className="text-info-3">For free. For everyone</p>
                  <button className="btn btn-green">
                    <span>Start learning now !</span>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-item">
            <div className="slider-4">
              <div className="slider-caption">
                <div className="container">
                  {/* <h5 className="text-info-2">You only have to know one thing</h5>

                  <h1 className="text-info-1">You can learn anything</h1>

                  <p className="text-info-3">For free. For everyone</p>
                  <button className="btn btn-green">
                    <span>Start learning now !</span>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default BannerSlider;
