import React from 'react';
import BackToTop from '../../components/Global/BackToTop';
import BannerSlider from '../../components/Home/BannerSlider';
import BestStaff from '../../components/Home/BestStaff';
import ChooseCourse from '../../components/Home/ChooseCourse';
import Consulting from '../../components/Home/Consulting';
import Pricing from '../../components/Home/Pricing';
import ProcessBars from '../../components/Home/ProcessBars';
import SliderTaklAboutUs from '../../components/Home/SliderTaklAboutUs';
import TopCourses from '../../components/Home/TopCourses';
import WhyChooseUs from '../../components/Home/WhyChooseUs';

const Homepage = () => {
  return (
    <>
      <div id="wrapper-content">
        <div id="page-wrapper">
          <div class="main-content">
            <div class="content">
              <BannerSlider />
              <ChooseCourse />
              <ProcessBars />
              <TopCourses />
              <WhyChooseUs />
              <Pricing />
              <SliderTaklAboutUs />
              <Consulting />
              <BestStaff />
            </div>
          </div>
          {/* <BackToTop /> */}
        </div>
      </div>
    </>
  );
};

export default Homepage;
