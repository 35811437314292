import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import { useRecoilValue } from 'recoil';
import { subjectByOrgState } from '../../../recoil/atom/subjectState';
import ListSimilar from '../../../components/Courses/ListSimilar';
import ListOther from '../../../components/Courses/ListOther';
import { useSubject } from '../../../hooks/subject';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { Autoplay } from 'swiper';
import MutationRegistry from '../../../components/Courses/MutationRegistry';
import { useEmployee } from '../../../hooks/employee';
import { listEmployeeFEState } from '../../../recoil/atom/employeeState';
import { ROUTER } from '../../../constant/router';
import { useGeneralSetting } from '../../../hooks/generalSettings';
import { generalSettingState } from '../../../recoil/atom/generalSettingState';
import parse from 'html-react-parser';
import ListLesson from '../../../components/Courses/ListLesson';
import { useLesson } from '../../../hooks/lessonManagement';
import { useCourse } from '../../../hooks/course';
import { formatDateToString } from '../../../common';
import { useCourseFile } from '../../../hooks/courseFile';
import { Col, Empty, Row, Space, Spin, Typography } from 'antd';
import { useOrg } from '../../../hooks/org';

const { Text } = Typography;

const CourseDetail = () => {
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subjectId, setSubjectId] = useState(false);
  useEmployee();
  useGeneralSetting();
  const listEmployee = useRecoilValue(listEmployeeFEState);
  const generalSetting = useRecoilValue(generalSettingState);
  const { getLessonByProgramSubjectName, lessonByQueryData, isLoading } = useLesson();
  const { getCourseFileByCourseId, courseFileByCourseId, isLoading: isLoadingCourseFile } = useCourseFile();
  const [lessonList, setLessonList] = useState([]);

  const [imagesTool, setImagesTool] = useState([]);
  const [imagesLearning, setImagesLearning] = useState([]);
  const { getAllCourses, courses, getCourse, course } = useCourse();
  const { getAllSubjects, subjects, getSubject, subject } = useSubject();

  // org
  useOrg();
  const subjectByOrg = useRecoilValue(subjectByOrgState);

  // GET SUBJECT BY ID
  useEffect(() => {
    if (id) {
      getCourse(+id);
      getCourseFileByCourseId(+id);
    }
  }, [id]);

  useEffect(() => {
    if (courseFileByCourseId?.length > 0) {
      const imgsTool = courseFileByCourseId.filter((item) => item.TYPE === 1);
      const imgsLearning = courseFileByCourseId.filter((item) => item.TYPE === 3);

      setImagesTool(imgsTool);
      setImagesLearning(imgsLearning);
    }
  }, [courseFileByCourseId]);

  // GET SUBJECT BY ID
  useEffect(() => {
    if (course) {
      getSubject(course?.SUBJECT_ID);
    }
  }, [course]);

  useEffect(() => {
    // GET ALL SUBJECT
    if (subjects?.length === 0) {
      getAllSubjects();
    }
    // GET ALL COURSES
    if (courses?.length === 0) {
      getAllCourses();
    }
  }, []);

  // GET ALL SUBJECT ID BY COURSES
  const subjectIdList = useMemo(() => {
    return courses?.map((course) => course?.SUBJECT_ID);
  }, [courses]);

  // FILTER SUBJECT BY COURSE
  const data = useMemo(() => {
    const tmp = subjectByOrg?.filter((subject) => subjectIdList?.includes(subject.id));
    const newData = [];
    tmp?.forEach((subject) => {
      courses?.forEach((course) => {
        if (subject?.id === course?.SUBJECT_ID) {
          return newData.push({
            COURSE_ID: course?.id,
            COURSE_CD: course?.COURSE_CD,
            COURSE_NAME: course?.NAME,
            LEARN_START_DATE: course?.LEARN_START_DATE,
            LEARN_END_DATE: course?.LEARN_END_DATE,
            GRADE_ID: course?.Grade?.id,
            GRADE_NAME: course?.Grade?.NAME,
            ...subject,
          });
        }
      });
    });
    const sortData = newData.sort((a, b) => a.INDEX - b.INDEX);
    return sortData;
  }, [subjectByOrg, subjectIdList, courses]);

  // SIMILAR SUBJECTs
  const similar = useMemo(() => {
    return data?.filter((item) => item?.PROGRAM_ID === subject?.PROGRAM_ID && item?.id !== subject?.id).slice(0, 5);
  }, [data, course, subject]);

  // OTHER SUBJECTs
  const otherSubjects = useMemo(() => {
    return data?.filter((item) => item?.PROGRAM_ID === subject?.PROGRAM_ID && item?.id !== subject?.id)?.slice(0, 3);
  }, [data, subject]);

  const handleShowModal = (id) => {
    if (id) {
      setSubjectId(id);
      setIsModalOpen(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // GET LESSON LIST
  useEffect(() => {
    if (subject) {
      const params = {
        PROGRAM_ID: null,
        SUBJECT_ID: subject.id,
        LESSON_NAME: null,
      };

      getLessonByProgramSubjectName(params);
    }
  }, [subject]);

  useEffect(() => {
    if (lessonByQueryData?.length > 0) {
      const newData = lessonByQueryData?.map((item, i) => ({
        ...item,
        ORDER: i,
      }));
      setLessonList(newData);
    }
  }, [lessonByQueryData]);

  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div className="main-content">
          <div className="content">
            <Breadcrumb address={'Khóa học chi tiết'} parent={'Course'} />
            {/* SEARCH */}
            <div className="section" style={{ display: 'none' }}>
              <div className="search-input">
                <div className="container">
                  <div className="search-input-wrapper">
                    <form>
                      <select className="form-select style-1 selectbox">
                        <option value="all">all categories</option>
                        <option value="languages">languages</option>
                        <option value="science">science</option>
                      </select>
                      <select className="form-select style-2 selectbox">
                        <option value="price">price</option>
                        <option value="datetime">datetime</option>
                        <option value="teacher">teacher</option>
                      </select>
                      <input type="text" placeholder="Do you want to learn today?" className="form-input" />
                      <button type="submit" className="form-submit btn btn-blue">
                        <span>
                          search now<i className="fa fa-search"></i>
                        </span>
                      </button>
                      <div className="clearfix"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* END SEARCH */}

            <div className="section section-padding courses-detail event-detail-section">
              <div className="container">
                <div className="courses-detail-wrapper">
                  <div className="row">
                    <div className="col-md-9 layout-left">
                      <h1 className="event-detail-title" style={{ marginBottom: 20 }}>
                        {subject?.NAME}
                      </h1>
                      <p style={{ fontSize: 18, fontWeight: 700, color: '#000' }}>{course?.NAME}</p>

                      {/* COURSE INFO */}
                      <div className="course-info info info-event-detail">
                        <div className="row">
                          <div className="col-xs-6 col-sm-4">
                            <div className="note-time-block">
                              <span className="label-time">Khai giảng:</span>
                              <span className="note-time">
                                {course?.LEARN_START_DATE === ('' || null)
                                  ? 'Chưa có'
                                  : formatDateToString(new Date(course?.LEARN_START_DATE))}
                              </span>
                            </div>
                            <div className="note-time-block">
                              <span className="label-time">Kết thúc:</span>
                              <span className="note-time">
                                {course?.LEARN_END_DATE === ('' || null)
                                  ? 'Chưa có'
                                  : formatDateToString(new Date(course?.LEARN_END_DATE))}
                              </span>
                            </div>
                          </div>
                          <div className="col-xs-6 col-sm-4">
                            <div className="address-info">
                              <span className="label-time">Địa điểm học</span>
                              {course?.Branches &&
                                course.Branches?.length > 0 &&
                                course.Branches?.map((branch) => (
                                  <p>
                                    <a href="#">
                                      {branch?.NAME}
                                      <span>
                                        View map <i className="fa fa-angle-double-right"></i>
                                      </span>
                                    </a>
                                  </p>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* END COURSE INFO */}

                      <div className="event-detail-thumb">
                        <img src={subject?.IMAGE} alt="" className="img-responsive" />
                      </div>
                      <div className="event-detail-des">
                        <div className="course-des-title underline">Mô tả</div>
                        {/* PROGRAM DESCRIPTION TEXT */}
                        <div className="event-detail-des-content">
                          <p style={{ fontSize: 16 }}>
                            {!subject?.DESC ? 'Chưa có thông tin khóa học!' : subject?.DESC}
                          </p>
                        </div>
                        <div className="course-des-title underline">Chi tiết</div>
                        <div className="event-detail-des-content content-viewer">
                          {!subject?.CONTENT ? 'Chưa có nội dung khóa học!' : parse(String(subject?.CONTENT))}
                        </div>
                        {/* END PROGRAM DESCRIPTION TEXT*/}

                        {/* DOCUMENT */}
                        <div className="course-des-title underline">Tài liệu học</div>
                        <div>
                          {isLoading ? (
                            <Space direction="vertical" align="center" style={{ width: '100%', padding: 20 }}>
                              <Spin />
                            </Space>
                          ) : (
                            <ListLesson isLoading={isLoading} data={lessonList} />
                          )}
                        </div>
                        {/* END DOCUMENT */}
                        <br />

                        {/* TEACHING TOOL */}
                        <div className="course-des-title underline">Công cụ giảng dạy</div>

                        {isLoadingCourseFile && (
                          <Space direction="vertical" align="center" style={{ width: '100%', padding: 20 }}>
                            <Spin />
                          </Space>
                        )}

                        {!isLoadingCourseFile && imagesTool?.length === 0 && (
                          <Empty
                            style={{ width: '100%', padding: 20 }}
                            description={<Text strong>Khóa học này chưa có hình ảnh công cụ nào!</Text>}
                          />
                        )}

                        {imagesTool?.length > 0 &&
                          imagesTool.map((item) => (
                            <div className="event-detail-thumb" key={item?.id}>
                              <img src={item?.FILENAME} alt="" className="img-responsive" />
                            </div>
                          ))}

                        {/* END TEACHING TOOL */}

                        {/* OPEN CLASS */}
                        <div className="course-des-title underline">Lịch khai giảng</div>
                        <div>
                          <img
                            className="img-responsive"
                            // style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                            src={generalSetting?.COURSE_OPENING_IMAGE}
                            alt=""
                          />
                        </div>
                        {/* END OPEN CLASS */}

                        {/*  GALLERY */}
                        <div className="section section-padding gallery-page gallery-mansonry">
                          <div className="course-des-title underline">Hình ảnh lớp học</div>
                          {isLoadingCourseFile && (
                            <Space direction="vertical" align="center" style={{ width: '100%', padding: 20 }}>
                              <Spin />
                            </Space>
                          )}

                          {!isLoadingCourseFile && imagesLearning?.length === 0 && (
                            <Empty
                              style={{ width: '100%', padding: 20 }}
                              description={<Text strong>Khóa học này chưa có hình ảnh học tập nào!</Text>}
                            />
                          )}
                          <div>
                            <Row gutter={[16, 16]}>
                              {imagesLearning?.length > 0 &&
                                imagesLearning.map((item) => (
                                  <Col xs={24} sm={12} md={12} key={item?.id}>
                                    <a href={item?.FILENAME} target="_blank" rel="noreferrer">
                                      <img
                                        src={item?.FILENAME}
                                        alt=""
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                      />
                                    </a>
                                  </Col>
                                ))}
                            </Row>
                          </div>
                        </div>
                        {/* END GALLERY */}

                        {/* SUBJECT SUBCRIPTION */}
                        <div className="course-des-title underline">Đăng ký học</div>
                        <div>
                          <p>Quý phụ huynh quan tâm, vui lòng kích nút đăng ký bên dưới</p>
                          <button className="btn btn-green" type="button" onClick={() => handleShowModal(subject?.id)}>
                            <span>Đăng kí học</span>
                          </button>

                          {/* MODAL COURSE REGISTRY */}
                          <MutationRegistry isShow={isModalOpen} onCancel={handleCancel} subjectId={subjectId} />
                        </div>
                        {/* END SUBJECT SUBCRIPTION*/}
                        <br />

                        {/* TEACHER LIST */}
                        <div className="course-syllabus-title underline">Giáo viên giảng dạy</div>
                        <div className="event-detail-list-staff">
                          <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            autoplay={{
                              delay: 3500,
                              disableOnInteraction: true,
                            }}
                            modules={[Autoplay]}
                            breakpoints={{
                              580: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                              },
                              // 766: {
                              //   slidesPerView: 3,
                              //   spaceBetween: 20,
                              // },
                              992: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                              },
                            }}
                          >
                            {listEmployee && listEmployee?.length ? (
                              listEmployee?.slice(0, 9)?.map((item, index) => (
                                <SwiperSlide key={index}>
                                  <Link to={`${ROUTER.HOME.DETAILPROFILE.path}/${item?.id}`}>
                                    <div className="staff-item">
                                      <div className="staff-item-wrapper" style={{ marginBottom: 15 }}>
                                        <div className="staff-info">
                                          <p className="staff-avatar">
                                            <img src={item.User?.AVATAR} alt="" className="img-responsive" />
                                          </p>
                                          <p className="staff-name">
                                            {item.ACADEMIC_LEVEL +
                                              ' ' +
                                              item.User?.FIRST_NAME +
                                              ' ' +
                                              item.User?.LAST_NAME}
                                          </p>

                                          <div className="staff-job">
                                            {item.User?.Roles?.map((el) => (
                                              <span>{el.NAME}</span>
                                            ))}
                                          </div>
                                          <div className="staff-desctiption">
                                            {item.JOB_FULL_DESC}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="staff-socials">
                                        <a href="#" className="facebook">
                                          <i className="fa fa-facebook"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </Link>
                                </SwiperSlide>
                              ))
                            ) : (
                              <div>Hiên tại chưa có giáo viên giảng dạy.</div>
                            )}
                          </Swiper>
                        </div>
                        {/* END TEACHER LIST */}
                      </div>
                    </div>

                    {/* ASSIDE RIGHT */}
                    <div className="col-sm-12 col-md-3 sidebar layout-right">
                      {/* LIST SUBJECT BY PROGRAM */}
                      <div className="popular-course-widget widget sd380">
                        <div className="title-widget">Khóa học liên quan</div>
                        <div className="content-widget">
                          <ListSimilar data={similar} />
                        </div>
                      </div>
                      {/* <div className="row">
                        <div
                          className="author-widget widget col-sm-12 col-md-12 col-xs-6 sd380"
                          style={{ display: 'none' }}
                        >
                          <div className="title-widget">GV MAI VĂN TÙNG</div>
                          <div className="content-widget">
                            <div className="staff-item author-widget-wrapper customize">
                              <div className="staff-item-wrapper">
                                <div className="staff-info">
                                  <a href="#" className="staff-avatar">
                                    <img
                                      src="assets/images/Teacher 2 - Mai Van Tung.jpg"
                                      alt=""
                                      className="img-responsive"
                                    />
                                  </a>
                                  <a href="#" className="staff-name">
                                    MAI VĂN TÙNG
                                  </a>

                                  <div className="staff-job">head teacher</div>
                                  <div className="staff-desctiption">
                                    Đã có 15 năm giảng dạy STEM Robotics, hiện nay là trưởng nhóm giáo viên tại STEM
                                    SQUARE.
                                  </div>
                                </div>
                              </div>
                              <div className="staff-socials">
                                <a href="#" className="facebook">
                                  <i className="fa fa-facebook"></i>
                                </a>
                                <a href="#" className="google">
                                  <i className="fa fa-google-plus"></i>
                                </a>
                                <a href="#" className="twitter">
                                  <i className="fa fa-twitter"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="popular-course-widget widget col-sm-12 col-md-12 col-xs-6 sd380">
                          <div className="title-widget">Khóa học liên quan</div>
                          <div className="content-widget">
                            <ListSimilar data={similar} />
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div> */}
                    </div>

                    {/* END ASSIDE RIGHT */}
                  </div>
                </div>

                {/* RELATED SUBJECTS */}
                <div className="event-detail-related">
                  <div className="course-syllabus-title underline">Có lẽ bạn cũng quan tâm các khóa học khác?</div>
                  <div className="event-detail-content" style={{ paddingBottom: 30 }}>
                    <ListOther data={otherSubjects} />
                  </div>
                </div>
                {/* END RELATED SUBJECT */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="back-top">
        <a href="#top">
          <i className="fa fa-angle-double-up"></i>
        </a>
      </div>
    </div>
  );
};

export default CourseDetail;
