import React from 'react';
import i18n from '../../lib/Language';

const Contact = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <div class="section background-opacity page-title set-height-top">
              <div class="container">
                <div class="page-title-wrapper">
                  <h2 class="captions">{i18n.t('menu.contacts.contact')}</h2>
                  <ol class="breadcrumb">
                    <li>
                      <a href="index-2.html">Home</a>
                    </li>
                    <li class="active">
                      <a href="#">{i18n.t('menu.contacts.contact')}</a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="section nav-subscribe">
              <div class="container">
                <div class="nav-subscribe-wrapper">
                  <div class="nav-subscribe-left">
                    <p class="subscribe-text">
                      {i18n.t('menu.contacts.let')} <b class="focus">STEM SQUARE</b>{' '}
                      {i18n.t('menu.contacts.bringToYou')}
                    </p>
                  </div>
                  <div class="nav-subscribe-right">
                    <button class="btn btn-green btn-bold">
                      <span>SUBSCRIBE NOW</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="section section-padding contact-main">
              <div class="container">
                <div class="contact-main-wrapper">
                  <div class="row contact-method">
                    <div class="col-md-4">
                      <div class="method-item">
                        <i class="fa fa-map-marker"></i>

                        <div style={{ marginBottom: 40 }}>
                          <p class="sub">{i18n.t('menu.contacts.mainOffice')}</p>
                          <div class="detail">
                            <p>{i18n.t('menu.contacts.mainAddress')}</p>
                          </div>
                        </div>
                        <div>
                          <p class="sub">{i18n.t('menu.contacts.trainingOffice')}</p>
                          <div class="detail">
                            <p>{i18n.t('menu.contacts.trainingAddress')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="method-item">
                        <i class="fa fa-phone"></i>

                        <p class="sub">{i18n.t('menu.contacts.Callus')}</p>

                        <div class="detail">
                          <p>Hotline: 0971-234-042/ 0971-234-043</p>

                          <p>Mobile: 0913-111-576/ 0934-876-616</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="method-item">
                        <i class="fa fa-envelope"></i>

                        <p class="sub">{i18n.t('menu.contacts.connect')}</p>

                        <div class="detail">
                          <p>stemsquare.vn@gmail.com</p>

                          <p>www.stemsquare.vn</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div class="row contact-method" style={{ marginTop: 40 }}>
                    <div class="col-md-6">
                      <div class="method-item">
                        <i class="fa fa-medium"></i>

                        <p class="sub">{i18n.t('menu.contacts.payTuition')}</p>

                        <div class="detail">
                          <p>
                            {i18n.t('menu.contacts.method')} 1. {i18n.t('menu.contacts.payOffice')}
                          </p>

                          <p>
                            {i18n.t('menu.contacts.method')} 2. {i18n.t('menu.contacts.tranfer')}
                          </p>
                          <p>
                            {i18n.t('menu.contacts.accountNumber')}: <strong>0041 000 250 181</strong>
                          </p>
                          <p>{i18n.t('menu.contacts.accountOwner')}: TRƯƠNG QUỐC TUẤN</p>
                          <p>{i18n.t('menu.contacts.bank')} Vietcombank (VCB - CN Đà Nẵng)</p>
                          <p>
                            {i18n.t('menu.contacts.content')}
                            {': NGUYEN VAN A - < LOP > - HP < THANG/NAM >'}
                          </p>
                          <p>{i18n.t('menu.contacts.example')}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="method-item">
                        <i class="fa fa-info-circle"></i>

                        <p class="sub">{i18n.t('menu.contacts.infoCompany')}</p>

                        <div class="detail">
                          <p>{i18n.t('menu.contacts.nameCompany')}</p>

                          <p>
                            {i18n.t('menu.contacts.address')}: {i18n.t('menu.contacts.mainAddress')}
                          </p>
                          <p>
                            {i18n.t('menu.contacts.taxCode')}: <strong>0401933243</strong>
                          </p>
                          <p>Email: stemsquare.vn@gmail.com</p>
                          <p>{i18n.t('menu.contacts.phone')}: 0971234042</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <form class="bg-w-form contact-form">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="control-label form-label">
                            NAME <span class="highlight">*</span>
                          </label>
                          <input type="text" placeholder="" class="form-control form-input" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="control-label form-label">
                            EMAIL <span class="highlight">*</span>
                          </label>
                          <input type="text" placeholder="" class="form-control form-input" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="control-label form-label">PURPOSE</label>
                          <select class="form-control form-input selectbox">
                            <option value="">Please Select</option>
                            <option value="">example 1</option>
                            <option value="">example 2</option>
                            <option value="">example 3</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="control-label form-label">SUBJECT</label>
                          <input type="text" placeholder="" class="form-control form-input" />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="contact-question form-group">
                          <label class="control-label form-label">
                            HOW CAN WE HELP? <span class="highlight">*</span>
                          </label>
                          <textarea class="form-control form-input"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="contact-submit">
                      <button type="submit" class="btn btn-contact btn-green">
                        <span>SUBMIT CONTACT</span>
                      </button>
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
