import React from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
import BtnViewMore from '../../../components/Global/Button/BtnViewMore';
import Teacher from '../../../components/Global/Teacher';
import Courses from '../../../components/Public/News/Courses';
import StempRobotics from '../../../components/Public/News/StempRobotics';
import { fll_pd_team1, fll_ss_team1, fll_ss_team2, fll_2018, fll_pd_team2 } from '../../../assets/images';
import i18n from '../../../lib/Language';

const FLL2019 = () => {
  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Breadcrumb address="FLL - First Lego League" />
            <div class="section" style={{ display: 'none' }}>
              <div class="search-input">
                <div class="container">
                  <div class="search-input-wrapper">
                    <form>
                      <select class="form-select style-1 selectbox">
                        <option value="all">all categories</option>
                        <option value="languages">languages</option>
                        <option value="science">science</option>
                      </select>
                      <select class="form-select style-2 selectbox">
                        <option value="price">price</option>
                        <option value="datetime">datetime</option>
                        <option value="teacher">teacher</option>
                      </select>
                      <input type="text" placeholder="Do you want to learn today?" class="form-input" />
                      <button type="submit" class="form-submit btn btn-blue">
                        <span>
                          search now<i class="fa fa-search"></i>
                        </span>
                      </button>
                      <div class="clearfix"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div class="section section-padding courses-detail">
              <div class="container">
                <div class="courses-detail-wrapper">
                  <div class="row">
                    <div class="col-md-9 layout-left">
                      <h1 class="course-title">FLL - First Lego League</h1>
                      <div class="course-info info">
                        <div class="author item">
                          <a href="#">
                            <span>By&nbsp;</span>
                            <span>Lego Education</span>
                          </a>
                        </div>
                        <div class="date-time item">
                          <a href="#">20/06/2019</a>
                        </div>
                      </div>
                      <div class="course-video">
                        <iframe
                          width="1257"
                          height="704"
                          src="https://www.youtube.com/embed/O93gPjd0OKI"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <div class="video-btn">
                          <button class="btn btn-grey video-btn-right pull-right">
                            <span>next video</span>
                            <i class="fa fa-angle-right"></i>
                          </button>
                          <button class="btn btn-grey video-btn-left pull-right">
                            <i class="fa fa-angle-left"></i>
                            <span>previous video</span>
                          </button>
                        </div>
                        <div class="clearfix"></div>
                      </div>

                      <div class="course-des">
                        <div class="course-des-title underline">Giới thiệu FLL</div>
                        <div class="course-des-content">
                          <p>LEGO EDUCATION</p>{' '}
                        </div>
                        <p>STEM SQUARE</p>
                        <p>
                          First Lego League là cuộc thi quốc tế về lập trình và lắp ghép Robot Lego khu vực Châu Á Thái
                          Bình Dương. Cuộc thi là sự kiện toàn cầu về khoa học, kỹ thuật được tổ chức thường niên dành
                          cho các em học sinh từ 6-16 tuổi. Hàng năm cuộc thi này thu hút hơn 255.000 thí sinh, 32.000
                          đội thi và hơn 88 quốc gia tham dự.
                        </p>
                        Với mục đích tìm những ứng cử viên tài năng của Việt Nam tham gia giải thi đấu quốc tế này, Sở
                        Giáo dục và Đào tạo Hồ Chí Minh kết hợp cùng với các đơn vị tổ chức “Cuộc thi khoa học ứng dụng”
                        Năm 2017, cuộc thi lần đầu tiên được tổ chức tại Việt Nam với hơn 40 đội - 120 thí sinh tham dự.
                        Năm 2018 - cuộc thi đã quy tụ hơn 1200 thí sinh trong khắp cả nước. Đây là điều minh chứng cho
                        sức hút của các nội dung, thử thách của FLL dành cho các thí sinh tham dự.
                        <p>Cuộc thi quốc tế First Lego League được chia làm 2 bảng thi đấu:</p>
                        1. Bảng Trung học: Dành cho học sinh từ 9 – 16 tuổi - Nội dung thi đấu: Lập trình Robot hoàn
                        thành thử thách của chương trình. - Công cụ thi đấu: Lego Mindstorm
                        <p>2. Bảng Tiểu học: Dành cho học sinh từ 6 – 11 tuổi</p>- Nội dung thi đấu: Lắp ráp, lập trình
                        và trưng bày sản phẩm; Thuyết trình nêu ý tưởng. - Công cụ thi đấu: Lego WeDo
                        <p> Thời gian tổ chức:</p>
                        <p>- Tại Việt Nam: Vào tháng 1 hằng năm</p>
                        <p>- Tại Mỹ: Vào tháng 4 hằng năm</p>
                      </div>

                      <div class="image">
                        <img
                          class="img-responsive"
                          src={fll_pd_team1}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                      </div>
                      <br></br>
                      <div class="course-des-title underline">Thể lệ tham gia ngày hội</div>
                      <div>
                        <p>Đăng ký học tại STEM SQUARE từ tháng 08 & 09/2019</p>
                        <p>Hoặc mua thiết bị tại STEM SQUARE</p>
                      </div>
                      <br></br>
                      <div class="course-des-title underline">Hình thức tính điểm</div>
                      <div>Cẽ được công bố sau khi có thể lệ cụ thể từ LEGO EDUCATION</div>
                      <br></br>
                      <div class="course-des-title underline">Giải thưởng</div>
                      <div>
                        <p>Nếu đội vô địch sẽ được phần thưởng là 1 chiếc Samsung Galaxy/ trên mỗi học sinh.</p>
                        <p>Toàn đội sẽ được nhận thêm 60 triệu đồng tiền để hỗ trợ sang nước ngoài thi đấu</p>
                        <p>Đặc biệt là sẽ sang Mỹ thi đấu vào tháng 04/2020</p>
                      </div>
                      <br></br>
                      <div class="course-syllabus">
                        <div class="course-syllabus-title underline">Lịch trình FLL - 2020</div>
                        <div class="course-table">
                          <div class="outer-container">
                            <div class="inner-container">
                              <div class="table-header">
                                <table class="edu-table-responsive">
                                  <thead>
                                    <tr class="heading-table">
                                      <th class="col-1">Nội dung</th>
                                      <th class="col-2">Ngày</th>
                                      <th class="col-3">Chú ý</th>
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                              <div class="table-body">
                                <table class="edu-table-responsive table-hover">
                                  <tbody>
                                    <tr class="heading-content">
                                      <td colspan="4" class="left heading-content">
                                        1. Thi đấu tại Đà Nẵng
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Tập trung tại sân thi đấu</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>12/01/2020</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>7h00:8h00</span>
                                      </td>
                                      <td class="green-color col-4">
                                        <i class="w27 fa fa-check-square-o"></i>
                                        <span>Mở</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="bg-green mr25 fa fa-caret-right"></i>
                                          <span>Thi đấu</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>12/01/2020</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>8h00:15h00</span>
                                      </td>
                                      <td class="bold-color col-4">
                                        <i class="w27 fa fa-pencil-square-o"></i>
                                        <span>Mở</span>
                                      </td>
                                    </tr>
                                    <tr class="table-row">
                                      <td class="left col-1">
                                        <a href="#">
                                          <i class="mr18 fa fa-file-text"></i>
                                          <span>Phát thưởng</span>
                                        </a>
                                      </td>
                                      <td class="col-2">
                                        <i class="w20 fa fa-date-time"></i>
                                        <span>12/01/2020</span>
                                      </td>
                                      <td class="col-3">
                                        <i class="w20 fa fa-clock-o"></i>
                                        <span>15h30:16h30</span>
                                      </td>
                                      <td class="bold-color col-4">
                                        <i class="w27 fa fa-pencil-square-o"></i>
                                        <span>Mở</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      <div class="course-des-title underline">
                        Một số hình ảnh từ cuộc thi FLL 2019 của các đội STEM SQUARE giảng dạy
                      </div>
                      <div class="image">
                        <img
                          class="img-responsive"
                          src={fll_ss_team1}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                      </div>

                      <div class="image">
                        <img
                          class="img-responsive"
                          src={fll_ss_team2}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                      </div>

                      <div class="image">
                        <img
                          class="img-responsive"
                          src={fll_2018}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                      </div>

                      <div class="image">
                        <img
                          class="img-responsive"
                          src={fll_pd_team2}
                          style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                        />
                      </div>

                      <div class="course-video">
                        <iframe
                          width="1280"
                          height="720"
                          src="https://www.youtube.com/embed/eBdluaXAkqc"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <div class="clearfix"></div>
                      </div>
                      <BtnViewMore title={i18n.t('general.button.seeMore')} />

                      <div class="course-des-title underline">CHỦ ĐỀ CUỘC THI FLL 2020</div>
                      <div class="course-video">
                        <iframe
                          width="1280"
                          height="720"
                          src="https://www.youtube.com/embed/hmQJVlVU24E"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <div class="clearfix"></div>
                      </div>

                      <div class="course-des-title underline">Đăng ký khóa luyện thi FLL 2020</div>
                      <BtnViewMore title="Đăng ký khóa học" />

                      <div class="news-list">
                        <div class="list-expand-title">Các tin liên quan</div>
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-xs-6">
                            <ul class="list-unstyled list-detail">
                              <li>
                                <i class="fa fa-angle-right"></i>
                                <a href="#">Lợi ích của việc học STEM.</a>
                              </li>
                              <li>
                                <i class="fa fa-angle-right"></i>
                                <a href="#">Tư vấn chương trình học STEM.</a>
                              </li>
                              <li>
                                <i class="fa fa-angle-right"></i>
                                <a href="#">Sân chơi STEM, thi đấu Robotics</a>
                              </li>
                              <li>
                                <i class="fa fa-angle-right"></i>
                                <a href="#">Chất lượng giáo dục tại STEM SQUARE.</a>
                              </li>
                              <li>
                                <i class="fa fa-angle-right"></i>
                                <a href="#">Nhà phân phối LEGO EDUCATION.</a>
                              </li>
                            </ul>
                          </div>
                          <div class="col-md-6 col-sm-6 col-xs-6">
                            <ul class="list-unstyled list-detail">
                              <li>
                                <i class="fa fa-angle-right"></i>
                                <a href="#">Cuộc thi đấu Robokids 2019.</a>
                              </li>
                              <li>
                                <i class="fa fa-angle-right"></i>
                                <a href="#">Cuộc thi đấu Robotacon Quốc Gia 2019.</a>
                              </li>
                              <li>
                                <i class="fa fa-angle-right"></i>
                                <a href="#">Cuộc thi đấu WRO 2019 tại Hungary.</a>
                              </li>
                              <li>
                                <i class="fa fa-angle-right"></i>
                                <a href="#">FLL 2019 - Tại Đà nẵng và Tại Mỹ.</a>
                              </li>
                              <li>
                                <i class="fa fa-angle-right"></i>
                                <a href="#">FLL 2019 - Vinh quang trên đất Mỹ.</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 sidebar layout-right">
                      <div class="row">
                        <Teacher />
                        {/* <StempRobotics /> */}
                        {/* <Courses /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FLL2019;
