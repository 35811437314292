import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import sourceRegistrationApi from "../api/sourceRegistrationApi";
import { sourceRegistrationState } from "../recoil/atom/customerState";

export const useSourceRegistration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sourceRegistrations, setSourceRegistrations] = useRecoilState(sourceRegistrationState);

  const getAllSourceRegistrations = async () => {
    setIsLoading(true);
    try {
      let res = await sourceRegistrationApi.getAllSourceRegistrations();
      if (res.data) {
        setSourceRegistrations(res.data.elements);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    sourceRegistrations,
    getAllSourceRegistrations,
    isLoading,
  };
};
