import { useState } from 'react';
import courseFileApi from '../api/courseFileApi';

export const useCourseFile = () => {
  const [courseFile, setCourseFile] = useState([]);
  const [courseFileByCourseId, setCourseFileByCourseId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const getAllCourseFile = async () => {
    setIsLoading(true);
    try {
      let res = await courseFileApi.getAll();
      if (res.data) {
        setCourseFile(res.data.elements);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getCourseFileByCourseId = async (id, callback) => {
    setIsLoading(true);
    try {
      let res = await courseFileApi.getCourseFileByCourseId(id);
      if (res.data) {
        setCourseFileByCourseId(res.data.elements.courseFile);
        callback();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const createCourseFile = async (data, callback) => {
    setIsLoading(true);
    try {
      let res = await courseFileApi.createCourseFile(data);
      if (res.data) {
        // getAllCourseFile();
        // enqueueSnackbar(res.data.message, { variant: "success" });
        setIsLoading(false);
        callback();
        setCourseFile(undefined);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const updateCourseFile = async (data, id, callback) => {
    setIsLoading(true);
    try {
      let res = await courseFileApi.updateCourseFile(data, id);
      if (res.data) {
        getAllCourseFile();
        // enqueueSnackbar(res.data.message, { variant: "success" });
        setIsLoading(false);
        callback();
        setCourseFile(undefined);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteCourseFile = async (id, callback) => {
    setIsLoading(true);
    try {
      let res = await courseFileApi.deleteCourseFile(id);
      if (res.data) {
        getAllCourseFile();
        // enqueueSnackbar(res.data.message, { variant: "success" });
        callback();
        setError(undefined);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    courseFile,
    courseFileByCourseId,
    getAllCourseFile,
    getCourseFileByCourseId,
    createCourseFile,
    updateCourseFile,
    deleteCourseFile,
    isLoading,
    // error,
  };
};
