import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import employeeApi from "../api/employeeApi";
import { employeeFEState, employeeState } from "../recoil/atom/employeeState";

export const useEmployee = () => {
    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const [employeeList, setEmployeeList] = useRecoilState(employeeState)
    const [employeeFEList, setEmployeeFEList] = useRecoilState(employeeFEState)



    useEffect(() => {
        if (employeeList?.length === 0) {
            getAllEmployees();
        }

        if (employeeFEList?.length === 0) {
            getAllFEDisplay();
        }
    }, []);

    const getAllEmployees = async () => {
        // setIsLoading(true);
        // try {
        //     let res = await employeeApi.getAllEmployees();
        //     if (res.data) {
        //         setEmployees(res.data.elements);
        //         setEmployeeList(res.data.elements);
        //         setIsLoading(false);
        //     }
        // } catch (error) {
        //     setIsLoading(false);
        // }
    };

    const getAllFEDisplay = async () => {
        setIsLoading(true);
        try {
            let res = await employeeApi.getAllFEDisplay();
            if (res.data) {
                setEmployeeFEList(res.data.elements);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const getEmployee = async (id, callback) => {
        setIsLoading(true);
        try {
            let res = await employeeApi.getEmployee(id);
            if (res.data) {
                setEmployee(res.data.elements.employee);
                callback();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };
    const createEmployee = async (data, callback) => {
        setIsLoading(true);
        try {
            let res = await employeeApi.createEmployee(data);
            if (res.data) {
                getAllEmployees();
                setIsLoading(false);
                callback();
                setEmployee(undefined);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };
    const updateEmployee = async (data, id, callback) => {
        setIsLoading(true);
        try {
            let res = await employeeApi.updateEmployee(data, id);
            if (res.data) {
                getAllEmployees();
                setIsLoading(false);
                callback();
                setEmployee(undefined);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const deleteEmployee = async (id) => {
        setIsLoading(true);
        try {
            let res = await employeeApi.deleteEmployee(id);
            if (res.data) {
                getAllEmployees();
                setError(undefined);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    return {
        employees,
        employee,
        getAllEmployees,
        getEmployee,
        createEmployee,
        updateEmployee,
        deleteEmployee,
        setEmployee,
        isLoading,
        error,
    };
};
