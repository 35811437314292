import { Spin } from 'antd';
import React, { useMemo } from 'react';
import Breadcrumb from '../../../components/Global/Breadcrumb';
// import Pagination from '../../../components/Global/Pagination';
// import Courses from '../../../components/Public/News/Courses';
import EdugateLayout from '../../../components/Public/News/EdugateLayout';
import RecentNews from '../../../components/Public/News/RecentNews';
// import StempRobotics from '../../../components/Public/News/StempRobotics';
import { useNews } from '../../../hooks/news';
import { useEffect } from 'react';
import i18n from '../../../lib/Language';

const News = () => {
  const { isLoading, getAllByQuery, newss } = useNews();

  useEffect(() => {
    if (newss?.length === 0) {
      getAllByQuery();
    }
  }, []);

  // SORT NEWS BY CREATED DATE AND INDEX
  const newsByLatest = useMemo(() => {
    const sortData = newss?.slice()?.sort((a, b) => {
      if (a.HOT_NEWS) return -1;
      if (!a.HOT_NEWS) return 1;

      if (a?.INDEX > b?.INDEX) return 1;
      if (a?.INDEX < b?.INDEX) return -1;
    });
    return sortData;
  }, [newss]);

  return (
    <div id="wrapper-content">
      <div id="page-wrapper">
        <div class="main-content">
          <div class="content">
            <Breadcrumb address={i18n.t('FLLs.newsAndUpdate')} parent={i18n.t('menu.community.news')} />
            {/*  */}
            <div class="section nav-subscribe">
              <div class="container">
                <div class="nav-subscribe-wrapper">
                  <div class="nav-subscribe-left">
                    <p class="subscribe-text">
                      {i18n.t('menu.community.registerWith')} <b class="focus">STEM SQUARE </b>{' '}
                      {i18n.t('menu.community.reciveNews')}
                    </p>
                  </div>
                  <div class="nav-subscribe-right">
                    <button class="btn btn-green btn-bold">
                      <span>{i18n.t('menu.community.registerNoew')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}

            {/*  */}
            <div class="section section-padding news-page">
              <div class="container">
                <div class="row">
                  <div class="col-md-9">
                    {isLoading ? (
                      <Spin />
                    ) : (
                      <div class="news-page-wrapper">
                        {newsByLatest &&
                          newsByLatest?.length &&
                          newsByLatest.map((news, index) => <EdugateLayout key={index} news={news} />)}
                        {/* <Pagination /> */}
                      </div>
                    )}
                  </div>
                  <div class="col-md-3 sidebar layout-right">
                    <div class="row">
                      <RecentNews />
                      {/* <StempRobotics /> */}
                      {/* <Courses /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
