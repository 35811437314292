import axiosApiInstance from "../utils/axiosClient";

const programApi = {
  getAllPrograms: () => {
    const path = `/program/getAll`;
    return axiosApiInstance.get(path);
  },
  getProgram: (id) => {
    const path = `/program/getProgram/${id}`;
    return axiosApiInstance.get(path);
  },
  getCountSubjectByProgramId: (id) => {
    const path = `/program/getCountSubjectByProgramId/${id}`;
    return axiosApiInstance.get(path);
  },
  createProgram: (data) => {
    const path = `/program/createProgram`;
    return axiosApiInstance.post(path, data);
  },
  updateProgram: (data, id) => {
    const path = `/program/updateProgram/${id}`;
    return axiosApiInstance.patch(path, data);
  },
  deleteProgram: (id) => {
    const path = `/program/deleteProgram/${id}`;
    return axiosApiInstance.delete(path);
  },
};

export default programApi;
