import { Col, Row, Space } from 'antd';
import React from 'react';
import Categories from '../../../../components/Competition/Categories';
import {
  ConnectingTheWorld,
  RoboMissionHeader,
  RoboMission,
  FutureEngineers,
  FutureInnovators,
  RoboSports,
} from '../../../../assets/images';
import { Link } from 'react-router-dom';
import i18n from '../../../../lib/Language';

const WROIntroduction = () => {
  return (
    <Space direction="vertical" style={{ width: '100%' }} className="wro">
      <div className="slider-banner">
        <div className="slider-item">
          <div
            className="slider-1"
            style={{
              backgroundImage: `url(${RoboMissionHeader})`,
            }}
          >
            <div class="slider-caption">
              <div class="container"></div>
            </div>
          </div>
        </div>
      </div>

      <Categories />
      <div className="section section-padding">
        <div className="container">
          <div className="group-title-index">
            <h2 className="center-title">{i18n.t('WROs.introWRO')}</h2>
            <p className="text-lg">{i18n.t('WROs.contents')}</p>
            <div className="bottom-title">
              <i className="bottom-icon icon-icon-04"></i>
            </div>
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8}>
              <img src={ConnectingTheWorld} alt="" className="img-responsive" width={'100%'} />
            </Col>
            <Col xs={24} sm={16}>
              <div className="post-content">
                <p>
                  The WRO<sup>®</sup> theme for 2023 is Connecting the World.
                </p>
                <p class="p1">
                  In 2023 teams will learn about shipping logistics and about the infrastructure for our digital
                  technology. They will investigate how robots can help both shipping and digital technology
                  infrastructure to be more safe, more sustainable and more efficient.
                </p>
                <p>
                  At this page you can find the official international Games &amp; Rules for WRO 2022, the{' '}
                  <a href="https://wro-association.org/competition/questions-answers/">Q&amp;A</a> and more important
                  information about competing.
                </p>
                <p>
                  More information about our international events can be found{' '}
                  <a href="https://wro-association.org/competition/international-events/">here</a>.
                </p>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="wro-introduction-row-2">
            <Col xs={24} sm={12} md={9}>
              <div>
                <div className="post-content">
                  <p>
                    Be aware that in your country local competitions may follow slightly different rules. Always use the
                    rules your National Organizer provides. Please contact your National Organizer first when you have
                    questions relating to the games &amp; rules. Click on the button to find your National Organizer.
                  </p>
                </div>
                <Link to={''}>
                  <button
                    className="btn btn-bold"
                    style={{ background: 'linear-gradient(90deg, #e62243 0%,#c1267d 100%)' }}
                  >
                    <span class="fusion-button-text">Find National Organizer</span>
                  </button>
                </Link>
              </div>
            </Col>
            <Col xs={24} sm={12} md={15}>
              <div style={{ margin: '0 auto', textAlign: 'center' }}>
                <div className="video-shortcode">
                  <iframe
                    width="100%"
                    style={{ maxWidth: 600 }}
                    height="338"
                    src="https://www.youtube.com/embed/BnZ7pmDCBDs"
                    title="WRO Connecting The World"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="section pricing">
        <div style={{ padding: '80px 30px', backgroundColor: '#161026' }}>
          <div className="row">
            <div className="pricing-wrapper">
              <div className="col-sm-3">
                <div className="pricing-widget">
                  <div className="pricing-content">
                    <h3 className="pricing-title">{i18n.t('WROs.missionRobot')}</h3>
                    <ul className="pricing-list">
                      <li>
                        <img src={RoboMission} alt="" width="250" height="250" />
                      </li>
                    </ul>
                    <div className="pricing-button">
                      <a
                        target="_blank"
                        href="https://drive.google.com/drive/folders/14GNBUwcIudcc0jR8nK-FdpGSh89pPLKF"
                      >
                        {i18n.t('public.viewDetail')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="pricing-widget main">
                  <div className="pricing-content">
                    <h3 className="pricing-title">{i18n.t('WROs.futureBuilder')}</h3>

                    <ul className="pricing-list">
                      <li>
                        <img src={FutureInnovators} alt="" width="250" height="250" />
                      </li>
                    </ul>
                    <div className="pricing-button">
                      <a
                        target="_blank"
                        href="https://drive.google.com/drive/folders/1JtTz51e3sy5Cw3zvOF9TPNMYrQ6R5sHO"
                      >
                        {i18n.t('public.viewDetail')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="pricing-widget">
                  <div className="pricing-content">
                    <h3 className="pricing-title">{i18n.t('WROs.sportRobot')}</h3>

                    <ul className="pricing-list">
                      <li>
                        <img src={RoboSports} alt="" width="250" height="250" />
                      </li>
                    </ul>
                    <div className="pricing-button">
                      <a
                        target="_blank"
                        href="https://drive.google.com/drive/folders/17o8aJQi1VwVdcQfjvbjJxtFp8Q_WG5ku"
                      >
                        {i18n.t('public.viewDetail')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="pricing-widget">
                  <div className="pricing-content">
                    <h3 className="pricing-title">{i18n.t('WROs.basicRObot')}</h3>

                    <ul className="pricing-list">
                      <li>
                        <img src={FutureEngineers} alt="" width="250" height="250" />
                      </li>
                    </ul>
                    <div className="pricing-button">
                      <a
                        target="_blank"
                        href="https://drive.google.com/drive/folders/13pNwKvBcNesqiRhFO_PZSL3uTeeuxMYa"
                      >
                        {i18n.t('public.viewDetail')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="group-btn-slider">
          <div className="btn-prev">
            <i className="fa fa-angle-left"></i>
          </div>
          <div className="btn-next">
            <i className="fa fa-angle-right"></i>
          </div>
        </div> */}
        </div>
      </div>
    </Space>
  );
};

export default WROIntroduction;
