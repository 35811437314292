import axiosApiInstance from "../utils/axiosClient";

const studentAttendanceStatusesApi = {
    getAllStudentAttendanceStatuses: () => {
        const path = `/studentAttendanceStatus/getAll`;
        return axiosApiInstance.get(path);
    },
    // getClass: (id) => {
    //     const path = `/class/getClass/${id}`;
    //     return axiosApiInstance.get(path);
    // },
    // getAllStudentByClass: (id) => {
    //     const path = `/class/getAllStudentByClassId/${id}`;
    //     return axiosApiInstance.get(path);
    // },
    // createClass: (data) => {
    //     const path = `/class/createClass`;
    //     return axiosApiInstance.post(path, data);
    // },
    // updateClass: (data, id) => {
    //     const path = `/class/updateClass/${id}`;
    //     return axiosApiInstance.patch(path, data);
    // },
    // deleteClass: (id) => {
    //     const path = `/class/deleteClass/${id}`;
    //     return axiosApiInstance.delete(path);
    // },
};

export default studentAttendanceStatusesApi;
